import {Helmet} from 'react-helmet-async'

export default function Licenses() {
    return (
        <div>
            <Helmet>
                <title>Licenses | Patent Cockpit</title>
            </Helmet>

            <div className="header-row">
                <h2>Licenses</h2>
            </div>
            <div className="main-content">
                Coming soon...
            </div>
        </div>
    )
}