import { createContext, useContext, useEffect, useState } from "react";
import { IoText } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Switch } from '@headlessui/react'
import _ from "lodash";

import { ErrorMessage } from "../Messages";
import { useAgorumObject, parentPaths } from "./backend";
import { ClockIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useLocalState } from "../settings/localStorage";

// TODO: Maybe Have a AgorumObjectProvider that provides the object and its children and its parent
// Plus, update, reload and delete functions

export interface AgorumObject {
    uuid: string;
    name: string;
    isFolder: boolean;
    contentType: string;
    inTrash?: boolean;
    description?: string;
    createDate?: string;
    updateDate?: string;
}

function defaultPreviewLink(object: Pick<AgorumObject, 'uuid'>) {
    return `/documents/browser/preview/${object.uuid}`
}

function defaultFullpageLink(object: Pick<AgorumObject, 'uuid'>) {
    return `/documents/browser/preview/${object.uuid}/fullpage`
}

const defaultPreviewBackLink = '/documents'
const defaultFullpageBackLink = '..'

const BrowserContext = createContext({
    isOpen: (uuid: string) => false as boolean,
    setOpen: (uuid: string, open: boolean) => {},

    previewLink: defaultPreviewLink,
    fullpageLink: defaultFullpageLink,
    previewBackLink: defaultPreviewBackLink,
    fullpageBackLink: defaultFullpageBackLink,

    openPath: (uuid: string, pathParts: string[]) => Promise.resolve(),
    findParent: (uuid: string) => Promise.resolve({parents: [] as {name: string, uuid: string}[]}),

    sortBy: 'text' as DocumentSortType,
    setSortBy: (sortBy: DocumentSortType) => {},
})

const pathKey = 'documents-path'

function saveOpenState(open: object) {
    localStorage.setItem(pathKey, JSON.stringify(open))
}
function loadOpenState(): object {
    const state = localStorage.getItem(pathKey)
    try {
        const openState = JSON.parse(state ?? '{}')
        //console.log({state, openState})
        return openState ?? {}
    } catch {
        return {}
    }
}

export type DocumentSortType = 'name' | 'updated'

export function DocumentSortButton() {
//export function DocumentSortButton({sortBy, setSortBy}: {sortBy: DocumentSortType, setSortBy: (sortBy: DocumentSortType) => void}) {
    const {t} = useTranslation()
    const {sortBy, setSortBy} = useBrowser()

    return (
        <Switch
            checked={sortBy === 'updated'} onChange={c => setSortBy(c ? 'updated' : 'name')}
            className='shrink-0 relative group flex w-9 h-5 rounded-full bg-white-200 border border-pcx-200 hover:border-pcx-300 hover:shadow-inner'
            title={`${t('sort-by')} ${t(sortBy)}`}
        >
            <span className={clsx(
                "group-hover:bg-white group-hover:shadow-sm border border-pcx-200 group-hover:border-pcx-300 text-slate-500 group-hover:text-pcx-500", 
                "rounded-full h-fit w-fit p-px m-px",
                "transition duration-200 ease-in-out group-data-[checked]:translate-x-4"
            )}>
                {sortBy === 'name' ? <IoText className="size-2.5 m-px" /> : <ClockIcon className="size-3" />}
            </span>
            <span className="sr-only">Sort by {sortBy}</span>
        </Switch>
    )
}


export function BrowserProvider({
    children, 
    previewLink = defaultPreviewLink, 
    fullpageLink = defaultFullpageLink,
    previewBackLink = defaultPreviewBackLink,
    fullpageBackLink = defaultFullpageBackLink
}) {
    const [sortBy, setSortBy] = useLocalState('documents-sort-by', 'text')

    const [open, setOpen] = useState(loadOpenState)
    useEffect(() => saveOpenState(open), [open])

    //console.log({open})

    function isOpen(uuid: string) {
        return open[uuid] === true
    }

    function _setOpen(uuid: string, _open: boolean) {
        setOpen(os => ({...os, [uuid]: _open}))
    }

    async function openPath(uuid: string, pathParts: string[]) {
        const result = await parentPaths(uuid, pathParts)
        const newOpen = _(result.parents ?? []).map(p => [p.uuid, true]).fromPairs().value()
        setOpen(os => ({...os, ...newOpen}))
    }

    async function findParent(uuid: string) {
        return await parentPaths(uuid, [])
    }

    return <BrowserContext.Provider value={{
        isOpen, setOpen: _setOpen,
        previewLink, fullpageLink,
        previewBackLink, fullpageBackLink,
        openPath, findParent,
        sortBy: sortBy as DocumentSortType, setSortBy,
    }}>
        {children}
    </BrowserContext.Provider>
}

export function useBrowser() {
    return useContext(BrowserContext)
}

const AgorumObjectContext = createContext({
    object: undefined as (AgorumObject & Record<string, any>) | undefined,
    parent: undefined as string | undefined,
    fallback: undefined as string | undefined,
    update: async (data: Record<string, any>) => Promise.resolve({}),
    revert: () => Promise.resolve({} as {target?: string}),
    deleteObject: () => Promise.resolve({}),
    isLoading: false,
    error: undefined as ErrorMessage | undefined,
    showDate: false as boolean,
})


export function AgorumObjectProvider(
    {uuid, parent, properties, fallback, children, showDate = false}: 
    {uuid?: string, parent?: string, properties?: string[], fallback?: string, children: React.ReactNode, showDate?: boolean}
) {
    const {t} = useTranslation()

    const { findParent } = useBrowser()

    const {object, revert, isLoading, error, update, deleteObject} = useAgorumObject({uuid, properties})

    const [_parent, set_parent] = useState(parent)
    useEffect(() => {
        if (!_parent) {
            findParent(uuid).then(res => set_parent(res?.parents?.[0]?.uuid))
        }
    })
    
    if (isLoading)
        return <div>{fallback ?? t('loading')}...</div>

    // @ts-ignore update
    return <AgorumObjectContext.Provider value={{object, parent: _parent, update, revert, deleteObject, isLoading, error, fallback, showDate}}>
        {children}
    </AgorumObjectContext.Provider>
}

export function useObject() {
    return useContext(AgorumObjectContext)
}

const AgorumRootContext = createContext({
    root: undefined as string | undefined,
})

export function AgorumRoot({root, children}) {
    const {t} = useTranslation()
    const {object, revert, isLoading, error, update, deleteObject} = useAgorumObject({root})

    if (isLoading)
        return <div>{t('loading')}...</div>

    return (
        <AgorumRootContext.Provider value={{ root: object.uuid }}>
            {/* @ts-ignore update */}
            <AgorumObjectContext.Provider value={{ object, update, revert, deleteObject, isLoading, error }}>
                {children}
            </AgorumObjectContext.Provider>
        </AgorumRootContext.Provider>
    )
}

export function useRoot() {
    return useContext(AgorumRootContext)
} 
