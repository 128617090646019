import React, { useContext } from "react";
import _ from "lodash";

import { useComments } from "../comments/CommentsProvider";
import { Family, Member } from "../patents/patents";
import { useFilter } from "./Filter";
import {
    FilteredPatents,
    countries_filter,
    empty_value,
    filterActiveOnly,
    filterByAgent,
    filterByCountry,
    filterByEmptyTags,
    filterByReference,
    filterByTags,
    filterPatentsByText,
    free_text_filter,
    inventor_filter,
    owner_filter,
    reference_filter,
    tag_filter,
} from "./utils";
import { FilterConfig } from "./Filter";
import { usePatents } from "../patents/PatentsProvider";
import { useAgents } from "../agents/AgentsProvider";
import { useTags } from "../tags/TagsProvider";

const FilteredPatentsContext = React.createContext({
    families: [] as Family[],
    members: [] as Member[],

    familyById: {} as Record<number, Family>,
    membersByFamilyId: {} as Record<number, Member[]>,
    memberById: {} as Record<number, Member>,

    fullContext: {
        families: [] as Family[],
        members: [] as Member[],

        familyById: {} as Record<number, Family>,
        membersByFamilyId: {} as Record<number, Member[]>,
        memberById: {} as Record<number, Member>,
    }
})

export function FilteredPatentsProvider({ children }) {
    const { showActiveOnly, activeFilters, filterText } = useFilter()
    const { families, members, membersByFamilyId: allMembersByFamilyId, } = usePatents();
    const { tagsLookup } = useTags();
    const { agentsLookup, agentsByMemberId } = useAgents();
    const { commentsLookUp } = useComments();

    const fullContext = (filterText !== "" ? [{name: free_text_filter, isActive: true, config: filterText}, ...activeFilters] : activeFilters)
        .filter(({ isActive }) => isActive)
        .reduce(
            (ctxt: FilteredPatents, filter: FilterConfig) => {
                switch (filter.name) {
                    case tag_filter:
                        if (filter.config === 'empty')
                            return filterByEmptyTags(ctxt, tagsLookup);
                        else
                            return filterByTags(ctxt, filter.config, tagsLookup);
                    case countries_filter:
                        return filterByCountry(ctxt, filter.config);
                    //case active_only_filter:
                    //    return filterActiveOnly(ctxt, allMembersByFamilyId);
                    case owner_filter:
                    case inventor_filter:
                        if (filter.config === 'empty') // TODO: remove
                            return filterByAgent(ctxt, [empty_value], filter.name, agentsLookup, agentsByMemberId)
                        else
                            return filterByAgent(ctxt, filter.config ?? [], filter.name, agentsLookup, agentsByMemberId)
                    case reference_filter:
                        return filterByReference(ctxt, filter.config);
                    case free_text_filter:
                        return filterPatentsByText(ctxt, filter.config, commentsLookUp);
                    // TODO: add other filters
                    default:
                        return ctxt;
                }
            },
            { families, members }
        );

    const context = showActiveOnly
        ? filterActiveOnly(fullContext, allMembersByFamilyId)
        : fullContext


    const value = {
        ...indexedContext(context),
        fullContext: indexedContext(fullContext),
    }

    return (
        <FilteredPatentsContext.Provider value={value}>{children}</FilteredPatentsContext.Provider>
    );
}

function indexedContext(context: FilteredPatents) {
    const familyById = _.keyBy(context.families, (f) => f.patentFamilyId);
    const membersByFamilyId = _.groupBy(context.members, (m) => m.patentFamilyId);
    const memberById = _.keyBy(context.members, (m) => m.familyMemberId);
    return { ...context, familyById, membersByFamilyId, memberById}
}

export function useFilteredPatents(includeStopped = false) {
    const ctx = useContext(FilteredPatentsContext);
    if (includeStopped) return ctx.fullContext;
    return ctx;
}

export function useAllOrFilteredPatents(applyGlobalFilters = true) {
    const allContext = usePatents();
    const filteredContext = useFilteredPatents();
    return applyGlobalFilters ? filteredContext : allContext;
}
