import { useEffect, useState } from "react"

function storeValue(key: string, value: string) {
    window.localStorage.setItem(key, value)
}
function loadValue(key: string){
    const value = window.localStorage.getItem(key)
    return value === null ? undefined : value
}

export function useLocalState(key: string, defaultValue: string[]): [string[], (value: string[]) => void];
export function useLocalState(key: string, defaultValue: string): [string, (value: string) => void];
export function useLocalState(key: string, defaultValue: number): [number, (value: number) => void];
export function useLocalState(key: string, defaultValue: boolean): [boolean, (value: boolean) => void];
export function useLocalState(key: string, defaultValue: string | number | boolean | string[]) {
    const isString = typeof defaultValue === "string"
    const isNumber = !isString && typeof defaultValue === "number"
    const isStringArray = !isString && !isNumber && Array.isArray(defaultValue)
    const _fromString = 
        isString ? ((s: string) => s) : 
        isNumber ? (s: string) => parseInt(s) : 
        isStringArray ? (s: string) => (s === '' ? [] : s.split(",")) :
        (s) => s === "true"
    const _toString = isStringArray ? t => t.join(",") : t => t?.toString()
    const [value, setValue] = useState(() => _fromString(loadValue(key) ?? _toString(defaultValue)))
    const value_s = _toString(value)
    useEffect(() => storeValue(key,value_s), [key, value_s])
    return [value, setValue]
}

export function storeFilterValue(value) {
  return window.localStorage.setItem("products-filter-value", value);
}

export function loadFilterValue() {
  return window.localStorage.getItem("products-filter-value") ?? "";
}