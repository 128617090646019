import { useMemo, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Formik, Form, Field } from "formik"
import _ from 'lodash'

import { TaskProps, dueDateDistance, augment } from './utils'
import { ToggleButtonField } from "../components/ToggleButton"
import DeleteButton from "../components/DeleteButton"
import { useTasks } from "./TasksProvider"
import clsx from "clsx"
import { useTrademarks } from "../trademarks/TrademarksProvider"
import { useUserSettings } from '../user/UserSettingsProvider'
import { plusPeriod } from "../utils/dates"
import Modal from "../components/Modal"
import { SortButton } from "../patents/datawizard/DataWizard"
import { IconEdit, IconPlus } from "../components/icons"
import { useAuth } from "../user/Auth"
import { DatePicker } from "../components/DatePicker"
import { emptyStringAsUndefined, firstUpperCase } from "../utils/strings"
import { useLocalState } from "../settings/localStorage"
import { ReferenceLinker, ReferenceProps } from "../components/input/ReferenceLinker"
import { getReference } from "../components/input/references"
import { usePatents } from "../patents/PatentsProvider"
import Fuse from "fuse.js"
import { useAllOrFilteredPatents } from "../filter/FilteredPatents"
import { useAllOrFilteredTrademarks } from "../filter/FilteredTrademarks"

export default function Tasks() {
    const {t} = useTranslation()

    const [applyGlobalFilter, setApplyGlobalFilter] = useLocalState('tasks-apply-global-filter', false)
    const {familyById, memberById} = useAllOrFilteredPatents(applyGlobalFilter)
    const {trademarkById} = useAllOrFilteredTrademarks(applyGlobalFilter)

    const {tasks} = useTasks()

    const {user: {name}} = useAuth()
    const {users} = useUserSettings()

    const [assignedTo, setAssignedTo] = useLocalState('tasks-assigned-to', '')

    const [showDoneState, setShowDoneState] = useLocalState('tasks-done-state', 'not-done')
    const filterIsDone = showDoneState === 'all-tasks' ? undefined : showDoneState === 'done'

    const [filterText, setFilterText ] = useLocalState('tasks-filter-text', "")
    const ft = filterText.toLowerCase()

    const [sortOrder, setSortOrder] = useLocalState('tasks-sort-order', 1)
    const [sortField, setSortField] = useLocalState('tasks-sort-field', "title")

    const filterUsers = userNames(users).filter(({user}) => user !== name)

    const preFiltered = useMemo(() => _(tasks)
        .filter(task => assignedTo === "" || task.assignedTo === assignedTo)
        .filter(task => filterIsDone === undefined || task.done === filterIsDone)
        .map(task => ({ ...task, ...augment(task, familyById, memberById, trademarkById, users) }))
        .filter(task => !applyGlobalFilter || task.internalReference !== undefined)
        .value(),
        [applyGlobalFilter, assignedTo, filterIsDone, tasks, familyById, memberById, trademarkById, users])

    const taskFuse = useMemo(() =>
        new Fuse(preFiltered, {
            ignoreLocation: true,
            threshold: 0.3,
            keys: ['title', 'internalDueDate', 'dueDate', 'assignedTo', 'internalReference', 'comment'],
        }), [preFiltered])

    const sortFunction =
        sortField === 'assignedTo'
        ? (task: AugmentedTaskProps) => task.displayName
        : sortField === 'dueDate'
        ? (task: AugmentedTaskProps) => task.dueDate + '_' + task.internalDueDate
        : sortField === 'internalDueDate'
        ? (task: AugmentedTaskProps) => task.internalDueDate + '_' + task.dueDate
        : (task: AugmentedTaskProps) => task[sortField]

    const displayedTasks = _(ft === "" ? preFiltered : taskFuse.search(ft).map(({item}) => item))
        .sortBy(sortFunction)
        .tap(tasks => sortOrder === -1 ? tasks.reverse() : tasks)
        .value()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('tasks')} | Patent Cockpit</title>
            </Helmet>
            <div className="portfolio-menu max-w-full">
                <h2 className='modern-h2'>{t('tasks')}</h2>
            </div>
            <div className="main-content bg-pcx-100 pt-0">
                <div className="w-full md:w-fit">
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 pb-3 pr-0 text-pcx-800">
                        <select className="form-select bg-transparent py-1" value={assignedTo} onChange={e => setAssignedTo(e.target.value)}>
                            <option value="">{t('all')} {t('users')}</option>
                            <option value={name}>{t('only-mine')}</option>
                            {filterUsers.map(u => <option key={u.user} value={u.user}>{u.displayName}</option>)}
                        </select>
                        <select className="form-select bg-transparent py-1" value={showDoneState} onChange={e => setShowDoneState(e.target.value)}>
                            {['not-done', 'all-tasks', 'done'].map(state =>
                                <option key={state} value={state}>{firstUpperCase(t(state))}</option>
                            )}
                        </select>
                        <input 
                            value={filterText} onChange={e => setFilterText(e.target.value)} 
                            type="search" className="form-input bg-transparent py-1" placeholder={t('search') + '...'} 
                        />
                        <label className="flex flex-row gap-1.5 items-center">
                            <input type="checkbox" className="form-checkbox" checked={applyGlobalFilter} onChange={e => setApplyGlobalFilter(e.target.checked)} />
                            <div>{t('apply-global-filter')}</div>
                        </label>
                        <Link to="post" className={clsx(displayedTasks?.length === 0 ? "btn-primary " : "btn-secondary", "ml-auto rounded font-normal text-base whitespace-nowrap")}>
                            <IconPlus className="w-5 h-5 inline" /> {t('add-task')}
                        </Link>
                    </div>
                    <table className="border-collapse bg-white rounded-lg overflow-hidden shadow max-md:w-full md:min-w-full">
                        <thead className="text-left hidden md:table-header-group">
                            <tr className="border-b-2 border-pcx-200">
                                <th className="md:pl-3 pr-3 pt-3 pb-3 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap" />
                                {['title', 'internalDueDate', 'dueDate', 'assignedTo', 'reference']
                                    .map(title => ({ title, display: t(title) }))
                                    .map(({ display, title }) =>
                                        <th key={title}
                                            className="md:pl-3 pr-3 pt-3 pb-3 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                                        >
                                            {display} <SortButton {...{ searchField: title, sortField, setSortField, sortOrder, setSortOrder }} />
                                        </th>)
                                }
                                <th className="table-cell">{/* For the actions */}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hidden last:table-row">
                                <td colSpan={6} className="text-slate-600 font-semidbold text-xl p-3">{t('no-tasks')}</td>
                            </tr>
                            {displayedTasks.map(task => <TaskRow key={task.taskId} task={task} />)}
                        </tbody>
                    </table>
                </div>
            </div>
            <Outlet />
        </>
    )
}

function userNames(users: Record<string, {displayName?: string}>) {
    return _(users)
        .toPairs()
        .map(([user, settings]) => ({user, displayName: settings?.displayName ?? user}))
        .sortBy(({displayName}) => displayName.toLowerCase())
        .value()
}

type AugmentedTaskProps = {
    internalReference?: string;
    to?: string;
    displayName: string;
} & TaskProps

function TaskRow({task}: {task: AugmentedTaskProps}) {
    const {t} = useTranslation()

    const {deleteTask} = useTasks()
    const {dueDate, internalDueDate, assignedTo, title, done} = task

    const [showDoneModal, setShowDoneModal] = useState(false)
    const [showComment, setShowComment] = useState(false)

    let link = null
    if (task.to !== undefined && task.internalReference !== undefined) {
        const {internalReference, to} = task
        link = <LinkTo {...{to, internalReference}} />
    }
    const today = new Date()

    const checkbox = <input type="checkbox" checked={done} onChange={() => setShowDoneModal(s => !s)} className="form-checkbox" />

    const title_row = <button 
        className="w-full text-left font-semibold text-pcx-600 overflow-hidden text-ellipsis" 
        onClick={() => setShowComment(s => !s)}
        title={title} 
    >{title}</button> 

    const internal_due_date = <ShowDate today={today} date={internalDueDate} done={done}/>
    const due_date = <ShowDate today={today} date={dueDate} done={done}/>
    const assigned_to = <span title={assignedTo}>{task.displayName}</span>

    const data = [
        {content: checkbox, className: "text-center"},
        {content: title_row, className: 'whitespace-nowrap max-w-md'}, 
        {content: internal_due_date, className: 'tabular-nums whitespace-nowrap'},
        {content: due_date, className: 'tabular-nums whitespace-nowrap'},
        {content: assigned_to },
        {content: link}
    ] as {content: any, className?: string}[]

    const actions = (
        <div className="flex flex-row gap-2">
            <Link to={`post?taskId=${task.taskId}`} className="btn-secondary w-6 h-6 p-0.5">
                <IconEdit />
            </Link>
            <DeleteButton className="btn-warn text-red-900 w-6 h-6 p-0.5" deleteAction={() => deleteTask(task)} />
        </div>
    ) 
    return <>
        <tr className="border-t first:border-t-0 border-pcx-200">
            {/* Large Screen Table Content */}
            {data.map(({ content, className }, i) =>
                <td key={i} className={clsx("md:pl-3 pr-3 py-3 hidden md:table-cell", className)}>{content}</td>)}
            <td className="p-3 hidden md:table-cell">{actions}</td>
            {/* Small Screen Table Content */}
            <td className={clsx("md:hidden flex flex-col gap-2 p-3", showComment && "pb-2")}>
                <div className="flex flex-row gap-2 items-center">
                    {checkbox}
                    <span className="max-w-xs overflow-hidden truncate">{title_row}</span>
                </div>
                <div className="flex flex-row gap-6 items-center">
                    {internal_due_date}
                    {assigned_to}
                </div>
                <div className="flex flex-row gap-2 items-center justify-between">
                    {link ?? <div />}
                    {actions}
                </div>
            </td>
        </tr>
        {showComment && <tr className="table-row bg-pcx-100">
            <td className="max-md:hidden"/>
            <td className="table-cell px-3 py-2 " colSpan={6}>
                <div className="text-slate-500 max-w-prose">
                    {emptyStringAsUndefined(task.comment) ?? t('no-comments')}
                </div>
            </td>
        </tr>}
        {showDoneModal && <tr><td><DoneModal {...{task, setShowDoneModal}} /></td></tr> }
    </>
}


export function DueDateBadge({date, done, today}: {date: string, done: boolean, today: Date}) {
  const distance = dueDateDistance(date, done, today)
  return <div className={clsx(
    "h-2 w-2 shrink-0 rounded-full", 
    distance === 'ok' ? 'bg-transparent' : 
    distance === 'soon' ? 'bg-pcx-300' : 
    distance === 'critical' ? 'bg-yellow-400' : 
    'bg-red-400')}/>
}

function ShowDate({date, done, today}: {date: string; done: boolean, today: Date}) {

    return <span className="inline-flex items-center gap-1 sm:gap-2">
        {date}
        <DueDateBadge {...{date, done, today}} />
    </span>
}

function DoneModal({task, setShowDoneModal}: {task: TaskProps, setShowDoneModal: (arg: boolean) => void}) {
    const {t} = useTranslation()
    const {postTask} = useTasks()
    const isDone = task.done

    function cancel() {
        setShowDoneModal(false)
    }

    function toggleDone() {
        postTask({ ...task, done: !task.done })
            .finally(() => setShowDoneModal(false))
    }

    return (
        <Modal escAction={cancel}>
            <div className="bg-white">
                <h3 className="px-4 pt-4 mb-2">{t(isDone ? 'set-to-open-long' : 'set-to-done-long')}</h3>
                <h4 className="px-4">
                    {task.title}
                </h4>
                {task.comment && <div className="mt-2 px-4 text-slate-500 max-w-prose">
                    {task.comment}
                </div>}
                <div />
                <div className="mt-4 flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                    <button className="btn-primary" onClick={toggleDone}>{t(isDone ? 'set-to-open' : 'set-to-done')}</button>
                    <button className="btn-secondary" onClick={cancel}>{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

function LinkTo({to, internalReference}: {to: string, internalReference: string}) {
    return <Link to={to} className="text-pcx-600 hover:text-pcx-800 font-medium whitespace-nowrap">
        {internalReference}
    </Link>
}

export function PostTask() {
    const {t} = useTranslation()

    const {user: {name}} = useAuth()
    const {users} = useUserSettings()
    const allUsers = userNames(users)

    const {memberById, familyById} = usePatents()
    const {trademarkById} = useTrademarks()

    const {taskById, postTask} = useTasks()

    //const {id} = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    function findTask() {
        const _taskId = searchParams.get('taskId')
        //const taskId = parseInt(id)
        const taskId = parseInt(_taskId)

        const task = taskById[taskId]
        if (task === undefined) return undefined

        const reference = getReference(task, familyById, memberById, trademarkById)
        return {...task, reference}
    }

    function initial(): TaskProps & {reference: ReferenceProps} {
        const today = new Date()
        const entity = searchParams.get('entity')
        const _entityId = searchParams.get('entityId')
        const entityId = parseInt(_entityId)
        const dueDate = searchParams.get('dueDate') ?? plusPeriod(today, { months: 3 })
        const internalDueDate = (plusPeriod(dueDate, { months: -1 }))

        const title = searchParams.get('title') ?? ''
        const comment = searchParams.get('comment') ?? ""

        return {
            title,
            comment,
            dueDate,
            internalDueDate,
            reference: getReference({entity, entityId}, familyById, memberById, trademarkById),
            done: false,
            assignedTo: name,
        }
    }

    const initialValues = findTask() ??  initial()
    const isAdding = !('taskId' in initialValues)

    function validate(values: any) {
        const errors = {}
        if (values.title === "") {
            errors['title'] = t('required')
        }
        if (values.internalDueDate && values.dueDate && values.internalDueDate > values.dueDate) {
            errors['internalDueDate'] = t('internal-due-date-after-due-date')
        }
        return errors
    }

    //console.log({initialValues})

    return (
        <Modal>
            <Formik 
                initialValues={initialValues}
                onSubmit={values => {
                    const task = {...values, ...values.reference, assignedTo: values.assignedTo === "" ? undefined : values.assignedTo}
                    postTask(task)
                    navigate(-1)
                }}
                validate={validate}
                enableReinitialize
            >{({errors, touched}) => {
                return <Form>
                <div className="grid grid-cols-2 gap-4 p-4">
                    <h3 className="col-span-2">{isAdding ? t('add-task') : t('edit-task')}</h3>

                    <div className="col-span-2 flex flex-row gap-4">
                        <label className="grow">
                            <div className="label mb-1">{t('title')}</div>
                            <Field type="text" name="title" className="form-input w-full" autoFocus />
                        </label>

                        <label>
                            <div className="label mb-1">{t('done')}</div>
                            <ToggleButtonField  name="done" />
                        </label>

                    </div>

                    <label className="col-span-2 w-full">
                        <div className="label mb-1">{t('comment')}</div>
                        <Field as="textarea" name="comment" className="w-full form-textarea h-36" />
                    </label>

                    <label className="max-sm:col-span-2">
                        <div className="label mb-1">{t('internalDueDate')}</div>
                        <Field name="internalDueDate" className="form-input py-1.5" as={DatePicker} />
                    </label>

                    <label className="max-sm:col-span-2">
                        <div className="label mb-1">{t('dueDate')}</div>
                        <Field name="dueDate" className="form-input py-1.5" as={DatePicker} />
                    </label>

                    {(touched.internalDueDate || touched.dueDate) && errors.internalDueDate &&
                        // @ts-ignore
                        <div className="col-span-2 text-red-700 text-sm">{errors.internalDueDate}
                        </div>}

                    <label className="col-span-2 w-full">
                        <div className="label mb-1">{t('assignedTo')}</div>
                        <Field name="assignedTo" className="form-select py-1.5 w-full" as="select">
                            <option value="">{t('all')}</option>
                            {allUsers.map(({ user, displayName }) =>
                                <option key={user} value={user}>{displayName}</option>)}
                        </Field>
                    </label>

                    <label className="col-span-2 w-full">
                        <div className="label mb-1">{t('reference')}</div>
                        <Field name="reference" as={ReferenceLinker} />
                    </label>

                </div>
                <div className="flex flex-row-reverse bg-pcx-200 gap-4 p-4">
                    <input type="submit" value={t("save")} className="btn-primary" />
                    <Link
                        // @ts-ignore
                        to={-1}
                        className="btn-secondary"
                    >{t('cancel')}</Link>
                </div>
                </Form>
            }}</Formik>
        </Modal>
    )
}