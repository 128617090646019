 import { unstable_usePrompt } from 'react-router-dom';
 

export function usePrompt(message: string, when = true) {
  unstable_usePrompt({
    message,
    when: ({ currentLocation, nextLocation }) =>
      when &&
      currentLocation.pathname !== nextLocation.pathname,
  });
}