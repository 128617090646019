import { Helmet } from "react-helmet-async"
import { Link, Outlet } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import Image from "../components/Image"
import { Invention, useInventions } from "./InventionsProvider"
import { DeleteButton } from "../components/edit-table"
import { IconEdit } from "../components/icons"
import { useRoles } from "../user/Auth"
import { BreadCrumbs } from "../components/BreadCrumbs"

function InventionRow({
    inventionId, 
    reference,
    referenceDate, 
    title, 
    summary,
}: Invention) {
    const {t} = useTranslation()

    const {delInvention, inventorById, inventorsByInventionId} = useInventions()
    const {isEditUser} = useRoles()

    function inventorName({agentId}: {agentId?: number}) {
        return `${inventorById[agentId]?.lastName}, ${inventorById[agentId]?.firstName}`
    }
    const inventionInventors = _(inventorsByInventionId[inventionId] ?? [])
        .sortBy(i => inventorName(i))
        .value()

    return (
        <div className="p-4 bg-white rounded-lg shadow max-w-3xl flex flex-row sm:flex-row">
            <div className="max-h-full min-h-[8rem] w-32 sm:w-40 float-left pr-4 shrink-0">
                <Image {...{entity: "invention", isEditable: isEditUser, entityId: inventionId, title: `${reference}: ${title}`}}/>
            </div>

            <div className="grow flex flex-col min-w-0"> {/* KEEP min-w-0 to make h4 truncatable */}
                <div className="flex flex-col sm:flex-row justify-between gap-1">
                    <Link to={reference} className="overflow-hidden">
                        <h4 className="whitespace-nowrap truncate text-ellipsis">{reference}: {title}</h4>
                    </Link>

                    <div className="flex flex-row gap-2 float-right pl-4 shrink-0">
                        <div className="grow text-right whitespace-nowrap text-slate-500">{referenceDate}</div>
                        {/* Note, the path is swapped to make sure the modal is over the right background */}
                        {isEditUser && <>
                            <Link title={t('edit')} to={`edit/${reference}`} className="btn-primary w-6 h-6 p-1"><IconEdit /></Link>
                            <DeleteButton {...{ small: true, del: () => delInvention(inventionId) }} />
                        </>}
                    </div>
                </div>

                <Link to={reference}>
                    <div className="text-slate-700 mt-2 mb-3 grow line-clamp-5 text-ellipsis overflow-hidden" title={summary}>
                        {summary}
                    </div>
                </Link>

                <div className="grow" />

                <div className="flex flex-wrap gap-x-2 gap-y-1 text-sm">{inventionInventors.map(i =>
                    <div className="ribbon w-fit whitespace-nowrap" key={i.agentId}>{inventorName(i)}</div>
                )}</div>
            </div>
        </div>
    )
}

export default function InventionPortfolio() {
    const {inventions} = useInventions()
    const {isEditUser} = useRoles()
    const {t} = useTranslation()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('inventions')} | Patent Cockpit</title>
            </Helmet>

            <div className="flex flex-row gap-2 items-center max-w-3xl">
                <BreadCrumbs parts={[t('inventions')]} />
                <div className="grow" />
                {isEditUser && <Link className="btn-primary pt-1 pb-1 px-2 text-sm" to="add">{t('add-invention')}</Link>}
            </div>
            <div className="main-content flex flex-col gap-2 bg-pcx-100 pt-0">
                {inventions.length === 0 &&
                    <div className="text-slate-500 text-lg p-6">
                        {t('add-invention-instruction')}
                    </div>
                }
                {inventions
                    .sort((a, b) => b.referenceDate.localeCompare(a.referenceDate))
                    .map(inv => <InventionRow key={inv.inventionId} {...inv} />)}
            </div>
            <Outlet />
        </>
    )
}