import { createContext, useContext } from "react";
import {
    useQuery,
} from '@tanstack/react-query'
import { getFxPairs } from "../backend";
import { DummyFxConverter, RealFxConverter, FxConverter } from "./fx";

const FxContext = createContext({
    fxConverter: new DummyFxConverter() as FxConverter,
});

export function FxProvider({ children }: { children: React.ReactNode }) {

    const {data: fx_pairs} = useQuery<Record<string, number>>({queryKey: ['fx'], queryFn: () => getFxPairs()})

    const fxConverter = fx_pairs === undefined ? new DummyFxConverter() : new RealFxConverter(fx_pairs)

    return <FxContext.Provider value={{fxConverter}}>
        {children}
    </FxContext.Provider>
}

export function useFxContext() {
    return useContext(FxContext)
    
}