import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { Link, Outlet, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import { Formik, Form, Field } from "formik"
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import _, { Dictionary } from 'lodash'

import { IconEdit } from "../components/icons";
import DeleteButton from "../components/DeleteButton";
import { useFxContext } from '../forecast/FxProvider'
import { ReferenceLinker } from '../components/input/ReferenceLinker'
import { getReference, isEquivalent } from '../components/input/references'
import { CostLink, useCosts } from './CostsProvider'
import Modal from '../components/Modal'
import { useSingleTeamMangement } from '../Management'
import { DatePicker } from '../components/DatePicker'
import { family_member, supportedCurrencies } from '../data'
import { useLocalState } from '../settings/localStorage'
import { Family, Member } from '../patents/patents'
import { CostItem } from './CostsProvider'
import {  familyUrl, memberUrl } from '../patents/utils'
import { emptyStringAsUndefined } from '../utils/strings'
import { exportCosts as exportCostsAction, AccumulationConfig, accumulateCosts } from './utils'
import { SuggestedInput } from '../components/input/SuggestedInput'
import { CostItemDetails } from './CostsTable'
import { XMarkIcon } from '@heroicons/react/20/solid'
import ToggleButton from '../components/ToggleButton'
import { useFilteredPatents } from '../filter/FilteredPatents'
import { CostsGraph, CostsMainDriversGraph } from './CostsGraph'
import { SortButton } from '../patents/datawizard/DataWizard'
import { usePatents } from '../patents/PatentsProvider'
import { useRoles } from '../user/Auth'

const groupBys = [
    'invoice-number',
    'cost-center',
    'patentFamilyReference',
    'familyMemberReference',
    'country',
]

interface CostRowProps {
    reference: string;
    url: string;
    total: number;
    byYear: Record<number, number>;
    costIds: Dictionary<number[]>;
}

function byInvoice(costs: CostItem[], linkByCostId: Record<number, CostLink>, memberById: Record<number, Member>, config: AccumulationConfig): CostRowProps[] {
    return _(costs)
        .groupBy(c => c.reference)
        .mapValues((costs: CostItem[], reference) => {
            const members = costs.map(c => linkByCostId[c.costId]).filter(l => l?.entity === family_member).map(l => memberById[l.entityId]).filter(Boolean)
            const result = members.length > 0 ? {
                reference,
                url: memberUrl(members[0]),
                ...accumulateCosts(costs, config)
            } : null
            //console.log({costs, result})
            return result
        })
        .values().filter(Boolean).value()
}

function byCostCenter(costs: CostItem[], linkByCostId: Record<number, CostLink>, memberById: Record<number, Member>, config: AccumulationConfig): CostRowProps[] {
    return _(costs)
        .groupBy(c => c.costCenter ?? '-')
        .mapValues((costs: CostItem[], reference) => {
            const members = costs.map(c => linkByCostId[c.costId]).filter(l => l?.entity === family_member).map(l => memberById[l.entityId]).filter(Boolean)
            return members.length > 0 ? {
                reference,
                url: memberUrl(members[0]),
                ...accumulateCosts(costs, config)
            }: null
        })
        .values().filter(Boolean).value()
}

function byFamily(families: Family[], membersByFamilyId: Record<number, Member[]>, costsPerMemberId: Record<number, CostItem[]>, config: AccumulationConfig): CostRowProps[] {
    return families.map(f => {
        const members = membersByFamilyId[f.patentFamilyId] ?? []
        const costs = members.flatMap(m => costsPerMemberId[m.familyMemberId] ?? [])
        return {
            reference: f.internalReference,
            url: familyUrl(f),
            ...accumulateCosts(costs, config),
        }
    })
}

function byMember(members: Member[], costsPerMemberId: Record<number, CostItem[]>, config: AccumulationConfig): CostRowProps[] {
    return members.map(m => {
        const costs = costsPerMemberId[m.familyMemberId] ?? []
        return {
            reference: m.internalReference,
            url: memberUrl(m),
            ...accumulateCosts(costs, config),
        }
    })
}

function byCountry(members: Member[], costsPerMemberId: Record<number, CostItem[]>, config: AccumulationConfig): CostRowProps[] {
    return _(members)
        .groupBy(m => m.countryCode)
        .mapValues((members: Member[], countryCode) => {
            const costs = members.flatMap(m => costsPerMemberId[m.familyMemberId] ?? [])
            return {
                reference: countryCode,
                url: memberUrl(members[0]),
                ...accumulateCosts(costs, config),
            }
        })
        .values()
        .value()
}


export function Costs() {
    const {t} = useTranslation()

    const {team} = useSingleTeamMangement()
    const {isEditUser} = useRoles()
    const currency = team?.currency ?? 'EUR'
    const {fxConverter} = useFxContext()
    const {families, members, membersByFamilyId, memberById} = useFilteredPatents()
    const {costsByMemberId, costs, linkByCostId} = useCosts()

    const [inclVat, setInclVat] = useLocalState('costs-incl-vat', false)
    const [groupBy, setGroupBy] = useLocalState('costs-group-by', groupBys[0])
    const [sortBy, setSortBy] = useLocalState('costs-sort-by', 'reference')
    const [sortOrder, setSortOrder] = useLocalState('costs-sort-order', 1)

    const config = {fxConverter, currency, inclVat}

    const rows: CostRowProps[] = groupBy === 'invoice-number'
        ? byInvoice(costs, linkByCostId, memberById, config)
        : groupBy === 'cost-center'
        ? byCostCenter(costs, linkByCostId, memberById, config)
        : groupBy === 'patentFamilyReference'
        ? byFamily(families, membersByFamilyId, costsByMemberId, config)
        : groupBy === 'familyMemberReference'
        ? byMember(members, costsByMemberId, config)
        : byCountry(members, costsByMemberId, config)

    const sortedRows = _(rows)
        .filter(r => r.total > 0)
        .sortBy(r => _.get(r, sortBy) ?? 0)
        .thru(rs => sortOrder === 1 ? rs : rs.reverse())
        .value()

    //console.log({sortedRows, sortBy})
    // TODO: should we make sure that we also "fill holes" in the years?
    const years = _(rows).flatMap(r => Object.keys(r.byYear)).uniq().sortBy().reverse().value()

    const format = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})

    const total = {
        total: _.sumBy(sortedRows, r => r.total),
        byYear: _.fromPairs(years.map(y => [y, _.sumBy(sortedRows, r => r.byYear[y] ?? 0)]))
    }
    
    const totalRow = (
        <tr className="border-b-2 border-pcx-300">
            <td className="py-2 px-3 text-pcx-600 font-semibold">{t('total')}</td>
            <td className="py-2 px-3 text-right font-semibold">{format.format(total.total)}</td>
            {years.map((y, i) =>
                <td key={i} className="py-2 px-3 text-right font-semibold">{format.format(total.byYear[y])}</td>
            )}
        </tr>
    )

    function exportCosts() {
        exportCostsAction(costs, linkByCostId, memberById, team.displayName ?? team.realm)
    }

    const mainDrivers = _(rows).map(r => ({name: r.reference, total: r.total})).sortBy('total').reverse().slice(0, 10).value()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('costs')} | Patent Cockpit</title>
        </Helmet>
        <div className={"portfolio-menu max-w-2xl items-baseline"}>
            <h2 className="modern-h2">
                {t('costs')}
            </h2>
            <span className='text-slate-500 text-sm mr-auto'>({t('in-currency', { currency })})</span>
        </div>
        <div className="main-content bg-pcx-100 pt-2">

            <div className='w-fit'>
                <div className='flex flex-col-reverse sm:flex-row gap-2 items-end sm:items-center justify-between max-w-3xl'>
                    <label>
                        <span className='mr-2 text-pcx-600'>{t('group-by')}:</span>
                        <select className='form-select py-px bg-transparent text-pcx-600' value={groupBy} onChange={e => setGroupBy(e.target.value)}>
                            {groupBys.map(g => <option key={g} value={g}>{t(g)}</option>)}
                        </select>
                    </label>
                    <CostActionMenu {...{ inclVat, setInclVat, exportCosts }} />
                </div>

                {sortedRows.length > 0 ?
                    <div className='py-4 max-w-5xl flex flex-col md:flex-row gap-8'>
                        <div className='min-w-xs max-w-xs p-4 bg-white rounded-lg shadow' key={_.size(total.byYear)}>
                            <CostsGraph {...total} />
                        </div>
                        <div className='min-w-xs max-w-xs p-4 bg-white rounded-lg shadow' key={_.size(mainDrivers)}>
                            <CostsMainDriversGraph data={mainDrivers} />
                        </div>
                    </div>
                    : <div className='p-4 bg-white rounded-md shadow w-fit mt-4'>
                            <h3>{t('no-costs')}</h3>
                            <p className='mt-2 mb-4 text-slate-600'>{t('add-cost-item')}</p>
                            {isEditUser && <Link to="cost" className="btn-secondary h-fit">{t('add-cost')}</Link>}
                        </div>
                    }
            </div> 
            <div className='flex flex-row gap-4 items-start'>
                {sortedRows.length > 0 &&
                    <div className='p-4 bg-white rounded-md shadow w-fit'>

                        <table className='tabular-nums'>
                            <thead>
                                <tr className="border-b-2 border-pcx-300">
                                    {[
                                        { label: t(groupBy), fieldName: 'reference' },
                                        { label: t('total'), fieldName: 'total' },
                                        ...(years.map(y => ({ label: y, fieldName: `byYear.${y}` })))
                                    ].map(({ label, fieldName }, i) =>
                                        <th key={i}
                                            className={clsx(
                                                "md:pl-3 pr-3 pt-3 pb-3 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap",
                                                i > 0 ? 'text-right' : 'text-left',
                                            )}
                                        >
                                            {label} <SortButton {...{ searchField: fieldName, sortField: sortBy, setSortField: setSortBy, sortOrder, setSortOrder }} />
                                        </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {totalRow}
                                {sortedRows.map((r, i) =>
                                    <tr key={i} className="border-b-2 border-pcx-200 last:border-pcx-300">
                                        <td className="py-2 px-3">
                                            <DetailsLink className="text-pcx-600 hover:text-pcx-800 font-medium" {...{ costIds: r.costIds.total }}>
                                                {r.reference}
                                            </DetailsLink>
                                        </td>
                                        <td className="py-2 px-3 text-right">
                                            <DetailsLink {...{ costIds: r.costIds.total }}>
                                                {format.format(r.total)}
                                            </DetailsLink>
                                        </td>
                                        {years.map((y, i) =>
                                            <td key={i} className="py-2 px-3 text-right">
                                                <DetailsLink {...{ costIds: r.costIds[y] }} >
                                                    {format.format(r.byYear[y])}
                                                </DetailsLink>
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {totalRow}
                            </tfoot>
                        </table>

                    </div>}
                <Outlet />
            </div>
        </div>
    </>
}

function DetailsLink({costIds, className, children}: {children: React.ReactNode, className?: string, costIds: number[]}) {
    if ((costIds ?? []).length === 0)
        return <span>-</span>
    else
        return (
            <Link
                to={{ pathname: 'details', search: `?costId=${costIds.join(',')}` }}
                className={className ?? "hover:text-pcx-600"}
            >
                {children}
            </Link>
        )
}

function CostActionMenu({inclVat, setInclVat, exportCosts}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const urls = isEditUser ? [
        {label: 'add-cost', url: 'cost'},
        {label: 'excel-import', url: 'import'},
    ] : []

    const itemStyle = 'hover:bg-pcx-100 font-normal whitespace-nowrap block px-3 py-1 text-sm text-gray-700 hover:text-pcx-700'

    return (
        <Popover as="div" className="relative">
            <Popover.Button className="btn-secondary border-slate-400 text-pcx-600 py-px pl-3 text-base rounded inline-flex justify-center gap-1 font-normal focus:outline-0 focus:ring-0">
                {t('forecast.actions')}
                <ChevronDownIcon className='h-5 w-5 mt-0.5' aria-hidden="true" />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 right-0 mt-2 rounded-sm shadow-lg overflow-hidden bg-white border border-pcx-500 ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none">
                {urls.map(({label, url}) =>
                        <Link key={label} to={url} className={itemStyle} >
                            {t(label)}
                        </Link>
                )}
                <button className={clsx(itemStyle, 'w-full text-left')} type="button" onClick={exportCosts}>
                    {t('excel-export')}
                </button>
                <label className={clsx(itemStyle, 'inline-flex gap-2')}>
                    {t('include-vat')} <ToggleButton checked={inclVat} setChecked={setInclVat} />
                </label>
            </Popover.Panel>
        </Popover>
    )
}

export function CostItemsDetails() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const [searchParams] = useSearchParams()
    const {costById, linkByCostId} = useCosts()
    const { memberById } = usePatents()

    const costIds = (searchParams.get('costId') ?? '').split(',').map(id => parseInt(id))

    const format = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})

    const details = _(costIds)
        .map(costId => {
            const cost = costById[costId]
            const link = linkByCostId[costId]

            if (cost === undefined || link === undefined) return null

            const member = link.entity === family_member ? memberById[link.entityId] : undefined

            if (member === undefined) return null

            return { cost, member }
        })
        .filter(Boolean)
        .sortBy(({ cost }) => cost.date)
        .value()

    if (details.length === 0) return null

    return (
        <div className='p-4 bg-white rounded-lg shadow flex flex-col min-w-xs'>
            <div className='flex flex-row gap-4 justify-between px-2'>
                <h4 className='mb-4'>{t('details')}</h4>
                <Link to=".." className='h-6 w-6 text-pcx-700'><XMarkIcon /></Link>
            </div>
            {details.map(({ cost, member }) =>
                <div key={cost.costId} className='p-2 border-t-2 last:border-b-2 border-pcx-300'>
                    <div className='flex flex-row gap-1 mb-2'>
                        <Link to={memberUrl(member)} className='text-pcx-600 hover:text-pcx-800 font-medium mr-auto'>{member?.internalReference}</Link>
                        {isEditUser && <Actions {...cost} />}
                    </div>
                    <CostItemDetails {...{ cost, format }} />
                </div>
            )}
        </div>
    )
}

function Actions(cost: CostItem) {
    const {deleteCost} = useCosts()
    return <>
        <Link
            to={{ 
                pathname: '../cost',
                search: `?${createSearchParams({entity: family_member, costId: '' + cost.costId })}` 
            }} 
            className="btn-secondary w-5 h-5 p-px"
        >
            <IconEdit />
        </Link>
        <DeleteButton
            className="w-5 h-5 p-px btn-warn-secondary"
            deleteAction={() => deleteCost(cost)} />
    </>
}


// TODO: should we allow no linking? I don't think so
export function PostCost() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {team} = useSingleTeamMangement()
    const currency = team?.currency ?? 'EUR'

    const {familyById, memberById} = usePatents()
    const {costs, costById, linkByCostId, postCost, deleteCostLink, postCostLink} = useCosts()
    
    const [searchParams] = useSearchParams()

    const costId = searchParams.get('costId')
    const entity = searchParams.get('entity')
    const _limitedIds = searchParams.get('limitedIds')
    //const entityId = parseInt(searchParams.get('entityId'))

    const limitedIds = (entity && _limitedIds) ? {[entity]: _limitedIds?.split(',').map(id => parseInt(id))} : {}

    const isEdit = costId in costById

    // TODO: validate - Patent Ref, Betrag, Datum, Rechnungsnummer  non empty
    const defaultLink = getReference({entity, entityId: limitedIds[entity]?.[0]}, familyById, memberById, {})
    const initialValues = isEdit
        ? { 
            costCenter: '',
            ...costById[costId],
            link: linkByCostId[costId] ? getReference(linkByCostId[costId], familyById, memberById, {}) : defaultLink
        } : {
            date: new Date().toISOString().slice(0, 10),
            amount: 0,
            currency,
            vat: 0,
            reference: '',
            comment: '',
            costCenter: '',
            link: defaultLink,
        }

    const costCenters = _(costs).map(c => c.costCenter).filter(Boolean).uniq().sortBy().value()

    return (
        <Modal>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values: CostItem & {link: CostLink}) => {
                    const clean = {...values, costCenter: emptyStringAsUndefined(values.costCenter)}
                    //console.log({clean})
                    postCost(clean).then(async ({costId}) => {
                        if (!isEdit) {
                            //console.log({costId, ...values.link, operation: 'add'})
                            await postCostLink({costId, ...values.link})
                        } else if (!isEquivalent(values.link, initialValues.link)) {
                            if (initialValues.link) {
                                // delete old link
                                //console.log({...initialValues.link, operation: 'delete'})
                                await deleteCostLink({...initialValues.link, costId})
                            }
                            //console.log({...values.link, costId, operation: 'add'})
                            await postCostLink({costId, ...values.link})
                        }
                        navigate('..')
                    })
                }}
                validate={(values) => {
                    const errors = {}
                    if (emptyStringAsUndefined(values.link.internalReference) === undefined) {
                        errors['link'] = t('required')
                    }
                    return errors
                }}
            >{({values, isSubmitting, touched, errors}) =>
                <Form>
                    <div className='p-4 grid grid-cols-1 sm:grid-cols-2 gap-4'>
                        <h2 className='sm:col-span-2'>{t(isEdit ? 'edit-cost' : 'add-cost')}</h2>

                        <div className='sm:col-span-2 flex flex-col sm:flex-row gap-4'>
                            <div className='grow'>
                                <label htmlFor="link" className='label mb-1'>
                                    {t('familyMemberReference')} {
                                        // @ts-ignore
                                        errors.link && touched.link && <span className='text-warn-600'>{errors.link}</span>
                                    }
                                </label>
                                {!isSubmitting && 
                                    // don't render when submitting as the reference changes...
                                    <Field id="link" name="link" {...{ limitedIds, disallowNoLink: true }} as={ReferenceLinker} supportedEntities={[family_member]} />}
                            </div>

                            <div>
                                <label htmlFor="date" className='label mb-1'>{t('date')}</label>
                                <Field id="date" name="date" required className="form-input" as={DatePicker} />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="reference" className='label mb-1'>{t('invoice-number')}</label>
                            <Field id="reference" name="reference" required className="form-input" />
                        </div>

                        <div>
                            <label htmlFor="costCenter" className='label mb-1'>{t('cost-center')}</label>
                                {!isSubmitting &&
                                    // don't render when submitting as the reference changes...
                                    <Field id="costCenter" name="costCenter" {...{ className: "form-input", suggestions: costCenters }} as={SuggestedInput} />}
                        </div>

                        <div className='flex flex-row gap-4 max-sm:flex-wrap sm:col-span-2'>
                            <div>
                                <label htmlFor="amount" className='label mb-1'>{t('amount-excl-vat')}</label>
                                <Field id="amount" name="amount" type="number" required className="form-input w-36" />
                            </div>

                            <div>
                                <label htmlFor='currency' className='label mb-1'>{t('currency')}</label>
                                <Field id="currency" name="currency" className="form-select" as="select">
                                    {supportedCurrencies.map(c => <option key={c} value={c}>{c}</option>)}
                                </Field>
                            </div>

                            <div>
                                <label htmlFor="vat" className='label mb-1'>{t('vat')}</label>
                                <Field id="vat" name="vat" type="number" className="form-input w-24" />
                            </div>

                            <div>
                                <div className='label mb-1'>{t('total')}</div>
                                <div className='py-2 px-1 label'>{add(values.amount, values.vat)} {values.currency}</div>
                            </div>
                        </div>

                        <div className='sm:col-span-2'>
                            <label htmlFor="comment" className='label mb-1'>{t('comment')}</label>
                            <Field id="comment" name="comment" as="textarea" className="w-full form-textarea h-36" />
                        </div>

                    </div>
                    <div className='p-4 bg-pcx-200 flex flex-row-reverse gap-4'>
                        <input type="submit" className="btn-primary" value={t('save')} />
                        <Link to='..' className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}

function add(a: string, b: string) {
    const an = parseFloat(a)
    const bn = parseFloat(b)
    return ((isNaN(an) ? 0.0 : an) + (isNaN(bn) ? 0.0 : bn)).toFixed(2)
}