import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import { get_api_status } from "../dennemeyer_backend"
import { SingleReponse } from "../DennemeyerProvider"
import { IconSpinner } from "../../components/icons"

type ApiStatus = {
    Status: undefined | 'Ok' | 'MinorDisruptionsPossible' | 'ScheduledDownTime';
    RetryAfter: undefined | string;
    Comment: undefined | string;
    NextScheduledDownTime: undefined | { from: string, to: string };
}

export default function ApiStatusView() {
    const {t} = useTranslation()

    const [status, setStatus] = useState(undefined as undefined | SingleReponse<ApiStatus>)
    //console.log(status)

    useEffect(() => {
      if (status === undefined) {
        get_api_status().then(s => setStatus(s))
      }
    }, [status])

    if (status?.HasError) {
        console.warn(status)
    }

    const statusIcon = status?.Data?.Status === 'Ok' ? '🟢' : status?.Data?.Status === 'MinorDisruptionsPossible' ? '🟡' : '🔴'

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('api-status')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>
        <div className="main-content bg-pcx-100">
            <div className="bg-white p-4 rounded-md shadow w-fit min-w-sm">
                <div className="float-right">{status && statusIcon}</div>
                <h2 className="mb-4">{t('api-status')}</h2>
                {status?.Data 
                    ?  <div className="grid grid-cols-[auto_auto] gap-x-2 w-fit">
                        {_(status.Data).toPairs().map(([k, v]) =>
                            <>
                                <div className="font-semibold text-right text-slate-500">{k}:</div>
                                <div>{typeof v === 'string' ? v : JSON.stringify(v)}</div>
                            </>
                        ).value()}
                    </div>
                    : <div><IconSpinner className="h-8 w-8 text-pcx-500 animate-spin" /></div>
                }
            </div>
        </div>
    </>
}