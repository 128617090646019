import clsx from "clsx";
import { Link } from "react-router-dom";

import { useRoles } from "../user/Auth";

export interface EmptyStateItem {
    title: string
    description: string
    to: string
    icon: any
    background: string
}

export default function EmptyState({items, title, text}: {items: EmptyStateItem[], title?: string, text?: string}) {
    const { isEditUser } = useRoles()
    return <>
        {title && <h3 className="">{title}</h3>}
        {text && <p className="mt-1 text-slate-500">{text}</p>}
        {isEditUser && <ul className="mt-2 grid grid-cols-1 gap-6 py-6 lg:grid-cols-2">
            {items.map((item, itemIdx) => (
                <li key={itemIdx} className="flow-root">
                    <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                        <div
                            className={clsx(
                                item.background,
                                'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                            )}
                        >
                            <item.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </div>
                        <div>
                            <h4 className="text-sm">
                                <Link to={item.to} className="focus:outline-none">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <span>{item.title}</span>
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </h4>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>}
    </>
}