import { ImageEntry } from "./Image"

const filenameComparator = new Intl.Collator('en', { sensitivity: 'base' })

export function sortImages(a: ImageEntry, b: ImageEntry, entity: string) {
  //console.log(a)
  if (a.entity !== b.entity) {
    if (a.entity === entity)
      return -1
    if (b.entity === entity)
      return +1
  }
  return filenameComparator.compare(a.originalFilename, b.originalFilename)
}