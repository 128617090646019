import { Link, createSearchParams } from 'react-router-dom'

import { TaskProps } from "./utils";
import { useTasks } from "./TasksProvider";
import { IconEdit } from '../components/icons'
import { DueDateBadge } from "../tasks/Tasks"
import DeleteButton from '../components/DeleteButton'
import { useRoles } from '../user/Auth';
import { useUserSettings } from '../user/UserSettingsProvider'
import { nonEmptyString } from '../utils/strings';

export default function Task({ task, entity, entityId }: { task: TaskProps & { date: string }, entity: string, entityId: (undefined | number | string) }) {
    const { isEditUser } = useRoles()
    const { users } = useUserSettings()

    const { deleteTask } = useTasks()

    const today = new Date()
    const basicParams = {entity, entityId: '' + entityId}

    return (
        <div className="border border-pcx-200 rounded-md overflow-hidden shadow-sm">
            <div className="px-2 py-1 flex flex-row gap-1 justify-between border-b border-pcx-200 bg-pcx-200">
                <h5 className="whitespace-nowrap font-medium text-pcx-900 overflow-hidden text-ellipsis min-w-0 grow">{task.title ?? ''}</h5>
                {isEditUser && <>
                    <Link to={`task?${createSearchParams({ ...basicParams, taskId: '' + task.taskId }).toString()}`} className="h-5 w-5 p-px btn-primary shrink-0">
                        <IconEdit />
                    </Link>
                    <DeleteButton className="h-5 w-5 p-px btn-warn shrink-0" deleteAction={() => deleteTask(task)} />
                </>}
            </div>
            {nonEmptyString(task.comment) &&
                <div className="text-black px-2 py-2 border-b border-pcx-200">{task.comment}</div>}
            <div className="flex flex-row justify-between px-2 py-1 text-sm bg-pcx-100 text-pcx-900">
                <div className='font-medium'>{users[task.assignedTo]?.displayName ?? task.assignedTo}</div>
                <div className="tabular-nums flex flex-row gap-1 items-center"><DueDateBadge {...{ ...task, today }} />{task.date}</div>
            </div>
        </div>)
}