import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from 'lodash'

import {downloadImportExample, extractExcel} from "../backend";
import { useMessages } from "../Messages";
import clsx from "clsx";
import { useProducts } from "./ProductsProvider";

export default function ImportPortfolio() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { setErrorMessage } = useMessages()
    const { commodities, postCommodity } = useProducts()

    const [products, setProducts] = useState(undefined)
    const [skipped, setSkipped] = useState(undefined)

    function hasCounterpart(commodity) {
        return commodities.find(c => c.commodityReference === commodity.commodityReference && c.commodityClass === commodity.commodityClass) !== undefined
    }

    async function handleExtract(e) {
        const file = e.target.files[0];
        extractExcel(file, 'product')
            .then(res => {
                const [skipped, products] = _(res?.commodities ?? [])
                    .sortBy('commodityClass')
                    .partition(hasCounterpart)
                    .value()
                setProducts(products)
                setSkipped(skipped)
            })
            .catch(err => setErrorMessage(err.message))
    }

    async function handleImport() {
        Promise.all(products.map(p => postCommodity(p)))
            .then(() => navigate('/products/portfolio'))
            .catch(err => setErrorMessage(err.message))
    }


    return <>
        <div className="header-row"><h2>{t('excel-import')}</h2></div>
        <div className="main-content">
            <div className="hidden only:block">
                <div className="flex flex-row flex-wrap sm:flex-row-reverse sm:flex-nowrap gap-2 w-fit">
                    <label className='btn-primary'>{t('upload-product-portfolio')}
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={handleExtract}
                            style={{
                                clip: "rect(0 0 0 0)",
                                clipPath: "inset(50%)",
                                height: "1px",
                                overflow: "hidden",
                                position: "absolute",
                                whiteSpace: "nowrap",
                                width: "1px",
                            }} />
                    </label>

                    <button onClick={() => downloadImportExample('product')} className="btn-secondary">{t('example-file')}</button>


                    <Link to="/products/portfolio" className="btn-secondary">{t('cancel')}</Link>
                </div>
                <div className="py-4">
                    <div className="info">
                        <p>{t('upload-tip-size')}</p>
                        <p>{t('upload-tip-formulas')}</p>
                        <ol>
                            <li>{t('upload-tip-formulas-remedy1')}</li>
                            <li>{t('upload-tip-formulas-remedy2')}</li>
                        </ol>
                    </div>
                </div>
            </div>
            {products && <div className="">
                <div className="flex gap-2 max-w-xl mb-4">
                    <h3 className="grow">{t('portfolio-import-h3')}</h3>
                    <Link to="/products/portfolio" className="btn-secondary">{t('cancel')}</Link>
                    <button onClick={handleImport} className="btn-primary">{t('import')}</button>
                </div>
                <ProductsTable products={products} />
                {skipped?.length > 0 && <>
                    <h3 className="mt-8 mb-2">{t('skipped-products')}</h3>
                    <p className="text-slate-500">{t('skipped-products-hint')}</p>
                    <ProductsTable products={skipped} className="text-slate-500" />
                </>}
            </div>
            }
        </div>
    </>
}

function ProductsTable({products = [], className = undefined}) {
    const {t} = useTranslation();

    const cellStyle = "px-2 border border-pcx-300/50"
    const headerStyle = cellStyle + ' font-medium text-left'

    const yes = t('yes')
    const no = t('no')
    
    return (
        <table className={clsx("text-sm whitespace-nowrap w-fit", className)}>
            <thead>
                <tr>
                    <th className={headerStyle}>{t('commodityReference')}</th>
                    <th className={headerStyle}>{t('commodityClass')}</th>
                    <th className={headerStyle}>{t('commodityDescription')}</th>
                    <th className={headerStyle}>{t('status')}</th>
                    <th className={headerStyle}>{t('isThirdParty')}</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, i) => <tr key={i}>
                    <td className={cellStyle}>{product.commodityReference}</td>
                    <td className={cellStyle}>{product?.commodityClass}</td>
                    <td className={cellStyle}>{product?.commodityDescription}</td>
                    <td className={cellStyle}>{t(product.status)}</td>
                    <td className={cellStyle}>{product.isThirdParty ? yes : no}</td>
                </tr>)}
            </tbody>
        </table>
    )
}