import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import _, { Dictionary } from 'lodash'

import { trade_mark } from "../data"
import { createExcelFile, parseExcelFile } from "../backend"
import { useTrademarks } from "./TrademarksProvider"
import { useMessages } from "../Messages"
import { Trademark, TrademarkFamily, BrandStatus, BrandType } from "./TrademarksProvider"
import { Agent, asCompany, asPerson, isEquivalentAgent, nameOf } from "../agents/utils"
import { useComments } from "../comments/CommentsProvider"
import { useAuth } from "../user/Auth"
import { fromExcelDate } from "../utils/dates"
import { emptyStringAsUndefined } from "../utils/strings"
import { TFunction } from "i18next"
import { LoadingModal } from "../components/Modal"
import { useAgents } from "../agents/AgentsProvider"

export default function TrademarkImport() {
    const {t} = useTranslation()
    const { setErrorMessage } = useMessages()
    const navigate = useNavigate()
    const {user: {name: user}} = useAuth()

    const {
        postTrademarkFamily, postTrademark, postTrademarkClass, 
        trademarkByReference, trademarkFamilyByReference,
        postTrademarkAgentLink, deleteTrademarkAgentLink,
        ownersByTrademarkId, contactsByTrademarkId,
        trademarkClassesByTrademarkId,
        reload,
    } = useTrademarks()
    const {addComment, commentsLookUp} = useComments()
    const commentsById = commentsLookUp[trade_mark] ?? {}

    //const {agents, entityOperation} = useBackend()
    const {agents, postAgent} = useAgents()

    const [importRows, setImportRows] = useState(undefined as ImportRow[] | undefined)
    const [isLoading, setIsLoading] = useState(false)

    //console.log({isLoading})

    // submit excel file and parse returned JSON
    async function handleSubmit(e) {
        const file = e.target.files[0];
        parseExcelFile(file, true)
            .then(res => {
                setImportRows((res?.sheets?.[0]?.rows ?? []).map((row: ImportRow) => {
                    const familyId: number | undefined = trademarkFamilyByReference[row.familyReference]?.familyId
                    const trademarkId: number | undefined = trademarkByReference[row.reference]?.trademarkId
                    const dateFields = ['applicationDate', 'registrationDate', 'renewalDate']
                    const dates = _(dateFields).map(f => [f, fromExcelDate(row[f])]).fromPairs().value()
                    const numberFields = ['applicationNumber', 'registrationNumber']
                    const numbers = _(numberFields).map(f => [f, typeof row[f] === 'number' ? '' + row[f] : row[f]]).fromPairs().value()
                    return {
                        ...row,
                        ...dates,
                        ...numbers,
                        trademarkId, familyId,
                    }
                }))
            })
            .catch(err => setErrorMessage(err.message))
    }

    function handleImport(importRows: ImportRow[]) {

        setIsLoading(true)

        const {newAgents, trademarkPortfolio, importContactsLookup, importOwnersLookup} = prepareForImport({importRows, agents})

        const noInvalidation = { onSettled: undefined }

        //console.log({trademarkPortfolio})
        //console.log({newlyCreatedAgents})
        //console.log({importOwnersLookup, importContactsLookup})

        return Promise.all(newAgents.map(agent => postAgent(agent)
            .then((res: Agent) => [agent.agentId, res.agentId])))
            .then(agents => {
                const newlyCreatedAgents = Object.fromEntries(agents)

                async function handleAgentImport(trademark: Trademark, lookup: Dictionary<number>, agentsByTrademarkId: Record<number, Agent[]>, type: "owner" | "contact") {
                    const trademarkId = trademark.trademarkId
                    if (trademark.reference in lookup) { //} && !(trademark.trademarkId in ownersByTrademarkId)) {
                        const o = lookup[trademark.reference]
                        const agentId = newlyCreatedAgents[o] ?? o
                        if (trademark.trademarkId in agentsByTrademarkId) {
                            // there is an existing owner
                            const existingAgent = agentsByTrademarkId[trademarkId][0]
                            if (existingAgent.agentId !== agentId) {
                                // the existing owner is not the same as the new owner
                                await deleteTrademarkAgentLink({ trademarkId, agentId: existingAgent.agentId, type })
                                await postTrademarkAgentLink({ trademarkId, agentId, type })
                            }
                            // it's the same: do nothing
                        } else {
                            //console.log({o, agentId})
                            await postTrademarkAgentLink({ trademarkId, agentId, type })
                        }
                    }
                }

                return Promise.all(
                    trademarkPortfolio.map(async family => {
                        const trademarkFamily = await postTrademarkFamily({ ...family, familyId: trademarkFamilyByReference[family.reference]?.familyId }, noInvalidation)
                        return Promise.all(family.members.map(async trademark => {
                            return postTrademark({ ...trademark, familyId: trademarkFamily.familyId }, noInvalidation).then(async ({ trademarkId }) => {

                                const existingComments = (commentsById[trademarkId] ?? []).map(c => c.comment)
                                const comment = emptyStringAsUndefined(trademark.comment)
                                if (comment && !existingComments.includes(comment)) {
                                    await addComment({
                                        entity: trade_mark,
                                        entityId: trademarkId,
                                        comment,
                                        created: new Date().toISOString(),
                                        user,
                                    })
                                }
                                // Do not overwrite existing classes
                                if (trademark.trademarkClasses?.length > 0 && trademarkClassesByTrademarkId[trademarkId]?.length === undefined) {
                                    await Promise.all(trademark.trademarkClasses.map(number =>
                                        postTrademarkClass({ trademarkId, number, description: '' }, noInvalidation)))
                                }
                                handleAgentImport({ ...trademark, trademarkId }, importOwnersLookup, ownersByTrademarkId, "owner")
                                handleAgentImport({ ...trademark, trademarkId }, importContactsLookup, contactsByTrademarkId, "contact")
                            })
                        }))
                    })
                )
            }).then(() => {
                setIsLoading(false)
                reload()
                navigate("/trademarks/portfolio")
            }).catch(() => {
                setIsLoading(false)
                reload()
            })
    }

    const exampleExcel = {
        sheets: [
            {
                name: 'portfolio',
                header: importFields.map(f => f.name),
                rows: exampleTrademarkFile,
            },
            {
                name: t('comments'),
                header: [ 'name', 'required', 'comment' ],
                rows: importFields.map(f => ({...f, comment: t(`trademark-comments.${f.name}`)})),
            }
        ]
    }

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('excel-import')} | {t('trademarks')} | Patent Cockpit</title>
            </Helmet>
            <div className="portfolio-menu">
                <h2 className="">
                    {t('excel-import')}
                </h2>
            </div>
            <div className="p-4 bg-white overflow-auto grow">
                <div className="hidden only:block">
                    <div className="flex flex-row-reverse gap-2 w-fit">
                        <label className='btn-primary'>{t('upload-trademark-portfolio')}
                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleSubmit}
                                style={{
                                    clip: "rect(0 0 0 0)",
                                    clipPath: "inset(50%)",
                                    height: "1px",
                                    overflow: "hidden",
                                    position: "absolute",
                                    whiteSpace: "nowrap",
                                    width: "1px",
                                }} />
                        </label>

                        <button 
                            className="btn-secondary" 
                            onClick={() => 
                                createExcelFile(exampleExcel, 'ExampleTrademarkPortfolioImport.xlsx')
                                    .catch(err => setErrorMessage(err.message))
                            }
                        >
                            {t('example-file')}
                        </button>
                        <Link className="btn-secondary" to="..">{t('cancel')}</Link>
                    </div>
                    <div className="py-4">
                        <div className="info">
                            <p>{t('upload-tip-size')}</p>
                            <p>{t('upload-tip-formulas')}</p>
                            <ol>
                                <li>{t('upload-tip-formulas-remedy1')}</li>
                                <li>{t('upload-tip-formulas-remedy2')}</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {isLoading && <LoadingModal />}
                {(importRows && !isLoading) && // don't render when loading so that new data is not pushed down
                    <ImportTmPortfolio {...{trademarkPortfolio: importRows, handleImport, isLoading, setIsLoading}} />}
            </div>
            <Outlet />
        </>
    )
}

type TmGraph = Trademark & { owner?: Agent, contact?: Agent, trademarkClasses: number[], comment?: string }
type TmFamilyGraph = TrademarkFamily & { members: TmGraph[] }
type TmPortfolioGraph = TmFamilyGraph[]

function parseTrademarkPortfolio(trademarkPortfolio: ImportRow[]): TmPortfolioGraph {
    return _(trademarkPortfolio)
        .groupBy(row => row.familyReference)
        .map((rows, familyReference) => {
            return {
                reference: familyReference,
                externalReference: rows[0].familyExtReference,
                name: rows[0].familyName,
                description: rows[0].description ?? '',
                members: rows.map(row => {
                    const owner = asCompany(row.owner)
                    const contact = asPerson(row.contactPerson)
                    return {
                        ...row, // The row should be more or less a Member
                        familyId: row.familyId ?? 0,
                        externalReference: row.memberExtReference,
                        fromWipo: emptyStringAsUndefined(row.fromWipo) as (boolean | undefined),
                        owner,
                        contact,
                        trademarkClasses: typeof row.trademarkClasses === 'string' 
                            ? row.trademarkClasses?.split(',').map(s => parseInt(s)).filter(n => !isNaN(n)) 
                            : [],
                    } as TmGraph
                }),
            }
        })
        .value()
}

function prepareForImport({importRows, agents}: {importRows: ImportRow[], agents: Agent[]}) {

    const trademarkPortfolio = parseTrademarkPortfolio(importRows ?? [])

    const importAgents = _(trademarkPortfolio)
        .flatMap(f => f.members.flatMap(m => [m.contact, m.owner]))
        .filter(Boolean)
        .uniqWith(isEquivalentAgent)
        .map((a, id) => {
            const existingAgent = agents.find(b => isEquivalentAgent(a, b))
            return {
                ...a,
                agentId: existingAgent?.agentId ?? (-id - 1), // negative id for new agents
            }
        })
        .value()

    const [newAgents, existingAgents] = _.partition(importAgents, a => a.agentId < 0)

    const importContactsLookup = _(trademarkPortfolio)
        .flatMap(f => f.members.map(m => m.contact ? [m.reference, importAgents.find(c => isEquivalentAgent(c, m.contact)).agentId] : []))
        .filter(p => p.length === 2)
        .fromPairs()
        .value()

    const importOwnersLookup: Dictionary<number> = _(trademarkPortfolio)
        .flatMap(f => f.members.map(m => m.owner ? [m.reference, importAgents.find(c => isEquivalentAgent(c, m.owner)).agentId] : []))
        .filter(p => p.length === 2)
        .fromPairs()
        .value()

    return {
        trademarkPortfolio,
        newAgents,
        existingAgents,
        importContactsLookup,
        importOwnersLookup,
    }
}

function overwrittenFields(newRow: ImportRow, existingRow?: ImportRow) {
    if (!existingRow) return {}

    return _(newRow).toPairs()
        .map(([k, v]) => {
            const old = emptyStringAsUndefined(existingRow[k])
            if (old && old !== v) return [k, old]
            else return undefined
        })
        .filter(Boolean)
        .fromPairs()
        .value()
}


// ImportRow should already have the familyId/trademarkId if there is an existing one
function ImportTmPortfolio({ trademarkPortfolio, handleImport }: { trademarkPortfolio: ImportRow[], handleImport: (importRows: ImportRow[]) => Promise<void> }) {
    const {t} = useTranslation()

    const {commentsLookUp} = useComments()
    const commentsById = commentsLookUp[trade_mark] ?? {}

    const [deselected, setDeselected] = useState({})

    const { trademarkById, trademarkFamilyById, ownersByTrademarkId, contactsByTrademarkId, trademarkClassesByTrademarkId } = useTrademarks()

    // Create a row representation of the existing trademark if it exists
    function toRow(newRow: ImportRow): ImportRow & {overwrites: Dictionary<string>} {
        const existingTm = trademarkById[newRow.trademarkId]
        if (!existingTm) return undefined

        const family = trademarkFamilyById[existingTm.familyId]
        const owner = ownersByTrademarkId[existingTm.trademarkId]?.[0]
        const contact = contactsByTrademarkId[existingTm.trademarkId]?.[0]
        const trademarkClasses = trademarkClassesByTrademarkId[existingTm.trademarkId]?.map(c => c.number)?.join(',')
        const comment = (commentsById[existingTm.trademarkId] ?? []).map(c => c.comment).find(c => newRow.comment === c)

        if (family === undefined) return null

        const existingRow = {
            ...existingTm,
            familyReference: family.reference,
            familyExtReference: family.externalReference,
            familyName: family.name,
            description: family.description,
            memberExtReference: existingTm.externalReference,
            owner: owner && nameOf(owner),
            contactPerson: contact && nameOf(contact),
            trademarkClasses,
            comment,
        }
        // These are the fields that would be overwritten
        const overwrites = overwrittenFields(newRow, existingRow)
        return { ...existingRow, overwrites, }
    }

    const existingRows = _(trademarkPortfolio).map(row => toRow(row)).filter(Boolean).keyBy('trademarkId').value()

    function doImport(doOverwrite = false) {
        const importRows = trademarkPortfolio
            .filter(row => !(deselected[row.reference] ?? false))
            .map(row => {
                const overwrites = existingRows[row.trademarkId]?.overwrites
                // The fields that would be overwritten are set back to their original value
                if (overwrites && !doOverwrite) return {...row, ...overwrites}
                return row
            })
        return handleImport(importRows)
    }

    const basicCellStyle = "px-2 border whitespace-nowrap text-sm border-pcx-300/50"
    const cellStyle = basicCellStyle + ""
    const cellNewStyle = basicCellStyle + " bg-pcx-200"
    const cellOverwriteStyle = basicCellStyle + " bg-warn-200"
    const headerStyle = cellStyle + ' font-medium text-left'

    return <>
        <div className="flex gap-2 max-w-5xl mb-4">
            <h3 className="grow">{t('portfolio-import-h3')}</h3>
            <Link to="/trademarks/portfolio" className="btn-secondary whitespace-nowrap">
                {t('cancel')}
            </Link>
            <button onClick={() => doImport(false)} className="btn-primary inline-flex gap-2 items-center whitespace-nowrap" >
                {t('import-only-new')}
            </button>
            <button onClick={() => doImport(true)} className="btn-warn inline-flex gap-2 items-center whitespace-nowrap" >
                {t('import-and-overwrite')}
            </button>
        </div>
        <div className="py-2 flex flex-row gap-2">
            <div className={cellStyle}>{t('new-value-new-trademark')}</div>
            <div className={cellNewStyle}>{t('new-value-existing-trademark')}</div>
            <div className={cellOverwriteStyle}>{t('overwrite-value-existing-trademark')}</div>
        </div>
        <table>
            <thead>
                <tr>
                    <th className={headerStyle}>{t('existing')}?</th>
                    <th className={headerStyle}>{t('import')}?</th>
                    {importFields.map(f => <th key={f.name} className={headerStyle}>{t(f.name)}</th>)}
                </tr>
            </thead>
            <tbody>
                {trademarkPortfolio.map((row: ImportRow, ri: number) => {

                    const existingRow = existingRows[row.trademarkId]
                    //console.log({row})

                    function renderField(field: string) {
                        // no existing or the same
                        const value = row[field]
                        if (!existingRow || existingRow[field] === value) 
                            return <td key={field} className={cellStyle}>
                                {renderValue(value, t)}
                            </td>
                        else {
                            // is new, is different, is same
                            const isOverwrite = existingRow.overwrites[field] !== undefined
                            return <td 
                                key={field} title={renderValue(existingRow[field], t)}
                                className={isOverwrite ? cellOverwriteStyle : (emptyStringAsUndefined(value) ? cellNewStyle : cellStyle)}
                            >
                                {renderValue(value, t)}
                            </td>
                        }
                    }

                    const selected = !deselected[row.reference]

                    return <tr key={ri} className={clsx(!selected && 'text-slate-400')}>
                        <td className={cellStyle}>{existingRow ? t('yes') : t('no')}</td>
                        <td className={cellStyle}>
                            <input type="checkbox"
                                className="form-checkbox mx-auto"
                                checked={selected} 
                                onChange={e => setDeselected({...deselected, [row.reference]: !e.target.checked})} />
                        </td>
                        {importFields.map(f => renderField(f.name))}
                    </tr>
                })}
            </tbody>
        </table>
    </>
}

function renderValue(value: any, t: TFunction) {
    if (typeof value === 'boolean')
        return t(value ? 'yes' : 'no')
    else
        return value
}

type ImportRow = {
    familyName: string,
    familyReference: string,
    familyExtReference?: string,
    description: string,
    familyId?: number;
    trademarkId?: number;
    reference: string;
    memberExtReference?: string,
    priorityId?: number; // TODO: as reference
    countryCode: string;
    brandType: BrandType;
    words?: string;

    applicationNumber?: string;
    applicationDate?: string;
    registrationNumber?: string;
    registrationDate?: string;

    renewalDate?: string;
    status: BrandStatus;
    fromWipo?: boolean;
    url?: string;

    trademarkClasses?: string;
    comment?: string;
    owner?: string;
    contactPerson?: string;
}

// TODO: add comments
const importFields = [
    {
        name: "familyName",
        required: true,
    },
    {
        name: "familyReference",
        required: true,
    },
    {
        name: "familyExtReference",
        required: false,
    },
    {
        name: "description",
        required: true,
    },
    {
        name: "reference",
        required: true,
    },
    {
        name: "memberExtReference",
        required: false,
    },
    {
        name: "countryCode",
        required: true,
    },
    {
        name: "brandType",
        required: true,
    },
    {
        name: "words",
        required: false,
    },
    
    {
        name: "applicationNumber",
        required: false,
    },
    {
        name: "applicationDate",
        required: false,
    },
    {
        name: "registrationNumber",
        required: false,
    },
    {
        name: "registrationDate",
        required: false,
    },

    {
        name: "renewalDate",
        required: false,
    },
    {
        name: "status",
        required: true,
    },
    {
        name: "fromWipo",
        required: true,
    },
    {
        name: "url",
        required: false,
    },
    {
        name: "trademarkClasses",
        required: false,
    },
    {
        name: "comment",
        required: false,
    },
    {
        name: "owner",
        required: false,
    },
    {
        name: "contactPerson",
        required: false,
    },
]
const exampleTrademarkFile: ImportRow[] = [
    {
        familyName: "Family Name",
        familyReference: "M001",
        familyExtReference: "EXT-42",
        description: "Family Description",
        reference: "M001DE",
        memberExtReference: "EXT-42 DE",
        countryCode: "DE",
        brandType: "Word",
        words: "WORD",

        applicationNumber: "DE123",
        applicationDate: "2022-12-12",
        registrationNumber: "DE456",
        registrationDate: "2023-01-01",

        renewalDate: "2033-01-01",
        status: "registered",
        fromWipo: true,
        url: "https://patent-cockpit.com",
        trademarkClasses: "9",
        owner: 'Acme Corp',
    },
    {
        familyName: "Family Name",
        familyReference: "M001",
        familyExtReference: "EXT-42",
        description: "Family Description",
        reference: "M001CH",
        memberExtReference: "EXT-42 CH",
        countryCode: "CH",
        brandType: "Word",
        words: "WORD",

        applicationNumber: "CH123",
        applicationDate: "2022-11-11",
        registrationNumber: "CH456",
        registrationDate: "2023-02-02",
        renewalDate: "2033-02-02",
        status: "applied",
        url: "https://patent-cockpit.com",
        comment: "A comment can be added here",
        contactPerson: "John Doe",
    },
    {
        familyName: "Other Family Name",
        familyReference: "M002",
        description: "2nd Family Description",
        reference: "M002CH",
        countryCode: "CH",
        brandType: "Word",
        words: "BRAND",

        applicationNumber: "CH321",
        applicationDate: "2016-11-11",
        registrationNumber: "CH543",
        registrationDate: "2016-02-02",

        renewalDate: "2026-02-02",
        status: "applied",
        fromWipo: false,
        url: "https://patent-cockpit.com",
        trademarkClasses: "1,2,3",
        contactPerson: "John Doe",
        owner: 'Acme Corp',
    },
]
