import { useState } from "react"
import { Field, Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'
import _ from "lodash"

import Modal from "../components/Modal"
import { userRoles as availableUserRoles } from "../data"
import { useAuth } from "../user/Auth"
import DeleteButton from "../components/DeleteButton"
import { SignupProps, useTeamManagementContext } from "../Management"
import { useUserSettings } from "../user/UserSettingsProvider"
import TagList, { TagListField } from "../components/TagList"

const widgetStyling = "pt-2 pb-4 w-fit break-inside-avoid-column"
const infoStyling = "py-2 my-2 text-slate-500 text-sm"
const infoStylingBorder = "px-2 pt-1 pb-2 my-2 border-l-4 border-slate-400 text-slate-500 text-sm"

export function UserTable({withHeader = false}) {
    const {users} = useTeamManagementContext()
    //console.log({users})
    // TODO: add user display names & sort by it
    const {t} = useTranslation()
    return (
        <div className={widgetStyling}>
            <h4 className={!withHeader ? "hidden" : ""}>{t('users')}</h4>
            <table>
                <thead className="text-left border-b-2 border-pcx-500">
                    <tr>
                        <th className="pl-2 pr-4">{t('user')}</th>
                        <th className="pr-4">{t('roles')}</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    {_.sortBy(users, u => u.userName.toLowerCase()).map(user =>
                        <UserEditRow key={user.userName} {...user} />
                    )}
                </tbody>
            </table>
        </div>
    )
}

function ViewRoleBox() {
    const {t} = useTranslation()
    return <span className="shadow-sm hover:shadow-md bg-pcx-100 hover:bg-white px-2 h-6 text-sm rounded-sm py-0.5 whitespace-nowrap">{t('view-role')}</span>
}

function sortRole(role) {
    switch(role) {
        case ("admin-role"): return 1
        case ("user-role"): return 0
        default: return 10
    }
}

function UserEditRow({userName, userRoles}) {
    const {t} = useTranslation()
    const {users} = useUserSettings()
    //console.log(users)
    const user = users[userName]
    const userDisplayName = user?.displayName
    const {updateUser, removeUser} = useTeamManagementContext()
    
    const cleanUserRoles = _.sortBy(userRoles.filter(r => r !== "view-role"), sortRole)
    const {user: me} = useAuth()


    const [newUserRoles, setNewUserRoles] = useState(cleanUserRoles)
    const [showModal, setShowModal] = useState(false)

    const isDirty = !_.isEqual(_.sortBy(newUserRoles, sortRole), cleanUserRoles)

    function update(force = false) {
        const aboutToRemoveAdminRoleForSelf = userName === me?.name && newUserRoles.indexOf("admin-role") < 0
        if ((!aboutToRemoveAdminRoleForSelf) || force) {
            setShowModal(false)
            updateUser({userName, userRoles: newUserRoles})
        } else
            setShowModal(true)
    }

    const display = `${userName} ${userDisplayName ? `(${userDisplayName})` : ''}`
    const display_names = Object.fromEntries(availableUserRoles.map(r => [r, t(r)]))
    return (
        <tr key={userName} className="border-b-2 border-pcx-200 text-xs sm:text-base">
            <td className="px-2 pr-4 whitespace-nowrap">
                {user?.email ? <a className="underline" href={`mailto:${user.email}`}>{display}</a> : display}
            </td>
            <td>
                <div className="flex flex-col sm:flex-row gap-1">
                    <ViewRoleBox />
                    <TagList {...{
                        tags: newUserRoles,
                        setTags: setNewUserRoles,
                        availableTags: availableUserRoles,
                        placeholder: t("add"),
                        tagDisplays: display_names,
                    }} />
                </div>
            </td>
            <td className="px-4 flex flex-col max-lg:items-start lg:flex-row gap-2 py-1 text-sm">
                <button 
                    disabled={!isDirty}
                    className="btn-primary disabled:btn-disabled"
                    onClick={() => update()}
                >
                    {t('save')}
                </button>
                <DeleteButton {...{
                    className: 'py-1 px-2 btn-warn',
                    deleteAction: () => removeUser(userName),
                    question: t('remove-user-from-team', {user: userName})
                }}><span className="whitespace-nowrap">{t('remove-from-team')}</span></DeleteButton>
                {showModal && 
                    <Modal blurClick={() => setShowModal(false)}>
                        <div className="flex flex-col gap-2 p-3">
                            <div>
                                {t('remove-from-team')}
                            </div>
                            <div className="flex flex-row justify-end gap-2">
                                <button onClick={() => setShowModal(false)} className="btn-secondary">{t('cancel')}</button>
                                <button onClick={() => update(true)} className="btn-primary">{t('remove')}</button>
                            </div>
                        </div>
                    </Modal>
                }
            </td>
        </tr>
    )
}

// eslint-disable-next-line
const asciCharacter = /[\x00-\x7F]/
function asAsci(str: string) {
    return Array.from(str).filter(c => asciCharacter.test(c)).join('');
}

export function CreateUser({withHeader = false}) {
    const {t} = useTranslation()

    const {createUser} = useTeamManagementContext()
    const display_names = Object.fromEntries(availableUserRoles.map(r => [r, t(r)]))

    const initialValues = {user: '', password: '', confirmPassword: '', userRoles: ['user-role'], email: ''}
    return (
        <div className={widgetStyling}>
            <h4 className={!withHeader ? "hidden" : ""}>Create new User</h4>
            <div className={infoStyling}>
                {t('only-use-asci')}
            </div>
            <Formik 
                initialValues={initialValues}
                onSubmit={({user, password, userRoles, email}, {resetForm}) => 
                    createUser({user, password, email, userRoles})
                        .then(() => { resetForm({values: initialValues}) })
                }
                validate={(values) => {
                    const errors = {} as Partial<SignupProps & {confirmPassword: string}>
                    if (values.user && values.user !== asAsci(values.user)) {
                        errors.user = `Don't use: ${Array.from(values.user).filter(c => !asciCharacter.test(c)).join(', ')}`
                    }
                    if (typeof values.password === 'string' && values.password.length < 8)
                        errors.password = t('at-least-8-chars')
                    if (typeof values.password === 'string' && asAsci(values.password) !== values.password)
                        errors.password = `Don't use: ${Array.from(values.password, c => !asciCharacter.test(c)).join(', ')}`
                    else if (values.password && values.confirmPassword && values.password !== values.confirmPassword)
                        errors.confirmPassword = "Passwords do not match"

                    if (values.email === undefined || values.email === null || values.email === '')
                        errors.email = t('email-error')
                    return errors
                }}
            >{({values, touched, errors}) => {
                const submittable = values.password.length >= 4 && values.password === values.confirmPassword
                return <Form
                    autoComplete="off"
                    className="flex flex-col sm:min-w-sm gap-1">
                    <label htmlFor="user" className="label">
                        {t('user')} {touched.user && errors.user && <span className="text-red-600">: {errors.user}</span>}
                    </label>
                    <Field
                        id="user"
                        name="user"
                        autoComplete="off" 
                        className="form-input mb-2"
                        type="text"
                        title={t("only-use-asci")}
                    />
                    <label htmlFor="password" className="label">
                        {t('password')} {touched.password && errors.password && <span className="text-red-600">: {errors.password}</span>}
                    </label>
                    <Field
                        id="password"
                        name="password"
                        autoComplete="off" 
                        className="form-input mb-2"
                        type="password"
                        title={t("only-use-asci")}
                    />

                    <label htmlFor="confirmPassword" className="label">
                        {t('confirm-password')} {touched.confirmPassword && errors.confirmPassword && <span className="text-red-600">: {errors.confirmPassword}</span>}
                    </label>
                    <Field
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="off" 
                        className="form-input mb-2"
                        type="password"
                        title={t("only-use-asci")}
                    />

                    <label htmlFor="email" className="label">
                        {t('email')} {touched.email && errors.email && <span className="text-red-600">: {errors.email}</span>}
                    </label>
                    <Field id="email" name="email" className="form-input mb-2" type="email" autoComplete="off"/>

                    <label htmlFor="userRoles" className="label">
                        {t('user-roles')}
                    </label>
                    <div className="inline-flex gap-1 py-1">
                        <ViewRoleBox />
                        <TagListField {...{
                            name: "userRoles",
                            title: "user-roles-select",
                            placeholder: t("add"),
                            tagDisplays: display_names,
                            availableTags: availableUserRoles,
                        }}/>
                    </div>
                    <div className={infoStylingBorder}>
                        {t('create-user-note')}
                    </div>
                    <div className="pt-2 flex flex-row justify-end">
                        <input
                            type="submit"
                            disabled={!submittable}
                            className="btn-primary disabled:btn-disabled max-w-fit"
                            value={t("create")}
                        />
                    </div>
                </Form>
            }}</Formik>
        </div>
    )
}

export function AddUser({withHeader = false, existingUsers = []}) {
    const {addUser, users} = useTeamManagementContext()
    
    const [responseMessage, setResponseMessage] = useState(undefined)

    const {t} = useTranslation()
    const display_names = Object.fromEntries(availableUserRoles.map(r => [r, t(r)]))

    const notAdded = existingUsers.filter(e => users.find(u => u.userName === e) === undefined)

    const labelStyle = "text-gray-800 pt-1"

    return (
        <div className={widgetStyling}>
            <h4 className={!withHeader ? "hidden" : ""}>Add Existing User</h4>
            <span className={infoStyling}>{t('only-use-asci')}</span>
            <Formik 
                initialValues={{
                    userName: '',
                    userRoles: ['user-role'],
                }}
                onSubmit={(user, {resetForm}) => addUser(user).then(res => setResponseMessage(res)).then(() => resetForm())}
                validate={(values) => {
                    const errors = {}
                    if (values.userName && values.userName !== asAsci(values.userName)) {
                        errors['userName'] = `Don't use: ${Array.from(values.userName).filter(c => !asciCharacter.test(c)).join(', ')}`
                    }
                    return errors
                }}
                className="min-w-sm flex flex-col gap-1"
            >{({dirty, touched, errors}) =>
                <Form className="flex flex-col gap-1">
                    <label htmlFor="userName" className={labelStyle}>
                        {t('user')} {touched.userName && errors.userName && <span className="text-red-600">: {errors.userName}</span>}
                    </label>
                    <Field 
                        id="userName" 
                        name="userName" 
                        list="userNames" 
                        className="form-input" 
                        autoComplete="off" 
                    />
                    <datalist id="userNames">
                        {notAdded.map(u => <option key={u} value={u} />)}
                    </datalist>

                    <label htmlFor="userRoles" className={labelStyle}>{t('user-roles')}</label>
                    <div className="inline-flex gap-1">
                        <ViewRoleBox />
                        <TagListField {...{
                            name: "userRoles",
                            availableTags: availableUserRoles,
                            tagDisplays: display_names,
                            placeholder: t("add"),
                            title: "userRoles",
                        }}/>
                    </div>
                    <div className={infoStylingBorder}>{t('add-user-note')}</div>

                    <div className="flex flex-row-reverse pt-2">
                        <button className={dirty ? "btn-primary" : "btn-disabled"} type='submit'>{t('add')}</button>
                    </div>
                </Form>
            }</Formik>
            {responseMessage && <div className="px-2 pt-1 pb-2 my-2 border-l-4 border-pcx-400 text-pcx-600">{t(responseMessage)}</div>}
        </div>
    )
}