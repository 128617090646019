import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import _ from 'lodash'

import { useBackend } from "../BackendProvider";
import { DateOrString } from "../utils/dates";
import { usePatents } from "../patents/PatentsProvider";

export interface Claim {
    claimId?: number;
    claimText: string;
    claimNumber: number;
    claimType: ClaimType;
    familyMemberId: number;
    claimScopeId?: number;
    version: string;
    versionDate: string;
}

type ClaimType = 'independent-claim' | 'dependent-claim'

const ClaimsContext = React.createContext({
    familyMemberId: -1 as number | undefined,
    claimVersion: {version: '', versionDate: ''},
    availableVersions: [] as {version: string; versionDate: DateOrString}[],
    setClaimVersion: ({version, versionDate}: {version: string; versionDate: DateOrString}) => {},
    claims: [] as Claim[],
    memberClaims: [] as Claim[],
    familyClaims: [] as Claim[],
    currentClaims: [] as Claim[],
    addClaim: (claim: Claim) => Promise.resolve({}),
    deleteClaim: (claim: Claim) => Promise.resolve({}),
    updateClaim: (claim: Claim) => {console.log('sss'); return Promise.resolve({})},
})

export function useClaims() {
    return useContext(ClaimsContext)
}

export function ClaimsProvider({children}) {
    const { internalReference } = useParams()
    const { members, memberByReference } = usePatents()
    const {claims, entityOperation} = useBackend()
    const member = memberByReference[internalReference ?? '']
    const familyMemberId = member?.familyMemberId

    const familyMemberIds = new Set(members.filter(m => m.patentFamilyId === member?.patentFamilyId).map(m => m.familyMemberId))

    const memberClaims = claims.filter(claim => claim.familyMemberId === familyMemberId)
    const familyClaims = claims.filter(claim => familyMemberIds.has(claim.familyMemberId)) 

    const [_claimVersion, setClaimVersion] = useState(undefined)
    const availableVersions = _(memberClaims)
        .map(claim => ({version: claim.version, versionDate: claim.versionDate}))
        .uniqWith(_.isEqual)
        .sortBy('versionDate')
        .reverse()
        .value()
    const fallBackVersion = availableVersions[0] ?? {version: '1', versionDate: new Date().toISOString().substring(0, 10)}
    const claimVersion = _claimVersion ?? fallBackVersion
    const currentClaims = _(memberClaims).filter(claim => claim.version === claimVersion.version).sortBy('claimNumber').value()
    //console.log({_claimVersion, claimVersion})

    const addClaim = async ({claimNumber, claimType, claimText}: Claim) => {
        return entityOperation('claim', 'add', {...claimVersion, familyMemberId, claimNumber, claimType, claimText})
            .then(() => entityOperation('claim', 'get'))
    }
    const deleteClaim = async ({claimId}: Claim) => {
        return entityOperation('claim', 'delete', claimId)
            .then(() => entityOperation('claim', 'get'))
    }
    const updateClaim = async ({claimId, claimNumber, claimType, claimText, claimScopeId}: Claim) => {
        //console.log('updating...')
        return entityOperation('claim', 'update', {...claimVersion, familyMemberId, claimId, claimNumber, claimType, claimText, claimScopeId})
            .then(() => entityOperation('claim', 'get'))
    }

    const value = {
        familyMemberId,
        availableVersions,
        claimVersion,
        setClaimVersion,
        claims,
        memberClaims,
        familyClaims,
        currentClaims,
        addClaim,
        deleteClaim,
        updateClaim,
    }
    return <ClaimsContext.Provider value={value}>
        {children}
    </ClaimsContext.Provider>
}
