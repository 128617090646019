import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { useBackend } from "../BackendProvider"
import { usePatents } from "../patents/PatentsProvider"
import { useTrademarks } from "../trademarks/TrademarksProvider"
import { useProducts } from "../products/ProductsProvider"
import { useAgents } from "../agents/AgentsProvider"

/*
 This page is meant for the E2E tests to have a clean slate to start working

 Add more deletions as you add more entities/pages to be tested
*/
export default function DevPage() {

    const {families, deleteFamily, members, deleteMember, isLoading: isPatentsLoading} = usePatents()
    const {trademarkFamilies, deleteTrademarkFamily, isLoading: isTmLoading} = useTrademarks()
    const {entityOperation, inventions, isLoading} = useBackend()
    const { agents, deleteAgent, reload: reloadAgents } = useAgents()
    const { commodities, deleteCommodity, isLoading: isCommoditiesLoading } = useProducts()
    const [error, setError] = useState(undefined)

    const isReady = !isPatentsLoading && !isTmLoading && !isLoading && !isCommoditiesLoading

    async function deleteAll() {
        return Promise.all([
            ...members.map(m => deleteMember(m)),
            ...families.map(f => deleteFamily(f)),
            ...inventions.map(i => entityOperation("invention", "delete", i.inventionId)),
            ...commodities.map(c => deleteCommodity(c)),
            ...trademarkFamilies.map(f => deleteTrademarkFamily(f)),
            ...agents.map(a => deleteAgent(a))
        ]).then(() => {
            reloadAgents()
            return Promise.all([
            entityOperation("invention", "get"),
        ])}).catch(err => setError(err))
    }

    const isEmpty = families.length === 0 && inventions.length === 0 && commodities.length === 0 && trademarkFamilies.length === 0 && agents.length === 0
    console.log({families, inventions, commodities, trademarkFamilies, agents, isEmpty})

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>DEV PAGE | Patent Cockpit</title>
        </Helmet>

        <div className="header-row">
            <div className="flex flex-row gap-2 max-w-3xl">
                <h2>DEV PAGE</h2>
            </div>
        </div>
        <div className="main-content space-y-4">
            <h3>YOU SHOULD NOT BE HERE - DANGER! </h3>
            <Link className="block w-fit btn-primary" to="/">Go back to home</Link>

            <div className="h-1 w-1" />

            <p className="pt-4 border-t-2 border-red-600 text-xl text-red-800">Pressing anything below will delete your portfolio!</p>

            {isReady && <button className="btn-warn" onClick={deleteAll}>Delete all data</button>}

            {isEmpty && <div>Success</div> }
            {error && <div className="text-red-800 mt-2">{error}</div> }
        </div>
    </>
}