import { Fragment, memo, useMemo, useState } from "react"
import { Combobox, Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next"
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import clsx from "clsx"
import { Link } from "react-router-dom"
import _ from 'lodash'

import { family_member, patent_family, trade_mark } from "../../data"
import { useTrademarks } from "../../trademarks/TrademarksProvider"
import { useRoles } from "../../user/Auth"
import { familyUrl, memberUrl } from "../../patents/utils"
import { trademarkUrl } from "../../trademarks/utils"
import { usePatents } from "../../patents/PatentsProvider"
import { isEquivalent } from "./references"

export function EntityLink({entity, entityId, className = undefined}: {entity: string, entityId: number, className?: string}) {
    const {memberById, familyById} = usePatents()
    const {trademarkById} = useTrademarks()

    let url: string | undefined = undefined
    let internalReference: string | undefined = undefined

    switch(entity) {
        case (patent_family):
            const family = familyById[entityId]
            if (family) {
                url = familyUrl(family)
                internalReference = family.internalReference
            }
            break
        case (family_member):
            const member = memberById[entityId]
            if (member) {
                url = memberUrl(member)
                internalReference = member.internalReference
            }
            break
        case (trade_mark):
            const trademark = trademarkById[entityId]
            if (trademark) {
                url = trademarkUrl(trademark)
                internalReference = trademark.reference
            }
            break
    }
    if (url === undefined || internalReference === undefined) {
        return null
    } else {
        return <Link to={url} className={className}>{internalReference}</Link>
    }
}

export interface ReferenceProps {
    internalReference?: string;
    entity?: string;
    entityId?: number;
}

export function ReferenceLinker(
    { value, onChange, name, supportedEntities = [family_member, trade_mark], limitedIds = {}, disallowNoLink = false  }:
        { value: ReferenceProps, onChange: (arg: any) => void, name: string, supportedEntities: string[], limitedIds: {[key: string]: number[]}, disallowNoLink?: boolean }
) {
    const {hasBrands} = useRoles()

    const { members, memberById } = usePatents()

    const {trademarks: _trademarks, trademarkById: _trademarkById} = useTrademarks()

    const [query, setQuery] = useState('')

    const filteredMembers = useMemo(() =>
        family_member in limitedIds
            ? limitedIds[family_member].map(id => memberById[id]).filter(Boolean)
            : members,
        [members, memberById, limitedIds])

    const filteredTrademarks = useMemo(() => {
        const trademarks = hasBrands ? _trademarks : []
        const trademarkById = hasBrands ? _trademarkById : {}

        return trade_mark in limitedIds
            ? limitedIds[trade_mark].map(id => trademarkById[id]).filter(Boolean)
            : trademarks

    }, [_trademarks, _trademarkById, hasBrands, limitedIds])

    const references = useMemo(() => {
        const withMember = supportedEntities.indexOf(family_member) >= 0
        const withTrademark = supportedEntities.indexOf(trade_mark) >= 0
        return _([
            withMember ? filteredMembers.map(({ internalReference, familyMemberId }) => ({ internalReference, entity: family_member, entityId: familyMemberId })) : [],
            // ...families.map(({internalReference, patentFamilyId}) => ({internalReference, entity: 'patent-family', entityId: patentFamilyId})), // at first only members
            withTrademark ? filteredTrademarks.map(({ reference, trademarkId }) => ({ internalReference: reference, entity: trade_mark, entityId: trademarkId })) : [],
        ]).flatten().map(r => ({...r, lcReference: r.internalReference.toLocaleLowerCase()})).sortBy(({ lcReference }) => lcReference).value()
    }, [filteredMembers, filteredTrademarks, supportedEntities])

    //console.log({references})

    return (
        <div className="w-full">
            <Combobox value={value} onChange={v => onChange({ target: { value: v, name } })} by={isEquivalent}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden form-input bg-white text-left focus:outline-none ">
                        <Combobox.Input
                            className="w-full border-none pr-10 leading-5 focus:ring-0 focus:outline-none truncate"
                            displayValue={(reference: ReferenceProps) => reference.internalReference}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <div> {/* Leave as otherwise we get an error that the ref is missing */}
                        <ReferenceList {...{query, references, disallowNoLink, value}} />
                        </div>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}

const ReferenceList = memo(function ReferenceList({query, references, disallowNoLink, value}: {query: string, references: (ReferenceProps & {lcReference: string})[], disallowNoLink: boolean, value?: ReferenceProps}) {
    const {t} = useTranslation()
    const lcQuery = query.toLowerCase()
    const filteredReferences = useMemo(() => lcQuery === '' ? references.slice(0, 100) : references.filter(({lcReference}) => lcReference.includes(lcQuery)), [references, lcQuery])

    return (
        <Combobox.Options className="z-10 absolute mt-1 list-none max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border border-pcx-500 sm:text-sm">
            {!disallowNoLink &&
                <Combobox.Option
                    className={({ active }) => clsx(
                        "relative cursor-default select-none py-2 pl-10 pr-4",
                        active ? 'bg-pcx-500 text-white' : 'text-gray-900'
                    )}
                    value={{}}
                >{t('remove-link')}
                </Combobox.Option>}
            {filteredReferences.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-700">
                    Nothing found.
                </div>
            ) : (
                filteredReferences.map((reference) => {
                    const selected = value?.internalReference === reference.internalReference
                    return (
                        <Combobox.Option
                            key={reference.internalReference}
                            className={({ active }) => clsx(
                                "relative cursor-default select-none py-2 px-4",
                                active ? 'bg-pcx-500 text-white' : 'text-gray-900'
                            )}
                            value={reference}
                        >
                            {({ active }) => (
                                <>
                                    <span className={clsx("block truncate pl-6", selected ? 'font-medium' : 'font-normal')}>
                                        {reference.internalReference}
                                    </span>
                                    {selected ? (
                                        <span className={clsx(
                                            "absolute inset-y-0 left-0 flex items-center pl-3",
                                            active ? 'text-white' : 'text-pcx-500'
                                        )}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Combobox.Option>
                    )
                })
            )}
        </Combobox.Options>
    )
})
