import { createContext, useContext } from "react";
import { Tag } from "../patents/patents";
import { useLinkedCrud } from "../BackendProvider";
import _ from "lodash";

const TagsContext = createContext({
    tags: [] as Tag[],
    postTag: (tag: Tag | Tag[]) => Promise.resolve({}),
    deleteTag: (tag: Tag | Tag[]) => Promise.resolve({}),
    reload: () => {},

    tagsLookup: {} as Record<string, Record<number, string[]>>, // tagsLookup[entity][entityId] -> ["tag"]
})

export function isEqualTag(a: Tag, b: Tag) {
    return a.entity === b.entity && a.entityId === b.entityId && a.tag === b.tag
}

export default function TagsProvider({children}) {
    const {data: tags, postMutation: postTag, deleteMutation: deleteTag, reload} = useLinkedCrud<Tag>('tag', isEqualTag)

    //console.log({tags})
    const tagsLookup = _(tags)
        .groupBy(tag => tag.entity)
        .mapValues(entityTags => _(entityTags)
            .groupBy(tag => tag.entityId)
            .mapValues(ts => _(ts)
                .map(t => t.tag)
                .sortBy(t => t.toLowerCase())
                .value())
            .value())
        .value()

    //console.log({tagsLookup})

    const value = {
        tags,
        postTag,
        deleteTag,
        reload,

        tagsLookup
    }

    return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>
}

export function useTags() {
    return useContext(TagsContext)
}