import { useLocation } from "react-router-dom"
import { useRoles } from "../../user/Auth"
import { afterUpdateActionsStr } from "."
import { OnlyRenameFolder } from "./AutomaticBackgroundChanges"
import { commodity } from "../../data"

export function ProductChanged() {
    const {hasDocuments} = useRoles()
    const location = useLocation()

    if (!hasDocuments)
        return null 

    const state = location.state ?? {}
    const changes = state[afterUpdateActionsStr] ?? []
    //console.log({state})

    // currently only move-folder is supported for families
    const doMoveFolder = changes.includes('move-folder')

    if (!doMoveFolder)
        return null

    // TODO: what to do if the class changes?
    const from = state.from.commodityReference
    const to = state.to.commodityReference
    return <OnlyRenameFolder {...{ from, to, type: commodity, parent: state.from.commodityClass}} />
}