import { diffDays } from "../utils/dates"

export function inventionUrl({reference}){
    return `/inventions/portfolio/${encodeURIComponent(reference)}`
}

export function deadlineColor(dl: string) {
    const now = new Date()
    const delta = diffDays(now, dl)
    if (delta > 7)
        return "text-gray-600"
    else if (delta > 0)
        return "text-orange-600"
    else
        return "text-red-600"
}
