// Use when you have a list of pre-existing input values
import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

export function SuggestedInput({name, value, onChange, suggestions = []}: {name, className?: string, value: string, onChange: (arg: any) => void, suggestions: string[]}) {
    const {t} = useTranslation()
    const [query, setQuery] = useState('')
  
    const filteredSuggestions =
      query === ''
        ? suggestions
        : suggestions.filter((suggestion) => {
            return suggestion.toLowerCase().includes(query.toLowerCase())
          })

    const hasPerfectMatch = filteredSuggestions.some(s => s.toLowerCase() === query.toLowerCase())
    const optionStyle = 'whitespace-nowrap truncate py-1 hover:bg-pcx-100 cursor-pointer'
  
    return (
        <div className='w-fit relative'>
            <Combobox value={value ?? ''} onChange={v => onChange({target: {name, value: v}})}>
                <div className='form-input relative focus-within:border-pcx-300'>
                    <Combobox.Input
                        className="focus:ring-0 focus:outline-none"
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Combobox.Options as="ul" className="absolute mt-1 bg-white rounded border empty:border-0 border-pcx-300 overflow-hidden left-0 right-0 shadow list-none">
                    {!hasPerfectMatch && query.length > 0 && (
                        <Combobox.Option value={query} className={clsx(optionStyle, 'px-2')}>
                            <PlusIcon className='h-5 w-5 mr-2 text-gray-700 inline' aria-hidden="true" />
                            {t('create-name', { name: query })}
                        </Combobox.Option>
                    )}
                    {filteredSuggestions.map((s) => (
                        <Combobox.Option key={s} value={s} className={optionStyle}>{({ selected }) =>
                            <div className={clsx('flex flex-row', selected && 'text-pcx-700 font-bold')}>
                                <div className='w-9'>{selected && <CheckIcon className='mx-auto my-px h-5 w-5 text-pcx-700' aria-hidden="true" />}</div>
                                <div>{s}</div>
                            </div>}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </Combobox>
        </div>
    )
}