import { patent_family } from "../data";
import { Comment, Family, Member } from "../patents/patents";
import { isActiveMember } from "../patents/utils";
import { Commodity } from "../products/products";
import { Trademark, TrademarkFamily } from "../trademarks/TrademarksProvider";
import _ from 'lodash'
import { isActiveTrademark } from "../trademarks/utils";

export interface FilteredPatents {
    families: Family[];
    members: Member[];
}

export interface FilteredCommodities {
    commodities: Commodity[];
}

export interface FilteredTrademarks {
  trademarkFamilies: TrademarkFamily[];
  trademarks: Trademark[];
}


export const tag_filter = "tags"
export const countries_filter = "countries"
export const products_filter = "products"
export const patent_family_filter = "patent-family"
export const product_ownership_filter = "product-ownership"
export const product_class_filter = "commodityClass"
export const product_filter = "commodityReference"
export const active_only_filter = "active-only"
export const owner_filter = "owner"
export const inventor_filter = "inventor"
export const contact_filter = "contact"
export const reference_filter = "reference"
export const free_text_filter = "free-text"

export const availableFilters = [
  tag_filter,
  countries_filter,
  product_ownership_filter,
  product_filter,
  product_class_filter,
  active_only_filter,
  inventor_filter,
  owner_filter,
  reference_filter,
  free_text_filter,
  //products_filter,
  //patent_family_filter,
]

export const empty_value = "__EMPTY__"

// type for object from string to object from number to array of strings
export function filterByTags(context: FilteredPatents, tags: string[], tagsLookup: Record<string, Record<number, string[]>>): FilteredPatents {
    const byFamily = tagsLookup[patent_family]

    const familyIds = _(byFamily)
        .toPairs()
        .filter(([, value]) => tags.some(tag => value.includes(tag)))
        .map(([key]) => parseInt(key))
        .value()
    
    return {
        families: context.families.filter(family => familyIds.includes(family.patentFamilyId)),
        members: context.members.filter(member => familyIds.includes(member.patentFamilyId)),
    }
}

export function filterByEmptyTags(context: FilteredPatents, tagsLookup: Record<string, Record<number, string[]>>): FilteredPatents {

  const families = context.families.filter(f => (tagsLookup[patent_family]?.[f.patentFamilyId] ?? []).length === 0)
  const familyIds = new Set(families.map(f => f.patentFamilyId))
  const members = context.members.filter(m => familyIds.has(m.patentFamilyId))

  return { families, members }
}


export function filterByCountry(context: FilteredPatents, countries: string[]): FilteredPatents {
    const members = context.members.filter(member => countries.includes(member.countryCode))
    const familyIds = _(members).map(m => m.patentFamilyId).uniq().value()

    return {
        families: context.families.filter(family => familyIds.includes(family.patentFamilyId)),
        members,
    }
}

export function filterTrademarkByCountry(context: FilteredTrademarks, countries: string[]): FilteredTrademarks {
  const trademarks = context.trademarks.filter(trademark => countries.includes(trademark.countryCode))
  const familyIds = _(trademarks).map(m => m.familyId).uniq().value()

  return {
    trademarkFamilies: context.trademarkFamilies.filter(family => familyIds.includes(family.familyId)),
    trademarks,
  }
}

export function filterActiveTrademarksOnly(context: FilteredTrademarks, trademarksByFamilyId: Record<number, Trademark[]>): FilteredTrademarks {

    const activeMembers = _(trademarksByFamilyId)
        .toPairs()
        .map(([key, ms]) => [key, ms.filter(isActiveTrademark)])
        .fromPairs()
        .value()

    // active families have either active members or no members at all
    const activeFamilies = context.trademarkFamilies.filter(family => 
      activeMembers[family.familyId]?.length > 0 || trademarksByFamilyId[family.familyId] === undefined)

    return {
        trademarkFamilies: activeFamilies,
        trademarks: _(activeMembers).values().flatten().value(),
    }
}

export function filterActiveOnly(context: FilteredPatents, membersByFamilyId: Record<number, Member[]>): FilteredPatents {

    const activeMembers = _(context.members)
        .filter(isActiveMember)
        .groupBy('patentFamilyId')
        .value()

    // active families have either active members or no members at all
    const activeFamilies = context.families.filter(family => activeMembers[family.patentFamilyId]?.length > 0 || membersByFamilyId[family.patentFamilyId] === undefined)

    return {
        families: activeFamilies,
        members: _(activeMembers).values().flatten().value(),
    }
}

export function filterActiveCommoditiesOnly(context: FilteredCommodities): FilteredCommodities {
    return {
        commodities: context.commodities.filter(c => c.status !== 'stopped'),
    }
}

export function filterByProductOwnership(context: FilteredCommodities, isThirdPary: boolean): FilteredCommodities {
  return {
    commodities: context.commodities.filter(c => c.isThirdParty === isThirdPary)
  }
}

export function filterByProductName(context: FilteredCommodities, productNames: string[]): FilteredCommodities {
  return {
    commodities: productNames?.length > 0
      ? context.commodities.filter(c => productNames.includes(c.commodityReference))
      : context.commodities
  }
}

export function filterByProductClass(context: FilteredCommodities, productClasses: string[]): FilteredCommodities {
  return {
    commodities: productClasses?.length > 0
      ? context.commodities.filter(c => productClasses.includes(c.commodityClass))
      : context.commodities
  }
}

export function matchParts(searchTerm: string) {
  // sanitize the search term for regex usage
  const regex = new RegExp(
    searchTerm
      .trim()
      .replace(/[#}()]/g, "\\$&")
      .split(" ")
      .join(".*"),
    "i"
  ); // i is for ignore case
  return (str: string) => regex.test(str);
}

// This doesn't filter on the tags. Should we do this?
export function filterPatentsByText(context: FilteredPatents, text: string, commentsLookUp: Record<string, Record<number, Comment[]>>): FilteredPatents {

    const trimmed = text.trim();
    const matchFilterText = matchParts(trimmed);
    function familyHasText(f: Family): boolean {
      const r =
        trimmed.length < 2 ||
        matchFilterText(f.internalReference) ||
        matchFilterText(f.familyName) ||
        matchFilterText(f.summary) ||
        (
          commentsLookUp[patent_family]?.[f.patentFamilyId]?.map(
            (c: Comment) => c.comment
          ) ?? []
        ).find((c) => matchFilterText(c)) !== undefined;
      return r;
    }

    const memberHasText =
      trimmed.length > 0
        ? (m: Member) =>
            trimmed.length < 2 || matchFilterText(m.internalReference)
        : (m: Member) => true;

    const membersWithText = context.members.filter(memberHasText);
    const memberFamilyIds = membersWithText.map(m => m.patentFamilyId)
    // Question: should we differentiate between families with text and families with members with text?
    // Then, only include members if the family has text?
    const families = context.families.filter(f => familyHasText(f) || memberFamilyIds.includes(f.patentFamilyId));
    const familyIds = new Set(families.map(f => f.patentFamilyId))
    const members = context.members.filter(m => familyIds.has(m.patentFamilyId))

    return {
        families,
        members,
    }
}

export function filterCommoditiesByText(context: FilteredCommodities, text: string): FilteredCommodities {
    //const trimmed = text.trim();
    //const matchFilterText = matchParts(trimmed);

    //function commodityHasText(c: Commodity): boolean {
    //  const r =
    //    trimmed.length < 2 ||
    //    matchFilterText(c.commodityClass) ||
    //    matchFilterText(c.commodityReference) ||
    //    matchFilterText(c.commodityDescription);
    //  return r;
    //}
    // The problem is that the filter is too aggressive wrt the product patent mapping table

    return {
      //commodities: context.commodities.filter(commodityHasText), // TODO: uncomment this line to filter by commodity text
      commodities: context.commodities
    }
}

export function filterTrademarksByText(context: FilteredTrademarks, text: string): FilteredTrademarks {
  const trimmed = text.trim();
  const matchFilterText = matchParts(trimmed);

  function trademarkHasText(t: Trademark): boolean {
    const r =
      trimmed.length < 2 ||
      matchFilterText(t.reference) ||
      matchFilterText(t.words ?? '') ||
      matchFilterText(t.registrationDate ?? '');
    return r;
  }

  function familyHasText(f: TrademarkFamily): boolean {
    const r =
      trimmed.length < 2 ||
      matchFilterText(f.reference) ||
      matchFilterText(f.name ?? '') ||
      matchFilterText(f.description ?? '');
    return r;
  }

  const tmsWithText = context.trademarks.filter(trademarkHasText);
  const tmFamilyIds = new Set(tmsWithText.map(m => m.familyId))
  //console.log({tmsWithText})

  const trademarkFamilies = context.trademarkFamilies.filter(f => tmFamilyIds.has(f.familyId) || familyHasText(f));
  const allFamilyIds = new Set(trademarkFamilies.map(f => f.familyId))
  const trademarks = context.trademarks.filter(t => allFamilyIds.has(t.familyId))
  //console.log({trademarkFamilies})

  return {
    trademarkFamilies,
    trademarks,
  }
}

export function filterByAgent(
  context: FilteredPatents, 
  agentIds: (number | '__EMPTY__' )[], 
  linkType: string,
  agentsLookup: Record<string, Record<number, number[]>>,
  agentsByMemberId: Record<string, Record<number, number[]>>
): FilteredPatents {

  const memberIds = new Set(agentIds.flatMap(id => agentsLookup[linkType]?.[id] ?? []))

  const isAllowedEmpty = agentIds.includes(empty_value) 
    ? (familyMemberId: number) => (agentsByMemberId[linkType]?.[familyMemberId] ?? []).length === 0 
    : (familyMemberId: number) => false
  const members = context.members.filter(m => memberIds.has(m.familyMemberId) || isAllowedEmpty(m.familyMemberId))

  const familyIds = new Set(members.map(m => m.patentFamilyId))
  const families = context.families.filter(f => familyIds.has(f.patentFamilyId))

  return {...context, members, families}
}

export function filterTrademarkByAgent(
  context: FilteredTrademarks, 
  agentIds: (number | '__EMPTY__' )[], 
  linkType: string,
  agentsLookup: Record<string, Record<number, number[]>>,
  agentsByRightId: Record<string, Record<number, number[]>>
): FilteredTrademarks {

  const trademarkIds = new Set(agentIds.flatMap(id => agentsLookup[linkType]?.[id] ?? []))

  const isAllowedEmpty = agentIds.includes(empty_value) 
    ? (trademarkId: number) => (agentsByRightId[linkType]?.[trademarkId] ?? []).length === 0 
    : (trademarkId: number) => false
  const trademarks = context.trademarks.filter(t => trademarkIds.has(t.trademarkId) || isAllowedEmpty(t.trademarkId))

  const familyIds = new Set(trademarks.map(m => m.familyId))
  const trademarkFamilies = context.trademarkFamilies.filter(f => familyIds.has(f.familyId))

  return {...context, trademarks, trademarkFamilies}
}

function singleLines(list: string): string[] {
  return _(list)
    .split('\n')
    .map(_.trim)
    .map(_.toLower)
    .filter(s => typeof s === 'string' && s !== '')
    .value()
}

// TODO: if references matches exactly member, don't include sibilings
export function filterByReference(context: FilteredPatents, referencesList: string): FilteredPatents {
  const references = singleLines(referencesList)

  if (references.length === 0) {
    return context
  }

  const familiesSet = new Set(context.families.filter(f => references.includes(f.internalReference?.toLocaleLowerCase())).map(f => f.patentFamilyId))
  const members = context.members.filter(m => references.includes(m.internalReference?.toLowerCase()) ||  familiesSet.has(m.patentFamilyId))

  const memberFamilyIds = new Set(members.map(m => m.patentFamilyId))
  const allFamilies = new Set([...familiesSet, 
    ...memberFamilyIds
  ])

  const families = context.families.filter(f => allFamilies.has(f.patentFamilyId))
  return {...context,
     members,
      families}
}

export function filterTrademarksByReference(context: FilteredTrademarks, referencesList: string): FilteredTrademarks {
  const references = singleLines(referencesList)

  if (references.length === 0) {
    return context
  }

  const familiesSet = new Set(context.trademarkFamilies.filter(f => references.includes(f.reference?.toLocaleLowerCase())).map(f => f.familyId))
  const trademarks = context.trademarks.filter(m => references.includes(m.reference?.toLocaleLowerCase()) ||  familiesSet.has(m.familyId))

  const allFamilyIds = new Set([...familiesSet, ...trademarks.map(m => m.familyId)])

  const trademarkFamilies = context.trademarkFamilies.filter(f => allFamilyIds.has(f.familyId))
  return {...context, trademarks, trademarkFamilies}
}

