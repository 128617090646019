import { useTranslation } from "react-i18next"
import { useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import _ from "lodash"

import { TrademarkFamily, TrademarkProductMapping, useTrademarks } from "../trademarks/TrademarksProvider"
import { Commodity, CommodityFamilyLink } from "./products"
import { linkDifference } from "../BackendProvider"
import { ProtectionIcon as FullProtectionIcon } from "../products/ClaimScopesMapping";
import Modal from "../components/Modal"
import { usePatents } from "../patents/PatentsProvider"
import { useRoles } from "../user/Auth"
import { useProducts } from "./ProductsProvider"
import { Family } from "../patents/patents"

export function ProductTrademarkFamilyMappingEdit() {
    const [searchParams] = useSearchParams()
    const {trademarkProductMappingsByFamilyId} = useTrademarks()

    const familyId = parseInt(searchParams.get('familyId'))

    const mappings = trademarkProductMappingsByFamilyId[familyId] ?? []

    const {postTrademarkProductMapping, deleteTrademarkProductMapping} = useTrademarks()
    const equalLink = (a: TrademarkProductMapping, b: TrademarkProductMapping) => 
        a.commodityId === b.commodityId && a.protected === b.protected && a.familyId === b.familyId

    return (
        <Modal>
            <MappingTable {...{
                mappings,
                postMapping: postTrademarkProductMapping,
                deleteMapping: deleteTrademarkProductMapping,
                equalLink,
                base: {familyId},
                matrixLink: "/trademarks/products",
            }} />
        </Modal>
    )
}

export function ProductPatentFamilyMappingEdit() {
    const {hasClaimScopes} = useRoles()
    const {familyByReference} = usePatents()
    const { internalReference } = useParams()

    const patentFamilyId = familyByReference[internalReference]?.patentFamilyId
    const {
        commodityFamilyLinks, 
        postCommodityFamilyLink, deleteCommodityFamilyLink,
    } = useProducts()

    if (!patentFamilyId) return null

    // TODO warning about claim scopes
    const mappings: CommodityFamilyLink[] = commodityFamilyLinks.filter(l => l.patentFamilyId === patentFamilyId)

    return (
        <Modal>
            {hasClaimScopes && <div className="p-2 text-center bg-red-200 text-red-800 font-medium">Only Family Mapping - No Claim Scopes</div>}
            <MappingTable<CommodityFamilyLink> {...{
                mappings,
                postMapping: postCommodityFamilyLink,
                deleteMapping: deleteCommodityFamilyLink,
                equalLink: (a, b) => a.commodityId === b.commodityId && a.protected === b.protected,
                base: {patentFamilyId},
                matrixLink: "/patents/products",
            }} />
        </Modal>
    )
}

function productName(commodity?: Commodity) {
    if (commodity)
        return `${commodity.commodityClass}: ${commodity.commodityReference}`
    else
        return null
}

type ProductMapping = {commodityId: number, protected: boolean}


function MappingTable<T extends ProductMapping>(
    {mappings, postMapping, deleteMapping, equalLink, base, matrixLink}:
    {  
        mappings: T[],
        postMapping: (arg: T[]) => Promise<T | T[]>,
        deleteMapping: (arg: T[]) => Promise<object>, 
        equalLink: (a: T, b: T) => boolean,
        base: Partial<T>,
        matrixLink: string,
    }) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const {commodities, commodityById} = useProducts()

    const [workingCopy, setWorkingCopy] = useState(_.sortBy(mappings, m => productName(commodityById[m.commodityId])))

    function nextState(isProtected?: boolean) {
        if (isProtected === undefined) return false
        else if (isProtected === false) return true
        else return undefined
    }

    function toggleProtected(index: number) {
        setWorkingCopy(cs => {
            return [
                ...cs.slice(0, index),
                {...cs[index], protected: nextState(cs[index].protected)},
                ...cs.slice(index + 1)
            ]
        })
    }

    const availableCommodities = _(commodities)
        .filter(c => workingCopy.find(w => w.commodityId === c.commodityId) === undefined)
        .sortBy(c => productName(c))
        .value()

    function addMapping(_commodityId: string) {
        const commodityId = parseInt(_commodityId)
        if (commodityId >= 0) {
            setWorkingCopy(cs => [...cs, {...base, commodityId, protected: true} as T])
        }
    }

    async function save() {
        const definedOnly = workingCopy.filter(w => w.protected !== undefined)
        const [toAdd, toDelete] = linkDifference(mappings, definedOnly, equalLink)
        await deleteMapping(toDelete)
        await postMapping(toAdd)
        navigate("..")
    }

    return <>
        <div className="p-4 space-y-2">
            <h2 className="mb-0">{t('edit-product-mapping')}</h2>
            <p className="text-slate-600 pb-0">{t('click-icon-to-edit')}</p>
            <table>
                <tbody>
                    {workingCopy.map((c, ci) => {
                        const commodity: Commodity = commodityById[c.commodityId]
                        const isProtected = c.protected
                        return commodity && <tr key={ci}>
                            <td className="text-center pt-1 px-2">
                                <button onClick={() => toggleProtected(ci)}>
                                    <FullProtectionIcon {...{isProtected, isUndefined: isProtected === undefined, isThirdParty: commodity.isThirdParty}} />
                                </button>
                            </td>
                            <td className="px-3 py-1">{productName(commodity)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
            <div />
            <select className="form-select btn-secondary max-w-[20rem]" onChange={e => addMapping(e.target.value)}>
                <option value={-1}>+ {t('add-product')}</option>
                {availableCommodities.map(c => <option key={c.commodityId} value={c.commodityId}>{productName(c)}</option>)}
            </select>
        </div>
        <div className="p-4 flex flex-col sm:flex-row-reverse gap-2 bg-pcx-200">
            <button className="btn-primary" onClick={save}>{t('save')}</button>
            <Link className="btn-secondary text-center" to="..">{t('cancel')}</Link>
            <Link to={matrixLink} className="pl-0 sm:pr-4 btn-tertiary text-center sm:mr-auto">{t('edit-matrix')}</Link>
        </div>
    </>
}

type TrademarkFamilyWithId = Omit<TrademarkFamily, 'familyId'> & {familyId: number}
export function TrademarksPerCommodityMapping() {
    const { trademarkFamilies, trademarkFamilyById, trademarkProductMappings, postTrademarkProductMapping, deleteTrademarkProductMapping } = useTrademarks()
    const { commodityById, } = useProducts()
    const {id} = useParams()

    const commodity = commodityById[id]

    if (!commodity) return null

    const { isThirdParty } = commodity
    const mappings = trademarkProductMappings.filter(l => l.commodityId === commodity.commodityId)

    return (
        <Modal>
            <PerCommodityMappingTable<TrademarkProductMapping, { familyId: number }, TrademarkFamilyWithId> {...{
                isThirdParty,
                mappings,
                postMapping: postTrademarkProductMapping,
                deleteMapping: deleteTrademarkProductMapping,
                equalLink: (a, b) => a.familyId === b.familyId && a.protected === b.protected,
                ipRights: trademarkFamilies as TrademarkFamilyWithId[],
                ipRightById: trademarkFamilyById as Record<number, TrademarkFamilyWithId>,
                ipRightIdName: ({ familyId }: { familyId: number }) => trademarkFamilyById[familyId]?.reference,
                idName: 'familyId',
                base: { commodityId: commodity.commodityId },
                matrixLink: "/trademarks/products",
            }} />
        </Modal>

    )
}




// PER COMMODITY
type FamilyWithId = Omit<Family, 'patentFamilyId'> & {patentFamilyId: number}
export function PatentsPerCommodityMapping() {
    const {hasClaimScopes} = useRoles()

    const {families, familyById} = usePatents()
    const {
        commodityFamilyLinks, commodityById,
        postCommodityFamilyLink, deleteCommodityFamilyLink,
    } = useProducts()
    const {id} = useParams()

    const commodity = commodityById[id]

    if (!commodity) return null

    const { isThirdParty } = commodity
    const mappings = commodityFamilyLinks.filter(l => l.commodityId === commodity.commodityId)

    return (
        <Modal>
            {hasClaimScopes && <div className="p-2 text-center bg-red-200 text-red-800 font-medium">Only Family Mapping - No Claim Scopes</div>}
            <PerCommodityMappingTable<CommodityFamilyLink, { patentFamilyId: number }, FamilyWithId> {...{
                isThirdParty,
                mappings,
                postMapping: postCommodityFamilyLink,
                deleteMapping: deleteCommodityFamilyLink,
                equalLink: (a, b) => a.patentFamilyId === b.patentFamilyId && a.protected === b.protected,
                ipRights: families as FamilyWithId[],
                ipRightById: familyById as Record<number, FamilyWithId>,
                ipRightIdName: ({ patentFamilyId }: { patentFamilyId: number }) => familyById[patentFamilyId]?.internalReference,
                idName: 'patentFamilyId',
                base: { commodityId: commodity.commodityId },
                matrixLink: "/patents/products",
            }} />
        </Modal>
    )
}



function PerCommodityMappingTable<T extends {protected: boolean} & ID, ID, I extends ID>(
    {isThirdParty, mappings, postMapping, deleteMapping, equalLink, ipRights, ipRightById, ipRightIdName, idName, base, matrixLink}:
    {  
        isThirdParty: boolean,
        mappings: T[],
        postMapping: (arg: T[]) => Promise<T | T[]>,
        deleteMapping: (arg: T[]) => Promise<object>, 
        equalLink: (a: T, b: T) => boolean,
        ipRights: I[],
        ipRightById: Record<string, I>,
        ipRightIdName: (i: ID) => string,
        idName: string,
        base: Partial<T>,
        matrixLink: string,
    }) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const [workingCopy, setWorkingCopy] = useState(_.sortBy(mappings, m => ipRightIdName(m)))

    function nextState(isProtected?: boolean) {
        if (isProtected === undefined) return false
        else if (isProtected === false) return true
        else return undefined
    }

    function toggleProtected(index: number) {
        setWorkingCopy(cs => {
            return [
                ...cs.slice(0, index),
                {...cs[index], protected: nextState(cs[index].protected)},
                ...cs.slice(index + 1)
            ]
        })
    }

    const availableIpRights = _(ipRights)
        .filter(ipRight => workingCopy.find(w => w[idName] === ipRight[idName]) === undefined)
        .sortBy(ipRight => ipRightIdName(ipRight))
        .value()

    function addMapping(_id: string) {
        const id = parseInt(_id)
        if (id >= 0) {
            setWorkingCopy(cs => [...cs, {...base, [idName]: id, protected: true} as T])
        }
    }

    async function save() {
        const definedOnly = workingCopy.filter(w => w.protected !== undefined)
        const [toAdd, toDelete] = linkDifference(mappings, definedOnly, equalLink)
        await deleteMapping(toDelete)
        await postMapping(toAdd)
        navigate("..")
    }

    return <>
        <div className="p-4 space-y-2">
            <h2 className="mb-0">{t('edit-product-mapping')}</h2>
            <p className="text-slate-600 pb-0">{t('click-icon-to-edit')}</p>
            <div className="columns-[10rem] max-h-[60vh]">
                {workingCopy.map((w, ci) => {
                    const ipRightId = w[idName]
                    const ipRight: I = ipRightById[ipRightId]
                    const isProtected = w.protected
                    // console.log({workingCopy, w, ipRightId, ipRight})
                    return ipRight && 
                        <div key={ci} className="flex flex-row">
                            <div className="text-center pt-1 px-2">
                                <button onClick={() => toggleProtected(ci)}>
                                    <FullProtectionIcon {...{ isProtected, isUndefined: isProtected === undefined, isThirdParty }} />
                                </button>
                            </div>
                            <div className="px-3 py-1">{ipRightIdName(ipRight)}</div>
                        </div>
                })}
            </div>
            <div />
            <select className="form-select btn-secondary min-w-[16rem]" onChange={e => addMapping(e.target.value)}>
                <option value={-1}>+ {t('add-ip-right')}</option>
                {availableIpRights.map(ipRight => {
                    const ipRightId = ipRight[idName]
                    return <option key={ipRightId} value={ipRightId}>{ipRightIdName(ipRight)}</option>
                })}
            </select>
        </div>
        <div className="p-4 flex flex-col sm:flex-row-reverse gap-2 bg-pcx-200">
            <button className="btn-primary" onClick={save}>{t('save')}</button>
            <Link className="btn-secondary text-center" to="..">{t('cancel')}</Link>
            <Link to={matrixLink} className="pl-0 sm:pr-4 btn-tertiary text-center sm:mr-auto">{t('edit-matrix')}</Link>
        </div>
    </>
}