import { IpType } from "../patents/patents"

export const patent_division = "patent division"
export const patent_continuation = "patent continuation"

export const patentIpSubTypes = [
    "patent", 
    "patent addition", 
    "patent black box", 
    "patent cautionary notice",
    "patent certificate of addition", 
    //"patent continuation",
    //"patent division", 
    patent_continuation,
    patent_division,
    "patent extended",
    "patent importation",
    "patent pipeline", 
    "Plant Patent",
    "patent provisional",
    "patent reissue",
    "patent secrecy",
    "Statutory Invention Registration", 
    "patent SPC",
    "patent SPC paediatric"


]

export const utility_model = "utility model"
export const um_division = "utility model division"

export const utilityModelIpSubTypes = [
    utility_model,
    //"utility model", 
    //"utility model division",
    um_division,
    "innovation patent",
    "petty patent",
    "utility model secrecy",
    "patent short term"
]

export const ipSubTypes: Record<IpType, {name: string, label?: string, withLink?: boolean}[]> = {
    patent: [
        {name: "patent", label: 'no'},
        {name: patent_continuation, withLink: true},
        {name: patent_division, withLink: true},
    ],
    'utility-model': [
        {name: utility_model, label: 'no'},
        {name: um_division, withLink: true},
    ]
}

export const origins = [
    {name: "European", description: 'european-validation'},
    {name: "National", description: 'first-or-priority-filing'},
    {name: "PCT",      description: 'pct-nationalization'},
    {name: "Eurasia",  description: 'eurasian-patent'},
]

export const statuses = [
    "Pending", "Granted", "Inactive"
]

