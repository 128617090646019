import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import _ from 'lodash'

import { IconCheck } from "../components/icons"
import { isActiveTrademark, trademarkFamilyUrl, trademarkUrl } from "./utils"
import { useFilteredTrademarks } from "../filter/FilteredTrademarks"

export default function TrademarkCountries() {
    const {t} = useTranslation()

    const { trademarks, trademarkFamilies, trademarksByFamilyId } = useFilteredTrademarks()

    const countries = _(trademarks).map(trademark => trademark.countryCode).uniq().sort().value()

    const families = _.sortBy(trademarkFamilies, f => f.reference)

    return (
        <>
        {/* @ts-ignore */}
            <Helmet>
                <title>{t('country-map')} | {t('trademarks')} | Patent Cockpit</title>
            </Helmet>
            <div className="portfolio-menu max-w-3xl">
                <h2 className="modern-h2">{t('country-map')}</h2>
            </div>
            <div className="p-4 pt-0 main-content-pure">
                <div className="p-4 bg-white dark:bg-pcx-200 rounded-lg shadow w-fit">
                <table>
                    <thead>
                        <tr>
                            <th className="font-normal text-pc-500 px-0 sticky -top-4 -left-4 z-10 bg-white dark:bg-pcx-200">
                                <div className="px-2 flex flex-row gap-4 justify-between border-b-2 border-pcx-300">
                                    <span>{t('trademark-family')}</span>
                                    <span>{t('countries')}</span>
                                </div>
                            </th>
                            {countries.map(c =>
                                <th key={c} className="sticky -top-4 bg-inherit  px-0">
                                    <div className=" border-b-2 border-pcx-300 px-0.5 pb-0">
                                        <Link className="font-normal bg-pc-200 py-0.5 rounded-sm px-3 text-sm" to={`/search?search=${c}`}>{c}</Link>
                                    </div>
                                </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {families.map(family => {
                            const tms = _(trademarksByFamilyId[family.familyId] ?? []).filter(isActiveTrademark).keyBy("countryCode").value()
                            return (
                                <tr key={family.familyId} className="border-b-2 border-pcx-200 dark:border-pcx-300/50">
                                    <td className="px-2 sticky -left-4 ">
                                        <Link
                                            className="flex flex-row gap-2 items-center bg-white dark:bg-pcx-200 "
                                            to={trademarkFamilyUrl(family)} >
                                            <div className={`max-w-[60ch] whitespace-nowrap py-1 overflow-hidden text-ellipsis text-sm text-slate-700`}>
                                                {family.reference}: {family.name}
                                            </div>
                                        </Link>
                                    </td>
                                    {countries.map(c =>
                                        <td key={c} className="px-2">
                                            {c in tms
                                                ? <Link
                                                    className="text-pc-400 h-full text-xs"
                                                    to={trademarkUrl(tms[c])}
                                                >
                                                    <IconCheck />
                                                </Link>
                                                : ""}
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </>
    )
}