import { Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'


export default function Settings() {
    const {t} = useTranslation()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('settings')} | Patent Cockpit</title>
            </Helmet>
            <Outlet />
        </>
    )
}
