import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import _, { sortBy } from 'lodash'

import { countryCodes } from '../data'
import { findModels, memberModel, scenarioModel, selectModel, sum_amounts } from './forecasting'
import modelParameters from './model.json'
import { memberUrl } from '../patents/utils'
import { CostChart } from './Forecast'
import { sumModels } from './forecasting'
import TagList from '../components/TagList'
import clsx from 'clsx'
import { useFxContext } from './FxProvider'
import { usePatents } from '../patents/PatentsProvider'

export default function ShowModels() {
    const [currency, setCurrency] = useState("EUR")
    const [refDate, setRefDate] = useState(`${new Date().getFullYear()}-01-01`)

    return <>
        <Helmet>
            <title>Forecast | Patent Cockpit</title>
        </Helmet>
        <div className="header-row flex gap-2">
            <h2 className="grow">Forecast Models</h2>
            <label>Reference Date: <input className='form-input py-1' type="date" value={refDate} onChange={e => setRefDate(e.target.value)} /></label>
            <select className='form-select py-1 w-fit' value={currency} onChange={e => setCurrency(e.target.value)}>
                <option>CHF</option>
                <option>EUR</option>
                <option>USD</option>
                <option>CNY</option>
                <option>JPY</option>
            </select>
            <Link className='btn-secondary py-1 text-sm' to={-1}>Back</Link>
        </div>
        <div className="main-content bg-pcx-200 flex flex-col gap-2 items-start">
            <ScenarioModelSandbox {...{ currency, refDate }} />
            <DescribeModel {...{ currency, refDate }} /> 
            <ModelForKey {...{currency}} />
            <ModelParameters />
        </div>
    </>
}

function TableCopyButton({tableId}) {

    const copyTableToClipboard = (id) => {
        const elTable = document.getElementById(id);

        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {

            const range = document.createRange();
            const sel = window.getSelection();
            // unselect any element in the page
            sel.removeAllRanges();

            try {
                range.selectNodeContents(elTable);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(elTable);
                sel.addRange(range);
            }

            document.execCommand('copy');
            sel.removeAllRanges();
        }

    }

    return (
        <button className='btn-secondary my-2 focus:scale-125 focus:translate-x-4' onClick={() => copyTableToClipboard(tableId)}>Copy to Clipboard</button>
    )
}

function DescribeModel({currency, refDate}) {
    const {fxConverter} = useFxContext()
    const {families, members: _members} = usePatents()

    const membersByFamily = _(_members)
        .groupBy(m => m.patentFamilyId)
        .mapValues(ms => sortBy(ms, m => m.internalReference))
        .value()

    const [useModelCcy, setUseModelCcy] = useState(true)
    const [internalReference, setInternalReference] = useState(_.last(families)?.internalReference)

    const family = families.find(f => f.internalReference === internalReference)
    const members = membersByFamily[family?.patentFamilyId] ?? []

    const today = new Date(refDate)
    const models = members.map(member => {
        const {other_costs, fees} = findModels(member, member.ipType)
        const compCurrency = (useModelCcy ? other_costs?.currency : currency)
        const amounts = selectModel({ member, currency: compCurrency, priorityDate: family?.priorityDate, fxConverter })
        const monthly = memberModel({member, family, today: refDate, currency: compCurrency, fxConverter})
        const byYear = sumModels({models: [monthly], today})
        //console.log({member, amounts, byYear})
        return { member, model: {other_costs, fees}, amounts, monthly, byYear }
    })

    const total = sumModels({models: models.map(m => m.monthly), today})
    //console.log({today, total})

    return (
        <Disclosure as='div' className='p-4 bg-white rounded-md'>{({open}) => <>
            <Disclosure.Button className='flex justify-between min-w-sm'>
                <h3 className='mb-2'>Describe Model</h3>
                <ChevronUpIcon className={clsx('w-5 h-5', open ? 'rotate-180' : '')} />
            </Disclosure.Button>
            <Disclosure.Panel>
                <div className='mb-2 flex flex-row gap-4 items-start'>
                    <select value={internalReference} onChange={e => setInternalReference(e.target.value)} className='form-select font-medium'>
                        {_(families)
                            .sortBy(({ internalReference }) => internalReference)
                            .reverse()
                            .map(f => <option key={f.internalReference} >{f.internalReference}</option>)
                            .value()}
                    </select>
                    <button onClick={() => setUseModelCcy(!useModelCcy)} className='btn-secondary w-64'>
                        Use {!useModelCcy ? 'Model Currency' : `Scenario  Currency ${currency}`}
                    </button>
                    {family?.priorityDate && <div>Priority Date: {family.priorityDate}</div>}
                </div>
                {family && <div className='pt-2'>
                    <div className='flex gap-4 items-center'>
                        <h4>Used Input Parameters</h4>
                        <TableCopyButton tableId='family-table' />
                    </div>
                    <table id='family-table' className='whitespace-nowrap'>
                        <thead>
                            <tr className='text-left pb-1 border-b-2 border-pcx-300'>
                                <th className='pr-2'>Member</th>
                                <th className='pr-2'>Key</th>
                                <th className='pr-2'>Currency</th>
                                <th className='pr-2'>Model</th>
                                {[...Array(25).keys()].map(i => <th className='pr-2' key={i}>Year {i}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {models.flatMap(({ member, model: models, amounts }) => {
                                //console.log({model, amounts})
                                //console.log({byYear})
                                return ['other_costs', 'fees'].map(costType => {
                                    const model = models?.[costType]
                                    const amountsByYear = useModelCcy ? (model?.amounts ?? []) : fxConverter.convert(model?.amounts ?? [], model?.currency ?? currency, currency)
                                    return <tr key={costType + '-' + member.familyMemberId} className='hover:bg-pcx-200' >
                                        <td className='pr-2'><Link className='underline' to={memberUrl(member)}>{member.internalReference}</Link></td>
                                        <td className='pr-2 font-mono'>{model?.key}</td>
                                        <td className='pr-2 font-mono'>{model?.currency}</td>
                                        <td className='pr-2 font-mono'>{costType}</td>
                                        {[...Array(25).keys()].map(i => <td className='pr-2 text-right tabular-nums' key={i}>{amountsByYear[i]?.toFixed(0) ?? ''}</td>)}
                                    </tr>
                                })
                            })}
                        </tbody>
                    </table>

                    <div className='flex gap-4 items-center mt-4'>
                        <h4>Shifted Costs</h4>
                        <TableCopyButton tableId='adjusted-costs' />
                    </div>
                    <table id='adjusted-costs' key={total.length} className='whitespace-nowrap'>
                        <thead>
                            <tr className='text-left pb-1 border-b-2 border-pcx-300'>
                                <th className='pr-2'>Member</th>
                                <th className='pr-2'>Application Date (or Prio)</th>
                                {total.map(({year}, i) => <th className='pr-2' key={year}>Year {year}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {models.map(({ member, byYear }) => {
                                return <tr key={member.familyMemberId} className='hover:bg-pcx-200' >
                                    <td className='pr-2'><Link className='underline' to={memberUrl(member)}>{member.internalReference}</Link></td>
                                    <td className='pr-2 tabular-nums text-right'>{member.applicationDate ?? `PD: ${family.priorityDate}` ?? ''}</td>
                                    {byYear.map(({ cost, year }) => <td className='pr-2 text-right tabular-nums' key={year}>{cost?.toFixed(0) ?? ''}</td>)}
                                </tr>
                            })}
                            <tr className='text-left pt-1 border-t-2 border-pcx-300'>
                                <td className='pr-2'>Total</td>
                                <td></td>
                                {total.map(({year, cost}) => <td className='pr-2 text-right tabular-nums' key={year}>{cost?.toFixed(0) ?? 0}</td>)}
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </Disclosure.Panel>
        </>}</Disclosure>
    )
}

function ScenarioModelSandbox({currency, refDate}) {
    const {fxConverter} = useFxContext()

    const today = new Date(refDate)
    const [type, setType] = useState("national")
    const [countries, setCountries] = useState([])
    const [firstFiling, setFirstFiling] = useState(false)
    const [unitaryPatent, setUnitaryPatent] = useState(false)
    const [applicationDate, setApplicationDate] = useState(refDate)

    const scenarioMember = {type, countries, firstFiling, unitaryPatent, applicationDate}

    const models = [scenarioModel({scenarioMember, today, currency, fxConverter})]
    const costs = sumModels({models, today})
    return (
        <Disclosure as='div' className='bg-white p-4 rounded-md w-fit'>{({open}) => <>
            <Disclosure.Button className='flex justify-between min-w-sm'>
                <h3 className='mb-2'>Scenario Model Sandbox</h3>
                <ChevronUpIcon className={clsx('w-5 h-5', open ? 'rotate-180' : '')} />
            </Disclosure.Button>
            <Disclosure.Panel className='flex flex-row flex-wrap gap-8 items-start'>
                <div className='flex flex-col gap-2 max-w-md'>
                    <select className='form-select w-fit' value={type} onChange={e => setType(e.target.value)}>
                        <option value="national">National Applications</option>
                        <option value="pct">PCT</option>
                        <option value="ep">EP</option>
                    </select>
                    <TagList {...{ tags: countries, setTags: setCountries, availableTags: countryCodes }} />
                    <label><input className='form-checkbox' type="checkbox" checked={firstFiling} onChange={e => setFirstFiling(e.target.checked)} /> First Filing</label>
                    <label><input className='form-checkbox' type="checkbox" checked={unitaryPatent} onChange={e => setUnitaryPatent(e.target.checked)} /> Unitary Patent</label>
                    <label>Application Date: <input className='form-input py-1' type="date" value={applicationDate} onChange={e => setApplicationDate(e.target.value)} /></label>
                    <CostChart {...{ costs }} />
                </div>

                <table>
                    <thead><tr><th className='pr-2'>Year</th><th>Cost</th></tr></thead>
                    <tbody className='text-xs'>
                        {_.chunk(models[0], 12).map((ms, i) =>
                            <tr key={i}>
                                <td className='text-right pr-4'>{i}</td>
                                <td className='text-right tabular-nums'>{_.sum(ms).toFixed(1)}</td>
                            </tr>)}
                    </tbody>
                </table>
            </Disclosure.Panel>
        </>}</Disclosure>
    )
}

function key2member(key) {
    return {
        countryCode: key.length > 1 ? key.slice(key.length - 2) : '**',
        validated: key.slice(0, 2) === "EP" || key.slice(2, 4) === "EP",
        unitaryPatent: key.slice(0, 2) === "EU" || key.slice(2, 4) === "EU",
        pctRouteFiling: key.slice(0, 2) === "WO" || key.slice(2, 4) === "WO",
        firstFiling: key.length === 2,
    }
}

function ModelForKey({currency}) {
    const {fxConverter} = useFxContext()
    const [key, setKey] = useState('')
    const [keys, setKeys] = useState(['CH', 'DE'])
    const [ipType, setIpType] = useState('patent')
    const [withHeader, setWithHeader] = useState(true)

    const years = [...Array(25).keys()]

    return (
        <Disclosure as='div' className='bg-white p-4 rounded-md w-fit'>{({open}) => <>
            <Disclosure.Button className='flex justify-between min-w-sm'>
                <h3>Model for Key</h3>
                <ChevronUpIcon className={clsx('w-5 h-5', open ? 'rotate-180' : '')} />
            </Disclosure.Button>
            <Disclosure.Panel>
                <div className='flex flex-row gap-4 my-2'>
                    <select className='form-select py-px' value={ipType} onChange={e => setIpType(e.target.value)}>
                        <option value="patent">Patent</option>
                        <option value="utility-model">Utility Model</option>
                    </select>
                    <label className='inline-flex gap-2 items-center'>
                        <input className='form-checkbox' type="checkbox" checked={withHeader} onChange={e => setWithHeader(e.target.checked)} />
                        With Header
                    </label>
                    <TableCopyButton tableId='key-model-table' />
                </div>
                <form onSubmit={e => { e.preventDefault(); setKeys(ks => [...ks, key]); setKey('') }}>
                    <table id="key-model-table">
                        <thead>
                            {withHeader && <tr className='whitespace-nowrap text-left'>
                                <th>Input Key</th>
                                <th>Model Key</th>
                                {years.map(y => <th className='px-1' key={y}>Year {y}</th>)}
                            </tr>}
                        </thead>
                        <tbody>
                            {keys.map((key, ki) => {
                                const {fees, other_costs} = findModels(key2member(key), 'patent', currency)
                                const amounts = sum_amounts(
                                    fxConverter.convert(fees.amounts, fees.currency, currency), 
                                    fxConverter.convert(other_costs.amounts, other_costs.currency, currency))
                                function update(text) {
                                    setKeys(ks => ks.map((k, i) => i === ki ? text : k))
                                }
                                return (
                                    <tr key={ki}>
                                        <td>
                                            <div suppressContentEditableWarning={true} contentEditable={true}
                                                className='border w-20' onBlur={e => update(e.target.innerText)} >
                                                {key}
                                            </div>
                                        </td>
                                        <td>{other_costs?.key}/{fees?.key} <button type="button" onClick={() => setKeys(ks => ks.filter((k, i) => i !== ki))} className='float-right mr-1'>X</button>
                                        </td>
                                        {years.map(y => <td className='px-1 text-right tabular-nums' key={y}>{(amounts[y] ?? 0).toFixed(2)}</td>)}
                                    </tr>
                                )
                            })}
                            <tr>
                                <td>
                                    <input className='border w-20' type="text" value={key} onChange={e => setKey(e.target.value)} />
                                </td>
                                <td><input type="submit" className='btn-secondary py-0' value="add" /></td>
                            </tr>
                        </tbody>

                    </table>
                </form>
                </Disclosure.Panel>
        </>}</Disclosure>
    )
}

function ModelParameters() {

    const parameterNames = [
        "key", "currency", "ipType", "costType",
    ]
    const [parameterFilterValues, setParameterFilterValues] = useState(parameterNames.map(() => "")) 
    //console.log({parameterFilterValues})

    const costTypes = ['other_costs', 'fees']

    function filterParameter(params) {
        return parameterFilterValues.reduce((acc, value, i) => {
            return acc && (value === "" || params[parameterNames[i]].toLowerCase().includes(value.toLowerCase()))
        }, true)
    }
    return (
        <Disclosure as='div' className='bg-white p-4 rounded-md w-fit'>{({open}) => <>
            <Disclosure.Button className='flex justify-between min-w-sm'>
                <h3>Parameters</h3>
                <ChevronUpIcon className={clsx('w-5 h-5', open ? 'rotate-180' : '')} />
            </Disclosure.Button>
            <Disclosure.Panel>
                <TableCopyButton tableId='model-table' />
                <table id="model-table">
                    <thead>
                        <tr className='whitespace-nowrap text-left'>
                            {[
                                <th key='seq' className='pr-1'>Sequence</th>,
                                <th key='cur' className='px-1'>Currency</th>,
                                <th key='ipt' className='px-1'>IP Type</th>,
                                <th key='ct' className='px-1'>Cost Type</th>,
                                ...[...Array(25).keys()].map(i => <th className='px-1' key={i}>Year {i}</th>)
                            ]}
                        </tr>
                    </thead>
                    <tbody>
                        {[
                            <tr key="filter-row">
                                {parameterNames.map((name, i) =>
                                    <td className='px-1' key={i}>
                                        <input
                                            className='border border-slate-200 focus:border-slate-600 w-16' type="text"
                                            value={parameterFilterValues[i]}
                                            onChange={e => setParameterFilterValues(vs => _.set([...vs], i, e.target.value))}
                                        />
                                    </td>
                                )}
                            </tr>,
                            ...costTypes.flatMap(costType =>
                                modelParameters[costType].filter(ps => filterParameter({...ps, costType})).map(({ key, currency, ipType, amounts }) => {
                                    const formatter = new Intl.NumberFormat('de-CH') // .format(number));
                                    return (
                                        <tr key={`${key}-${costType}-${ipType}`} className='whitespace-nowrap'>
                                            {[
                                                <td key='key' className='pr-1'>{key}</td>,
                                                <td key='cur' className='px-1'>{currency}</td>,
                                                <td key='ipt' className='px-1'>{ipType}</td>,
                                                <td key='ct' className='px-1'>{costType}</td>,
                                                ...[...Array(25).keys()].map(i =>
                                                    <td className='px-1 text-right tabular-nums' key={i}>{amounts[i] && formatter.format(amounts[i])}</td>)
                                            ]}
                                        </tr>
                                    )
                                })
                            )
                        ]}
                    </tbody>
                </table>
            </Disclosure.Panel>
        </>}</Disclosure>
    )
}