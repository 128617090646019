import clsx from "clsx"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { NavLink, Outlet } from "react-router-dom"
import {Formik, Form, Field} from "formik"

import { useDmSettings } from "../DennemeyerProvider"
import { DatePicker } from "../../components/DatePicker"

export default function Settings() {
    const {t} = useTranslation()

    return <>
        <div className="header-row pb-1 flex flex-row gap-x-6 gap-y-2 w-full flex-wrap sm:flex-nowrap">
            <NavLink to="credentials" className={navLinkStyle }>{t('settings')}</NavLink>
            <NavLink to="bulk-import" className={navLinkStyle }>{t('bulk-import')}</NavLink>
            {/* TODO DELETE */}
            {/* <NavLink to="import" className={navLinkStyle }>{t('import')}</NavLink>
            <NavLink to="old-import" className={navLinkStyle }>{'OLD ' + t('import')}</NavLink> */}
            <NavLink to="notifications" className={navLinkStyle }>{t('notifications')}</NavLink>
        </div>
        <Outlet />
    </>
}

function navLinkStyle({ isActive }) {
    return clsx(
        "text-base hover:text-pcx-400 whitespace-nowrap",
        isActive ? "text-pcx-500" : "text-slate-400",
    )
}

export function MasterData() {
    const {t} = useTranslation()

    return <>
        <div className="header-row pb-1 flex flex-row gap-x-6 gap-y-2 w-full flex-wrap sm:flex-nowrap">
            <NavLink to="owners" className={navLinkStyle }>{t('owners')}</NavLink>
            <NavLink to="cost-centers" className={navLinkStyle }>{t('cost-centers')}</NavLink>
            <NavLink to="api-status" className={navLinkStyle }>{t('api-status')}</NavLink>
        </div>
        <Outlet />
    </>
}

export function Credentials() {
    const {t} = useTranslation()

    const {settings, postSettings} = useDmSettings()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('credentials')} | {t('admin-settings')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <div className="main-content bg-pcx-100">
            <Formik 
                initialValues={settings || {clientId: '', clientSecret: '', renewalNoticeMonths: 5, instructionPeriodMonths: 3, paymentTermsDays: 30}} 
                enableReinitialize
                onSubmit={(values) => {
                    postSettings(values)
                }}
            >{({values, dirty, resetForm}) => 
                <Form className="grid grid-cols-1 md:grid-cols-[auto_auto] gap-8 w-fit p-4 rounded-md bg-white shadow">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('account-details')}</h2>
                        <p className="max-w-sm mt-1 text-sm leading-6 text-gray-600">{t('account-details-desc')}</p>
                    </div>
                    <div className="flex flex-col gap-6">
                        <label>
                            <div className="label mb-1">{t('clientId')}</div>
                            <Field className='form-input' name="clientId" type="text" required />
                        </label>
                        <label>
                            <div className="label mb-1">{t('clientSecret')}</div>
                            <Field className='form-input' name="clientSecret" type="password" required />
                        </label>
                    </div>

                    <div className="md:col-span-2 border-b border-gray-900/10 pt-2" />

                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('instruction-timeline')}</h2>
                        <p className="max-w-sm mt-1 text-sm leading-6 text-gray-600">{t('instruction-timeline-desc')}</p>
                        <p className="text-xl text-gray-400 font-semibold text-center mt-3">
                            {values.renewalNoticeMonths}-{values.instructionPeriodMonths}-{values.paymentTermsDays}
                        </p>
                    </div>
                    <div className="flex flex-col gap-6">
                        {['renewalNoticeMonths', 'instructionPeriodMonths', 'paymentTermsDays'].map(field =>
                            <label key={field}>
                                <div className="label mb-1">{t(field)}</div>
                                <Field className='form-input' name={field} type="number" required />
                            </label>
                        )}
                    </div>

                    <div className="md:col-span-2 border-b border-gray-900/10 pt-2" />

                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Various</h2>
                        <p className="max-w-sm mt-1 text-sm leading-6 text-gray-600">{t('startDate')}: Only show maintenance actions for after this date.</p>
                    </div>

                    <div className="flex flex-col gap-6">
                        <label>
                            <div className="label mb-1">{t('startDate')}</div>
                            <Field className='form-input' name="startDate" as={DatePicker} />
                        </label>
                    </div>

                    <div className="md:col-span-2 border-b border-gray-900/10 pt-2" />
                    {/* Handling of client secret */}
                        <div className="md:col-span-2">
                            <div className="flex flex-row-reverse gap-4 pb-4">
                                <input type="submit" disabled={!dirty} className="btn-primary disabled:btn-disabled" value={t('save')} />
                                <button type="button" className="btn-secondary" onClick={() => resetForm()}>{t('cancel')}</button>
                            </div>
                        </div>
                </Form>
            }</Formik>
        </div>
    </>
}