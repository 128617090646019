import clsx from "clsx";
import { useTranslation } from "react-i18next"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon, PauseIcon, PlayIcon, StopIcon } from "@heroicons/react/24/solid";

import { MaintenanceAction, PcInstruction } from "./DennemeyerProvider";
import { DueDateStatus } from "./settings/instruction_timeline";
import { extractStatusDescription } from "./utils";


export function FeeAmount({amount, currency}: {amount: number | string, currency: string}) {
    return (
        <div className='md:text-lg font-semibold text-pcx-900 tabular-nums whitespace-nowrap self-end'>
            {amount} <span className="text-slate-500 font-normal text-sm">{currency}</span>
        </div>
    )
}

export const icons = {
    "Auto-Pay in Progress": <PlayIcon className="w-5 h-5 text-pcx-500" />,
    "Instructed / In Progress": <PlayIcon className="w-5 h-5 text-pcx-500" />,
    "Closed": <CheckIcon className="w-5 h-5 text-pcx-500" />,
    "Waiting For Instruction": <PauseIcon className="w-5 h-5 text-pcx-300" />,
    "Inactive": <StopIcon className="w-5 h-5 text-warn-300" />,
    "Unknown": <QuestionMarkCircleIcon className="w-5 h-5 text-slate-500" />,
}

export function StatusView(
    {maintenanceAction, status, instruction, deprecated = false}: 
    {maintenanceAction: MaintenanceAction, status: DueDateStatus, instruction?: PcInstruction, deprecated?: boolean}
) {
    const {t} = useTranslation()

    const [phase, icon_str] = extractStatusDescription({maintenanceAction, status, instruction, t})
    const icon = icons[icon_str] ?? null

    return (
        <div
            className={clsx("relative pl-6 text-left text-sm", deprecated ? "text-slate-400 line-through" : "text-pcx-800")}
            title={'Payment Provider: ' + maintenanceAction?.Phase + (deprecated ? ` (${t('superseded')})` : '')}
        >
            <div className={clsx("absolute left-0", deprecated && "opacity-20")}>
                {icon ?? <div className="w-5 h-5" />}
            </div>
            {phase}
        </div>
    )
}


// Permanent Payment
//   - Auto Pay in Progress (without instruction)
//     ->  [~Pay] -> 'Will be Paid' (= Auto Pay in Progress | Instructed / In Progress)
//     ->  [Hold] -> Waiting for Instructions
//     ->  [Cancel] -> Inactive
//   - Waiting for Instruction 
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - 'Will be Paid' (Auto Pay in Progress with custom Pay instruction)
//      -> [Undecide] -> (remove instruction) -> Auto Pay in Progress 
//      -> [Hold] -> Waiting for Instructions
//      -> [Cancel] -> Inactive
//   - Instructed / In Progress
//     (no actions)
//   - Inactive
//     (no actions)

// Manual Instructions
//   - Waiting for Instruction (without instruction)
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - 'Will not be paid' (Waiting for Instruction with custom Skip instruction)
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - Inactive
//     (no actions)
//   - Instruction / In Progress
//     (no actions)


// Test cases
// 1. Permanent Order
//  1.1 Auto Pay in Progress
/*
        {
          "DennemeyerId" : "ce8ae042-cf4c-4387-bf47-c642fe4c8b6c",
          "IpRightInfo" : {
            "DennemeyerId" : "860d9bc7-6981-4509-80fa-06aef820bf2c",
            "UniqueCaseKey" : "214",
            "Type" : "Patent",
            "SubType" : "patent",
            "CountryCode" : "DE",
            "ApplicationNumber" : "15721532-8",
            "GrantNumber" : "602015014742-1",
            "CustomerReference" : "P3999 EP-DE",
            "Status" : "Granted/Registered"
          },
          "ActionType" : "Annuity",
          "DueDate" : "2024-05-04",
          "Annuity" : 10,
          "Status" : "Auto-Pay in Progress",
          "Phase" : "Auto-Pay in Progress",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1599461,
              "RNDennemeyerId" : "c3f9d93a-e82b-44d8-bfdc-f826967c221e",
              "IssueDate" : "2023-11-24",
              "Currency" : "EUR",
              "TotalFee" : 445.00
            }
          ],
          "FeesActual" : [
          ]
        },

        // INACTIVE
                {
          "DennemeyerId" : "40923b09-6c80-46a7-b747-90212fdfb9f2",
          "ActionType" : "Annuity",
          "DueDate" : "2023-07-16",
          "Annuity" : 3,
          "Status" : "Inactive",
          "Phase" : "Inactive",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1594789,
              "RNDennemeyerId" : "1eb6c6ad-3cf1-4e43-a8c8-19669f7fb176",
              "IssueDate" : "2023-11-15",
              "Currency" : "EUR",
              "TotalFee" : 545.00
            }
          ],
          "FeesActual" : [
          ]
        }

        // INSTRUCTED
                {
          "DennemeyerId" : "ca193c11-0995-4579-8636-72e05c773a17",
          "ActionType" : "Annuity",
          "DueDate" : "2024-04-07",
          "Annuity" : 4,
          "Status" : "Instructed / In Progress",
          "Phase" : "Instructed / In Progress",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1594805,
              "RNDennemeyerId" : "d6c5a51f-7323-41e9-891f-0af2f6ff2e9c",
              "IssueDate" : "2023-11-15",
              "Currency" : "EUR",
              "TotalFee" : 675.00
            }
          ],
          "FeesActual" : [
          ]
        }
*/
//  1.2 Waiting for Instruction