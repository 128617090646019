export function detectOS() {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes('mac')) {
        return 'Mac';
    } else {
        return 'Windows/Linux';
    }
}


export const ctrlKey = detectOS() === 'Mac' ? '⌘' : 'CTRL';