import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import deCommon from './locales/de/common.json'
import enCommon from './locales/en/common.json'
import frCommon from './locales/fr/common.json'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        resources: {
            en: { common: enCommon },
            de: { common: deCommon },
            fr: { common: frCommon },
        },
        fallbackLng: "en",
        debug: process.env.NODE_ENV !== 'production',
        defaultNS: "common",
        transKeepBasicHtmlNodesFor: ['br', 'i', 'p', 'strong', 'sup', 'sub']
    });

export default i18n