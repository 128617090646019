import { createContext, useContext } from "react";
import { Agent, AgentLink, createAgentsByMemberId, createAgentsLookup, isEqualAgentLink } from "./utils";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import _ from 'lodash'
import { agent_link } from "../data";

const AgentsContext = createContext({
    agents: [] as Agent[],
    postAgent: (agent: Agent) => Promise.resolve(agent),
    deleteAgent: (agent: Agent) => Promise.resolve({}),
    agentById: {} as Record<number, Agent>,

    agentLinks: [] as AgentLink[],
    postAgentLink: (link: AgentLink | AgentLink[]) => Promise.resolve(link) as Promise<AgentLink | AgentLink[]>,
    deleteAgentLink: (link: AgentLink | AgentLink[]) => Promise.resolve({}),
    agentsLookup: {} as Record<string, Record<number, number[]>>, // agentLookup[linkType][agentId] => [familyMemberId]
    agentsByMemberId: {} as Record<string, Record<number, number[]>>, // agentsByMemberId[linkType][memberId] => [agentId]

    isLoading: false as boolean,
    reload: () => {},
    refetchAgents: () => Promise.resolve({}),
})

export default function AgentsProvider({ children }: { children: React.ReactNode }) {
    const {data: agents, postMutation: postAgent, deleteMutation: deleteAgent, isLoading: isLoadingAgents, reload: reloadAgents, refetch: refetchAgents} =
        useCrud<Agent>("agent", a => a.agentId)

    const {data: agentLinks, postMutation: postAgentLink, deleteMutation: deleteAgentLink, isLoading: isLinksLoading, reload: reloadAgentLinks} =
        useLinkedCrud<AgentLink>(agent_link, isEqualAgentLink)

    const agentById = _.keyBy(agents, a => a.agentId)
    const agentsLookup = createAgentsLookup(agentLinks)
    const agentsByMemberId = createAgentsByMemberId(agentLinks, agentById)

    const isLoading = isLoadingAgents || isLinksLoading

    function reload() {
        reloadAgents()
        reloadAgentLinks()
    }

    const value = {
        agents,
        postAgent,
        deleteAgent,
        agentById,
        agentLinks,
        postAgentLink,
        deleteAgentLink,
        agentsLookup,
        agentsByMemberId,
        isLoading,
        reload,
        refetchAgents,
    }

    return <AgentsContext.Provider {...{value}}>
        {children}
    </AgentsContext.Provider>
}

export function useAgents() {
    return useContext(AgentsContext)
}