import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PlayIcon, RectangleStackIcon, StopIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import _ from "lodash"

import { AugmentedMaintenanceAction, canBeInstructed } from "../utils"
import Modal from "../../components/Modal";
import { usePatents } from "../../patents/PatentsProvider";
import { ExpiryRibbon, MemberRibbons } from "../components"
import { DmStatus, useDennemeyer } from "../DennemeyerProvider"
import { IconSpinner } from "../../components/icons"
import { emptyStringAsUndefined } from "../../utils/strings"
import { useComments } from "../../comments/CommentsProvider"
import { family_member } from "../../data"
import { useAuth } from "../../user/Auth"
import { Instruction } from "../renewals"
import { useIpRightsImport } from "../import/PaymentHandlingProvider"
import { post_instructions } from "../dennemeyer_backend"

export function BulkEditRow({maintenanceActions}: {maintenanceActions: AugmentedMaintenanceAction[]}) {
    const {t} = useTranslation()

    const {instructionByDennemeyerId} = useDennemeyer()
    const [showBulkInstruction, setShowBulkInstruction] = useState(false)
    const possible = maintenanceActions.filter(maintenanceAction => canBeInstructed({
        maintenanceAction, 
        status: maintenanceAction.status,
        strict: true,
        instruction: instructionByDennemeyerId[maintenanceAction.DennemeyerId]
    }))
    //console.log({maintenanceActions, possible})

    if (possible.length < 2) return null

    return <>
        <div className="col-span-4 px-2 pt-1.5 mt-1.5 first:pt-0 first:mt-0">
            <div className="flex flex-row-reverse py-1">
                <button className="btn-tertiary whitespace-nowrap text-sm" onClick={() => setShowBulkInstruction(s => !s)}>
                    <RectangleStackIcon className="size-4 inline mb-0.5 mr-1" />
                    {t('bulk-instruction')} ({possible.length})
                </button>
            </div>
        </div>
        {showBulkInstruction && <BulkEditModal {...{maintenanceActions: possible, close: () => setShowBulkInstruction(false)}} />}
    </>
}

// Assumption: The maintenanceActions should all be not instructed yet
function BulkEditModal({maintenanceActions, close}: {maintenanceActions: AugmentedMaintenanceAction[], close: () => void}) {
    const {t} = useTranslation()
    const {user: {name: user}} = useAuth()

    const {familyById, postMember} = usePatents()
    const {postInstruction, owners, invalidateIpRights} = useDennemeyer()
    const fallbackId = owners[0]?.ownerId
    const {deriveInitial, postIpRights} = useIpRightsImport(fallbackId)
    const {addComment} = useComments()

    const [instructions, setInstructions] = useState({} as Record<string, boolean | undefined>)
    const [comment, setComment] = useState('')
    const [alsoStopWithPc, setAlsoStopWithPc] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const maintenanceActionById = _.keyBy(maintenanceActions, 'DennemeyerId')

    async function instruct() {
        setIsSubmitting(true)
        const instructionsToProcess = _(instructions).toPairs()
            .map(([DennemeyerId, instruction]) => {
                 if (instruction !== undefined) {
                     return {dennemeyerId: DennemeyerId, instruction: (instruction ? 'Pay' : 'Cancel') as Instruction}
                 }
            })
            .filter(Boolean)
            .value()
        const _comment = emptyStringAsUndefined(comment)

        const finalInstructionsP = instructionsToProcess
            .map(async ({ dennemeyerId, instruction }) => {
                const maintenance = maintenanceActionById[dennemeyerId]
                const ipRight = maintenance.ipRight
                const member = maintenance.member
                let commentId = undefined
                if (_comment) {
                    const result = await addComment({
                        entity: family_member, entityId: ipRight.familyMemberId,
                        comment: `${instruction} - ${_comment}`,
                        user, created: new Date().toISOString()
                    })
                    commentId = result?.commentId
                }
                await postInstruction({ dennemeyerId: maintenance.DennemeyerId, instruction, ipRightId: ipRight.ipRightId, commentId })
                return { DennemeyerId: dennemeyerId, Instruction: instruction, maintenance, member }
            })
        const finalInstructions = await Promise.all(finalInstructionsP)

        const toDm = finalInstructions.filter(({Instruction, maintenance}) => 
            (Instruction === 'Cancel' && maintenance.PermanentOrder) || (Instruction === 'Pay' && !maintenance.PermanentOrder))

        if (toDm.length > 0)
            await post_instructions(toDm)

        const cancel = finalInstructions.filter(({Instruction}) => Instruction === 'Cancel')
        const cancelDm = cancel.map(({member}) => {
            const initial = deriveInitial({familyMemberId: member.familyMemberId})
            return {...initial, status: 'Inactive' as DmStatus}
        })
        if (cancelDm.length > 0)
            await postIpRights(cancelDm)

        if (alsoStopWithPc && cancel.length > 0) {
            await Promise.all(cancel.map(({member}) => postMember({...member, familyMemberStatus: 'stopped'})))
        }
        
        invalidateIpRights()
        setIsSubmitting(false)
        close()
    }

    const buttonStyle = "border p-0.5 rounded"
    function StopButton({ DennemeyerId }: { DennemeyerId: string }) {
        const isStopped = instructions[DennemeyerId] === false
        return <button
            title={t('ip-right-cancel')}
            className={clsx(buttonStyle, "border-warn-400", isStopped ? "bg-warn-400 text-white hover:bg-warn-300" : "text-warn-300 hover:bg-warn-400 hover:text-white")}
            onClick={() => setInstructions({...instructions, [DennemeyerId]: isStopped ? undefined: false})}
        >
            <StopIcon className="size-4 " /> <span className="sr-only">Cancel</span>
        </button>
    }
    function PlayButton({ DennemeyerId }: { DennemeyerId: string }) {
        const isPlay = instructions[DennemeyerId] === true
        return <button
            title={t('ip-right-pay')}
            className={clsx(buttonStyle, "border-pcx-400", isPlay ? "text-white bg-pcx-400 hover:bg-pcx-300" : "text-pcx-300 hover:bg-pcx-400 hover:text-white")}
            onClick={() => setInstructions({...instructions, [DennemeyerId]: isPlay ? undefined: true})}
        >
            <PlayIcon className="size-4 " /> <span className="sr-only">Pay</span>
        </button>
    }
    function setAll(instruction: boolean) {
        setInstructions(_(maintenanceActions).map(ma => [ma.DennemeyerId, instruction]).fromPairs().value())
    }

    let familyName = undefined
    const families = _(maintenanceActions).map(m => m.member.patentFamilyId).uniq().value()
    if (families.length === 1) {
        familyName = familyById[families[0]]?.familyName
    }

    const nothingSelected = _.every(instructions, i => i === undefined)

    const isLongList = maintenanceActions.length > 5

    return (
        <Modal escAction={() => nothingSelected && close()}>
            <div className="py-4 px-2 min-w-md">
                <h3 className="mb-2 px-2">{t('bulk-instruction')} {familyName ? `- ${familyName}` : ''}</h3>
                <p className="mb-2 text-slate-500 px-2">{t('only-undecided-listed')}</p>
                <div className="grid grid-cols-[1fr_auto_auto_auto] gap-x-8">
                    <div className={clsx("col-span-4 grid grid-cols-subgrid items-end border-b-2 border-pcx-400", isLongList && "mx-2")}>
                        {['patent', 'due', 'fees'].map(label =>
                            <div key={label} className="text-pcx-600 text-sm font-semibold uppercase tracking-wider py-1 first:pl-2">{t(label)}</div>
                        )}
                        <div className="flex flex-col items-start text-xs place-self-end pb-1 pr-2">
                            <button onClick={() => setAll(true)}  className="text-pcx-700 hover:text-pcx-500 group">  <PlayIcon className="text-pcx-400  group-hover:text-pcx-300 size-4 inline mr-1 mb-0.5" />{t('pay-all')}</button>
                            <button onClick={() => setAll(false)} className="text-warn-700 hover:text-warn-500 group"><StopIcon className="text-warn-400 group-hover:text-warn-300 size-4 inline mr-1 mb-0.5" />{t('cancel-all')}</button>
                        </div>
                    </div>
                <div className={clsx(
                    "col-span-4 grid grid-cols-subgrid max-h-[40vh] overflow-y-auto overflow-x-hidden",
                    isLongList && "shadow-inner-lg rounded-lg px-2 ",
                )}>
                    {maintenanceActions.map(maintenanceAction => {
                        const member = maintenanceAction.member
                        return (
                            <div
                                key={maintenanceAction.DennemeyerId} 
                                className="border-pcx-200 border-b-2 py-1 px-2 col-span-4 grid grid-cols-subgrid items-center" 
                            >
                                <div className="flex flex-row gap-1 items-center">
                                    <span className="text-lg text-pcx-800 mr-2" title={member.countryCode + ' - ' + (member.patentNumber ?? member.applicationNumber)}>
                                        {member.internalReference}
                                    </span>
                                    <div className="grow" />
                                    <ExpiryRibbon {...{ member }} short />
                                    <MemberRibbons {...{ member }} short />
                                </div>
                                <div title={t('instruction-due')} className="md:text-lg font-base text-pcx-900 tabular-nums whitespace-nowrap flex flex-row gap-1 items-center">
                                    {maintenanceAction.instructionDueDate}
                                </div>
                                <div className="place-self-end flex flex-col" title={maintenanceAction.Annuity ? `${t('annuity')}: ${maintenanceAction.Annuity}` : undefined}>
                                    {_(maintenanceAction.fees)
                                        .toPairs()
                                        .map(([currency, amount]) =>
                                            <div key={currency} className='md:text-lg font-base text-pcx-900 tabular-nums whitespace-nowrap self-end'>
                                                {amount} <span className="text-slate-500 font-normal text-sm">{currency}</span>
                                            </div>)
                                        .value()}
                                </div>
                                <div className="flex flex-row-reverse gap-1 items-center">
                                    <StopButton {...maintenanceAction} />
                                    <PlayButton {...maintenanceAction} />
                                </div>
                            </div>
                        )
                    })}
                </div>
                </div>
                <div className="w-full mt-3 px-2">
                    <label className="label my-2">{t('comment')}</label>
                    <textarea className="form-textarea w-full h-24 px-2" value={comment} onChange={e => setComment(e.target.value)}/>
                </div>
                <div className="p-2 pb-0 flex flex-row gap-2 items-center">
                    <input 
                        type="checkbox" className="form-checkbox disabled:bg-slate-300 peer" 
                        disabled={!_.some(instructions, i => i === false)}
                        checked={alsoStopWithPc} 
                        onChange={e => setAlsoStopWithPc(e.target.checked)} />
                    <div className="text-sm peer-disabled:text-slate-400">{t('stop-pc-cases')}</div>
                </div>
            </div>
            <div className="bg-pcx-200 flex flex-row-reverse gap-4 p-4">
                <button className="btn-primary" disabled={nothingSelected} onClick={instruct}>
                    {t('instruct')} {isSubmitting && <IconSpinner className="animate-spin size-4 text-pcx-100 inline ml-1 mb-0.5" />}
                </button>
                <button className="btn-secondary" onClick={close}>{t('cancel')}</button>
            </div>
        </Modal>
    )
}
