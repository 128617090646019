import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { colors } from "../data";

export type CostsOverview = {
    byYear: Record<number, number>;
}

const format = new Intl.NumberFormat(undefined, {maximumFractionDigits: 0})

export function CostsGraph({byYear}: CostsOverview) {
    const {t} = useTranslation()

    if (_.size(byYear) === 0) 
        return null

    const minYear = Math.min(...Object.keys(byYear).map(y => parseInt(y)))
    const maxYear = Math.max(...Object.keys(byYear).map(y => parseInt(y)))

    const data = _.range(minYear, maxYear + 1).map(year => ({year, amount: byYear[year]}))

    //console.log({byYear, data})

    return (
        <ResponsiveContainer className='text-sm' width="100%" height={200}>
            <BarChart width={500} height={300} data={data} >

                <XAxis dataKey="year" />
                <YAxis dataKey="amount" tickFormatter={format.format}/>

                <Bar name={t('costs')} dataKey="amount" fill={colors['pcx-500']} />

                <Tooltip 
                    cursor={false} wrapperStyle={{ outline: "none", background: colors['pcx-100'] }} 
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

export function CostsMainDriversGraph({ data }: { data: { name: string, total: number }[] }) {
    return (
        <ResponsiveContainer className="text-sm" width="100%" height={200}>
            <BarChart
                width={500} height={300}
                layout="vertical"
                data={data.slice(0, 10)}
                margin={{ left: 0, top: 5, right: 0, bottom: -10 }}
            >
                <YAxis type="category" dataKey="name" hide={true} tickFormatter={format.format} />
                <XAxis type="number" allowDecimals={false} />
                {/* @ts-ignore */}
                <Tooltip wrapperStyle={{ outline: "none" }} formatter={(value, name) => [format.format(value), name]} />
                <Bar
                    dataKey="total" name="Costs" fill={colors['pcx-300']}
                    label={({ x, y, height, value, name, width }) => {
                        //if (width < 32)
                        //    return null
                        //console.log({name, width})
                        const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size 
                        return <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']} >{name}: {format.format(value)}</text>
                    }}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}