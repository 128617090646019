import dayjs from 'dayjs'

// ==================
// Date Functions

export type DateOrString = Date | string;

export function plusPeriod(date: DateOrString, {years = 0, months = 0, days = 0}) {
    const dateInstance = date instanceof Date ? date : new Date(date)
    let result = new Date(
        dateInstance.getFullYear() + years,
        dateInstance.getMonth() + months,
        dateInstance.getDate() + days,
    )
    const offset = result.getTimezoneOffset()
    result = new Date(result.getTime() - (offset * 60 * 1000))
    return result.toISOString().split('T')[0]
}

const msInDay = 24 * 60 * 60 * 1000

export function diffDays(date1: DateOrString, date2: DateOrString) {
    const d1: Date = date1 instanceof Date ? date1 : new Date(date1)
    const d2: Date = date2 instanceof Date ? date2 : new Date(date2)
    const diff = d2.valueOf() - d1.valueOf()

    return Math.round(diff / msInDay)
}

const msInMonth = msInDay * 30

// TODO: look at the actual dates
export function diffMonths(date1: DateOrString, date2: DateOrString) {
    const d1 = date1 instanceof Date ? date1 : new Date(date1)
    const d2 = date2 instanceof Date ? date2 : new Date(date2)

    return Math.round((d2.valueOf() - d1.valueOf()) / msInMonth)
}

export function fromExcelDate(excelDate: number | undefined | string) {
    if (excelDate === undefined || excelDate === "")
        return undefined
    else if (typeof excelDate === "number") {
        const utc_days  = Math.floor(excelDate - 25569)
        const utc_value = utc_days * 86400 
        return new Date(utc_value * 1000).toISOString().slice(0, 10)
    } else
        return excelDate
}

export function prettyDate(date: string) {
    if (date === undefined)
        return ''
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}