import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { useTrademarks } from './TrademarksProvider'
import {LoadingModal} from '../components/Modal'

export default function TrademarksMenu() {
    const {t} = useTranslation()

    const {isLoading} = useTrademarks()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('trademarks')} | Patent Cockpit</title>
            </Helmet>
            {isLoading
                ? <LoadingModal />
                : <Outlet />}
        </>
    )
}