import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _, { Dictionary } from 'lodash'

import TagList from '../components/TagList'
import { Trademark, TrademarkFamily, useTrademarks } from './TrademarksProvider'
import { useLocalState } from '../settings/localStorage'
import { trademarkFamilyUrl, trademarkUrl } from './utils'
import { SortButton } from '../patents/datawizard/DataWizard'
import { nameOf } from '../agents/utils'
import { useFilteredTrademarks } from '../filter/FilteredTrademarks'
import { createExcelFile } from '../backend'
import { useAuth } from '../user/Auth'

const byTrademark = 'trademark'
const byTrademarkFamily = 'family'

const groupByFields = [byTrademark, byTrademarkFamily]

function useRows(groupBy: string): {trademarks: Trademark[], families: TrademarkFamily[], id: number}[] {
    // TODO: based on filter
    const { trademarks, trademarkFamilyById, trademarkFamilies, trademarksByFamilyId } = useFilteredTrademarks()
    switch (groupBy) {
        case byTrademark:
            return trademarks.map(tm => ({
                id: tm.trademarkId,
                trademarks: [tm],
                families: [trademarkFamilyById[tm.familyId]],
            }))
        case byTrademarkFamily:
            return trademarkFamilies.map(family => ({
                id: family.familyId,
                trademarks: trademarksByFamilyId[family.familyId] ?? [],
                families: [family],
            }))
        default:
            console.error("Unknown group by ", groupBy)
            return []
    }
}


export default function DataWizard() {
    const {t} = useTranslation()

    const {team} = useAuth()
    const [groupBy, setGroupBy] = useLocalState('tm-dw-group-by', byTrademark)

    const {trademarkById} = useTrademarks()
    const {trademarkClassesByTrademarkId, ownersByTrademarkId, contactsByTrademarkId} = useTrademarks()

    // TODO Bilder
    const _searchableFields = {
        trademark: {
            reference: (tm: Trademark) => tm.reference,
            words: (tm: Trademark) => tm.words,
            status: (tm: Trademark) => t(tm.status),
            countryCode: (tm: Trademark) => tm.countryCode,
            brandType: (tm: Trademark) => t(tm.brandType),
            applicationNumber: (tm: Trademark) => tm.applicationNumber,
            applicationDate: (tm: Trademark) => tm.applicationDate,
            registrationNumber: (tm: Trademark) => tm.registrationNumber,
            registrationDate: (tm: Trademark) => tm.registrationDate,
            renewalDate: (tm: Trademark) => tm.renewalDate,
            usageProofDate: (tm: Trademark) => tm.usageProofDate,
            priority: (tm: Trademark) => trademarkById[tm.priorityId]?.reference,
            trademarkClasses: (tm: Trademark) => (trademarkClassesByTrademarkId[tm.trademarkId] ?? []).map(tc => tc.number), //.sortBy().join(', '),
            owner: (tm: Trademark) => ownersByTrademarkId[tm.trademarkId]?.map(agent => nameOf(agent)),
            contactPerson: (tm: Trademark) => contactsByTrademarkId[tm.trademarkId]?.map(agent => nameOf(agent)),
            memberExtReference: (tm: Trademark) => tm.externalReference,
        },
        family: {
            familyReference: (family: TrademarkFamily) => family.reference,
            familyExtReference: (family: TrademarkFamily) => family.externalReference,
            name: (family: TrademarkFamily) => family.name,
            description: (family: TrademarkFamily) => family.description,
        },
    }

    const searchableFields = _(_searchableFields).toPairs()
        .flatMap(([group, fields]) => _(fields).toPairs().filter(([field, fn]) => fn !== undefined).value())
        .fromPairs()
        .value()
    const validSearchFields = Object.keys(searchableFields)

    const [_sf, _setSF] = useLocalState('tm-dw-search-fields', '')
    const searchFields = _sf.split(',').filter(f => validSearchFields.includes(f))
    const setSearchFields = (fs: string[]) => _setSF(fs.join(','))
    //const [searchFields, setSearchFields] = useState([])

    const [sortField, setSortField] = useLocalState('tm-dw-sort-field', searchFields[0] ?? '')
    const [sortOrder, setSortOrder] = useLocalState('tm-dw-sort-order', 1)

    const sortIndex = searchFields.indexOf(sortField)
    const collator = new Intl.Collator()
    
    const displayFields = Object.fromEntries([
        //...groupableFields,
        ..._(searchableFields).toPairs().map(([group, fields]) => [group, ..._.keys(fields)]).value()
    ].map(f => [f, t(f)]))

    const rows = useRows(groupBy)

    const rowData = _(rows)
        .map(row => ({
            ...row,
            columns: searchFields.map(field => {
                let values = []
                if (field in _searchableFields.trademark) {
                    values = _(row.trademarks).flatMap(_searchableFields.trademark[field]).value()
                } else if (field in _searchableFields.family) {
                    values = _(row.families).flatMap(_searchableFields.family[field]).value()
                }
                return _(values).filter(v => v !== undefined && v !== '').countBy().value()
            })
        }))
        .sort((rowA, rowB) => {
            if (sortIndex < 0) return 0
            const a = Object.keys(rowA.columns[sortIndex]).sort()?.[0]
            const b = Object.keys(rowB.columns[sortIndex]).sort()?.[0]
            return a === undefined ? 1 : b === undefined ? -1 : (collator.compare(a, b) * sortOrder)
        })
        .value()

    const tableData = { rows: rowData, columnKeys: searchFields, columns: searchFields.map(t) }

    function exportTable() {
        const excelFile = {
            sheets: [
                {
                    name: t('trademarks'),
                    header: tableData.columns,
                    rows: tableData.rows.map(row => row.columns.map(values => Object.keys(values).sort().join(', ')))
                }
            ]
        }
        createExcelFile(excelFile, `trademarks-data-${team.replace(' ', '-')}-${new Date().toISOString()}.xlsx`)
    }

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('data-wizard')} | {t('trademarks')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row max-w-3xl flex flex-row gap-2">
            <h2 className="modern-h2">{t('data-wizard')}</h2>
            <button onClick={exportTable} type="button" className='btn-secondary ml-auto text-sm py-0.5'>{t('excel-export')}</button>
        </div>
        <div className='main-content'>
            <div className='grid grid-cols-[8rem_1fr] gap-2 items-center'>
                <label htmlFor='group-by' className='label normal-case'>{t('group-by')}</label>
                <select id='group-by' className='form-select py-px pl-2 w-fit' value={groupBy} onChange={e => setGroupBy(e.target.value)}>
                    {groupByFields.map(field => <option key={field} value={field}>{t(field)}</option>)}
                </select>

                <label htmlFor='fields' className='label normal-case'>{t('fields')}</label>

                <TagList {...{
                    name: "searchFields",
                    dragable: true,
                    availableTags: _(_searchableFields)
                        .toPairs()
                        .map(([k, vs]) => [t(k), _(vs).keys().filter(v => vs[v] !== undefined).sortBy(v => displayFields[v] ?? v).value()])
                        .fromPairs()
                        .value(),
                    tags: searchFields,
                    setTags: setSearchFields,
                    tagDisplays: displayFields,
                    placeholder: t('field'),
                }} />
            </div>
            <table className='mt-8 border-spacing-0 border-collapse'>
                <thead className='text-left'>
                    <tr>
                    {searchFields.map(searchField => 
                        <th key={searchField} className='sticky bg-white dark:bg-pcx-100 font-semibold text-lg px-0 whitespace-nowrap'>
                            <div className='flex flex-row gap-2 items-center px-1 border-b-2 border-pcx-500 w-full'>
                            {displayFields[searchField]}

                            <SortButton {...{searchField, sortField, setSortField, sortOrder, setSortOrder}}  />
                            </div>
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                        {tableData.rows.map(row =>
                            <tr key={row.id} className='even:bg-pcx-100 dark:even:bg-pcx-200 last:border-b-2 last:border-pcx-500'>
                                {row.columns.map((values, colIdx) =>
                                    <td key={`${row.id}-${colIdx}`} className='px-1 align-top py-0.5'>
                                        {renderCell(values, searchFields[colIdx])}
                                    </td>)}
                            </tr>)}
                </tbody>
            </table>
        </div>
    </>
}

const linkStyle = "underline-link py-0 whitespace-nowrap after:content-[';'] mr-1 last:mr-0 last:after:content-['']"
function renderCell(values: Dictionary<number>, field: string) {
    // TODO Bilder
    if (field === 'reference' || field === 'priority') {
        return _.keys(values).sort().map(reference => <Link key={reference} className={linkStyle} to={trademarkUrl({reference})}>{reference}</Link>)
    } else if (field === 'familyReference') {
        return _.keys(values).sort().map(reference => <Link key={reference} className={linkStyle} to={trademarkFamilyUrl({reference})}>{reference}</Link>)
    } else {
        return _.keys(values).sort().join(', ')
    }
}