import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom";

import { AgorumRoot, BrowserProvider } from "./DocumentsProvider";
import { FolderView} from "./DocumentFiles"
import { getClientRoot, /*useAgorumObject*/ } from "./backend";
import { useDocumentSettings } from "./DocumentsSettings";
import { emptyStringAsUndefined } from "../utils/strings";
// import { DocumentSearch } from "./DocumentSearch";


export function DocumentsMenu() {
    const {t} = useTranslation()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('documents')} | Patent Cockpit</title>
            </Helmet>
            <div className="modern-header-row min-w-md max-w-xl">
                <h2 className="modern-h2">{t('documents')}</h2>
            </div>
            <Outlet />
        </>
    )
}

export default function DocumentsBrowser() {

    const {number} = useDocumentSettings()
    //const number = settings?.agorumClientId?.toString()

    if (!number) return null // TODO return empty state
    else return <DocumentsBrowserPage number={number} />
}

function DocumentsBrowserPage({number}: {number: string}) {
    const {t} = useTranslation()

    if (!emptyStringAsUndefined(number)) return null

    const root = getClientRoot(number)
    // TODO only used for search. Maybe push down?
    // const {object} = useAgorumObject({root})

    return (
        <div className="main-content pt-0 bg-pcx-100">
            <BrowserProvider>
                <div className="flex flex-row gap-4 items-start">
                    <div className="min-w-md xl:min-w-xl max-w-xl xl:max-w-2xl bg-white p-4 rounded-xl shadow hidden last:block md:block space-y-0.5">
                        <AgorumRoot {...{ root }} >
                            <FolderView name={t("documents")} defaultOpen nonEditable hasSortButton />
                        </AgorumRoot>
                    </div>
                    <Outlet />
                </div>
            </BrowserProvider>
        </div>
    )
}
