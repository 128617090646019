import { parseLight } from "../backend"

///const adminPath = '/api/dms/xxx'
const adminPath = '/api/dms/admin-pass-through'
const identifier = 'client-areas'

export function getAreaStatistics(number: string, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/areaStatistic/${identifier}/${number}?realm=${realm}`
    return parseLight(fetch(path))
}

export function getUserList(number: string, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/listUsers/${identifier}/${number}?realm=${realm}`
    return parseLight(fetch(path))
}

export function createArea(number: string, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/createArea?realm=${realm}`
    const body = { identifier, number}

    return parseLight(fetch(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json'},
    }))
}

export function deleteArea(number: string, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/deleteArea/${identifier}/${number}?realm=${realm}`

    return parseLight(fetch(path, {
        method: 'DELETE',
    }))
}

export interface AgorumUser {
    name: string;
    password: string;
    emailaddress: string;
    givenName: string;
    familyName: string;
    language: string;
    features: string[];
}

export function createUser(number: string, user: AgorumUser, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/createUser/${identifier}/${number}?realm=${realm}`
    const body = user

    return parseLight(fetch(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json'},
    }))
}

export function updateUser(number: string, user: AgorumUser, realm: string) {
    if (number.length !== 6) throw new Error('Invalid number')

    const path = `${adminPath}/api/rest/custom/agorum.client.areas.tools.noauth/modifyUser/${identifier}/${number}/${user.name}?realm=${realm}`
    const body = user

    return parseLight(fetch(path, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json'},
    }))
}

export function getOverview() {
    return parseLight(fetch("/api/dms/admin", {
        method: 'POST',
        body: JSON.stringify({ type: 'overview', operation: 'get' }),
        headers: { 'Content-Type': 'application/json'},
    })).then(r => r.payload)
}
