import { Popover } from "@headlessui/react";
import { IconCircleQuestionFlex } from "./icons";

export default function ToolTip({className, anchor, children}: {className?: string, anchor?: JSX.Element, children: JSX.Element | string}) {
    const backup =  <div className="w-5 h-5 cursor-help text-pc-400"><IconCircleQuestionFlex /></div>
    return (
        <Popover className="group hover:relative z-0 hover:z-20">
            {anchor ?? backup}
            <Popover.Panel static className={`hidden group-hover:block absolute ${className} bg-pc-500 text-slate-100 w-fit p-4 rounded-lg sm:min-w-md min-w-xs`}>
                {children}
            </Popover.Panel>
        </Popover>
    )
}