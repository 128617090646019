import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import _ from 'lodash'

import { getPortfolioUpdates } from '../backend'
import { ImportPortfolioSetup } from "./PatentsImport";
import { useFilteredPatents } from "../filter/FilteredPatents";

export default function PortfolioUpdate() {
    const {t} = useTranslation()

    const {families} = useFilteredPatents()

    const [selected, setSelected] = useState({} as {[key: string]: boolean})
    const all: boolean = _(families).every(f => selected[f.patentFamilyId] ?? false)

    function setAll(checked: boolean) {
        const s = _(families).map(f => [f.patentFamilyId, checked]).fromPairs().value()
        //console.log(s)
        setSelected(s)
    }

    const qString = _(selected).toPairs().filter(([k, v]) => v).map(([k, v]) => `patentFamilyId=${k}`).join("&")
    const nonSelected = qString.length === 0
    //console.log(qString)

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('portfolio-update')} | Patent Cockpit</title>
        </Helmet>
        <div className="portfolio-menu max-w-full">
            <h2>{t("portfolio-update")}</h2>
        </div>
        <div className="main-content bg-pcx-100">
            <div className="max-w-lg rounded-lg bg-white p-2 shadow-md">
                <Link className={clsx(nonSelected ? "btn-disabled" : "btn-secondary", "mt-2 ml-auto block w-fit")} 
                    to={nonSelected ? '.' : `import?${qString}`}>{t("import")}</Link>
                <div className="flex flex-row items-center gap-2 w-full border-b-2 border-pcx-200 py-2 px-2">
                    <input type="checkbox" className="form-checkbox" id="all" checked={all} onChange={e => setAll(e.target.checked)} />
                    <label htmlFor="all" className="text-slate-600">{t("all")}</label>
                </div>
                {_(families)
                    .sortBy(f => f.internalReference)
                    .map(family =>
                        <label key={family.internalReference} htmlFor={family.internalReference} className="flex flex-row items-center gap-2 py-2 px-2">
                            <input type="checkbox" id={family.internalReference}
                                className="form-checkbox"
                                checked={selected[family.patentFamilyId] ?? false}
                                onChange={e => setSelected(ss => ({ ...ss, [family.patentFamilyId]: e.target.checked }))}
                            />
                            <span className="whitespace-nowrap overflow-hidden text-ellipsis text-pcx-800">
                                {[family.internalReference, family.familyName].filter(Boolean).join(": ")}
                            </span>
                        </label>)
                    .value()}
            </div>
        </div>
    </>
}

function usePortfolioUpdates({patentFamilyIds}) {
    // TODO: don't use tanstack here
    const [portfolio, setPortfolio] = useState(undefined)
    useEffect(() => {
        if (patentFamilyIds.length > 0 && portfolio === undefined)
            getPortfolioUpdates({ patentFamilyIds })
                .then(setPortfolio)
    }, [patentFamilyIds, portfolio])
    //return useQuery(
    //    ['portfolio-updates', patentFamilyIds], 
    //    () => getPortfolioUpdates({patentFamilyIds}), { 
    //    enabled: patentFamilyIds.length > 0
    //})
    return {portfolio}
}

export function PortfolioUpdateImport() {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const patentFamilyIds = searchParams.getAll('patentFamilyId')
        .map(id => parseInt(id))

    const navigate = useNavigate()
        
    
    // TODO: adjust families to not update but keep what is there
    //const {data: portfolio, isLoading} = usePortfolioUpdates({patentFamilyIds})
    const {portfolio} = usePortfolioUpdates({patentFamilyIds})

    function afterImportAction() {
        navigate('/patents/portfolio')
    }

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('portfolio-update')} | Patent Cockpit</title>
        </Helmet>
        <div className="portfolio-menu max-w-full">
            <h2>{t("portfolio-update")}</h2>
        </div>
        <div className="main-content ">
            <Link to=".." className="text-slate-500 hover:text-slate-600 font-base text-base group">
                <ChevronLeftIcon className="w-5 h-5 inline" /> <span className="group-hover:underline">{t('back')}</span>
            </Link>
            {portfolio !== undefined &&
                <ImportPortfolioSetup {...{portfolio, maxFamilies: 100, afterImportAction, updateByDefault: true}} />
            }
        </div>
    </>

}