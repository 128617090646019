import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Disclosure } from "@headlessui/react";

import { useTrademarks } from "./TrademarksProvider";
import { Comment } from "../comments/Comments";
import { trade_mark, trademark_family } from "../data";
import { useComments, Comment as CommentProps } from "../comments/CommentsProvider";
import { useRoles } from "../user/Auth";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function TrademarkComments({trademarkId, familyId}: {
    trademarkId?: number;
    familyId: number;
}) {
    const {trademarkById, trademarkFamilyById} = useTrademarks()
    const {commentsLookUp} = useComments()

    const family = trademarkFamilyById[familyId]
    const trademark = trademarkId && trademarkById[trademarkId]

    return (
        <div className="flex flex-col gap-5">
            <TmEntityComments entity={trademark_family} entityId={familyId} comments={commentsLookUp[trademark_family]?.[familyId] ?? []} name={family.reference} />
            {trademark &&
                <TmEntityComments entity={trade_mark} entityId={trademark.trademarkId} comments={commentsLookUp[trade_mark]?.[trademark.trademarkId] ?? []} name={trademark.reference} />
            }
        </div>
    )
}

function TmEntityComments({entity, entityId, comments, name}: {name: string, entity: string, entityId: number, comments: CommentProps[]}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const addButtonStyle = "btn-secondary bg-white text-sm whitespace-nowrap min-w-fit"

    return <Disclosure as="div" defaultOpen>
        <div className="flex flex-row gap-2 p-1 mb-1 hover:bg-pcx-100 rounded-md">
            <Disclosure.Button className="flex items-center gap-1 grow min-w-0">{({open}) => <>
                <ChevronDownIcon className={`w-5 h-5 transform transition min-w-0 ${open ? '' : 'rotate-180'}`} />
                <h3 title={name} className="whitespace-nowrap overflow-hidden truncate min-w-0 shrink">{t('comment-for', { name })}</h3>
            </>}</Disclosure.Button>
            {isEditUser && 
                <Link title={t('add-comment')} to={`comments?entity=${entity}&entityId=${entityId}&name=${name}`} className={addButtonStyle}>
                    {t('add')}
                </Link>}
        </div>
        <Disclosure.Panel className="space-y-3">
            {comments.map((c, ci) => <Comment key={ci} {...c} />)}
        </Disclosure.Panel>
        <div />
    </Disclosure>
}