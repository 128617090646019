import { useField } from 'formik';


export default function Trinary({name, disabled}: {name: string, disabled?: boolean}) {
    const [field, meta, helpers] = useField({name})
    
    const {value} = meta
    const {setValue} = helpers

    function nextState() {
        const ns =
            value === undefined ? true
            : value === true ? false
            : undefined
        setValue(ns)
    }

    if (disabled)
        return <div className="border-pcx-500/20 border-2 h-5 w-5 rounded-sm" />
    else
        return (
            <button
                id={field.name}
                key={value} // looks weird, but this prevents a "double-click"
                type="button"
                disabled={disabled ?? false}
                className="cursor-pointer"
                onClick={() => !disabled && nextState()}
            >
                {
                    value === undefined
                        ? <span className="border-pcx-500/40 border-2 h-5 w-5 flex flex-row items-center justify-center font-medium text-base text-pcx-500/40"><span>?</span></span>
                        : value
                        ? <span className="block border-pcx-500 bg-pcx-500 border-2 h-5 w-5" ><CheckIcon className="font-medium h-4 w-4 text-white" /></span>
                        : <span className="block border-pcx-500 border-2 h-5 w-5" />
                }
            </button>
        )
}

function CheckIcon({className}) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect width="20" height="20" />
            <path d="M4 11.1429L7.9 15L17 6" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

interface StateButtonProps<T> {
    className?: string;
    disabled?: boolean;
    value: T;
    title?: string;
    onChange: (v: T) => void;
    states: {state: T, icon: JSX.Element}[];
}

export function StateButton<T>({value, onChange, states, className, title, disabled=false}: StateButtonProps<T>) {
    const current = states.findIndex(s => s.state === value)

    if (current < 0) return null

    function onClick() {
        const next = (current + 1) % states.length
        onChange(states[next].state)
    }

    return (
        <button type="button" {...{className, disabled, onClick, title}}>
            {states[current].icon}
        </button>
    )
}