import React from 'react'
import { Transition } from '@headlessui/react'
import { InformationCircleIcon, IdentificationIcon, EnvelopeIcon, KeyIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import { useDennemeyer, useDmSettings } from './DennemeyerProvider'
import { useRoles } from '../user/Auth'
import clsx from 'clsx'

/*
1. Has Team the correct settings?
    No -> Show contact page to get started
    Yes -> 2.
2. Has Team some basic settings (at least one owner incl. billing address) ?
    No -> Ask to provide information
    Yes -> 3.
3. Has Team any IP Rights?
    No -> Ask to start importing them
    Yes -> 4.
4. Show open imports, documents & instructions 
*/
export function useHints() {
    const {settings, isLoadingSettings} = useDmSettings()
    const {owners, ipRights, isLoading} = useDennemeyer()

    const has_no_settings = settings === undefined
    const has_no_owners  = owners?.length === 0
    const has_no_ip_rights = ipRights?.length === 0

    const isHintNeeded = has_no_settings || has_no_owners || has_no_ip_rights

    const hint = <Transition
        appear={true}
        show={isHintNeeded}
        as="div"
        className={clsx([
            'transition-opacity delay-1000',
            'data-[closed]:opacity-0',
            'data-[transition]:duration-150',
            'opacity-100',
        ])}
    >
        {(isLoading || isLoadingSettings) ? null
        : has_no_settings ? <NoSettingsHint />
        : has_no_owners ? <NoOwnersHint />
        : has_no_ip_rights ? <NoIpRightsHint />
        : null}
    </Transition>
    
    return {isHintNeeded, hint}
}

const linkStyle = 'font-medium text-pcx-600 hover:text-pcx-500 underline decoration-2 underline-offset-2 decoration-pcx-500/60'

function HintCard({title, children}: {title: string; children: React.ReactNode}) {
    return <>
        <div className='header-row'>
            <h2>{title}</h2>
        </div>
        <div className="main-content bg-pcx-100 text-slate-700 space-y-4 max-w-3xl">
            {children}
        </div>
    </>
}

type HintProps = {
    icon: React.ReactNode;
    text: React.ReactNode;
}

function Hints({hints}: {hints: HintProps[]}) {
    return <> {hints.map(({icon, text}, i) => 
        <div key={i} className='flex flex-row items-center p-4 gap-6 rounded-md bg-white shadow-sm max-w-xl ml-0'>
            <div className='text-pcx-500'>{icon}</div>
            <div>{text}</div>
        </div>
    )}</>
}

function NoSettingsHint() {
    const {t} = useTranslation()
    const { isEditUser } = useRoles()
    const hints = [
        {
            icon: <InformationCircleIcon className="h-6 w-6 " aria-hidden="true" />, 
            text: t('dm-not-setup')},
        {
            icon: <IdentificationIcon className="h-6 w-6 " aria-hidden="true" />, 
            text: <Trans i18nKey="go-to-settings" components={{settingslink: isEditUser ? <Link className={linkStyle} to="/renewals/settings/credentials" /> : <span/>}} />},
        {
            icon: <EnvelopeIcon className="h-6 w-6 " aria-hidden="true" />, 
            // eslint-disable-next-line 
            text: <Trans i18nKey="dm-contact-us" components={{maillink: <a className={linkStyle} href="mailto:support@patent-cockpit.com?subject=Dennemeyer" />}} />},
    ]
    return (
        <HintCard title={t('managing-with-dm')}>
            <Hints hints={hints} />
        </HintCard>
    )
}

function NoOwnersHint() {
    const {t} = useTranslation()
    const { isEditUser } = useRoles()
    const hints = [
        {
            icon: <KeyIcon className="h-6 w-6 " aria-hidden="true" />,
            text: <Trans i18nKey="add-one-owner" components={{ownerslink: isEditUser ? <Link className={linkStyle} to="/renewals/settings/owners/add" /> : <span/>}} />,
        }
    ]
    return (
        <HintCard title={t('no-owners-title')}>
            <Hints hints={hints} />
        </HintCard>
    )
}

function NoIpRightsHint() {
    const {t} = useTranslation()
    const hints = [
        {
            icon: <ArrowUpTrayIcon className="h-6 w-6 " aria-hidden="true" />,
            text: <Trans i18nKey="import-ip-rights-link" components={{importlink: <Link className={linkStyle} to="/renewals/import" />}} />,
        }
    ]
    return (
        <HintCard title={t('no-ip-rights-imported')}>
            <Hints hints={hints} />
        </HintCard>
    )
}