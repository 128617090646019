import React, { useContext, useState } from "react";

const MessagesContext = React.createContext({
    errorMessage: undefined,
    setErrorMessage: (err: string | JSX.Element) => {},
    infoMessage: undefined,
    setInfoMessage: (info: string | JSX.Element) => {}
})

export function MessagesProvider({children}) {
    const [errorMessage, setErrorMessage] = useState(undefined)
    const [infoMessage, setInfoMessage] = useState(undefined)

    const value = { errorMessage, setErrorMessage, infoMessage, setInfoMessage }

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    )
}

export function useMessages() {
    return useContext(MessagesContext)
}

// error response
export interface ErrorMessage {
    status: string;
    message: string;
}