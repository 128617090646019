import { useLocation } from "react-router-dom"
import { useRoles } from "../../user/Auth"
import { afterUpdateActionsStr } from "."
import { OnlyRenameFolder } from "./AutomaticBackgroundChanges"
import { invention } from "../../data"

export function InventionChanged() {
    const {hasDocuments} = useRoles()
    const location = useLocation()

    if (!hasDocuments)
        return null 

    const state = location.state ?? {}
    const changes = state[afterUpdateActionsStr] ?? []

    // currently only move-folder is supported for families
    const doMoveFolder = changes.includes('move-folder')

    if (!doMoveFolder)
        return null

    return <OnlyRenameFolder {...{
        from: state.from.reference,
        to: state.to.reference,
        type: invention,
    }} />
}