import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import { getTeams } from "../backend";
import { useMessages } from "../Messages";
import { useAuth } from "./Auth";
import { emptyStringAsUndefined } from "../utils/strings";
import { postEvent } from "../settings/events"

export default function SelectTeam() {

    const { setErrorMessage } = useMessages()

    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation()

    const { getTeamToken, user: {name: user} } = useAuth()
    const [clicked, setClicked] = useState(false)

    const [teams, setTeams] = useState([])

    useEffect(() => {
      if (teams.length === 0)
        getTeams().then(rs => setTeams(rs))
    }, [teams])

    function getDisplayName(team) {
        return emptyStringAsUndefined(team.displayName) ?? team.name
    }

    const byDisplayName = _.groupBy(teams, getDisplayName)
    
    function selectTeam(team) {
        if (!clicked && typeof (team) === 'string' && team !== '') {
            setClicked(true)
            //console.log(location)
            //console.log(longSession)
            const dest = location?.state?.from?.pathname ?? "/"
            getTeamToken(team)
                .then(() => navigate(dest, { replace: true }))
                .then(() => postEvent({ eventType: 'team-login', realm: team, user}))
                .catch(err => { console.warn(err); setErrorMessage(err.message) })
        }
    }

    if (teams.length > 1)
        return (
            <div className="w-full sm:w-72">
                <div className="pb-2">
                    <h2 className="text-center pb-2">{t('select-team')}</h2>
                </div>
                <div className="flex flex-col">
                    {teams.sort()
                        .map(r => {
                            const displayName = getDisplayName(r)
                            const isAmbiguous = byDisplayName[displayName].length > 1
                            return (
                                <button
                                    key={r.name}
                                    className="text-left border-b-2 first:border-t-2 border-pc-200 px-2 py-2 font-light text-lg hover:bg-pc-300 hover:text-white"
                                    onClick={() => selectTeam(r.name)}
                                >
                                    {displayName} {isAmbiguous && `(${r.name})`}
                                </button>
                            )
                        })}
                    <div className="h-8" />
                    <Link to="/signout" className="btn-secondary text-center">{t('log-out')}</Link>
                </div>
            </div>
        )
    else {
        if (teams.length === 1 && !clicked)
            selectTeam(teams[0].name)
        return (
            <div className="w-full sm:w-72 py-2">
                <h2 className="mb-2">{t('logging-in')}</h2>
                <Link to="/login" className="btn-tertiary text-xs px-1 w-fit block mt-6">{t('login')}</Link>
            </div>

        )
    }
}