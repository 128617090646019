import { useField } from 'formik'
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

export default function ToggleButton({ checked, setChecked, disabled = false }) {
    return (
        <Switch
            checked={checked}
            onChange={setChecked}
            className={clsx(
                "group relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent",
                "transition-colors duration-200 ease-in-out",
                disabled
                    ? "bg-slate-300"
                    : checked
                        ? "bg-pcx-300"
                        : "bg-pcx-300/30 "
            )}
        >
            <span
                aria-hidden="true"
                className={clsx(
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    checked && "translate-x-4"
                )} />
        </Switch>
    )
}

export function ToggleButtonField(props) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);
    return <ToggleButton id={props.name} {...{...props, checked: meta.value, setChecked: helpers.setValue}} />
}