import React, { useContext } from "react";
import _ from 'lodash'

import { useFilter } from "./Filter";
import {
    FilteredTrademarks,
    active_only_filter,
    countries_filter,
    filterActiveTrademarksOnly,
    filterTrademarksByText,
    filterTrademarkByCountry,
    reference_filter,
    filterTrademarksByReference,
    free_text_filter,
    owner_filter,
    filterTrademarkByAgent,
    contact_filter,
} from "./utils";
import { FilterConfig } from "./Filter";
import { Trademark, TrademarkFamily, useTrademarks } from "../trademarks/TrademarksProvider";

const FilteredTrademarksContext = React.createContext({
    trademarks: [] as Trademark[],
    trademarkFamilies: [] as TrademarkFamily[],

    trademarkById: {} as Record<number, Trademark>,
    trademarksByFamilyId: {} as Record<number, Trademark[]>,
    trademarkFamilyById: {} as Record<number, TrademarkFamily>,
})

export function FilteredTrademarksProvider({ children }) {
    const { showActiveOnly, activeFilters, filterText } = useFilter()

    const { trademarks, trademarkFamilies, trademarksByFamilyId, agentsLookup, agentsByTrademarkId } = useTrademarks()

    let context = (showActiveOnly ? [{ name: active_only_filter, isActive: true }, ...activeFilters] : activeFilters)
        .filter(({ isActive }) => isActive)
        .reduce(
            (ctxt: FilteredTrademarks, filter: FilterConfig) => {
                switch (filter.name) {
                    case active_only_filter:
                        return filterActiveTrademarksOnly(ctxt, trademarksByFamilyId);
                    case countries_filter:
                        return filterTrademarkByCountry(ctxt, filter.config);
                    case reference_filter:
                        return filterTrademarksByReference(ctxt, filter.config);
                    case free_text_filter:
                        return filterTrademarksByText(ctxt, filter.config);
                    case owner_filter:
                    case contact_filter:
                        return filterTrademarkByAgent(ctxt, filter.config, filter.name, agentsLookup, agentsByTrademarkId);
                    default:
                        return ctxt;
                }
            },
            { trademarkFamilies, trademarks }
        );

    // maybe there is a more elegant way?
    if (filterText !== "") {
        context = filterTrademarksByText(context, filterText);
    }

    const value = {
        ...context,
        trademarkById: _.keyBy(context.trademarks, 'trademarkId'),
        trademarksByFamilyId: _.groupBy(context.trademarks, 'familyId'),
        trademarkFamilyById: _.keyBy(context.trademarkFamilies, 'familyId'),
    }

    return (
        <FilteredTrademarksContext.Provider value={value}>{children}</FilteredTrademarksContext.Provider>
    );
}

export function useFilteredTrademarks() {
    return useContext(FilteredTrademarksContext);
}

export function useAllOrFilteredTrademarks(applyGlobalFilter = true) {
    const allContext = useTrademarks()
    const filteredContext = useFilteredTrademarks()

    return applyGlobalFilter ? filteredContext : allContext
}
