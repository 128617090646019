import { useState } from "react"
import { Popover } from '@headlessui/react'
import { useTranslation, Trans } from "react-i18next"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { FaCheckDouble } from "react-icons/fa6";
import _ from 'lodash'

import { IconCheck } from "../components/icons"
import ToggleButton from '../components/ToggleButton'
import { familyUrl, memberUrl } from "./utils"
import { unitaryPatentCountries } from "../data"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { usePatents } from "./PatentsProvider"
import { Member } from "./patents"

const maxFamilies = 150

const isUnitary = ({countryCode, unitaryPatent}: Member) => countryCode === "EP" && unitaryPatent

export default function CountryFamilyMap() {
    const { t } = useTranslation()

    const {families: allFamilies} = usePatents()
    const {families: _families, members, membersByFamilyId} = useFilteredPatents()

    const families = _.sortBy(_families.slice(0, maxFamilies), f => f.internalReference)

    const availableCountries = _([
        ...members.map(m => m.countryCode),
        ...(members.find(m => isUnitary(m)) ? unitaryPatentCountries : [])
    ]).uniq().sortBy().value()
    const [longNames, setLongNames] = useState(true)

    // This neglects patents in the same family with the same country code
    const membersByFamilyIdAndCountry = _(membersByFamilyId).mapValues(ms => {
        const [unitaries, normal] = _.partition(ms, m => isUnitary(m))
        const fullUnitaries = unitaries.flatMap(u => unitaryPatentCountries.map(c => ({...u, countryCode: c})))
        return _.groupBy([...normal, ...fullUnitaries], m => m.countryCode)
    }).value()

    if (families.length === 0)
        return <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('country-map')} | Patent Cockpit</title>
            </Helmet>
            <div className="flex flex-row gap-2 header-row">
                <h2 className="modern-h2">{t('country-coverage')}</h2>
            </div>
            {families.length === allFamilies.length
                ? <div className="main-content text-center">
                    {t('assess-countries')}
                    <br />
                    <Link className="text-pc-300 underline-link" to="/patents/portfolio">{t('add-patents')}</Link>
                </div>
                : <div className="main-content text-center">
                    <Trans i18nKey="no-patent-results" />
                </div>
            }
        </>
    else
        return <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('country-map')} | Patent Cockpit</title>
            </Helmet>
            <div className="header-row flex flex-row">
                <h2 className='modern-h2'>{t('country-coverage')}</h2>
                <div className="sm:grow" />
                <div className="flex flex-row gap-2 items-center">
                    <label htmlFor="long-name" className="text-sm font-normal text-pc-500 whitespace-nowrap">{t('show-long-names')}</label>
                    <ToggleButton {...{ checked: longNames, setChecked: setLongNames }} />
                </div>
            </div>
            <div className="main-content">
                <div className="pb-4 w-visible">
                    {families.length !== _families.length &&
                        <div className="warning">
                            <Trans i18nKey="max-families" values={{maxFamilies}} />
                        </div>
                    }
                </div>
                <table className="table-auto bg-inherit border-spacing-0">
                    <thead>
                        <tr>
                            <th className="font-normal text-pc-500 px-0 sticky -top-4 -left-4 z-10 bg-white dark:bg-pcx-100">
                                <div className="px-2 flex flex-row justify-between border-b-2 border-pcx-300">
                                    <span className="pr-4">{t('patent-family')}</span>
                                    <span>{t('countries')}</span>
                                </div>
                            </th>
                            {availableCountries.map(c =>
                                <th key={c} className="sticky -top-4 bg-white dark:bg-pcx-100 px-0">
                                    <div className=" border-b-2 border-pcx-300 px-0.5 ">
                                    <Link className="font-normal bg-pc-200 py-0.5 rounded-sm px-3 text-sm" to={`/search?search=${c}`}>{c}</Link>
                                    </div>
                                </th>)}
                        </tr>
                    </thead>
                    <tbody >
                        {families
                            .map(f => {
                                const byCountry = membersByFamilyIdAndCountry[f.patentFamilyId] ?? {}
                                //console.log({byCountry})
                                //console.log(m, image)
                                return (
                                    <tr key={f.internalReference}
                                        className="border-b-2 border-pc-200"
                                    >
                                        <td className="px-2 sticky -left-4 bg-white dark:bg-pcx-100">
                                            <Link
                                                className="flex flex-row gap-2 items-center"
                                                to={familyUrl(f)} >
                                                <div className={`${!longNames && "w-60"} line-clamp-2 py-1 overflow-hidden text-sm text-slate-700`}>
                                                    {f.internalReference}: {f.familyName}
                                                </div>
                                            </Link>
                                        </td>
                                        {availableCountries.map(c =>
                                            <td key={c} className="px-2">
                                                <CountryLinks countries={byCountry[c] ?? []} />
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </>
}

function CountryLinks({ countries }: { countries: Member[] }) {
    const {t} = useTranslation()

    if (countries.length === 1) {
        const c = countries[0]
        return (
            <Link key={c.countryCode} to={memberUrl(c)} title={makeTitle(c, t)} className="text-pcx-500 text-xs h-full">
                <IconCheck />
            </Link>
        )
    } else if (countries.length > 1) {
        return <Popover className="relative">
            <Popover.Button className="text-pcx-500 text-xs h-full">
                <FaCheckDouble className="text-pcx-500" />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 px-4 py-2 bg-white border border-pcx-400 rounded shadow">
                <div className="flex flex-col gap-1">
                    {_(countries)
                        .sortBy(c => c.countryCode)
                        .map(c =>
                            <Link key={c.countryCode} to={memberUrl(c)} className="text-pcx-500 text-sm whitespace-nowrap hover:underline">
                                {makeTitle(c, t)}
                            </Link>
                        )
                        .value()}
                </div>
            </Popover.Panel>
        </Popover>
    }
    // no countries
    return null
}

function makeTitle(c: Member, t: (s: string) => string) {
    if (c.firstFiling)
        return `${c.internalReference} (${t(c.familyMemberStatus)}; ${t('firstFiling')})`
    else
        return `${c.internalReference} (${t(c.familyMemberStatus)})`
}