import Agents from "../agents/Agents"
import ImageAdmin from "./ImageAdmin"
import ClaimScopeAdmin from "./ClaimScopeAdmin"
import { useAuth } from "../user/Auth";

export function DataCuration() {
    const {user: {roles}} = useAuth()
    return (
        <div>
            <div className="header-row">
                <h2>Data Curation</h2>
            </div>
            <div className="main-content">
                <Agents />
                {roles.indexOf('dev') >= 0 && <>
                    <ImageAdmin />
                    <ClaimScopeAdmin />
                </>}
            </div>
        </div>
    )
}