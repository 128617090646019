export default function Logo({className}: {className?: string}) {
    return (
        <svg className={className} viewBox="0 0 5514 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1024" height="1024" rx="83.8788" fill="url(#paint0_linear_331_5490)" />
            <g clipPath="url(#clip0_331_5490)">
                <path d="M893.809 511.963L736.417 379.253V470.14H661.965C653.397 393.859 622.249 321.873 572.511 263.406C513.647 194.515 433.327 147.42 344.464 129.69L327.712 213.058C352.999 218.147 377.579 226.272 400.917 237.255L130.191 511.628L400.638 786.895C377.328 797.814 352.787 805.882 327.545 810.924L344.148 894.292C433.067 876.607 513.454 829.53 572.381 760.632C622.171 702.145 653.353 630.116 661.928 553.786H736.38V644.636L893.809 511.963ZM473.843 284.383C485.973 294.853 497.28 306.24 507.663 318.444C544.343 361.547 568.111 414.128 576.234 470.14H290.635L473.843 284.383ZM507.589 705.557C497.15 717.835 485.767 729.28 473.546 739.786L290.803 553.786H576.234C568.108 609.834 544.313 662.444 507.589 705.557V705.557Z" fill="white" />
            </g>
            <path d="M1351 683.596V241.27H1525.45C1558.99 241.27 1587.56 247.677 1611.16 260.492C1634.77 273.163 1652.76 290.801 1665.14 313.407C1677.66 335.869 1683.92 361.786 1683.92 391.159C1683.92 420.533 1677.59 446.45 1664.92 468.912C1652.26 491.374 1633.91 508.868 1609.87 521.395C1585.97 533.922 1557.04 540.185 1523.07 540.185H1411.88V465.24H1507.96C1525.95 465.24 1540.78 462.145 1552.44 455.953C1564.24 449.618 1573.02 440.907 1578.78 429.82C1584.68 418.589 1587.63 405.702 1587.63 391.159C1587.63 376.473 1584.68 363.658 1578.78 352.715C1573.02 341.628 1564.24 333.061 1552.44 327.014C1540.63 320.822 1525.67 317.726 1507.53 317.726H1444.49V683.596H1351Z" fill="currentColor" />
            <path d="M1813.38 689.859C1792.22 689.859 1773.36 686.188 1756.81 678.844C1740.26 671.357 1727.16 660.342 1717.52 645.799C1708.02 631.113 1703.27 612.826 1703.27 590.94C1703.27 572.51 1706.65 557.032 1713.41 544.505C1720.18 531.978 1729.39 521.899 1741.05 514.268C1752.71 506.636 1765.95 500.877 1780.78 496.989C1795.75 493.102 1811.43 490.366 1827.84 488.782C1847.13 486.766 1862.68 484.895 1874.48 483.167C1886.28 481.295 1894.85 478.559 1900.17 474.959C1905.5 471.36 1908.16 466.032 1908.16 458.977V457.681C1908.16 444.002 1903.84 433.419 1895.21 425.932C1886.71 418.445 1874.62 414.701 1858.93 414.701C1842.38 414.701 1829.21 418.373 1819.42 425.716C1809.64 432.915 1803.16 441.987 1799.99 452.93L1714.93 446.018C1719.24 425.86 1727.74 408.438 1740.4 393.751C1753.07 378.921 1769.41 367.546 1789.41 359.626C1809.56 351.563 1832.88 347.532 1859.37 347.532C1877.79 347.532 1895.42 349.691 1912.26 354.011C1929.25 358.331 1944.29 365.026 1957.39 374.097C1970.63 383.168 1981.06 394.831 1988.69 409.086C1996.32 423.196 2000.13 440.115 2000.13 459.841V683.596H1912.91V637.592H1910.32C1904.99 647.959 1897.87 657.102 1888.94 665.022C1880.02 672.797 1869.3 678.916 1856.77 683.38C1844.25 687.699 1829.79 689.859 1813.38 689.859ZM1839.72 626.361C1853.25 626.361 1865.19 623.697 1875.56 618.37C1885.92 612.898 1894.05 605.555 1899.96 596.34C1905.86 587.125 1908.81 576.686 1908.81 565.023V529.818C1905.93 531.69 1901.97 533.418 1896.93 535.002C1892.04 536.442 1886.5 537.81 1880.31 539.105C1874.12 540.257 1867.93 541.337 1861.74 542.345C1855.55 543.209 1849.94 544.001 1844.9 544.721C1834.1 546.305 1824.68 548.824 1816.62 552.28C1808.56 555.736 1802.29 560.415 1797.83 566.319C1793.37 572.078 1791.14 579.278 1791.14 587.917C1791.14 600.444 1795.67 610.019 1804.74 616.642C1813.95 623.121 1825.61 626.361 1839.72 626.361Z" fill="currentColor" />
            <path d="M2232.68 351.851V420.965H2032.97V351.851H2232.68ZM2078.31 272.371H2170.29V581.653C2170.29 590.149 2171.58 596.772 2174.17 601.524C2176.76 606.131 2180.36 609.371 2184.97 611.243C2189.72 613.114 2195.19 614.05 2201.38 614.05C2205.7 614.05 2210.01 613.69 2214.33 612.97C2218.65 612.107 2221.96 611.459 2224.26 611.027L2238.73 679.492C2234.12 680.932 2227.65 682.588 2219.3 684.46C2210.95 686.475 2200.8 687.699 2188.86 688.131C2166.69 688.995 2147.26 686.043 2130.56 679.276C2114.01 672.509 2101.13 661.998 2091.91 647.743C2082.7 633.488 2078.17 615.49 2078.31 593.748V272.371Z" fill="currentColor" />
            <path d="M2424.97 690.075C2390.85 690.075 2361.49 683.164 2336.88 669.341C2312.41 655.374 2293.55 635.648 2280.31 610.163C2267.07 584.533 2260.45 554.224 2260.45 519.235C2260.45 485.111 2267.07 455.161 2280.31 429.388C2293.55 403.614 2312.19 383.528 2336.23 369.129C2360.41 354.731 2388.77 347.532 2421.3 347.532C2443.17 347.532 2463.54 351.059 2482.4 358.114C2501.4 365.026 2517.95 375.465 2532.05 389.432C2546.3 403.398 2557.39 420.965 2565.3 442.131C2573.22 463.153 2577.18 487.774 2577.18 515.996V541.265H2297.15V484.247H2490.6C2490.6 471 2487.72 459.265 2481.97 449.042C2476.21 438.819 2468.22 430.828 2458 425.068C2447.92 419.165 2436.19 416.213 2422.81 416.213C2408.85 416.213 2396.47 419.453 2385.67 425.932C2375.02 432.268 2366.67 440.835 2360.63 451.634C2354.58 462.289 2351.49 474.168 2351.34 487.27V541.481C2351.34 557.896 2354.37 572.078 2360.41 584.029C2366.6 595.98 2375.31 605.195 2386.54 611.675C2397.76 618.154 2411.08 621.394 2426.48 621.394C2436.7 621.394 2446.05 619.954 2454.55 617.074C2463.04 614.194 2470.31 609.875 2476.35 604.115C2482.4 598.356 2487 591.301 2490.17 582.949L2575.24 588.565C2570.92 609.011 2562.07 626.865 2548.68 642.128C2535.44 657.246 2518.31 669.053 2497.29 677.548C2476.42 685.899 2452.31 690.075 2424.97 690.075Z" fill="currentColor" />
            <path d="M2713.97 491.806V683.596H2622V351.851H2709.66V410.382H2713.54C2720.88 391.087 2733.19 375.825 2750.46 364.594C2767.73 353.219 2788.68 347.532 2813.29 347.532C2836.32 347.532 2856.4 352.571 2873.53 362.65C2890.66 372.729 2903.97 387.128 2913.47 405.846C2922.97 424.42 2927.72 446.594 2927.72 472.368V683.596H2835.74V488.782C2835.89 468.48 2830.71 452.642 2820.2 441.267C2809.69 429.748 2795.23 423.988 2776.8 423.988C2764.42 423.988 2753.48 426.652 2743.98 431.98C2734.63 437.307 2727.29 445.082 2721.96 455.305C2716.78 465.384 2714.12 477.551 2713.97 491.806Z" fill="currentColor" />
            <path d="M3161.4 351.851V420.965H2961.69V351.851H3161.4ZM3007.03 272.371H3099.01V581.653C3099.01 590.149 3100.3 596.772 3102.89 601.524C3105.48 606.131 3109.08 609.371 3113.69 611.243C3118.44 613.114 3123.91 614.05 3130.1 614.05C3134.41 614.05 3138.73 613.69 3143.05 612.97C3147.37 612.107 3150.68 611.459 3152.98 611.027L3167.45 679.492C3162.84 680.932 3156.36 682.588 3148.02 684.46C3139.67 686.475 3129.52 687.699 3117.57 688.131C3095.41 688.995 3075.98 686.043 3059.28 679.276C3042.73 672.509 3029.84 661.998 3020.63 647.743C3011.42 633.488 3006.89 615.49 3007.03 593.748V272.371Z" fill="currentColor" />
            <path d="M3723.49 396.127H3628.93C3627.2 383.888 3623.67 373.017 3618.35 363.514C3613.02 353.867 3606.19 345.66 3597.84 338.892C3589.49 332.125 3579.85 326.941 3568.91 323.342C3558.11 319.742 3546.38 317.942 3533.72 317.942C3510.83 317.942 3490.89 323.63 3473.91 335.005C3456.93 346.236 3443.76 362.65 3434.4 384.248C3425.04 405.702 3420.37 431.764 3420.37 462.433C3420.37 493.966 3425.04 520.459 3434.4 541.913C3443.9 563.367 3457.14 579.566 3474.13 590.509C3491.11 601.452 3510.76 606.923 3533.07 606.923C3545.59 606.923 3557.18 605.267 3567.83 601.955C3578.62 598.644 3588.19 593.82 3596.54 587.485C3604.89 581.005 3611.8 573.158 3617.27 563.943C3622.88 554.728 3626.77 544.217 3628.93 532.41L3723.49 532.842C3721.05 553.144 3714.93 572.726 3705.14 591.588C3695.5 610.307 3682.47 627.081 3666.06 641.912C3649.8 656.598 3630.37 668.261 3607.77 676.9C3585.32 685.396 3559.91 689.643 3531.56 689.643C3492.12 689.643 3456.85 680.716 3425.76 662.862C3394.82 645.007 3370.35 619.162 3352.36 585.325C3334.51 551.488 3325.58 510.524 3325.58 462.433C3325.58 414.197 3334.65 373.161 3352.79 339.324C3370.92 305.488 3395.54 279.714 3426.63 262.004C3457.72 244.149 3492.69 235.222 3531.56 235.222C3557.18 235.222 3580.93 238.822 3602.8 246.021C3624.83 253.22 3644.33 263.731 3661.31 277.554C3678.3 291.233 3692.12 308.007 3702.77 327.877C3713.56 347.747 3720.47 370.497 3723.49 396.127Z" fill="currentColor" />
            <path d="M3923.44 690.075C3889.9 690.075 3860.9 682.948 3836.43 668.693C3812.11 654.294 3793.32 634.28 3780.08 608.651C3766.84 582.877 3760.22 553 3760.22 519.019C3760.22 484.751 3766.84 454.801 3780.08 429.172C3793.32 403.398 3812.11 383.384 3836.43 369.129C3860.9 354.731 3889.9 347.532 3923.44 347.532C3956.98 347.532 3985.91 354.731 4010.23 369.129C4034.7 383.384 4053.56 403.398 4066.8 429.172C4080.04 454.801 4086.66 484.751 4086.66 519.019C4086.66 553 4080.04 582.877 4066.8 608.651C4053.56 634.28 4034.7 654.294 4010.23 668.693C3985.91 682.948 3956.98 690.075 3923.44 690.075ZM3923.87 618.802C3939.13 618.802 3951.87 614.482 3962.09 605.843C3972.31 597.06 3980.01 585.109 3985.19 569.991C3990.52 554.872 3993.18 537.666 3993.18 518.371C3993.18 499.077 3990.52 481.871 3985.19 466.752C3980.01 451.634 3972.31 439.683 3962.09 430.9C3951.87 422.116 3939.13 417.725 3923.87 417.725C3908.47 417.725 3895.52 422.116 3885.01 430.9C3874.65 439.683 3866.8 451.634 3861.48 466.752C3856.3 481.871 3853.7 499.077 3853.7 518.371C3853.7 537.666 3856.3 554.872 3861.48 569.991C3866.8 585.109 3874.65 597.06 3885.01 605.843C3895.52 614.482 3908.47 618.802 3923.87 618.802Z" fill="currentColor" />
            <path d="M4281.11 690.075C4247.14 690.075 4217.92 682.876 4193.45 668.477C4169.13 653.935 4150.41 633.776 4137.32 608.003C4124.36 582.229 4117.88 552.568 4117.88 519.019C4117.88 485.038 4124.43 455.233 4137.53 429.604C4150.77 403.83 4169.56 383.744 4193.88 369.345C4218.21 354.803 4247.14 347.532 4280.68 347.532C4309.61 347.532 4334.94 352.787 4356.67 363.298C4378.41 373.809 4395.61 388.568 4408.27 407.574C4420.94 426.58 4427.92 448.898 4429.22 474.527H4342.42C4339.98 457.969 4333.5 444.65 4322.99 434.571C4312.63 424.348 4299.03 419.237 4282.19 419.237C4267.94 419.237 4255.49 423.124 4244.84 430.9C4234.33 438.531 4226.12 449.69 4220.22 464.376C4214.32 479.063 4211.37 496.845 4211.37 517.723C4211.37 538.889 4214.25 556.888 4220.01 571.718C4225.91 586.549 4234.18 597.852 4244.84 605.627C4255.49 613.402 4267.94 617.29 4282.19 617.29C4292.69 617.29 4302.12 615.13 4310.47 610.811C4318.96 606.491 4325.94 600.228 4331.41 592.02C4337.03 583.669 4340.7 573.662 4342.42 561.999H4429.22C4427.78 587.341 4420.87 609.659 4408.49 628.953C4396.26 648.103 4379.34 663.078 4357.75 673.877C4336.16 684.676 4310.61 690.075 4281.11 690.075Z" fill="currentColor" />
            <path d="M4556.24 588.133L4556.46 477.767H4569.84L4676.07 351.851H4781.65L4638.93 518.587H4617.13L4556.24 588.133ZM4472.9 683.596V241.27H4564.88V683.596H4472.9ZM4680.17 683.596L4582.58 539.105L4643.9 474.096L4787.91 683.596H4680.17Z" fill="currentColor" />
            <path d="M4811.57 808V351.851H4902.25V407.574H4906.35C4910.38 398.647 4916.21 389.576 4923.84 380.36C4931.61 371.001 4941.69 363.226 4954.07 357.035C4966.59 350.699 4982.13 347.532 5000.7 347.532C5024.88 347.532 5047.19 353.867 5067.63 366.538C5088.07 379.065 5104.41 397.999 5116.64 423.34C5128.88 448.538 5134.99 480.143 5134.99 518.155C5134.99 555.16 5129.02 586.405 5117.07 611.891C5105.27 637.232 5089.15 656.454 5068.71 669.557C5048.42 682.516 5025.67 688.995 5000.49 688.995C4982.64 688.995 4967.45 686.043 4954.93 680.14C4942.55 674.237 4932.4 666.821 4924.49 657.894C4916.57 648.823 4910.53 639.68 4906.35 630.465H4903.54V808H4811.57ZM4901.6 517.723C4901.6 537.45 4904.34 554.656 4909.81 569.343C4915.28 584.029 4923.19 595.476 4933.56 603.683C4943.92 611.746 4956.51 615.778 4971.34 615.778C4986.31 615.778 4998.97 611.675 5009.34 603.467C5019.7 595.116 5027.55 583.597 5032.87 568.911C5038.34 554.08 5041.07 537.018 5041.07 517.723C5041.07 498.573 5038.41 481.727 5033.09 467.184C5027.76 452.642 5019.92 441.267 5009.55 433.059C4999.19 424.852 4986.45 420.749 4971.34 420.749C4956.37 420.749 4943.7 424.708 4933.34 432.627C4923.12 440.547 4915.28 451.778 4909.81 466.32C4904.34 480.863 4901.6 497.997 4901.6 517.723Z" fill="currentColor" />
            <path d="M5181.11 683.596V351.851H5273.08V683.596H5181.11ZM5227.31 309.087C5213.64 309.087 5201.91 304.552 5192.12 295.48C5182.48 286.265 5177.66 275.25 5177.66 262.436C5177.66 249.765 5182.48 238.894 5192.12 229.823C5201.91 220.608 5213.64 216 5227.31 216C5240.99 216 5252.65 220.608 5262.29 229.823C5272.08 238.894 5276.97 249.765 5276.97 262.436C5276.97 275.25 5272.08 286.265 5262.29 295.48C5252.65 304.552 5240.99 309.087 5227.31 309.087Z" fill="currentColor" />
            <path d="M5507.95 351.851V420.965H5308.24V351.851H5507.95ZM5353.58 272.371H5445.56V581.653C5445.56 590.149 5446.85 596.772 5449.45 601.524C5452.04 606.131 5455.63 609.371 5460.24 611.243C5464.99 613.114 5470.46 614.05 5476.65 614.05C5480.97 614.05 5485.29 613.69 5489.6 612.97C5493.92 612.107 5497.23 611.459 5499.53 611.027L5514 679.492C5509.39 680.932 5502.92 682.588 5494.57 684.46C5486.22 686.475 5476.07 687.699 5464.13 688.131C5441.96 688.995 5422.53 686.043 5405.83 679.276C5389.28 672.509 5376.4 661.998 5367.19 647.743C5357.97 633.488 5353.44 615.49 5353.58 593.748V272.371Z" fill="currentColor" />
            <defs>
                <linearGradient id="paint0_linear_331_5490" x1="1023" y1="1.75263" x2="0.751093" y2="1002.47" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#247FC1" />
                    <stop offset="1" stopColor="#2463C1" />
                </linearGradient>
                <clipPath id="clip0_331_5490">
                    <rect width="763.619" height="764.62" fill="white" transform="translate(130.191 129.69)" />
                </clipPath>
            </defs>
        </svg>
    )
}