import clsx from "clsx";

export function MenuBarBadge({show, warn = false}: {show: boolean, warn?: boolean}) {
    return (
        <span className='relative w-1 h-[1em]'>
            {show && <span className={clsx(
                "absolute -top-1 -left-0.5 h-2 w-2 rounded-full",
                warn ? "bg-yellow-400 shadow-sm shadow-white" : "bg-notification"
            )} />}
        </span>
    )
}

export type StatusSeverity = 'no-show' | 'info' | 'warning' | 'error'

export function SideBarBadge({severity, smallMenu}: {severity: StatusSeverity; smallMenu?: boolean}) {
    const show = severity !== 'no-show'
    return (
        <span className='relative w-0 h-[1em]'>
            {show && <span className={clsx(
                smallMenu ? "-top-1 right-0" : "-top-1 -right-1",
                "absolute h-2 w-2 rounded-full", 
                severity === 'info' ? "bg-notification" :
                severity === 'warning' ? "bg-yellow-500" :
                "bg-red-500"
            )} />}
        </span>
    )
}