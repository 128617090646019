import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from "react-i18next"

import { LoadingModal } from '../components/Modal'
import { usePatents } from './PatentsProvider'


export default function PatentsMenu() {
    const { t } = useTranslation()

    const {isLoading} = usePatents()

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('patents')} | Patent Cockpit</title>
            </Helmet>
            {isLoading
                ? <LoadingModal />
                : <Outlet />}
        </>
    )
}
