import { useBackend } from "../BackendProvider"
import { DeleteButton } from "../components/edit-table"

export default function ClaimScopeAdmin() {

    const {claims, claimScopes, entityOperation} = useBackend()

    const usedClaimScopeIds = new Set(claims.map(c => c.claimScopeId))

    const unlinked = claimScopes.filter(c => !usedClaimScopeIds.has(c.claimScopeId))
    //console.log(unlinked)

    return (
        <div className="py-2">
            <h3>Claim Scopes</h3>
            <div className="py-2">
                {unlinked.map(u => 
                    <div className="space-x-2 flex flex-row" key={u.claimScopeId}>
                        <div>
                            <DeleteButton {...{
                                del: () => entityOperation("claim-scope", "delete", u.claimScopeId),
                                small: true
                            }} />
                        </div>
                        <div>
                            {u.claimScopeSummary}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}