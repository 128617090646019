import { Fragment } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { CloudArrowUpIcon, CloudArrowDownIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Helmet } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"
import _ from 'lodash'

import { ExcelEpoImportButton } from './PatentsImport'
import DeleteButton from '../components/DeleteButton';
import { patent_family } from '../data'
import {PlainImage} from '../components/Image'
import { downloadReport } from '../backend'
import { useMessages } from '../Messages'
import { useRoles } from '../user/Auth'
import { countryLabel, familyUrl, memberUrl } from './utils'
import EmptyState from '../components/EmptyState'
import { useFilteredPatents } from '../filter/FilteredPatents';
import { usePatents } from './PatentsProvider';
import { IconChevronDown } from '../components/icons';
import clsx from 'clsx';
import { BreadCrumbs } from '../components/BreadCrumbs';
import { Family } from './patents';
import { useTags } from '../tags/TagsProvider';


function CountryMemberLinks({members, predicate}) {
  const toShow = _.sortBy(Object.entries( _.groupBy(members.filter(predicate), m => m.countryCode)), ([cc]) => cc)

  return (
    <div className="flex flex-wrap gap-1 text-sm sm:mb-1 mr-1 sm:mr-0">
      {_.flatMap(toShow, ([countryCode, ms]) =>
        _.map(ms, m => {
          const {label, title} = countryLabel(m, ms.length === 1)
          return (
            <Link
              key={m.internalReference}
              className="ribbon"
              to={memberUrl(m)} 
              title={title}
            >
              {label}
            </Link>
          )
        })
      )}
    </div>
  )
}


const pageSize = 50

export default function Portfolio() {
  const { t } = useTranslation()
  const { isDev, isEditUser, hasExcelExport, hasSortByPrio } = useRoles()
  const {setErrorMessage} = useMessages()

  const {families: allFamilies, deleteFamily, reload, membersByFamilyId} = usePatents()
  const {tagsLookup} = useTags()
  const {families} = useFilteredPatents()
  //console.log({families, members})

  const [params] = useSearchParams()
  const page = parseInt(params.get('page') ?? '1')

  const today = new Date().toISOString().slice(0, 10)
  function sortByPriority(family: Family) {
    let date = today
    if (family.priorityDate)
      date = family.priorityDate
    else {
      const applicationDates = membersByFamilyId[family.patentFamilyId]?.map(m => m.applicationDate).filter(Boolean) ?? []
      if (applicationDates.length > 0)
        date = _.min(applicationDates)
    }
    return `${date} ${family.internalReference}`
  }

  const sortFamilyBy = hasSortByPrio 
    ? sortByPriority
    : (f: Family) => f.internalReference

  const sortedFamilies = _(families).sortBy(sortFamilyBy).reverse().value()
  const shownFamilies = sortedFamilies.slice((page - 1) * pageSize, page * pageSize)

  const maxPage = Math.ceil(families.length / pageSize)

  //console.log(families.length)
  //console.log(sortedFamilies.length)
  //console.log(shownFamilies.length)
  //console.log(maxPage)
  const isEmptyFilterPortfolio = families?.length === 0 && allFamilies?.length !== 0
  const isFullyEmpty = families?.length === 0 && allFamilies?.length === 0

  if (isFullyEmpty) {
    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>{t('patents')} | Patent Cockpit</title>
        </Helmet>
        <div className="portfolio-menu ">
            <h2>{t('patents')}</h2>
        </div>
        <div className='main-content'>
          <div className='max-w-screen-lg'>
            <EmptyPortfolioState />
          </div>
        </div>
      </>
    )
  } else if (page > 1 && shownFamilies.length === 0) {
    return <Navigate to={`?page=1`} />
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{t('patents')} | Patent Cockpit</title>
      </Helmet>
      <div className="py-0 pl-0 portfolio-menu pr-0">
        <div className="grow max-w-screen-lg flex flex-row items-start sm:items-center sm:gap-2 mr-4">
          <BreadCrumbs parts={[t('patents')]} />  
          <div className='grow'/>
          <div className='flex flex-col max-sm: py-1 max-sm:items-end sm:flex-row gap-2'>
            {isDev &&
              <DeleteButton {...{
                className: 'font-normal text-sm btn-warn w-full text-left px-4 py-2 border-0',
                deleteAction: () =>
                  Promise.all(families.map(f => deleteFamily(f)))
                    .then(() => reload())
              }}>Delete ALL!</DeleteButton>
            }
            {hasExcelExport && <button
              className='btn-secondary px-2 py-0.5 text-sm w-fit'
              onClick={() => downloadReport({ url: "/api/excel", report: "patents-portfolio-report" }).catch(e => setErrorMessage(e.message))}
            >
              {t('excel-export')}
            </button>}
            {isEditUser && <PortfolioMenu />}
          </div>
        </div>
      </div>
      <div key={page} className="overflow-auto grow pl-2 sm:pl-4">
        <div className="max-w-screen-lg pr-2 sm:pr-4">
          <div className="flex flex-col gap-2">
            {isEmptyFilterPortfolio &&
              <div className="text-center py-4">
                <Trans i18nKey="no-patent-results" />
              </div>}
            {shownFamilies
              .map(family => 
                <FamilyRow 
                  key={family.internalReference} 
                  {...{
                    family, 
                    tagsLookup, 
                  }} 
                />)}
            {shownFamilies.length !== sortedFamilies.length &&
              <Pagination {...{page, maxPage, maxCount: sortedFamilies.length}} />
            }
            {/* {shownFamilies.length !== sortedFamilies.length &&
              <div className="flex flex-row py-4 max-w-md justify-center">
                {page > 1 && <Link className="btn-secondary" to={`.?page=${page - 1}`} onClick={() => window.scrollTo(0, 0)} >{t('prev-page')}</Link>}
                <div className="py-1 border bg-pc-200 text-pc-500 px-2">{t('page')} {page}</div>
                {page < maxPage && <Link className="btn-secondary" to={`.?page=${page + 1}`} onClick={() => window.scrollTo(0, 0)} >{t('next-page')}</Link>}
              </div>
            } */}
          </div>
        </div>
      </div>
    </>
  )
}

function Pagination({page, maxPage, maxCount}) {
  const {t} = useTranslation()

  const start = (page - 1) * pageSize + 1
  const end = Math.min(page * pageSize, maxCount)

  return (
    <nav
      aria-label="Pagination"
      className="flex items-center justify-between py-3 px-1"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <Trans i18nKey="showing_from_to_of" values={{start, end, maxCount}} components={{b: <span className='font-medium' />}} />
          {/* Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
          <span className="font-medium">{maxCount}</span> results */}
        </p>
      </div>
      <div className="flex flex-1 gap-3 justify-between sm:justify-end">
        <PageButton disabled={page === 1} to={`.?page=${page - 1}`}>{t('prev-page')}</PageButton>
        <PageButton disabled={page === maxPage} to={`.?page=${page + 1}`}>{t('next-page')}</PageButton>
      </div>
    </nav>
  )
}

function PageButton({disabled, to, children}) {
  return disabled
    ? <div className='relative inline-flex items-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-gray-200'>
      {children}
    </div>
    : <Link
      className="relative inline-flex items-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-pcx-500 hover:text-white ring-1 ring-inset ring-gray-300 hover:bg-pcx-600 hover:ring-pcx-600 focus-visible:outline-offset-0"
      to={to} onClick={() => window.scrollTo(0, 0)} >
      {children}
    </Link>
}

function PortfolioMenu() {
  const {t} = useTranslation()

  const {families, deleteFamily, reload} = usePatents()
  const {isEditUser, isDev, hasExcelImport} = useRoles()

  const items = [
    {
      label: t('add-patent-family'),
      to: 'family/add',
      disabled: !isEditUser,
    },
    {
      label: t('epo-import-family'),
      to : './import',
      disabled: !isEditUser,
    },
    {
      label: t('excel-import'),
      to: 'extract',
      disabled: !isEditUser || !hasExcelImport,
    },
    {
      label: 'epo-import',
      body: <div className='text-sm'><ExcelEpoImportButton /></div>,
      disabled: !isDev,
    },
    {
      label: 'DELETE ALL!',
      disabled: !isDev,
      body: <DeleteButton {...{
        className: 'font-normal text-sm btn-warn w-full text-left px-4 py-2 border-0',
        deleteAction: () =>
          Promise.all(families.map(f => deleteFamily(f)))
            .then(() => reload())
      }}>Delete ALL!</DeleteButton>
    }
  ]

  return (
    <Menu as="div" className="relative inline-block text-left font-normal">
      <MenuButton className="btn-secondary py-0.5 rounded-sm flex justify-center gap-1 font-medium text-sm focus:outline-0 focus:ring-0">
        {t('add-patent-family')}
        <IconChevronDown aria-hidden="true" />
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-sm bg-white border border-pcx-500 shadow-lg ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none overflow-hidden">
          {items.map(({ to, label, disabled, body }) =>
            disabled ? null :
              <MenuItem key={label}>
                {({ active }) =>
                  body ? body :
                    <Link to={to} className={clsx(
                      active ? 'bg-pcx-200 text-pcx-800' : 'text-pcx-600',
                      'block px-4 py-2 text-sm whitespace-nowrap'
                    )}>
                      {label}
                    </Link>}
              </MenuItem>
          )}
        </MenuItems>
      </Transition>
    </Menu>
  )
}

const noFamiliesActions = (t) => [
    {
        title: t("upload-patent-portfolio"),
        description: t("upload-patent-patent-desc"),
        icon: CloudArrowUpIcon,
        background: 'bg-pcx-700',
        to: "/patents/portfolio/extract",
    },
    {
        title: t("add-patent-family"),
        description: t("manually-add-patent-desc"),
        icon: PencilSquareIcon,
        background: 'bg-pcx-500',
        to: "/patents/portfolio/family/add",
    },
    {
        title: t("epo-import-family"),
        description: t('import-family-from-epo-desc'),
        icon: CloudArrowDownIcon,
        background: 'bg-pcx-300',
        to: "/patents/portfolio/import",
    },
]

export function EmptyPortfolioState() {
  const {t} = useTranslation()
  const items = noFamiliesActions(t)
  return <EmptyState title={t('add-patent-family')} text={t('add-patent-family-text')} items={items} />
}


function FamilyRow({family, tagsLookup}) {
  const {membersByFamilyId} = useFilteredPatents()

  const members = membersByFamilyId[family.patentFamilyId] ?? []

  return (
    <div
      key={family.patentFamilyId}
      id={encodeURIComponent(family.internalReference)}
      tabIndex={-1}
      className="p-4 bg-white rounded-xl shadow flex flex-col lg:grid lg:grid-cols-[1fr_250px] gap-2 min-w-0 max-w-full focus:outline-none lg:h-44"
    >
      <div className="flex flex-row gap-2 w-full min-w-0">
        <div className="sm:w-48 w-24 h-24 shrink-0">
          <PlainImage {...{
            entity: "patent-family",
            entityId: family.patentFamilyId,
            title: `${family.internalReference}: ${family.familyName}`,
            text: family.summary,
          }} />
        </div>
        <Link className="overflow-hidden min-w-0" to={familyUrl(family)}>
          <div className="flex flex-col gap-1">
            <h4 className="whitespace-nowrap truncate">
              {family.internalReference}: {family.familyName}
            </h4>
            <div className="text-gray-500 text-sm">
              {(tagsLookup[patent_family]?.[family.patentFamilyId] ?? []).join(', ')}
            </div>
            <div className={`text-gray-700 text-ellipsis h-fit line-clamp-4 overflow-hidden text-sm sm:text-base`}>
              {family.summary}
            </div>
          </div>
        </Link>
      </div>
      <div className="lg:border-l-2 border-pc-200 flex flex-row items-center lg:flex-col lg:items-start max-lg:pt-2 lg:pl-2">
        <div className="overflow-auto lg:h-36">
          <CountryMemberLinks {...{ members, predicate: (m) => m.familyMemberStatus === 'pending' || m.familyMemberStatus === 'granted' }} />
        </div>
      </div>
    </div>
  )
}