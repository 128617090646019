import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash'

import { loadUsers, management } from '../backend'
import { useMessages } from '../Messages'
import { useAuth } from './Auth'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

export interface UserSettings {
    user: string,
    userId?: number;
    displayName?: string;
    email?: string;
    emailVerified: boolean;
    language?: string;
}

const UserSettingsContext = React.createContext({
    userSettings: undefined as (UserSettings | undefined),
    users: {} as Record<string, UserSettings>,
    updateUserSettings: (settings: UserSettings) => Promise.resolve(),
})

export function useUserSettings() {
    return useContext(UserSettingsContext)
}

export function useUserSettingsFor(user: string) {
    const {data: userSettings } = useQuery<UserSettings>({
        queryKey: ['userSettings', user], 
        queryFn: () => management({user, type: "settings", operation: "get"}),
    })
    return {userSettings}
}

export function UserSettingsProvider({children}) {
    const {i18n} = useTranslation()
    const {user: {name: user}} = useAuth()
    const {setErrorMessage, setInfoMessage} = useMessages()

    const [hasLoaded, setHasLoaded] = useState(false)
    const [userSettings, setUserSettings] = useState(undefined as (UserSettings | undefined))
    const [users, setUsers] = useState({})

    useEffect(() => {
        const lang = (userSettings?.language ?? i18n.resolvedLanguage)
        if (i18n.resolvedLanguage !== lang)
            i18n.changeLanguage(lang)
    }, [userSettings?.language, i18n])
    

    useEffect(() => {
        if (!hasLoaded) {
            setHasLoaded(true)
            management({ user, type: "settings", operation: "get" })
                .then(setUserSettings)
                .then(() => loadUsers().then(us => setUsers(_.keyBy(us, 'user'))))
                .then(() => setHasLoaded(true))
                .catch(err => setErrorMessage(err.message))
        }
    }, [hasLoaded, user, setErrorMessage])

    useEffect(() => {
        if (hasLoaded && userSettings?.user !== undefined) {
            const email = userSettings?.email
            //console.log({userSettings, email})
            if (email === undefined)
                setInfoMessage(<div><Trans i18nKey="no-email-go-to-settings" components={{settings: <Link className='underline' to="/settings/user"/>}} /></div>)
            return

        }
    }, [hasLoaded, userSettings, setInfoMessage])
    
    async function updateUserSettings(settings?: UserSettings) {
        setUserSettings(settings)
        return management({user, type: "settings", operation: "update", ...settings})
            .then(() => setHasLoaded(false))
    }

    return (
        <UserSettingsContext.Provider value={{userSettings, updateUserSettings, users}}>
            {children}
        </UserSettingsContext.Provider>
    )
}