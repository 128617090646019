import clsx from "clsx"
import { EntityStatistics, IpRightsStatistics, RealmStatistics, UserStatistics, useStatistics } from "."
import { Link } from "react-router-dom"
import { Fragment, useState } from "react"
import _ from "lodash"
import { plans } from "../../data"

const fields = [
    'Realm', 'Patents', 'Trademarks', 'Products', 'Inventions', 'Users', 'Flags'
]

export default function StatisticsPage() {

    const [onlyActive, setOnlyActive] = useState(true)

    const {data: statistics, isLoading, error} = useStatistics(onlyActive)

    if (error) {
        console.error(error)
    }

    const [filter, setFilter] = useState('')
    const trimmedFilter = filter?.trim().toLowerCase() ?? ''

    const [selectedPlans, setSelectedPlans] = useState([])

    function filterStats(s: RealmStatistics) {
        // TODO more efficient?
        const matchesFilter = trimmedFilter === '' || s.realm.name.toLowerCase().includes(trimmedFilter)
        const matchesPlan = selectedPlans.length === 0 || selectedPlans.includes(s.realm.plan)
        return matchesFilter && matchesPlan
    }

    const filteredStats = _(statistics?.filter(filterStats) ?? []).sortBy(s => s.realm.name).value()

    return <>
        <h1 className="sr-only xmb-4 text-slate-500">Statistics</h1>
        <div className="flex flex-row gap-4 mb-4">
            <input type="search" className="form-input text-sm" placeholder="filter"
                value={filter} onChange={e => setFilter(e.target.value)} />
            <label className="font-medium text-slate-600 py-1.5">Only Active?
                <input type="checkbox" className="ml-2 form-checkbox" checked={onlyActive} onChange={e => setOnlyActive(e.target.checked)} />
            </label>
            <FeatureSelection features={plans} selected={selectedPlans} setSelected={setSelectedPlans} />
        </div>
        <div className="grid grid-cols-[repeat(7,auto)] w-fit">
            {fields.map((title, i) =>
                <div key={i}
                    className="text-pcx-600 text-sm capitalize border-b border-pcx-300 py-2 px-3 font-medium tracking-wider text-center first:text-left"
                >
                    {title}
                </div>
            )}
            {isLoading && <div className="col-span-7 text-center text-pcx-600 text-lg py-2">Loading...</div>}
            {filteredStats.map(s => <RealmRow key={s.realm.name} statistics={s} />)}
        </div>
    </>
}

function FeatureSelection({features, selected, setSelected}: {features: string[], selected: string[], setSelected: (s: string[]) => void}) {
    return (
        <div className="flex flex-row gap-1 flex-wrap">
            {features.map(f => {
                const isSelected = selected.includes(f)
                return <button
                    key={f} className={clsx(isSelected ? 'btn-primary' : 'btn-secondary', 'capitalize')}
                    onClick={() => setSelected(isSelected ? selected.filter(s => s !== f) : [...selected, f])}
                >
                    {f}
                </button>
            })}
        </div>
    )
}

const generalBoxStyle = 'px-3 py-1 border-b-2 border-pcx-200 text-sm'

function RealmRow({statistics}: {statistics: RealmStatistics}) {
    return <>
        <RealmBox realm={statistics.realm} />
        <IpRightsStatisticsBox title="Patents" statistics={statistics.patents} />
        <IpRightsStatisticsBox title="Trademarks" statistics={statistics.trademarks} />
        <EntityStatisticsBox title="Products" statistics={statistics.products} />
        <EntityStatisticsBox title="Inventions" statistics={statistics.inventions} />
        <UsersBox statistics={statistics.users} />
        <FlagsBox flags={statistics.flags} />
    </>
}

function RealmBox({realm}: {realm: {name: string, active: boolean, plan?: string}}) {
    return (
        <div className={clsx(generalBoxStyle)}>
            <h3><Link to={`/settings/admin/teams?team=${realm.name}`}>{realm.name}</Link></h3>
            <p className="text-lg text-slate-600 capitalize">
                <span title={realm.active ? 'active' : 'inactive'}
                    className={clsx("h-3 w-3 inline-block rounded-full mr-2", realm.active ? "bg-green-500" : "bg-slate-500")}/>
                {realm.plan ?? 'not-set'}
            </p>
        </div>
    )
}

function IpRightsStatisticsBox({title, statistics}: {title: string; statistics: IpRightsStatistics}) {
    const rows = [
        {label: 'Families', active: statistics.activeFamilies, inactive: statistics.inactiveFamilies,
         numbers: [{label: 'active', num: statistics.activeFamilies}, {label: 'inactive', num: statistics.inactiveFamilies}]},
        {label: 'Members', active: statistics.activeMembers, inactive: statistics.inactiveMembers,
        numbers: [{label: 'active', num: statistics.activeMembers}, {label: 'inactive', num: statistics.inactiveMembers}]}
    ]
    return (
        <div title={title} className={clsx(generalBoxStyle)}>
            {/*<h3>{title}</h3>*/}
            {rows.map(row => <BoxRow key={row.label} {...row} />)}
            {/*<p className="text-lg text-slate-600 flex flex-row items-baseline mb-0"><span className="text-sm mr-auto">Families:</span> <span title="active">{statistics.activeFamilies}</span> / <span title="inactive">{statistics.inactiveFamilies}</span></p>
            <p className="text-lg text-slate-600 flex flex-row items-baseline mb-0"><span className="text-sm mr-auto">Members:</span>  <span title="active">{statistics.activeMembers}</span> / <span title="inactive">{statistics.inactiveMembers}</span></p>
    */}
        </div>
    )
}

function EntityStatisticsBox({title, statistics}: {title: string; statistics: EntityStatistics}) {
    return (
        <div title={title} className={clsx(generalBoxStyle, 'flex flex-row gap-1 items-baseline tabular-nums')}>
            {/*<h3>{title}</h3>*/}
            <div className="text-slate-600 text-lg min-w-[4ch] text-right" title={"active " + title}>{statistics.active}</div>
            <div className="text-slate-600" title={"inactive " + title}> / {statistics.inactive}</div>
        </div>
    )
}

function UsersBox({statistics}: {statistics: UserStatistics}) {
    const cells = [
        {num: statistics.view, label: 'view'},
        {num: statistics.edit + statistics.admin, label: 'edit'},
    ]
    return (
        <div title={'Users'} className={generalBoxStyle}>
            {/*<h3>Users</h3>*/}
            <div className="h-fit grid grid-cols-[auto_auto] gap-x-2 items-baseline">
                {cells.map(({ num, label }) => <Fragment key={label}>
                    <div className="text-slate-600 text-lg min-w-[2ch] text-right">{num}</div>
                    <div className="text-slate-600 text-sm capitalize">{label}</div>
                </Fragment>)}
            </div>
        </div>
    )
}

function FlagsBox({flags}: {flags: string[]}) {
    return (
        <div title="Flags" className={clsx(generalBoxStyle)}>
            {/*<h3>Flags</h3>*/}
            <ul className="list-none flex flex-row flex-wrap gap-1">
                {_(flags).sortBy().map(f => <li key={f} className="ribbon text-pcx-800 font-medium">{f}</li>).value()}
            </ul>
        </div>
    )
}

function BoxRow({label, active, inactive}: {label: string; active: number, inactive: number }) {
    return (
        <div className="flex flex-row items-baseline gap-2">
            <div title="active" className="text-lg text-slate-600 min-w-[4ch] text-right tabular-nums">{active}</div>
            <div title="inactive" className="text-slate-600 min-w-[5ch] tabular-nums">/ {inactive}</div>
            <div className="text-slate-600 w-[6ch] xl:w-[8ch] tabular-nums hidden lg:block max-lg text-xs">{label}</div>
        </div>
    )
}


