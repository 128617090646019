import {useState} from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import { useRoles } from '../user/Auth'
import { useLocalState } from '../settings/localStorage.js'
import { IconSpinner } from '../components/icons'
import { useDocumentAcknowledge, useDocuments } from './DennemeyerProvider'
import { download_document } from './dennemeyer_backend'
import { useMessages } from '../Messages'
import { memberUrl } from "../patents/utils"
import { LoadButton } from "./components"
import { usePatents } from "../patents/PatentsProvider"

export type Document = {
    DocumentDate: string;
    DocumentName: string;
    DocumentType: string;
    DennemeyerId: number;
    References?: DocumentIpRightReference[];
}

export type DocumentIpRightReference = {
    DennemeyerReference: string;
    YourReference: string;
    Type: string;
    IpRightDennemeyerId: string;
}

const documentTypes = [
    'only-new-documents',
    'all-documents',
]

export default function Documents() {
    const {t} = useTranslation()

    // TODO: mirror this as a query param
    const [documentType, setDocumentType] = useLocalState('renewals-document-type', documentTypes[0])
    const [filterTerm, setFilterTerm] = useLocalState('renewals-filter-term', '')
    const filterTermLow = filterTerm.toLowerCase()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('documents')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <div className="header-row">
            <div className="flex flex-row max-w-md">
                <h2 className="mr-auto relative modern-h2">{t('documents')} </h2>
                <select className="form-select border-pcx-200 hover:border-pcx-300  focus:border-pcx-300 py-1 bg-transparent  focus:bg-white rounded-md focus:ring-0 text-pcx-400 focus:text-pcx-900"
                    value={documentType} onChange={e => setDocumentType(e.target.value)}>
                    {documentTypes.map(type => <option key={type} value={type}>{t(type)}</option>)}
                </select>
            </div>
        </div>
        <div className="max-w-md mx-4 mb-2">
            <input
                type="text" className="form-input w-full border-pcx-200 hover:border-pcx-300 focus:border-pcx-300 py-1 px-2 bg-pcx-100 focus:bg-white rounded-md focus:ring-0"
                placeholder={`${t('filter-imp')}...`} value={filterTerm} onChange={e => setFilterTerm(e.target.value)} />
        </div>
        {documentType === 'only-new-documents' ? <NewDocuments filterTerm={filterTermLow} /> : <AllDocuments filterTerm={filterTermLow}/>}
    </>
}

function filterDocument(filterTerm: string, document: Document): boolean {
    if (filterTerm === undefined || filterTerm === '') return true

    return (document.DocumentName.toLowerCase().includes(filterTerm))
        || document.DocumentType.toLowerCase().includes(filterTerm)
        || document.DocumentDate.includes(filterTerm)
        || document?.References?.find(({YourReference}) => YourReference?.toLowerCase()?.includes(filterTerm)) !== undefined
}

function NewDocuments({filterTerm}: {filterTerm: string}) {
    const {t} = useTranslation()
    // we assume for the moment that all new documents fit onto one page
    const {documentPages: newDocuments} = useDocuments({onlyNew: true})
    //const newDocuments = {pages: []}
    //console.log({newDocuments})

    return (
        <div className="main-content dark:border-t-0 pt-0 bg-pcx-100 flex flex-col gap-4 pb-20">
            <div className="hidden only:block pt-2">
                {filterTerm === ''
                    ? <h3 className="text-2xl text-pcx-600 font-medium">{t("no-new-documents")}</h3>
                    : <>
                        <h3 className="text-2xl text-pcx-600 font-medium">{t("no-new-documents-for-search")}</h3>
                        <p className="mt-2 text-slate-500 text-lg">{t("adapt-search")}</p>
                    </>}
            </div>
            {_(newDocuments?.pages ?? [])
                .flatMap(page => page?.Data?.Page ?? [])
                .sortBy('DocumentDate')
                .reverse()
                .map(d => ({...d, DocumentType: t(d.DocumentType)}))
                .filter(d => filterDocument(filterTerm, d))
                .map(document => <DocumentCard key={document.DennemeyerId} document={document}/>)
                .value()}
        </div>
    )
}

function AllDocuments({filterTerm}: {filterTerm: string}) {
    const {t} = useTranslation()
    const {documentPages, isFetchingNextPage, hasNextPage, fetchNextPage} = useDocuments({onlyNew: false})
    return (
        <div className="main-content dark:border-t-0 pt-0 bg-pcx-100 space-y-4 pb-20">
            {_(documentPages?.pages ?? [])
                .flatMap(page => page?.Data?.Page ?? [])
                // we already translate here so we can filter
                .map(d => ({...d, DocumentType: t(d.DocumentType)}))
                //.sortBy('DocumentDate')
                //.reverse()
                .filter(d => filterDocument(filterTerm, d))
                .map(document => <DocumentCard key={document.DennemeyerId} document={document}/>)
                .value()}
            {isFetchingNextPage 
                ?  <div className="text-pcx-400 animate-spin w-6 h-6"><IconSpinner /></div> 
                : hasNextPage ?  <LoadButton {...{hasNextPage, fetchNextPage}} />
                : null}
        </div>
    )
}


function DocumentCard({document}: {document: Document}) {
    const {isDev, isEditUser} = useRoles()
    const {memberByReference} = usePatents()
    const {setErrorMessage} = useMessages()
    const {acknowledge} = useDocumentAcknowledge()
    const {DennemeyerId, DocumentType, DocumentName, DocumentDate, References = []} = document

    const [isLoading, setIsLoading] = useState(false)

    //const {ipRightByDennemeyerId} = useDennemeyer()

    //const ipRights = (document.References ?? [])
    //    .map(ref => ipRightByDennemeyerId[ref.IpRightDennemeyerId])
    //    .filter(ipRight => ipRight)
    //    .map(ipRight => ({ipRight, member: memberById[ipRight.familyMemberId]}))

    const references = References.map(({YourReference}) => 
        YourReference in memberByReference 
            ? ({internalReference: YourReference, to: memberUrl(memberByReference[YourReference])}) 
            : ({internalReference: YourReference}))

    function downloadDocument() {
        setIsLoading(true)
        return download_document(DennemeyerId, DocumentName)
            .then(() => isEditUser ? acknowledge(DennemeyerId) : ({})) // don't acknowledge for view-only users
            .catch(err => setErrorMessage(err.message))
            .finally(() => setIsLoading(false))
    }

    return (
        <div
            className="max-w-md p-4 rounded-lg bg-white shadow-sm"
        >
            <span className="text-sm text-gray-600">{DocumentType}</span>
            <span className="text-sm text-gray-600 float-right">{DocumentDate?.substring(0, 10) ?? ''}</span>
            <h3 
                className="my-2 text-xl font-normal text-pcx-600 hover:text-pcx-500 flex flex-row items-center cursor-pointer" 
                onClick={() => downloadDocument()}
                title={`Download ${DocumentName}`}
            >
                {DocumentName}
                {isLoading && <IconSpinner className="h-5 w-5 text-pcx-600 ml-2 animate-spin" />}
            </h3>
            {isDev && <span className="text-sm text-gray-600">{DennemeyerId}</span>}
            <div className="flex flex-row gap-2 flex-wrap">
                {references.map(({internalReference, to}, ri) => to 
                    ? <Link key={ri} to={to} className="ribbon-clickable">{internalReference}</Link>
                    : <div key={ri} className="ribbon">{internalReference}</div>)}
            </div>
            {/*<div>
                {ipRights.map(({ipRight, member}) => 
                    <div key={ipRight.DennemeyerId}>
                        {member.internalReference}
                    </div>)}
    </div>*/}
        </div>
    )
}