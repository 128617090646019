import { useTranslation } from "react-i18next";

import { IconTrash } from "./icons";
import Modal from "./Modal";
import { useState } from "react";

export interface DeleteButtonProps {
    deleteAction: () => void | Promise<any>;
    question?: string;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
}
export default function DeleteButton({deleteAction, question, children, className, disabled}: DeleteButtonProps) {
    const {t} = useTranslation()
    const [showPopup, setShowPopup] = useState(false)

    return <>
        <button type="button" title={t('delete')} disabled={disabled} className={className ?? "btn-warn disabled:btn-disabled w-full h-full p-0.5"} onClick={() => setShowPopup(s => !s)}>
            {children ?? <IconTrash />}
        </button>
        {showPopup && <DeletePupop {...{deleteAction, question, setShowPopup}} /> }
    </>
}

function DeletePupop({deleteAction, question, setShowPopup}: {deleteAction: () => void | Promise<any>, question?: string, setShowPopup: (arg: boolean) => void}) {
    const {t} = useTranslation()
    return (
        <Modal>
                <div className="p-4">

                    <div className="pb-2 text-lg">
                        {question ?? t('really-delete')}
                    </div>
                </div>
                <div className="flex flex-row-reverse gap-4 text-base font-medium bg-pcx-200 p-4">
                    <button
                        className="btn-warn text-red-900"
                        onClick={() => { deleteAction(); setShowPopup(false) }} >
                        {t('delete')}
                    </button>
                    <button className="btn-secondary" onClick={() => setShowPopup(false)}>{t('cancel')}</button>
                </div>
        </Modal>
    )
}