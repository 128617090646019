import { transliterate as tr } from 'transliteration';


export default function kanji2roman(kanji: string) {
    const noWeirdSpaces = kanji.replace(/[\u2000-\u200F]/g, ' ')
    const transliterated = tr(noWeirdSpaces)
    if (transliterated === noWeirdSpaces) 
        return undefined
    else
        return transliterated
}