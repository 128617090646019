import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid"
import _ from "lodash"

import Modal from "../Modal"
import { IconSpinner } from "../icons"
import { useNavigate } from "react-router-dom"
import { useDocumentSettings } from "../../documents/DocumentsSettings"
import { useAgorumObject, useFindUuid, useInvalidateFindUuid } from "../../documents/backend"


interface AfterChangeAction {
    key: string,
    label: string,
    action: () => Promise<void>, // throw a string if there is an error
}

export default function AutomaticBackgroundChanges({actions, close}: {actions: AfterChangeAction[], close}) {
    const {t} = useTranslation()

    const [areTasksRunning, setAreTasksRunning] = useState(false)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        async function handleUpdates() {
            // console.log('Handling updates')
            const results = await Promise.all(actions.map(async (a, i) => {
                // console.log('Action ' + a.key)
                try {
                    await a.action()
                    setErrors(e => ({ ...e, [a.key]: 0 }))
                    return 0
                } catch (error) {
                    console.warn(error)
                    setErrors(e => ({ ...e, [a.key]: error }))
                    return 1
                }
            }))
            setAreTasksRunning(false)
            if (_.sum(results) === 0) {
                close()
            }
        }

        // console.log({actions, areTasksRunning})
        if (actions.length > 0 && !areTasksRunning) {
            const timer = setTimeout(() => {
                setAreTasksRunning(true)
                handleUpdates()
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [actions, areTasksRunning, close])

    const isDone = actions.length > 0 && actions.every(a => errors[a.key] === 0)
    if (actions.length === 0 || isDone) {
        return null
    }

    const hasErrors = actions.some(a => typeof errors[a.key] === 'string')

    // TODO: Fully translate
    return (
        <Modal>
            <div>
                <div className="p-4 space-y-2 max-w-md">
                    <h4>Please wait</h4>
                    <p className="text-slate-500">Running some background tasks...</p>
                    <ul>
                        {actions.map(({ key, label }) =>
                            <li key={key} className="list-none">
                                <div className="flex flex-row gap-1 items-center">
                                    <ProgressIndicator {...{ hasSubmitted: true, error: errors[key] }} />{label}
                                </div>
                                {typeof errors[key] === 'string' && <div className="text-warn-800 pl-4">{errors[key]}</div>}
                            </li>)}
                    </ul>
                </div>
                <div className="bg-pcx-200 flex flex-row-reverse gap-4 justify-between p-4">
                    {hasErrors 
                        ? <>
                            <button className="btn-primary" onClick={() => {
                                setErrors({})
                                close()
                            }}>
                                {t('close')}
                            </button>
                            <a href={`mailto:support@patent-cockpit?subject=Error&body=${encodeURIComponent(_(errors).values().filter(e => typeof e === 'string').join("\n"))}`}
                                className="btn-tertiary"
                            >
                                {t('support')}
                            </a>
                        </>
                        : <button onClick={() => {
                            setErrors({})
                            close()
                        }}>
                            <IconSpinner className="h-6 w-6 text-pcx-500 animate-spin" />
                        </button>
                    }
                </div>
            </div>
        </Modal>
    )
}

function ProgressIndicator({hasSubmitted, error}: {hasSubmitted: boolean, error: number | undefined | any}) {
    if (!hasSubmitted)
        return null
        
    return error === undefined
        //? <div className="size-4 border-2 border-slate-500 rounded-full" />
        ? <IconSpinner className="size-4 animate-spin text-pcx-500" />
        : error === 0
        ? <CheckCircleIcon className="size-5 text-lime-500" />
        : <ExclamationCircleIcon className="size-5 text-warn-500" title={typeof error === 'string' ? error : undefined} />
}

export function OnlyRenameFolder({ from, to, type, parent }: { from: string, to: string, type: string, parent?: string }) {

    const navigate = useNavigate()

    const { number } = useDocumentSettings()
    const { uuid } = useFindUuid(number, type, from, parent)
    //console.log(number, type, from, parent, uuid)
    const invalidateFindUuid = useInvalidateFindUuid(type, from)

    const { object, update, isLoading } = useAgorumObject({ uuid })

    const hasFolder = uuid !== undefined

    //console.log({hasFolder, from, to, object})
    async function moveFolder() {
        //console.log('moving', {from, to, object})
        if (object !== undefined) {
            return update({ ...object, name: to })
        }
    }

    function close() {
        //console.log('closing')
        invalidateFindUuid()
        navigate('.')
    }

    const actions = [
        hasFolder && { key: 'move-folder', label: 'Renaming the folder', action: moveFolder }
    ].filter(Boolean)

    return isLoading ? null : <AutomaticBackgroundChanges {...{actions, close}} />
}


export function ExampleModal() {
    const [actions, setActions] = useState([])

    return <>
        <button className="btn-primaryt" onClick={() => {
            setActions([{
                key: 'a',
                label: 'a label',
                action: () => new Promise(resolve => setTimeout(resolve, 500))//.then(() => { throw 'A long long Error A long long Error A long long Error A long long Error ' })
            }, {
                key: 'b',
                label: 'b label',
                action: () => new Promise(resolve => setTimeout(resolve, 1500))//.then(() => { throw 'A long long Error A long long Error A long long Error A long long Error BBBB ' })
            }])
            // setErrors({})
        }}>Trigger Change Modal</button>
        {actions.length > 0 && <AutomaticBackgroundChanges {...{actions, close: () => setActions([])}} />}
    </>
}