import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, } from "react";
import { parseBackend } from "../backend";
import { ErrorMessage } from "../Messages";
import { useRoles } from "../user/Auth";

const dummySession = {sessionId: '', baseurl: ''}   

export const DmsContext = createContext(dummySession)

type DocumentSession = Pick<typeof dummySession, 'sessionId' | 'baseurl'>

// TODO add open state for folders?
export function DmsProvider({children}) {
    const {hasDocuments} = useRoles()

    const {data: session} = useQuery<DocumentSession, ErrorMessage, DocumentSession>({
        queryKey: ['agorum-session'],
        queryFn: async () => parseBackend(fetch('/api/dms/session')),
        enabled: hasDocuments,
        refetchInterval: 1000 * 60 * 9,
    })

    //console.log({session})
    
    return <DmsContext.Provider value={{...(session ?? dummySession)}}>
        {children}
    </DmsContext.Provider>
}

export function useDms() {
    return useContext(DmsContext)
}