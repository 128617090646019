import {Button, CalendarCell, CalendarGrid, DateInput, DateRangePicker, DateSegment, DateValue, Group, Heading, RangeCalendar} from 'react-aria-components';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { parseDate } from '@internationalized/date';
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon  } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export interface SelectDateRangeProps {
    className?: string;
    name: string;
    minDate: string;
    minValue?: DateValue;
    maxDate: string;
    setMinDate: (date: string) => void;
    setMaxDate: (date: string) => void;
    title?: string;
    disabled?: boolean;
    errorPosition?: ErrorPosition;
}

const dataSegmentStyle = "focus:ring-0 focus:outline-none tabular-nums"

function saveParseDate(date: string) {
    try {
        return parseDate(date)
    } catch {
        return undefined
    }
}

function validate({minDate, maxDate, minValue: _minValue, t}: {minDate: string, maxDate: string, minValue?: DateValue, t: TFunction<"translation", undefined>}) {

    let error = undefined

    if(minDate && maxDate && minDate > maxDate) {
        error = t("start-date-before-end-date")
    }

    if (_minValue) {
        const minValue = _minValue.toString()
        if (minDate < minValue) {
            error = t("start-date-before-min-date", {minValue})
        }
    }

    return error
}

export type ErrorPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'

function getErrorPositionClass(position?: ErrorPosition) {
    switch (position) {
        case 'bottom-right':
            return '-bottom-6 -right-0'
        case 'bottom-left':
            return '-bottom-6 -left-0'
        case 'top-left':
            return '-top-6 -left-0'
        case 'top-right':
        default:
            return '-top-6 -right-0'
    }
}

export default function SelectDateRange({ className = undefined, name, minDate, maxDate, setMinDate, setMaxDate, title, disabled, errorPosition, ...props }: SelectDateRangeProps) {
    const {t} = useTranslation()

    const error = !disabled && validate({minDate, maxDate, minValue: props['minValue'], t})
    const isInvalid = !disabled && error !== undefined

    return (
        <DateRangePicker
            aria-label={name}
            isDisabled={disabled}
            shouldForceLeadingZeros={true}
            value={{
                start: saveParseDate(minDate),
                end: saveParseDate(maxDate),
            }}
            onChange={(value) => {
                setMinDate(value.start?.toString())
                setMaxDate(value.end?.toString())
            }}
            isInvalid={isInvalid}
            {...props}
        >
            <Group 
                className={clsx('flex flex-row gap-1.5 items-center form-input relative', className, isInvalid && 'border-red-600 text-red-800')} 
                title={error ?? title}
            >
                {error && 
                    <div className={clsx(
                        getErrorPositionClass(errorPosition),
                        'absolute bg-warn-100 text-2xs px-2 py-0.5 border border-warn-400 rounded whitespace-nowrap'
                    )}>{error}</div>}
                <DateInput slot="start" className="flex flex-row gap-0.5">
                    {(segment) => <DateSegment className={dataSegmentStyle} segment={segment} />}
                </DateInput>
                <span aria-hidden="true">–</span>
                <DateInput slot="end" className="flex flex-row gap-0.5">
                    {(segment) => <DateSegment className={dataSegmentStyle} segment={segment} />}
                </DateInput>
                <Popover className="relative">
                    <PopoverButton disabled={disabled} className="block">
                        <CalendarDaysIcon className='h-5 w-5 text-pcx-700' />
                    </PopoverButton>
                    <PopoverPanel className="absolute mt-2 -right-2 bg-white rounded-md shadow border border-pcx-400 p-2">
                        <RangeCalendar>
                            <header className='flex flex-row gap-2 w-full justify-between mb-3'>
                                <Button slot="previous"><ChevronLeftIcon className='h-5 w-5' /></Button>
                                <Heading />
                                <Button slot="next"><ChevronRightIcon className='h-5 w-5' /></Button>
                            </header>
                            <CalendarGrid>
                                {(date) =>
                                    <CalendarCell
                                        className={({ isSelected, isDisabled }) => clsx(
                                            'tabular-nums m-0.5 p-1 text-right rounded',
                                            isDisabled ? 'text-slate-400' : 'hover:bg-pcx-200',
                                            isSelected &&
                                            (isDisabled
                                                ? 'bg-slate-100 text-slate-400'
                                                : 'bg-pcx-400 text-white hover:text-slate-900')
                                        )}
                                        date={date} />}
                            </CalendarGrid>
                        </RangeCalendar>
                    </PopoverPanel>
                </Popover>
            </Group>
        </DateRangePicker>
    )
}