import React, {useState, useEffect, useContext} from "react";
import { getBillingInformation, getBillingPlan, getBills, getPrices, postBillingInformation, postBillingPlan } from "../../backend";
import { useMessages } from "../../Messages";
import { useAuth } from "../../user/Auth";

export const initialBillingInformation = {
    companyName: "",
    address1: "",
    address2: "",
    zip: "",
    city: "",
    countryCode: "CH",
}

const initialPlan = {
    frequency: "1Y",
    currency: "CHF",
}

const BillsContext = React.createContext({
    prices: [],
    bills: [],
    billingInformation: {},
    updateBillingInformation: () => {},
    billingPlan: {},
    updatePlan: () => {},
    hasLoaded: false,
})


export function BillsProvider({children}) {
    const {team} = useAuth()
    const { setErrorMessage } = useMessages()

    const [hasLoaded, setHasLoaded] = useState(false)

    const [prices, setPrices] = useState([])
    const [bills, setBills] = useState([])
    const [billingInformation, setBillingInformation] = useState(initialBillingInformation)
    const [billingPlan, setBillingPlan] = useState(initialPlan)

    useEffect(() => {
        if (!hasLoaded) {
            //console.log("Loading from backend...")
            getBillingInformation().then(setBillingInformation)
                .then(() => getPrices().then(setPrices))
                .then(() => getBills().then(setBills))
                .then(() => getBillingPlan().then(setBillingPlan))
                .then(() => setHasLoaded(true))
                .catch(err => setErrorMessage(err.message))
        }
    }, [hasLoaded, setErrorMessage])
    
    function updateBillingInformation(info) {
        postBillingInformation({...info, realm: team})
        setBillingInformation(info)
    }

    function updatePlan(plan) {
        postBillingPlan({...plan, realm: team})
        setBillingPlan(plan)
    }

    const value = {
        prices,
        bills,
        billingInformation,
        updateBillingInformation,
        billingPlan,
        updatePlan,
        hasLoaded,
    }

    return (
        <BillsContext.Provider value={value}>
            {children}
        </BillsContext.Provider>
    )
}


export function useBills() {
    return useContext(BillsContext)
}
