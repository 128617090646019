import { Trademark } from "./TrademarksProvider"

export function trademarkUrl({reference}: {reference: string}) {
    return `/trademarks/portfolio/member/${reference}`
}
export function trademarkFamilyUrl({reference}: {reference: string}) {
    return `/trademarks/portfolio/family/${reference}`
}

export function isActiveTrademark(trademark: Trademark) {
    // TODO: what about "refused" protection status?
    return trademark.status === "planned" || trademark.status === "applied" || trademark.status === "registered"
}