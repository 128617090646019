export default function ComingSoon({title}) {
    if (title)
        return (
            <div>
                <div className="header-row">
                    <h2 className="pb-2">{title}</h2>
                </div>
                <div className="main-content">
                Coming Soon...
                </div>
            </div>
        )
    else
        return (
            <div>
                <h2>Coming Soon...</h2>
            </div>
        )
}