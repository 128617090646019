import { family_member, patent_family, trade_mark } from "../../data"
import { Family, Member } from "../../patents/patents"
import { Trademark } from "../../trademarks/TrademarksProvider"
import { ReferenceProps } from "./ReferenceLinker"


export function getReference(link: {entity?: string, entityId?: number}, familyById: Record<number, Family>, memberById: Record<number, Member>, trademarkById: Record<number, Trademark>): ReferenceProps {
    const entity = link.entity
    const entityId = link.entityId
    let internalReference = undefined
    if (entity === patent_family && entityId in familyById) {
        internalReference = familyById[entityId].internalReference
    } else if (entity === family_member && entityId in memberById) {
        internalReference = memberById[entityId].internalReference
    } else if (entity === trade_mark && entityId in trademarkById) {
        internalReference = trademarkById[entityId].reference
    }

    return ({
        entity,
        entityId,
        internalReference,
    })
}

export function isEquivalent(a?: ReferenceProps, b?: ReferenceProps) {
    return a?.entity === b?.entity && a?.entityId === b?.entityId
}