import React from 'react';
import {
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import { pass_through } from '../dennemeyer_backend'
import { PageResponse } from '../DennemeyerProvider'

export interface Subscription  {
    SubscriptionId: number;
    ClientId?: {Value: string};
    IpRightId?: string;
    EventType?: string;
    EventParameters?: string;
    NotificationType: "EMail" | "WebHook";
    NotificationRecipient?: string;
    CreatedAt: string;
    Active: boolean;
}

const notificationsKey = ['notifications']

const DmNotifications = React.createContext({
    subscriptions: [] as Subscription[],
    isLoading: false,
    reloadSubscriptions: () => { },
})

export function DmNotificationsProvider({children}: {children: React.ReactNode}) {

    const queryClient = useQueryClient()

    // https://api.dennemeyer.com/v1.2/api/notifications/subscriptions
    const { data, isLoading } = useQuery<PageResponse<Subscription>>({
        queryKey: notificationsKey,
        queryFn: () => pass_through('/v1.2/api/notifications/subscriptions'),
        enabled: true
    })

    function reloadSubscriptions() {
        console.log("invalidating query")
        return queryClient.invalidateQueries({queryKey: notificationsKey})
    }

    const value = {subscriptions: data?.Data?.Page ?? [], isLoading, reloadSubscriptions}

    return <DmNotifications.Provider value={value}>{children}</DmNotifications.Provider>
}

export function useDmNotifications() {
    return React.useContext(DmNotifications)
}