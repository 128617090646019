import React, {useContext} from "react";
import _ from 'lodash'

import { useBackend } from "../BackendProvider";
import { invention_inventor_link } from "../data";
import { Agent } from "../agents/utils";
import { useRoles } from "../user/Auth";
import { useAgents } from "../agents/AgentsProvider";

export interface Invention {
    inventionId?: number;
    reference: string;
    referenceDate: string;
    title: string;
    summary: string;
    freeInvention: boolean;
    receiptSent: boolean;
    objectionToDisclosure: string;
    objectionToFreeInvention: string;
    claimOnInvention: string;
    foreignClaims: string;
    familyMemberId?: number;
}

export interface InventionInventor {
    inventionId: number;
    agentId: number;
}

export interface InventionPayment {
    paymentId?: number,
    dueDate: string,
    paid: boolean,
    agentId: number,
    inventionId: number,
    amount: number,
    currency: string,
}

const InventionsContext = React.createContext({
    inventions: [] as Invention[],
    addInvention: (invention: Invention) => Promise.resolve(invention),
    updateInvention: (invention: Invention) => Promise.resolve(invention),
    delInvention: (id: number) => Promise.resolve({}), 

    inventionCountries: [],

    inventors: [] as Agent[],

    inventionInventors: [] as InventionInventor[],
    addInventionInventors: (ii: InventionInventor) => Promise.resolve(ii),
    delInventionInventors: (ii: InventionInventor) => Promise.resolve({}),

    payments: [] as InventionPayment[],
    addPayment: (payment: InventionPayment) => Promise.resolve(payment),
    updatePayment: (payment: InventionPayment) => Promise.resolve(payment),
    delPayment: (id: number) => Promise.resolve({}), 

    inventionById: {} as Record<number, Invention>,
    inventionByReference: {} as Record<string, Invention>, 
    inventionsByMemberId: {} as Record<number, Invention[]>,
    paymentsByInventionId: {} as Record<number, InventionPayment[]>,
    inventorById: {} as Record<number, Agent>,
    inventorsByInventionId: {} as Record<number, Agent[]>,

    isLoading: false as boolean,
})

export function useInventions() {
    return useContext(InventionsContext)
}

export default function InventionsProvider({children}) {
    const {hasInnovation} = useRoles()

    if (hasInnovation) {
        return <RealInventionsProvider>{children}</RealInventionsProvider>
    } else {
        return children
    }
}

function RealInventionsProvider({children}) {

    const {
        inventions, 
        inventionPayments: payments, 
        inventionInventors, 
        inventionCountries, 
        entityOperation, 
        linkOperation, 
        //agents,
        isLoading,
    } = useBackend()
    const {agents} = useAgents()
    const inventors = agents.filter(a => a.agentType === "person")

    function addInvention(inv: Invention) {
        return entityOperation("invention", "add", inv) as Promise<Invention>
    }

    function delInvention(id: number) {
        // reload payments after deletion of inventions
        return entityOperation("invention", "delete", id)
            .then(() => entityOperation("invention-payment", "get"))
    }

    function updateInvention(inv: Invention) {
        return entityOperation("invention", "update", inv) as Promise<Invention>
    }

    function addInventionInventors(ii: InventionInventor) {
        return linkOperation(invention_inventor_link, "add", ii) as Promise<InventionInventor>
    }

    function delInventionInventors(ii: InventionInventor) {
        return linkOperation(invention_inventor_link, "delete", ii)
    }

    function addPayment(payment: InventionPayment) {
        return entityOperation("invention-payment", "add", payment) as Promise<InventionPayment>
    }

    function delPayment(id: number) {
        return entityOperation("invention-payment", "delete", id)
    }

    function updatePayment(payment: InventionPayment) {
        return entityOperation("invention-payment", "update", payment) as Promise<InventionPayment>
    }

    const inventionById: Record<number, Invention> = _.keyBy(inventions, "inventionId")
    const inventionByReference: Record<string, Invention> = _.keyBy(inventions, "reference")
    const inventionsByMemberId: Record<number, Invention[]> = _.groupBy(inventions, "familyMemberId")
    const paymentsByInventionId: Record<number, InventionPayment[]> = _.groupBy(payments, "inventionId")
    const inventorById: Record<number, Agent> = _.keyBy(inventors, "agentId")
    const inventorsByInventionId: Record<number, Agent[]> = _(inventionInventors)
        .groupBy("inventionId")
        .mapValues((iis) => iis.map(ii => inventorById[ii.agentId]).filter(Boolean))
        .value()

    const value = {
        inventions, addInvention, updateInvention, delInvention,
        inventionCountries,
        inventors,
        inventionInventors, addInventionInventors, delInventionInventors, 
        payments, addPayment, updatePayment, delPayment,
        inventionById, inventionByReference, inventionsByMemberId, paymentsByInventionId, inventorsByInventionId, inventorById,
        isLoading,
    }

    return (
        <InventionsContext.Provider {...{value}}>
            {children}
        </InventionsContext.Provider>
    )
}