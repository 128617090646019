import { useLocation } from "react-router-dom"
import { useRoles } from "../../user/Auth"
import { afterUpdateActionsStr } from "."
import { OnlyRenameFolder } from "./AutomaticBackgroundChanges"
import { trademark_family } from "../../data"
import { useTrademarks } from "../../trademarks/TrademarksProvider"

export function TrademarkFamilyChanged() {
    const {hasDocuments} = useRoles()
    const location = useLocation()

    if (!hasDocuments)
        return null 

    const state = location.state ?? {}
    const changes = state[afterUpdateActionsStr] ?? []
    //console.log({state})

    // currently only move-folder is supported for families
    const doMoveFolder = changes.includes('move-folder')

    if (!doMoveFolder)
        return null

    const from = state.from.reference
    const to = state.to.reference
    return <OnlyRenameFolder {...{ from, to, type: trademark_family}} />
}

export function TrademarkChanged() {
    const {hasDocuments} = useRoles()
    const location = useLocation()
    const {trademarkFamilyById} = useTrademarks()

    if (!hasDocuments)
        return null 

    const state = location.state ?? {}
    const changes = state[afterUpdateActionsStr] ?? []
    //console.log({state})

    // currently only move-folder is supported for trademarks
    const doMoveFolder = changes.includes('move-folder')

    if (!doMoveFolder)
        return null

    const from = state.from.reference
    const to = state.to.reference
    const family = trademarkFamilyById[state.from.familyId]

    return <OnlyRenameFolder {...{ from, to, type: trademark_family, parent: family?.reference}} />
}