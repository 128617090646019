import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

import ToggleButton from "../components/ToggleButton"
import { useBackend } from "../BackendProvider"
import { IconShieldCheck, IconCircleX } from "../components/icons"
import { PlainImage } from "../components/Image"
import { memberUrl } from "../patents/utils"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { useFilteredCommodities } from "../filter/FilteredCommodities"
import { usePatents } from "../patents/PatentsProvider"

function onlyNewestClaims(rawClaims) {
    const byMember = _.groupBy(rawClaims, c => c.familyMemberId)
    return Object.values(byMember).flatMap(claims => {
        const maxVersion = Math.max(...claims.map(c => c.version))
        return claims.filter(c => c.version === maxVersion)
    })
}

function loadDisplayCountry() {
    return window.localStorage.getItem('ppm-display-country')
}

function saveDisplayCountry(displayCountry) {
    if (displayCountry === undefined)
        window.localStorage.removeItem('ppm-display-country')
    else
        window.localStorage.setItem('ppm-display-country', displayCountry)
}

export default function ProductProtectionMap() {

    const { claims: rawClaims, } = useBackend()
    const { commodityClaimScopeLinks, commodityFamilyLinks, commodityCountryLinks } = usePatents()
    const { members } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    const commodityIds = new Set(commodities.map(c => c.commodityId))

    const [displayCountry, setDisplayCountry] = useState(loadDisplayCountry())
    const [showPictures, setShowPictures] = useState(true)

    const claims = onlyNewestClaims(rawClaims)
    const membersById = _.keyBy(members, m => m.familyMemberId)

    const marketCountryByProduct = commodityCountryLinks
        .filter(l => commodityIds.has(l.commodityId))
        .reduce((acc, { commodityId, countryCode }) =>
            ({ ...acc, [commodityId]: [...(acc[commodityId] ?? []), countryCode] })
            , {})
    const membersByFamilyId = _.groupBy(members, m => m.patentFamilyId)
    const countryByMemberId = Object.fromEntries(members.map(m => [m.familyMemberId, m.countryCode]))
    const membersByCountry = _.groupBy(members, m => m.countryCode)

    const displayMembers = displayCountry ? (membersByCountry[displayCountry] ?? []) : []
    //console.log(displayMembers)
    //console.log(claims)
    //console.log(commodityClaimScopeLinks)
    //console.log(commodities)

    const directFamiliesByProduct = commodityFamilyLinks.reduce((acc, { commodityId, patentFamilyId }) =>
        ({ ...acc, [commodityId]: [...(acc[commodityId] ?? []), patentFamilyId] })
        , {})

    const memberByClaimScopeId = claims
        .filter(c => c.claimScopeId && c.familyMemberId in countryByMemberId)
        .reduce((acc, { familyMemberId, claimScopeId }) =>
            ({ ...acc, [claimScopeId]: _.uniq([...(acc[claimScopeId] ?? []), familyMemberId]) })
            , {})
    // TODO: filter out old claim scopes; only add when protected!!!!
    const membersIdByProduct = commodityClaimScopeLinks
        .filter(l => l.protected)
        .reduce((acc, { commodityId, claimScopeId }) =>
            ({ ...acc, [commodityId]: _.uniq([...(acc[commodityId] ?? []), ...(memberByClaimScopeId[claimScopeId] ?? [])]) })
            , {})
    //console.log(membersIdByProduct)

    const membersByProduct = Object.fromEntries(commodities.map(c => {
        // direct by family
        const directMembers = (directFamiliesByProduct[c.commodityId] ?? [])
            .flatMap(f => (membersByFamilyId[f] ?? []))
        // indirect by claim scope
        const indirectMembers = (membersIdByProduct[c.commodityId] ?? []).map(m => membersById[m])
        const allMembers = _.uniqBy([...directMembers, ...indirectMembers], m => m.internalReference)

        return [c.commodityId, allMembers]
    }))

    //console.log(membersByProduct)
    const protectedCountryByProduct = _.mapValues(membersByProduct, ms => new Set(ms.map(m => m.countryCode)))
    //console.log(protectedCountryByProduct)

    const allCountries = [...new Set([..._.flatMap(Object.values(marketCountryByProduct)), ..._.flatMap(Object.values(protectedCountryByProduct).map(cs => [...cs]))])].sort()

    const commoditiesByClass = _.groupBy(commodities, c => c.commodityClass)
    const sortedClasses = Object.keys(commoditiesByClass).sort()

    function insetBorder(idx) {
        if (idx === 0) 
            return "border-l border-pc-200"
        else if (idx === displayMembers.length - 1)
            return "border-r border-pc-200"
        else
            return ""
    }

    if (commodities.length === 0)
        return (
            <>
                <Helmet>
                    <title>Product Protection Map | Patent Cockpit</title>
                </Helmet>
                <div className="header-row">
                    <h2 className="">Product Protection</h2>
                </div>
                <div className="main-content text-center">
                    Visualize which products are protected in which markets.
                    <br />
                    Add some products <Link className="text-pc-300 underline-link" to="/products/portfolio">in the portfolio page</Link> to get started!
                </div>
            </>
        )
    else
        return (
            <>
                <Helmet>
                    <title>Product Protection Map | Patent Cockpit</title>
                </Helmet>
                <div className="header-row flex flex-wrap justify-between">
                    <h2>Product Protection</h2>
                    <label className="flex flex-row gap-2 items-center">
                        <span>Show Pictures </span>
                        <ToggleButton {...{ checked: showPictures, setChecked: setShowPictures }} />
                    </label>
                </div>
                <div className="main-content">
                    <table className="bg-white dark:bg-pc-100">
                        <thead>
                            <tr>
                                <td></td>
                                {allCountries.flatMap(cc =>
                                    [
                                        <td key={cc}>
                                            <button
                                                className="btn-secondary py-0 px-1 w-10 mx-px"
                                                onClick={() => {
                                                    if (displayCountry !== cc) {
                                                        setDisplayCountry(cc)
                                                        saveDisplayCountry(cc)
                                                    } else {
                                                        setDisplayCountry(undefined)
                                                        saveDisplayCountry(undefined)
                                                    }
                                                }}
                                            >
                                                {cc}
                                            </button>
                                        </td>,
                                        ...(displayCountry === cc
                                            ? displayMembers.map(m =>
                                                <td className="bg-pc-100 dark:bg-pc-200" key={m.internalReference}>
                                                    <div className="px-1">
                                                        <Link 
                                                            to={memberUrl(m)} 
                                                            className="whitespace-nowrap underline-link">
                                                            {m.internalReference}
                                                        </Link>
                                                    </div>
                                                </td>)
                                            : [])
                                    ]
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedClasses.flatMap(cl =>
                                [
                                    <tr key={`row-class-${cl}`} className=" border-t-2 border-pc-250">
                                        <td>
                                            <div className=" pr-2">
                                                <h3>
                                                    {cl !== "undefined" ? cl : "Unclassified"}
                                                </h3>
                                            </div>
                                        </td>
                                        {allCountries.flatMap(c => [
                                            <td key={`${cl}-${c}`}></td>,
                                            ...(displayCountry === c
                                                ? displayMembers.map((m, mIdx) => <td className={`${insetBorder(mIdx)} bg-pc-100 dark:bg-pc-200`} key={`${cl}-${m.internalReference}`}></td>)
                                                : [])
                                        ])}
                                    </tr>,
                                    ...commoditiesByClass[cl].map(commodity =>
                                        <tr key={`row-${commodity.commodityId}`} className="border-t-2 border-pc-200 dark:border-t dark:border-pc-250">
                                            <td>
                                                <div>
                                                    <Link className="underline-link" to="../portfolio">
                                                        {commodity.commodityReference}
                                                        {showPictures && 
                                                            <div className="h-16 w-32">
                                                                <PlainImage {...{
                                                                    entity: "commodity", 
                                                                    entityId: commodity.commodityId, 
                                                                    title: commodity.commodityReference
                                                                    }} />
                                                                </div>
                                                                }
                                                    </Link>
                                                </div>
                                            </td>
                                            {allCountries.flatMap(c => {
                                                const inMarket = (marketCountryByProduct[commodity.commodityId] ?? []).indexOf(c) >= 0
                                                const isProtected = protectedCountryByProduct[commodity.commodityId].has(c)
                                                return [
                                                    <td key={`${commodity.commodityId}-${c}`}>
                                                        <div className="flex flex-col items-center">
                                                            {inMarket && isProtected
                                                                ? <span className="text-pc-500"><IconShieldCheck /></span>
                                                                : inMarket && !isProtected
                                                                    ? <span className="text-warn-600"><IconCircleX /></span>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </td>,
                                                    ...(displayCountry === c
                                                        ? displayMembers.map((m, mIdx) =>
                                                            <td className={`${insetBorder(mIdx)} bg-pc-100 dark:bg-pc-200`} key={`${commodity.commodityId}-${m.internalReference}`}>
                                                                <div className="flex flex-col items-center">
                                                                    {inMarket && membersByProduct[commodity.commodityId].find(mbp => mbp.internalReference === m.internalReference)
                                                                        ? <span className="text-pc-500"><IconShieldCheck /></span>
                                                                        : <div />
                                                                    }
                                                                </div>
                                                            </td>)
                                                        : [])
                                                ]
                                            })}
                                        </tr>
                                    )]
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        )
}