import { Fragment, ReactNode  } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import clsx from "clsx";

type BreadCrumb = {
    to: string;
    label: string | ReactNode;
    className?: string;
};

const linkStyle = "text-base font-medium text-gray-700 hover:text-gray-900";

export function BreadCrumbs({parts}: {parts: (BreadCrumb | ReactNode)[]}) {
    function showChevron(index: number) {
        if (parts.length - index >= 2) 
            return "max-sm:hidden"
        else
            return ""
    }

    return (
        <nav className="flex flex-row items-center gap-2 sm:gap-4 px-4 sm:px-5 py-4 whitespace-nowrap">
            {parts.map((part, index) => <Fragment key={index}>
                {index > 0 &&
                    <ChevronRightIcon className={clsx(showChevron(index), "h-5 w-5 flex-shrink-0 text-gray-600")} aria-hidden="true" />}
                {typeof part === 'object' && 'to' in part
                    ? <Link className={clsx(linkStyle, part.className)} to={part.to}>{part.label}</Link>
                    : <div className={linkStyle}>{part}</div>}
            </Fragment>)}
        </nav>
    )

}