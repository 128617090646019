import _ from 'lodash'
import { DateOrString } from "./dates";

export function firstUpperCase(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export function noHtml(str: string) {
    return str.replace(/<[^>]*>/g, '');
}

export function emptyStringAsUndefined<T>(s: T | ""): T | undefined {
    return s === "" ? undefined : s
}

export function nonEmptyString(s?: string): boolean {
    return s !== undefined && s !== ""
}

export function isEmptyString(s?: string): boolean {
    return s === undefined || s === ""
}

export function objWithoutEmptyStrings<T>(obj: T): T {
    return _(obj).toPairs().filter(([_, v]) => v !== undefined && v !== '').fromPairs().value() as T
}

export function nonEmptyDate(s?: DateOrString) {
    return s !== undefined && s !== ""
}

export function isEmptyDate(s: Date | string) {
    return s === undefined || s === ""
}

export function trimStringNonEmpty(s?: string) {
    const trimmed = s?.trim()
    if (trimmed === '') return undefined
    return trimmed
}

export function capitalize(str?: string) {
    if (!str) return undefined

    const words = str.split(' ')
    return _(words).map(w => // if word starts with uppercase, leave it alone; otherwise capitalize it
        w[0] === w[0].toUpperCase() ? w : w[0].toUpperCase() + w.substring(1))
        .join(' ')
}

export function truncate(s: string, maxLength: number = 30) {
    return s.length > maxLength ? s.slice(0, maxLength) + '...' : s
}

export function parseNumber(value: string | null) {
    if (value === null)
        return undefined
    const num = parseInt(value)
    if (isNaN(num))
        return undefined
    return num
}

/*
Sort filenames such that they appear in the following order
    '2020-02-01 abc',
    '2019-02-01 abc',
    '1abc',
    'ABC',
    'dfg',
    'XZY'

    A negative number if referenceStr occurs before compareString; positive if the referenceStr occurs after compareString; 0 if they are equivalent.
*/
export function fileNameCompare(a: string, b: string) {
    const regex = /^[0-9/\-.]/; // Regex to detect numbers or special characters at the beginning

    const startsWithSpecialA = regex.test(a);
    const startsWithSpecialB = regex.test(b);
    //console.log({a,b, startsWithSpecialA, startsWithSpecialB})

    // If both start with a number or special character, sort descending
    if (startsWithSpecialA && startsWithSpecialB) {
        return b.toLocaleLowerCase().localeCompare(a.toLocaleLowerCase());
    }

    // If only one starts with a number or special character
    if (startsWithSpecialA) return -1; // `a` goes first
    if (startsWithSpecialB) return 1;  // `b` goes first

    // If neither starts with a number or special character, sort alphabetically case insensitive
    return a.toLowerCase().localeCompare(b.toLowerCase());
}