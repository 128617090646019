import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import {Formik, Form, Field} from "formik"
import _ from 'lodash'

import { DmNotificationsProvider, useDmNotifications } from "./Notifications"
import { delete_subscription, save_subscription, send_test_notification } from "../dennemeyer_backend";
import { useDmSettings, useDennemeyer } from "../DennemeyerProvider";
import { useRoles } from "../../user/Auth";
import { Fragment } from "react"


export function Notifications() {
    const {t} = useTranslation()
    const {isDev} = useRoles()

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('notifications')} | {t('admin-settings')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <DmNotificationsProvider>
            <div className="main-content bg-pcx-100">
                <SubscriptionsTable />
                {isDev && <SendDemoNotification />} {/* TODO: Delete or with isDev only */}
            </div>
        </DmNotificationsProvider>
    </>
}

function SubscriptionsTable() {
    const {t} = useTranslation()
    const { subscriptions, reloadSubscriptions } = useDmNotifications()
    const {settings} = useDmSettings()

    const host = getHost()
    function saveSubscription(eventType: string) {
        return save_subscription({
            clientId: settings?.clientId,
            eventType,
            notificationId: settings?.notificationId,
            host,
        })
    }

    const currentState = Object.fromEntries(meaningfulEventTypes.map(eventType => ([eventType, subscriptions.find(subscription => subscription.EventType === eventType)])))

    const initialValues = _.mapValues(currentState, subscription => !!subscription)

    return (
        <Formik {...{ initialValues }}
            onSubmit={values => {
                const toAdd = _(values).toPairs().filter(([eventType, checked]) => checked && currentState[eventType] === undefined).map(([eventType]) => eventType).value()
                const toDel = _(values).toPairs().filter(([eventType, checked]) => !checked && currentState[eventType] !== undefined).map(([eventType]) => currentState[eventType]?.SubscriptionId).value()
                //console.log({currentState, toAdd, toDel})
                return Promise.all([...toAdd.map(saveSubscription), ...toDel.map(delete_subscription)])
                    .then(reloadSubscriptions)
            }}
            enableReinitialize
        >{({ dirty, resetForm }) => 
            <Form className="bg-white p-4 rounded-xl shadow w-fit">
                <p className="max-w-[50ch] text-sm text-slate-600 mb-4">{t('add-notification-p')}</p>
                <div className="grid grid-cols-[1fr_48px]">
                    {meaningfulEventTypes.map(eventType =>
                        <Fragment key={eventType}>
                            <div className="text-slate-700 pr-2">{t(eventType)}</div>
                            <label className="p-2">
                                <Field className='mx-auto block form-checkbox' name={eventType} type="checkbox" />
                            </label>
                        </Fragment>)}
                </div>
                <div className="flex flex-row-reverse gap-2 mt-4">
                    {/* TODO: Save button only if there are changes; cancel reverts */}
                    <input type="submit" disabled={!dirty} className="btn-primary disabled:btn-disabled" value={t("save")} />
                    <button type="button" onClick={() => resetForm()} className="btn-secondary">{t('cancel')}</button>
                </div>
            </Form>
        }</Formik>
    )
}

const meaningfulEventTypes = [
    "IpRightCreated",
    "NewDocumentAvailable",
    "MaintenanceActionPhaseChanged",
    "NewRenewalNotice",
]
//IPright created/MaintenanceActionPhaseChanged/NewRenewalNotice
function getHost() {
    return window.location.hostname !== 'localhost' ? window.location.origin : 'https://dev.patent-cockpit.com'
} 

/*
function Subscriptions() {
    const {t} = useTranslation()
    const { subscriptions } = useDmNotifications()


    return subscriptions?.length > 0
        ? <div className="hidden only:flex lg:flex flex-col gap-4 sm:min-w-sm w-full sm:max-w-md">
            {subscriptions?.map(subscription => <SubscriptionCard key={subscription.SubscriptionId} {...{ subscription }} />)}
            <Link
                to='add'
                className="rounded-md bg-white p-2 hover:text-pcx-500 shadow-sm hover:shadow-md inline-flex items-center gap-2 text-pcx-700"
            ><PlusCircleIcon className="h-6 w-6" />{t('add')}</Link>
        </div>
        : <NoSubscriptionYet />
}

function SubscriptionCard({subscription}: {subscription: Subscription}) {
    const {t} = useTranslation()
    const {reloadSubscriptions} = useDmNotifications()
    const {setErrorMessage} = useMessages()
    //console.log(subscription)
    const { SubscriptionId, EventType } = subscription
    return (
        <div className="bg-white p-4 rounded-xl shadow-sm">
            <div className="flex fle-row justify-between mb-2">
                <div className="text-slate-400">{subscription.CreatedAt.substring(0, 10)}</div>
                <DeleteButton small del={() =>
                    delete_subscription(SubscriptionId)
                        .then(() => reloadSubscriptions())
                        .catch(err => setErrorMessage(err.message))
                } />
            </div>

            <div className="text-pcx-500 text-xl mb-2">{t('notification')}: {t(EventType)}</div>
        </div>
    )
}

export function AddNotification() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {settings} = useDmSettings()
    const {reloadSubscriptions} = useDmNotifications()

    //const initialValues = {
    //    eventType: "IpRightCreated",
    //}
    const initialValues = _(meaningfulEventTypes).map(eventType => [eventType, true]).fromPairs().value()
    const host = getHost()

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                console.log(values)
                Promise.all(meaningfulEventTypes
                    .filter(eventType => values[eventType])
                    .map(eventType => save_subscription({
                        clientId: settings?.clientId,
                        eventType,
                        notificationId: settings?.notificationId,
                        host,
                        //notificationRecipient: values.email,
                    }))).then(() => reloadSubscriptions()
                    ).then(() => navigate('..')
                    ).catch(err => console.error(err))
            }}
        >
            <Form className="w-full sm:w-fit flex flex-col gap-4 p-4 bg-white rounded-xl shadow-sm">
                <Link className="self-end" to=".."><IconX /></Link>
                <p className="text-slate-600">
                    {t('set-notifications-for')}
                </p>
                {meaningfulEventTypes.map(eventType => <label className="flex flex-row items-center gap-2" key={eventType}>
                    <Field className='form-checkbox' name={eventType} type="checkbox" />
                    <div className="label">{t(eventType)}</div>
                </label>)}
                <div className="flex flex-row-reverse gap-4 pt-6">
                    <input type="submit" className="btn-primary disabled:btn-disabled" value={t("save")} />
                    <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                </div>
            </Form>
        </Formik>
    )
}*/

const eventTypes = [
    "NewDocumentAvailable",
    "NewFileAvailable",
    "NewDebitNoteAvailable",
    "IpRightDue",
    "MaintenanceActionChanged",
    "MaintenanceActionPhaseChanged",
    "NewRenewalNotice",
    "IpRightCreated",
    "NewPatentForecastCreated"
]

function SendDemoNotification() {
    const {ipRights} = useDennemeyer()
    const {settings} = useDmSettings()
    const ipRightDennemeyerId = ipRights?.[0]?.dennemeyerId
    return (
        <Formik initialValues={{eventType: eventTypes[0]}} onSubmit={({eventType}) => send_test_notification(getHost(), eventType, ipRightDennemeyerId, settings.notificationId)}>
            <Form className="mt-8 p-4 bg-yellow-200 flex flex-col gap-4 max-w-md">
                <Field name="eventType" className="form-select" as="select">
                    {eventTypes.map(eventType => <option key={eventType} value={eventType}>{eventType}</option>)}
                </Field>
                <button type="submit" className="btn-primary">Send Demo Notification</button>
            </Form>
        </Formik>
    )
}