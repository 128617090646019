import _ from "lodash"

export interface FxConverter {
    convert(amount: number, from: string, to: string): number
    convert(amount: number[], from: string, to: string): number[]
}

export class DummyFxConverter implements FxConverter {
    convert(amount: number, from: string, to: string): number;
    convert(amount: number [], from: string, to: string): number[];
    convert(amount: number | number [], from: string, to: string) {
        return amount
    }
}

export class RealFxConverter implements FxConverter {
    fx_pairs: Record<string, number>
    constructor(fx_pairs: Record<string, number>) {
        this.fx_pairs = fx_pairs
        if (_.isEmpty(fx_pairs)) {
            console.warn("FX Pairs is empty")
        }
    }
    convert(amount: number, from: string, to: string): number;
    convert(amount: number [], from: string, to: string): number[];
    convert(amount: number | number [], from: string, to: string) {
        // TS complains if the type of amount is still 'number | number[]' when calling 'convertFx'
        if (typeof amount === 'number')
            return convertFx(amount, from, to, this.fx_pairs)
        else
            return convertFx(amount, from, to, this.fx_pairs)
    }
}

//const fx_pairs = {
//    "EURCHF": 0.99,
//    "EURUSD": 1.07,
//    "USDCHF": 0.92,
//    "USDJPY": 132,
//    "GBPUSD": 1.21,
//    "AUDUSD": 0.68,
//    "USDCAD": 1.34,
//    "USDKRW": 1247.10,
//    "USDINR": 81.72,
//    "USDCNY": 6.78,
//}

function findFxPair(from: string, to: string, fx_pairs: Record<string, number>): number {
    if (from === to) {
        return 1.0
    }
    const direct = fx_pairs[`${from}${to}`]
    if (direct) {
        return direct
    }
    const reverse = fx_pairs[`${to}${from}`]
    if (reverse) {
        return 1.0 / reverse
    }
    return undefined
}

function createFx(from: string, to: string, fx_pairs: Record<string, number>) {
    const fx = findFxPair(from, to, fx_pairs)
    if (fx) {
        return fx
    }
    const fromUsd = findFxPair(from, "USD", fx_pairs)
    const toUsd = findFxPair("USD", to, fx_pairs)
    if (fromUsd && toUsd) {
        return fromUsd * toUsd
    }
    return undefined
}

export function convertFx(amount: number, from: string, to: string, fx_pairs: Record<string, number>): number
export function convertFx(amount: number[], from: string, to: string, fx_pairs: Record<string, number>): number[] 
export function convertFx(amount: number | number[], from: string, to: string, fx_pairs: Record<string, number>): number | number[] {
    if (from === to) {
        return amount
    }

    const fx = createFx(from, to, fx_pairs)
    if (fx) {
        if (Array.isArray(amount)) {
            return _(amount).dropRightWhile(a => a === 0.0).map(a => a * fx).value()
        } else {
            return amount * fx
        }
    }
    return undefined
}