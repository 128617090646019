import _ from 'lodash'
import { Family, Member, OriginationLink } from './patents'
import { Agent, AgentLink } from '../agents/utils'
import { unitaryPatentCountries } from '../data'
import { plusPeriod } from '../utils/dates'

// ====================
// AGENTS 
export function splitAgents({agents, allowedAgents}) {
    const result = agents.reduce((acc, agent) => {
        const agentType = agent.agentType
        if (allowedAgents.find(aa => aa === agentType))
            return {...acc, [agentType]: [...(acc[agentType] ?? []), agent]}
        else
            return acc
    }, {})

    return result
}

export interface ExtractLinkProps {
    familyMemberId: number;
    agents: Agent[];
    agentLinks: AgentLink[];
    link: string;

}
export function extractAgents({ familyMemberId, agents, agentLinks, link }: ExtractLinkProps): [number[], Record<number, string>] {
    const agentsByKey = _.keyBy(agents, a => a.agentId)
    const allowedAgents = new Set(Object.values(agentsByKey).map(a => a.agentType))

    const tagDisplays = agents.reduce((acc, a) => {
        const name = a.agentType === "person" ? `${a.lastName}, ${a.firstName}` : `${a.name}`
        return ({ ...acc, [a.agentId]: name })
    }, {})

    const currentAgents = agentLinks
        .filter(l => l.linkType === link && l.familyMemberId === familyMemberId && allowedAgents.has(l.agentType))
        .map(a => a.agentId)
        .sort((a, b) => {
            const aa = agentsByKey[a]
            const ab = agentsByKey[b]
            if (aa?.agentType === ab?.agentType)
                return tagDisplays[a].localeCompare(tagDisplays[b])
            else if (aa.agentType === "person")
                return -1
            else
                return +1
        })

    return [currentAgents, tagDisplays]
}

// ======================
// Family Member

export function sortCountryCode(a: string, b: string) {
    if (a === "WO")
        return -1
    else if (b === "WO" || b === "EP")
        return 1
    else if (a === "EP")
        return -1
    else   
        return a.localeCompare(b)
}

export function countryLabel(member: Member, isOnly = true): {label: string, title?: string} {
    const isUnitaryPatent = member.countryCode === 'EP' && member.unitaryPatent === true
    const countryCode = isUnitaryPatent ? 'UP' : member.countryCode
    const title = isUnitaryPatent ? `Unitary Patent: ${unitaryPatentCountries.join(', ')}` : undefined
    const label = isOnly
        ? countryCode
        : `${countryCode} (${member.internalReference})`

    return {label, title}
}

export function isActiveMember(member: Member) {
  return member.familyMemberStatus === 'pending' || member.familyMemberStatus === 'granted' || member.familyMemberStatus === 'in-preparation'
}

export function isGrantedMember(member: Member) {
  return member.familyMemberStatus === 'granted'
}

export function calculateExpiryDate(member: Member, family: Family) {
    const { priorityDate } = family
    const { applicationDate } = member
    const utilityModelCorrection = member?.ipType === "utility-model" ? -10 : 0
    if (member.familyMemberStatus === "stopped")
        return "expired/to be expired"
    else if (member.countryCode === "WO" && priorityDate)
        return plusPeriod(priorityDate, { months: 30 })
    else if (member.pctRouteFiling && applicationDate)
        return plusPeriod(applicationDate, { years: 20 + utilityModelCorrection })
    else if (priorityDate)
        return plusPeriod(priorityDate, { years: 20 + utilityModelCorrection })
    else
        return undefined
}

export function calculatePriorityDate(members: Member[]) {
    const dates = (members ?? [])
        .map(m => ({
            date: m.applicationDate, 
            internalReference: m.internalReference,
            pctRouteFiling: m.pctRouteFiling,
        }))
        .filter(d => d.date !== undefined)

    if (dates.length === 0)
        return undefined
    else {
        const priorityApplication = dates.sort((a, b) => (a.date < b.date) ? -1 : 1)[0]
        return priorityApplication
    }
}

export function memberUrl({internalReference}: {internalReference: string}) {
    return `/patents/portfolio/member/${encodeURIComponent(internalReference)}`
}

export function familyUrl({internalReference}: {internalReference: string}) {
    return `/patents/portfolio/family/${encodeURIComponent(internalReference)}`
}

export function groupByEp(members: Member[] = []) {
    const hasEp = members.find(m => m.countryCode === "EP") !== undefined || members.find(m => m.validated) !== undefined

    if (!hasEp)
        return _.sortBy(members, m => m?.internalReference?.toLowerCase())
    else {
        const [validated, others] = _.partition(members, m => m?.validated)
        //console.log({validated, others, members})
        const othersSorted = _.sortBy(others, m => m?.internalReference?.toLowerCase())
        const validatedSorted = _.sortBy(validated, m => m?.internalReference?.toLowerCase())
        const epIndex = _.findLastIndex(othersSorted, m => m?.countryCode === "EP")
        return [
            ...othersSorted.slice(0, epIndex + 1),
            ...validatedSorted,
            ...othersSorted.slice(epIndex + 1)
        ]
    }
}

//// ==================
//// Date Functions
//
//export type DateOrString = Date | string;
//
//export function plusPeriod(date: DateOrString, {years = 0, months = 0, days = 0}) {
//    const dateInstance = date instanceof Date ? date : new Date(date)
//    let result = new Date(
//        dateInstance.getFullYear() + years,
//        dateInstance.getMonth() + months,
//        dateInstance.getDate() + days,
//    )
//    const offset = result.getTimezoneOffset()
//    result = new Date(result.getTime() - (offset * 60 * 1000))
//    return result.toISOString().split('T')[0]
//}
//
//const msInDay = 24 * 60 * 60 * 1000
//
//// TODO delete completely
//export function diffDays(date1: DateOrString, date2: DateOrString) {
//    const d1: Date = date1 instanceof Date ? date1 : new Date(date1)
//    const d2: Date = date2 instanceof Date ? date2 : new Date(date2)
//    const diff = d2.valueOf() - d1.valueOf()
//
//    return Math.round(diff / msInDay)
//}
//
//const msInMonth = msInDay * 30
//
//// TODO: look at the actual dates
//export function diffMonths(date1: DateOrString, date2: DateOrString) {
//    const d1 = date1 instanceof Date ? date1 : new Date(date1)
//    const d2 = date2 instanceof Date ? date2 : new Date(date2)
//
//    return Math.round((d2.valueOf() - d1.valueOf()) / msInMonth)
//}
//
//export function fromExcelDate(excelDate: number | undefined | string) {
//    if (excelDate === undefined || excelDate === "")
//        return undefined
//    else if (typeof excelDate === "number") {
//        const utc_days  = Math.floor(excelDate - 25569)
//        const utc_value = utc_days * 86400 
//        return new Date(utc_value * 1000).toISOString().slice(0, 10)
//    } else
//        return excelDate
//}


// OriginationLinks
export function isEqualOriginationLink(a: OriginationLink, b: OriginationLink) {
    return a.from === b.from && a.to === b.to;
}

export function calculateExpiryDates(members: Member[], familyById: Record<number, Family>, bucket: (d: string) => string): Record<string, number> {
    return _(members)
        .filter(isActiveMember)
        .groupBy('patentFamilyId')
        .toPairs()
        .filter(([patentFamilyId]) => patentFamilyId in familyById)
        .map(([patentFamilyId, ms]) => {
            const dates = ms.map(({expiryDate}) => expiryDate).filter(d => d !== undefined)
            if (dates.length > 0)
                return _.max(dates)

            const prio = familyById[patentFamilyId]?.priorityDate
            return prio ? plusPeriod(prio, {years: 20}) : undefined
        })
        .filter(d => d !== undefined)
        .map(bucket)
        .countBy()
        .value()
}