import { diffMonths, DateOrString } from '../utils/dates';
import { Family, Member } from '../patents/patents';
import modelParametersJson from './model.json'
import _ from 'lodash'
import { ScenarioMember } from './ForecastProvider';
import { FxConverter } from './fx';

export interface ModelParameter {
    key: string;
    currency: string;
    ipType: string;
    sumApplicationYears?: number;
    grantingMonths?: number;
    amounts: number[];
}

export interface Model {
    other_costs: ModelParameter[];
    service_fee: number;
    fees: ModelParameter[];
}

export const modelParameters = modelParametersJson as Model

function keys_for(ipType: string, parameters: ModelParameter[]): string[] {
    return parameters.filter(m => m.ipType === ipType).map(m => m.key)
}

const available_keys_per_ip_type = {
    other_costs: {
        patent: keys_for("patent", modelParameters.other_costs),
        'utility-model': keys_for("utility-model", modelParameters.other_costs),
    },
    fees: {
        patent: keys_for("patent", modelParameters.fees),
        'utility-model': keys_for("utility-model", modelParameters.fees),
    },
}

function createModelLookup(type: string, ipType: string) {
    return { [ipType]: _(modelParameters[type]).filter(m => m.ipType === ipType).keyBy("key").value() }
}

const model_lookup = {
    other_costs: {
        ...createModelLookup("other_costs", "patent"),
        ...createModelLookup("other_costs", "utility-model"),
    },
    fees: {
        ...createModelLookup("fees", "patent"),
        ...createModelLookup("fees", "utility-model"),
    }
}

const fallBack = modelParameters.other_costs.find(m => m.key === "**" && m.ipType === "patent")

const toMonthly = (yearly = []) => yearly.flatMap(y => new Array(12).fill(y / 12.0))

export type SelectMember = Pick<Member, "countryCode" | "unitaryPatent" | "firstFiling" | "validated" | "pctRouteFiling" | "ipType"> & {
    familyMemberStatus?: string;
    date?: DateOrString;
}
export interface SelectModelArgs {member: SelectMember; currency: string}
export interface SelectModelResult {amounts: number[], model?: ModelParameter}

export function find_key(member: SelectMember, available_keys: string[]): string | undefined {
    let lookupKeys = ["**"]
    if (member.countryCode === "WO" && member.firstFiling) {
        lookupKeys = ["WO", "**"]
    } else if (member.countryCode === "WO") {
        lookupKeys = ["**WO", "WO", "****", "**"]
    } else if (member.countryCode === "EP") {
        const countryCode = member.unitaryPatent ? "EU" : "EP"
        if (member.firstFiling) {
            lookupKeys = [countryCode, "**"]
        } else if (member.pctRouteFiling) {  // EP from PCT
            lookupKeys = [`**WO${countryCode}`, `WO${countryCode}`, "WO**", "****", "**"]
        } else {
            lookupKeys = [`**${countryCode}`, countryCode, "****", "**"]
        }
    } else if (member.validated && member.pctRouteFiling) { // WO -> EP -> **
        lookupKeys = [
            ...["WOEP", "**WOEP", "WOEU", "**WOEU", "**", ""].map(k => `${k}${member.countryCode}`),
            "WOEP**", "**WOEP**", "WOEU**", "**WOEU**",
            ...["**", ""].map(k => `${k}${member.countryCode}`),
            "****", "**"
        ]
    } else if (member.validated) {
        lookupKeys = [
            ...["EP", "**EP", "EU", "**EU", "**", ""].map(k => `${k}${member.countryCode}`),
            "EP**", "**EP**", "EU**", "**EU**",
            ...["**", ""].map(k => `${k}${member.countryCode}`),
            "****", "**"
        ]
    } else if (member.pctRouteFiling) {
        lookupKeys = [
            ...["WO", "**WO"].map(k => `${k}${member.countryCode}`),
            "WO**", "**WO**",
            ...["**", ""].map(k => `${k}${member.countryCode}`),
            "****", "**"
        ]
    } else if (member.firstFiling) {
        lookupKeys = [member.countryCode, "**"]
    } else {
        lookupKeys = [
            `**${member.countryCode}`, member.countryCode,
            "****", "**"
        ]
    }

    for (const key of lookupKeys) {
        if (available_keys.includes(key)) {
            return key
        }
    }
}

export function findModel(member: SelectMember, ipType: string, type: 'other_costs' | 'fees'): ModelParameter | undefined {
    const available_keys = available_keys_per_ip_type[type][ipType]
    const key = find_key(member, available_keys)
    return key ? model_lookup[type][ipType][key] : undefined
}

export function findModels(member: SelectMember, ipType: string) {
    const other_costs = findModel(member, ipType, 'other_costs') ?? findModel(member, "patent", 'other_costs') ?? fallBack
    const fees = findModel(member, ipType, 'fees') ?? findModel(member, "patent", 'fees') ?? {ipType, key: '**', currency: 'CHF', amounts: []} // TODO
    return {other_costs, fees}
}

export function accrueForYears(amounts: number[], years?: number): number[] {
    if (years === undefined) return amounts

    const accrual = _(amounts).take(years).sum()

    return _.range(0, Math.max(years + 1, amounts.length))
        .map(i => 
            i < years 
                ? 0.0 
                // eslint-disable-next-line eqeqeq
                : i == years 
                ?  accrual + (amounts[i] ?? 0.0) 
                : (amounts[i] ?? 0.0))
}

// service fee is assumed to be in CHF
export function calculate_annuity_fees(
    model: ModelParameter, 
    service_fee: number, 
    priority_date: DateOrString, 
    currency: string, 
    start_date: DateOrString,
    fxConverter: FxConverter,
    grant_date?: DateOrString,
): number[] {
    const service_fee_fx = fxConverter.convert(service_fee, 'CHF', currency)
    const amounts =  fxConverter.convert(model.amounts ?? [], model.currency, currency)
    const accrued = accrueForYears(amounts, model?.sumApplicationYears)
    const monthly = toMonthly(accrued?.map(a => a > 0.0 ? a + service_fee_fx : a) ?? [])
    let delay = 0.0
    //console.log({model})
    if (model.grantingMonths) {
        if (grant_date) {
            delay = diffMonths(grant_date, start_date)
        } else {
            delay = diffMonths(priority_date, start_date) - model.grantingMonths
        }
    } else {
        delay = diffMonths(priority_date, start_date)
    }
    return shift(delay, monthly)
}

export function calculate_other_costs(
    model: ModelParameter, 
    application_date: DateOrString, 
    currency: string, 
    start_date: DateOrString,
    fxConverter: FxConverter,
): number[] {
    // other costs are currently anchored on the priority date, but we should shift it to the application date of the member
    // For it to work, remove all the zero cost entries in the amount
    const non_zero_amounts = _.dropWhile(model.amounts ?? [], a => a === 0.0)
    const amounts =  fxConverter.convert(non_zero_amounts, model.currency, currency)
    const monthly = toMonthly(amounts)
    const delay = diffMonths(application_date, start_date)
    return shift(delay, monthly)
}

export function sum_amounts(xs: number[], ys: number[]) {
    return _.range(0, Math.max(xs.length, ys.length)).map(i => (xs[i] ?? 0.0) + (ys[i] ?? 0.0))
}

export function memberModel({member, family, today, currency, fxConverter}: SelectMemberModelProps): number[] {
    if (member.familyMemberStatus === "stopped") {
        return []
    }

    const ipType = member.ipType ?? "patent"
    const {other_costs: cost_model, fees: fees_model} = findModels(member, ipType)

    const priorityDate = family.priorityDate ?? member.applicationDate ?? today
    const annuity_fees = calculate_annuity_fees(fees_model, modelParameters.service_fee, priorityDate, currency, today, fxConverter, member.patentDate)
    const applicationDate = member.applicationDate ?? family.priorityDate ?? today
    const other_costs = calculate_other_costs(cost_model, applicationDate, currency, today, fxConverter)

    return sum_amounts(annuity_fees, other_costs)
}

// generate monthly amounts relative to the priority date
export function selectModel(
    {member, currency, priorityDate, fxConverter}: 
    {member: SelectMember, currency: string, priorityDate: DateOrString, fxConverter: FxConverter}
): number[] {
    if (member.familyMemberStatus === "stopped") {
        return []
    }

    const ipType = member.ipType ?? "patent"
    const {other_costs: cost_model, fees: fees_model} = findModels(member, ipType)

    const annuity_fees = calculate_annuity_fees(fees_model, modelParameters.service_fee, priorityDate, currency, priorityDate, fxConverter)
    const applicationDate = member.date ?? priorityDate
    const other_costs = calculate_other_costs(cost_model, applicationDate, currency, priorityDate, fxConverter)

    return sum_amounts(annuity_fees, other_costs)
}

export type SelectMemberModelProps = {
    member: Member;
    family: Family;
    today: DateOrString;
    currency: string;
    fxConverter: FxConverter;
}


// delay is in months
function shift(delay: number, monthly: number[]) {
    if (delay > 0) {
        return monthly.slice(delay)
    } else if (delay < 0) {
        return [...new Array(-delay).fill(0), ...monthly]
    } else { //delay == 0
        return monthly
    }
}

// TODO divisional
export function scenarioModel(
    {scenarioMember, today, currency, priorityDate, fxConverter}:
    {scenarioMember: ScenarioMember, today: DateOrString, currency: string, priorityDate: DateOrString, fxConverter: FxConverter}
): number[] {

    if (scenarioMember === undefined) return []

    const {unitaryPatent, firstFiling, ipType, fromPct, onlyPrioYear} = scenarioMember
    let models = []
    if (scenarioMember.type === 'ep') {
        const member = {countryCode: "EP", unitaryPatent, firstFiling, pctRouteFiling: fromPct}
        const baseModel = selectModel({member, currency, priorityDate, fxConverter})
        const countryModels = scenarioMember.countries
            .map(countryCode => selectModel({member: {countryCode, validated: true}, currency, priorityDate, fxConverter}))
        models = [baseModel, ...countryModels]
    } else if (scenarioMember.type === 'pct') {
        const member = {countryCode: "WO", firstFiling}
        const pctModel = selectModel({member, currency, priorityDate, fxConverter})
        //console.log({pctModel})
        models = [pctModel]
    } else if (scenarioMember.type === 'ea') {
        const member = {countryCode: 'EA', pctRouteFiling: false, firstFiling}
        const baseModel = selectModel({member, currency, priorityDate, fxConverter})
        const countryModels = scenarioMember.countries
            .map(countryCode => selectModel({member: {countryCode}, currency, priorityDate, fxConverter}))
        //const countryModels = []
        models = [baseModel, ...countryModels]
    } else { // scenarioMember.type === 'national'
        if (scenarioMember.type !== 'national') 
            console.warn(`Unexpected scenario member type: ${scenarioMember.type}`)
        const countryModels = scenarioMember.countries
            .map(countryCode => selectModel({member: {countryCode, ipType, pctRouteFiling: fromPct, firstFiling}, currency, priorityDate, fxConverter}))
        models = countryModels
    }

    if (models.length === 0) return []

    //console.log({models})

    const longest = Math.max(...models.map(m => m.length))
    const monthly = Array.from(Array(longest).keys(), i => models.reduce((acc, m) => acc + (m[i] ?? 0), 0))
    //const monthly = toMonthly(combined)

    const start = scenarioMember.date ?? priorityDate
    const delay = diffMonths(start, today)
    // console.log({today, start, priorityDate, delay})
    //if (onlyPrioYear)
    //    console.log({models, monthly, priorityDate, today, delay})
    const shifted = shift(delay, onlyPrioYear ? monthly.slice(0, 12) : monthly)

    return shifted
}

export interface ScenarioAmount {
    date: DateOrString;
    amount: number;
    currency: string;
}

export function amountModel(
    { scenarioAmount, today, currency, fxConverter }:
    { scenarioAmount: ScenarioAmount, today: DateOrString, currency: string, fxConverter: FxConverter }
): number[] {
    const start = scenarioAmount.date
    const delay = diffMonths(start, today)
    const monthly = [fxConverter.convert(scenarioAmount.amount, scenarioAmount.currency, currency)]
    return shift(delay, monthly)
}

export interface YearlyCosts {
    year: number;
    cost: number;
}
export function sumModels({models, today: _today, years = 6}: {models: number[][], today: DateOrString, years?: number}): YearlyCosts[] {
    const total = new Array(12 * years).fill(0.0)
    for (const id in models) {
        for (let t = 0; t < total.length; t++) {
            total[t] += models[id][t] ?? 0.0
        }
    }
    const today = typeof _today === 'string' ? new Date(_today) : _today
    const year = today.getFullYear()
    const month = today.getMonth()

    //console.log({total})
    const fullYears = _(total.slice(month))
        .chunk(12).filter(ms => ms.length === 12)
        .map(_.sum).value()
    const yearly 
        = month === 0 ? fullYears
        : [_.sum(total.slice(0, month)), ...fullYears]
    
    return _(yearly).map((y, i) => ({cost: parseFloat(y.toFixed(2)), year: year + i})).value()
}
