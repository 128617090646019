import { Fragment } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Formik, Form, Field } from "formik"

import Editor from "../components/Editor";
import { IconEdit } from "../components/icons"
import { useTrademarks } from "./TrademarksProvider"
import { useTranslation } from "react-i18next"
import Modal from "../components/Modal"
import DeleteButton from "../components/DeleteButton"
import { useRoles } from "../user/Auth"

export default function TrademarkClasses({trademarkId}: {trademarkId: number}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {trademarkClassesByTrademarkId, trademarkById, deleteTrademarkClass} = useTrademarks()
    const trademarkClasses = trademarkClassesByTrademarkId[trademarkId] || []

    const trademark = trademarkById[trademarkId]

    return <>
        <div className="flex flex-row justify-between items-start mb-2">
            <h3 id="trademark_classes">{t('trademark-classes')}</h3>
            {isEditUser && <Link to={{pathname: "class", search: `trademarkId=${trademark.trademarkId}`}} className="btn-secondary py-0.5 text-sm whitespace-nowrap">{t('add')}</Link>}
        </div>
        <div className="grid grid-cols-[40px_1fr_44px] gap-3">
            {trademarkClasses.map(c => <Fragment key={c.classId}>
                <div className="text-right text-2xl text-pcx-400">{c.number}</div>
                <div className="pt-1" dangerouslySetInnerHTML={{ __html: c.description ?? "" }}></div>
                <div className="flex flex-row gap-1 items-start">{isEditUser && <>
                    <Link className="btn-primary p-px w-5 h-5" to={{pathname: 'class', search: `classId=${c.classId}&trademarkId=${trademark.trademarkId}`}}><IconEdit /></Link>
                    <DeleteButton deleteAction={() => deleteTrademarkClass(c)} className="btn-warn p-px w-5 h-5"></DeleteButton>
                </>}</div>
            </Fragment>)}
        </div>
    </>
}


export function PostTrademarkClass() {
    const {t} = useTranslation()

    const {trademarkClassesByTrademarkId, trademarkById, postTrademarkClass} = useTrademarks()

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const trademarkId = parseInt(searchParams.get('trademarkId') ?? "-1")
    const classId = parseInt(searchParams.get('classId') ?? "-1")

    const trademark = trademarkById[trademarkId]

    const trademarkClasses = trademarkClassesByTrademarkId[trademarkId] || []
    const trademarkClass = trademarkClasses.find(c => c.classId === classId)

    const existingClasses = trademarkClasses.filter(c => c.classId !== classId).map(c => c.number)

    const initialValues = trademarkClass ?? {
        trademarkId,
        number: 1,
        description: "",
    }

    return (
        <Modal>
            <Formik 
                initialValues={initialValues}
                onSubmit={values => {
                    postTrademarkClass(values).then(() => navigate(".."))
                }}
                enableReinitialize
                validate={values => {
                    const errors: any = {}
                    if (existingClasses.includes(values.number))
                        errors.number = t('is-already-used')
                    return errors

                }}
            >{({errors, touched}) => 
                <Form className="max-w-2xl">
                    <div className="p-4 bg-white flex flex-col gap-2">
                        <h4>{t('class-for', {name: trademark?.reference})}</h4>
                        <label className="w-full">
                            <div className="mb-1">
                                <span className="inline label">{t('number')}</span>
                                {errors.number && touched.number && <span className="ml-2 inline text-red-700"> {errors.number}</span>}
                            </div>
                            <Field name="number" type="number" className="form-input w-full" />
                        </label>

                        <div>
                            <label htmlFor="description" className="label mb-1">{t('description')}</label>
                            <div className="w-full max-sm:pb-10 h-[16rem] sm:h-[24rem] md:min-w-[40rem]">
                                <Editor name="description" />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
                        <input type="submit" className="btn-primary" value={t('save')} />
                        <Link 
                            // @ts-ignore
                            to={-1} className="btn-secondary"
                        >{t('cancel')}</Link>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}