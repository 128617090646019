import React, { useContext } from "react";

import { Commodity } from "../products/products";
import { useFilter } from "./Filter";
import {
    FilteredCommodities,
    active_only_filter,
    filterActiveCommoditiesOnly,
    filterByProductClass,
    filterByProductName,
    filterByProductOwnership,
    filterCommoditiesByText,
    free_text_filter,
    product_class_filter,
    product_filter,
    product_ownership_filter,
} from "./utils";
import { FilterConfig } from "./Filter";
import { useProducts } from "../products/ProductsProvider";

const FilteredCommoditiesContext = React.createContext({
    commodities: [] as Commodity[],
})

export function FilteredCommoditiesProvider({ children }) {
    const { showActiveOnly, activeFilters, filterText } = useFilter()

    const { commodities } = useProducts();

    let context = (showActiveOnly ? [{ name: active_only_filter, isActive: true }, ...activeFilters] : activeFilters)
        .filter(({ isActive }) => isActive)
        .reduce(
            (ctxt: FilteredCommodities, filter: FilterConfig) => {
                switch (filter.name) {
                    case product_ownership_filter:
                        return filterByProductOwnership(ctxt, filter.config?.value);
                    case active_only_filter:
                        return filterActiveCommoditiesOnly(ctxt);
                    case free_text_filter:
                        return filterCommoditiesByText(ctxt, filter.config);
                    case product_filter:
                        return filterByProductName(ctxt, filter.config);
                    case product_class_filter:
                        return filterByProductClass(ctxt, filter.config)
                    default:
                        return ctxt;
                }
            },
            { commodities } // TODO get rid of commodities here
        );

    // maybe there is a more elegant way?
    if (filterText !== "") {
        context = filterCommoditiesByText(context, filterText);
    }

    const value = context

    return (
        <FilteredCommoditiesContext.Provider value={value}>{children}</FilteredCommoditiesContext.Provider>
    );
}

export function useFilteredCommodities() {
    return useContext(FilteredCommoditiesContext);
}
