import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik"
import { parseBackend } from "../../backend";
import { ErrorMessage } from "../../Messages";
import { emptyStringAsUndefined, nonEmptyString } from "../../utils/strings";
import { isArray } from "lodash";
import { AgorumUser, createArea, createUser, deleteArea, getAreaStatistics, getUserList, updateUser, getOverview } from "./admin_backend";
import { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

/* 
TODO

[x] Set the client area settings
  What is the input?
[x] Action Create Client Area
  What is the input?
[x] List the client users for client area
[x] Create a new Client user
  What is the input?
[x] Edit a Client user

TODO later
[x] Delete a client area (always go through API)
* Delete a client user (always go through API)

*/

export default function AdminDmsSettings({team}: {team: string}) {

    return <div className="py-4">
        <h2 className="mb-2">Document Settings for {team}</h2>
        <p className="text-sm text-gray-500 max-w-2xl py-1">
            First create a client area in PC then at Agorum. Next, create a write user and if needed a read user. And create them also at Agorum.
            Check the statistics to see if everything is working.
        </p>
        <div className="flex flex-row gap-6 ">
            {/* 
            <LegacySettingsForm team={team} />
*/}
            <AreaStatistics {...{team}} />
            <ClientArea {...{team}} />
            <ClientUsers {...{team}} />
        </div>
    </div>
}

function AreaStatistics({team}: {team: string}) {
    const queryClient = useQueryClient()
    const {settings} = useSettings(team)

    const number = settings?.agorumClientId?.toString()
    const hasNumber = number?.length === 6

    const queryKey = ["dms", "area-statistics", team]
    const { data: statistics, isLoading, error } = useQuery({
        queryKey,
        queryFn: async () => getAreaStatistics(settings?.agorumClientId.toString(), team),
        enabled: hasNumber,
    })

    const usersQueyKey = ["dms", "users", team]
    const {data: users} = useQuery<string[], any, string[]>({
        queryKey: usersQueyKey,
        queryFn: async () => getUserList(settings?.agorumClientId.toString(), team),
        enabled: hasNumber 
    })

    //console.log({settings})
    //console.log({statistics})

    if (error) return <div>Error: {error.toString()}</div>

    return <div>
        <h3>Area Statistics {isLoading && 'Loading...'}</h3>
        {typeof statistics === 'string'
            // ERROR
            ? <div className="max-w-sm text-xs">
                <p>{statistics}</p>
            </div>
            : <div className="grid grid-cols-2 gap-x-4 gap-y-1 text-sm">
                {_(statistics).toPairs().sortBy(([key]) => key.toLocaleLowerCase()).map(([key, value]) => <Fragment key={key}>
                    <div className="text-right label">{key}</div>
                    <div>{value}</div>
                </Fragment>).value()}
                <div className="text-right label">Users</div>
                <ul>
                    {(isArray(users) && users.length > 0) ? users.map(u => <li key={u}>{u}</li>) : <li>None</li>}
                </ul>
            </div>
        }
        <button onClick={() => {
            queryClient.invalidateQueries({queryKey})
            queryClient.invalidateQueries({queryKey: usersQueyKey})
        }} className="btn-primary">Retry</button>
    </div>
}

interface AdminDmsSettingsProps {
    username?: string;
    password?: string;
    settingsId?: number;
    agorumClientId?: number;
    baseurl?: string;
    realm: string;
}

function useSettings(team: string) {
    const queryClient = useQueryClient()

    const queryKey = ["dms", "settings", team]
    async function queryFn() {
        return parseBackend(fetch('/api/dms/admin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                realm: team,
                "type": "client-area",
                "operation": "get",
            })
        }))
    }

    const {data: allSettings, isLoading} = useQuery<AdminDmsSettingsProps[], ErrorMessage, AdminDmsSettingsProps[]>({
        queryKey,
        queryFn
    })

    const postMutation = useMutation<AdminDmsSettingsProps, ErrorMessage, AdminDmsSettingsProps>({
        mutationFn: async (settings) =>
        parseBackend(fetch('/api/dms/admin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                realm: team,
                "type": "client-area",
                "operation": settings.settingsId ? "update" : "add",
                ...settings,
            })
        })),
        onSettled: () =>
            // @ts-ignore
            queryClient.invalidateQueries({queryKey, queryFn})
    })

    return {settings: allSettings?.find(s => s.realm === team), allSettings, postSettings: postMutation.mutateAsync, isLoading}
}

type ClientAreaType = {
    agorumClientId: number;
    baseurl?: string;
    realm: string;
}


function ClientAreaOverview() {
    const [query, setQuery] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [overview, setOverview] = useState([] as ClientAreaType[])

    useEffect(() => {
        if (isOpen) {
            getOverview().then(overview => setOverview(overview))
        }
    }, [isOpen, setOverview])

    //console.log({overview})

    return (
        <div>
            <button onClick={() => setIsOpen(o => !o)}><h4>Show Overview</h4></button>
            {isOpen &&
                <div className="flex flex-col divide-y-2 divide-pcx-300">
                    <input type="text" placeholder="Filter..." className="form-input bg-transparent py-px px-1.5 mb-1" value={query} onChange={e => setQuery(e.target.value)} />
                    {_(overview)
                        .filter(area => query === '' || area.realm.includes(query) || (area.baseurl ?? '').includes(query) || area.agorumClientId.toString().includes(query))
                        .sortBy('agorumClientId')
                        .reverse()
                        .take(20)
                        .map((area, i) =>
                            <div key={i} className="p-1">
                                <span className="float-right tabular-nums">{area.agorumClientId}</span>
                                <h5>{area.realm}</h5>
                                <p className="text-slate-600 mt-0.5 text-sm">{area.baseurl ?? '-'}</p>
                            </div>)
                        .value()}
                    <div />
                </div>}
        </div>
    )
}


function ClientArea({team}: {team: string}) {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {settings, allSettings, postSettings, isLoading} = useSettings(team)
    const initialValues = {
        agorumClientId: settings?.agorumClientId ?? 100100,
        baseurl: settings?.baseurl ?? '',
    }

    if (isLoading) return <div>Is Loading...</div>

    async function onSubmit({agorumClientId, baseurl}: {agorumClientId: number, baseurl: string}) {
        return await postSettings({...settings, agorumClientId, baseurl: emptyStringAsUndefined(baseurl)})
    }

    const number = settings?.agorumClientId?.toString()
    const hasNumber = number?.length === 6

    return <div>
        <h3 className="mb-4">Client Area</h3>
        <button onClick={() => getOverview()}>GET OVERVIEW</button>
        <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>{
            ({ values, resetForm }) => {
                const sameClientId = (nonEmptyString(`${values.agorumClientId}`) &&
                    allSettings?.filter(s =>
                        // eslint-disable-next-line eqeqeq
                        s.agorumClientId == values.agorumClientId &&
                        s.realm !== team
                    )) ?? []
                return <Form className="flex flex-col gap-2">
                    <label>
                        <div className="label mb-2">Base URL</div>
                        <Field name="baseurl" className="form-input" />
                    </label>
                    <label>
                        <div className="label mb-2">Agorum Client ID</div>
                        <Field name="agorumClientId" type="number" required className="form-input" />
                    </label>
                    {sameClientId.length > 0 &&
                        <div className="text-red-600 p-1 text-sm">
                            This client ID is already in use by {sameClientId.map(s => s.realm).join(', ')}
                        </div>}
                    <div className="flex flex-row-reverse gap-2 pt-4">
                        <input type="submit" className="btn-primary" value="Save" />
                        <button type="button" onClick={() => resetForm()} className="btn-secondary">Reset</button>
                    </div>
                </Form>
            }}</Formik>
        <div className="mt-8 pt-2 border-t  flex flex-col gap-2">
            <button className="btn-primary disabled:btn-disabled" disabled={!hasNumber || isSubmitting}
                onClick={async () => {
                    setIsSubmitting(true)
                    await createArea(number, team).finally(() => setIsSubmitting(false))
                }}
            >
                CREATE AREA @ Agorum
            </button>
            <p className="text-slate-600 text-xs mt-2">
                Might take a while until Statistics appear.
            </p>
            <button className="btn-warn disabled:btn-disabled" disabled={!hasNumber}
                onClick={() => deleteArea(number, team)}
            >
                DELETA AREA @ Agorum <br/> (no question)
            </button>
        </div>
        <div className="py-3">
        <ClientAreaOverview />
        </div>
    </div>
}

type AgorumPrivileges = 'write' | 'read'

interface ClientUser {
    userId?: number;
    username: string;
    password: string;
    email: string;
    privileges: AgorumPrivileges;
}
function useClientUsers(team: string) {
    const queryClient = useQueryClient()

    const type = "client-user"
    const queryKey = ["dms", "settings", type, team]
    async function queryFn() {
        return parseBackend(fetch('/api/dms/admin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                realm: team,
                type,
                "operation": "get",
            })
        }))
    }

    const {data: clientUsers, isLoading} = useQuery<ClientUser[], ErrorMessage, ClientUser[]>({queryKey, queryFn})

    const postMutation = useMutation<ClientUser, ErrorMessage, ClientUser>({
        mutationFn: async (settings) =>
            parseBackend(fetch('/api/dms/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    realm: team,
                    type,
                    "operation": settings.userId ? "update" : "add",
                    ...settings,
                })
            })),
        onSettled: () => {
            // @ts-ignore
            queryClient.invalidateQueries({ queryKey, queryFn })
        }
    })

    return {clientUsers, isLoading, postClientUser: postMutation.mutateAsync}
}

function ClientUsers({team}: {team: string}) {
    const {settings} = useSettings(team)
    const {clientUsers, isLoading, postClientUser} = useClientUsers(team)

    const [selectedUser, setSelectedUser] = useState(undefined)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const clientUser = clientUsers?.find(u => u.userId === selectedUser)
    const initialValues = clientUser  ?? {
        username: '',
        password: '',
        email: '',
        privileges: 'write' as AgorumPrivileges,
    }
    //console.log({initialValues})

    async function onSubmit(values: ClientUser) {
        //console.log(values)
        setIsSubmitting(true)
        await postClientUser(values)
            .then((resp: ClientUser) => setSelectedUser(resp.userId))
            .finally(() => setIsSubmitting(false))
    }

    const number = settings?.agorumClientId?.toString()
    const hasNumber = number?.length === 6
    // TODO: check if area really exists
    const canCreateUser = hasNumber && clientUser?.username && clientUser?.password && clientUser?.email

    function toAgorumUser(clientUser: ClientUser): AgorumUser {
        return {
            name: clientUser.username,
            password: clientUser.password,
            emailaddress: clientUser.email,
            givenName: clientUser.username,
            familyName: clientUser.username,
            language: 'en',
            features: clientUser.privileges === 'write' 
                ? ['aipm.patent.cockpit.structureAll']  // write rights
                : ['aipm.patent.cockpit.structureRead'] // read rights
        }
    }

    async function onClickCreateUser() {
        if (!canCreateUser) return

        const user = toAgorumUser(clientUser)
        setIsSubmitting(true)
        await createUser(number, user, team)
            .finally(() => setIsSubmitting(false))
    }

    async function onClickUpdateUser() {
        if (!canCreateUser) return

        const user = toAgorumUser(clientUser)
        setIsSubmitting(true)
        await updateUser(number, user, team)
            .finally(() => setIsSubmitting(false))
    }

    return <div>
        <h3>Client Users</h3>
        <p className="max-w-xs text-xs py-1">Create a write user and if needed a read user</p>
        {isLoading 
            ? <div>Loading...</div> 
            : <div>
                <div className="py-2 border-b mb-2 border-pcx-700">
                <select className="form-select w-full py-1" value={selectedUser} onChange={e => setSelectedUser(parseInt(e.target.value))}>
                    <option value={undefined}>New User</option>
                    {clientUsers?.map(u => <option key={u.userId} value={u.userId}>{u.username}</option>)}
                </select>
                </div>
                <Formik {...{ initialValues, onSubmit }} enableReinitialize>{({ resetForm }) => {
                    return <Form className="flex flex-col gap-2 w-fit">
                        <label>
                            <div className="label mb-2">Username</div>
                            <Field name="username" required className="form-input" />
                        </label>
                        <div>
                            <div className="label mb-2">
                            <label htmlFor="id">
                                Password
                            </label>
                                <button type="button" className="ml-1" onClick={() => setShowPassword(s => !s)}>
                                    {showPassword ? <EyeSlashIcon className="h-5 w-5 inline" /> : <EyeIcon className="h-5 w-5 inline" />}
                                </button>
                            </div>
                            <Field id="password" name="password" required type={showPassword ? 'text' : 'password'} className="form-input" />
                        </div>
                        <label>
                            <div className="label mb-2">Email</div>
                            <Field name="email" required type="email" className="form-input" />
                        </label>
                        <label>
                            <div className="label mb-2">Privileges</div>
                            <Field name="privileges" required className="w-full form-select" as="select" >
                                <option>write</option>
                                <option>read</option>
                            </Field>
                        </label>

                        <div className="flex flex-row-reverse gap-2 pt-4">
                            <input type="submit" className="btn-primary disabled:btn-disabled" value="Save" disabled={isSubmitting} />
                            <button type="button" onClick={() => resetForm()} className="btn-secondary">Reset</button>
                        </div>
                    </Form>
                }}</Formik>
                <div className="mt-4 flex flex-col gap-2">
                    <p className="text-sm text-gray-600">Don&apos;t be inpatient when clicking...</p>
                    <button className="btn-primary disabled:btn-disabled" disabled={!canCreateUser || isSubmitting} onClick={onClickCreateUser}>CREATE USER @ Agorum</button>
                    <button className="btn-primary disabled:btn-disabled" disabled={!canCreateUser || isSubmitting} onClick={onClickUpdateUser}>UPDATE USER @ Agorum</button>
                </div>
            </div>
        }
    </div>
}