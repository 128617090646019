import { useState } from "react"
import { parseBackend } from "../backend"
import exampleJson from "./example.json"
import _ from "lodash"
import { emptyStringAsUndefined } from "../utils/strings"
import { getLocalTimeZone, parseDate, today } from "@internationalized/date"

// This is meant for super admins to help in importing data from EPO while onboarding new clients
export default function AdminImport() {

    const [publicationNumber, setPublicationNumber] = useState('')
    const [familyPrefix, setFamilyPrefix] = useState('')
    const [_countries, setCountries] = useState('')
    const countries = _countries.split('\n').map(c => emptyStringAsUndefined(c)).filter(Boolean)
    
    const [family, setFamily] = useState(undefined)

    async function onSubmit(e) {
        e.preventDefault()
        console.log('Scraping publication number', publicationNumber)
        // TODO
        const result = await scrapeDirect(publicationNumber)
        //const result = exampleJson
        const parsed = createFamily(result, countries, familyPrefix)
        setFamily(parsed)
    }
        console.log({family})

    return <>
        <div className="modern-header-row">
            <h2>Family Scrape EPO</h2>
        </div>
        <div className="p-4">
            <form className="flex flex-row gap-2" onSubmit={onSubmit}>
                <div className="max-w-xs">
                    <label htmlFor="countries">Countries</label>
                    <textarea id="countries" className="form-textarea min-h-48" value={_countries} onChange={e => setCountries(e.target.value)} />
                </div>
                <div className="flex flex-col gap-2 max-w-xs">
                    <label>
                        <div className="label mb-1">Publication Number</div>
                        <input type="text" className="form-input" autoFocus value={publicationNumber} onChange={e => setPublicationNumber(e.target.value)} />
                    </label>
                    <label>
                        <div className="label mb-1">Family Prefix</div>
                        <input type="text" className="form-input" value={familyPrefix} onChange={e => setFamilyPrefix(e.target.value)} />
                    </label>
                    <input type="submit" className="btn-primary" value="Scrape" />
                </div>
            </form>

            {family && <>
                <CopyButton id="results" />
                <table id="results" className="mt-8 text-xs">
                    <thead>
                        <tr className="border-b-2 border-pcx-300">
                            {header.map(h =>
                                <th key={h} className="px-1 py-1 font-medium text-pcx-700 capitalize text-left">
                                    {h}
                                </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {family.members.map((member, i) =>
                            <tr key={i} className="hover:bg-white">
                                {header.map(h => 
                                    <td key={h} className="px-1 py-1 whitespace-nowrap">
                                        {h === 'url' 
                                            ? <a href={member[h]} target="_blank" rel="noreferrer" className="text-pcx-500 underline">{member[h]}</a>
                                            : member[h]}
                                    </td>
                                )}
                            </tr>)}
                    </tbody>
                </table>
            </>}
        </div>
    </>
}

async function scrapeDirect(publicationNumber: string) {
    return parseBackend(fetch("/api/scrape", {
        method: "POST",
        credentials: 'same-origin',
        body: JSON.stringify({publicationNumber, type: 'direct'}),
        headers: { 'Content-Type': 'application/json' },
    }))
}

function createFamily(obj: object, countries, familyPrefix) {
    const family = obj['ops:world-patent-data']['ops:patent-family']
    const roughMembers = objectOrArray(family['ops:family-member'], createRoughMember)
    const epoMembers = _(roughMembers)
        .groupBy('applicationNumber')
        .map(createEpoMember)
        .filter(m => m.publicationNumber !== undefined)
        .value()
    const members = adjustReferences(epoMembers, countries, familyPrefix) 
    return {members}
}

function adjustReferences(epoMembers: EpoMember[], countries: string[], familyPrefix: string) {
    const by_country = _.groupBy(epoMembers, 'country')

    const full = _(by_country)
        .flatMap(ms => ms.map((m, i) => ({...m, reference: deriveReference(m, familyPrefix, i)})))
        .value()

    const byApplication = _.keyBy(full, 'applicationNumber')

    const notPresent = countries
        .filter(c => !by_country[c])
        .map(country => ({country, reference: `${familyPrefix}${country}`, priorities: []}))

    const now = today(getLocalTimeZone())

    const everythin = _(full)
        // @ts-ignore
        .concat(notPresent)
        .map(m => {
            const priority = m.priorities.map(p => byApplication[p.reference]?.reference).filter(Boolean).join(';')

            let priorityDate = undefined
            if (m.priorities.length > 0) {
                priorityDate = _(m.priorities).map(p => p.date).filter(Boolean).min()
            }

            let familyMemberStatus = 'pending'
            if (m.country === 'WO') {
                const applicationDate = parseDate(m.applicationDate)
                if (applicationDate < now.add({months: -30})) {
                    familyMemberStatus = 'stopped'
                }
            } else {
                if (m.patentNumber !== undefined)
                    familyMemberStatus = 'granted'
            }

            let validatedFrom = undefined
            // if (m.validated) {
            //     validatedFrom = byApplication[m.priorities.find(p => p.country === 'EP')?.reference]?.reference
            // }

            return { ...m, priority, priorityDate, familyMemberStatus, validatedFrom }
        })
        .sortBy(m => countries.indexOf(m.country))
        .value()

    return everythin
}

function deriveReference(member: EpoMember, familyPrefix: string, i: number) {
    const number = i === 0 ? '' : `${i}`
    if (member.validated === 'yes') {
        return `${familyPrefix}EP${member.country}${number}`
    } else {
        return `${familyPrefix}${member.country}${number}`
    }
}

function objectOrArray<T>(obj: object, f: (obj: object) => T): T[] {
    if (obj === undefined) {
        return []
    } else if (Array.isArray(obj)) {
        return obj.map(f)
    } else {
        return [f(obj)]
    }
}

const header = [
    'priorityDate',
    'priority',
    'reference',
    'extReference',
    'title',
    'numberOfClaims',
    'applicationDate',
    'applicationNumber',
    'publicationDate',
    'publicationNumber',
    'patentDate',
    'patentNumber',
    'expiryDate',
    'country',
    'validated',
    'validatedFrom',
    'pct',
    'firstFiling',
    'unitaryPatent',
    'OptOut',
    'familyMemberStatus',
    'ipType',
    'url',
    'inventors',
    'applicants',
]

type EpoMember = ReturnType<typeof createEpoMember>

// TODO: Filter out when there is no publication number (e.g. for DE50301053.D1)
function createEpoMember(roughMembers: RoughMember[]) {
    const priorities = _(roughMembers).flatMap(r => r.priorities).uniqBy('reference').value()

    const patentMember = roughMembers.find(r => r.publicationNumber && isPatent(r._publicationNumber))
    const publicationMember = roughMembers.find(r => r.publicationNumber && isPublication(r._publicationNumber)) ??
        roughMembers.find(r => r.publicationNumber && isUtilityModel(r._publicationNumber)) ??
        roughMembers.find(r => r.publicationNumber && isValidation(r._publicationNumber))

    const is_utility_model = publicationMember && isUtilityModel(publicationMember._publicationNumber)
    const validated = publicationMember && isValidation(publicationMember._publicationNumber)

    const publicationNumber = publicationMember?.publicationNumber
    const publicationDate = publicationMember?._publicationNumber?.date
    const patentNumber = (is_utility_model || validated) ? publicationNumber : patentMember?.publicationNumber
    const patentDate = (is_utility_model || validated) ? publicationDate : patentMember?._publicationNumber?.date

    const pct = priorities.some(p => p.reference.startsWith('WO')) ? 'yes' : 'no'
    const ipType = is_utility_model ? 'utility model' : 'patent'

    const applicationNumber = roughMembers[0].applicationNumber
    const applicationDate = roughMembers[0]._applicationNumber?.date

    const firstFiling = priorities.some(p => p.reference === applicationNumber) ? 'yes' : 'no'

    const country = publicationMember?._publicationNumber?.country

    const url = patentMember?.url ?? publicationMember?.url
    const inventors = _(roughMembers).flatMap(r => r.inventors).uniq().sortBy().join('; ')
    const applicants = _(roughMembers).flatMap(r => r.applicants).uniq().sortBy().join('; ')

    return {
        applicationDate,
        applicationNumber,
        publicationDate,
        publicationNumber,
        patentDate,
        patentNumber,
        priorities,
        //is_utility_model,
        ipType,
        country,
        validated: validated ? 'yes' : 'no',
        pct,
        firstFiling,
        applicants,
        inventors,
        url,
    }
}

type RoughMember = ReturnType<typeof createRoughMember>

function createRoughMember(obj: object) {
    const publicationNumbers = createReferences(obj['publication-reference']['document-id'])
    const applicationNumbers = createReferences(obj['application-reference']['document-id'])
    const priorities = createPriorityReferences(obj['priority-claim'])

    const _publicationNumber = getBestReference(publicationNumbers)
    const _applicationNumber = getBestReference(applicationNumbers)

    const epoPublication = publicationNumbers.find(r => r.type === 'epodoc')
    const url = epoPublication ? `https://worldwide.espacenet.com/patent/search?q=pn%3D"${epoPublication.reference}"` : undefined

    // exchange-document.bibliographic-data.parties.inventors.inventor
    const inventors = objectOrArray(obj['exchange-document']?.['bibliographic-data']?.['parties']?.['inventors']?.['inventor'], getAgent)
    const applicants = objectOrArray(obj['exchange-document']?.['bibliographic-data']?.['parties']?.['applicants']?.['applicant'], getAgent)
    //console.log({inventors, applicants})

    const r = {
        applicationNumbers,
        publicationNumbers,

        _applicationNumber,
        applicationNumber: _applicationNumber?.reference,

        _publicationNumber,
        publicationNumber: _publicationNumber?.reference,

        priorities,
        url,
        inventors: toNice(inventors),
        applicants: toNice(applicants),
    }
    //console.log({r})
    return r
}

function createPriorityReferences(obj: object): Reference[] {
    return objectOrArray(obj, o => createReference(o['document-id']))
}

function createReferences(obj: object): Reference[] {
    return objectOrArray(obj, createReference)
}

function toIsoDate(date?: string) {
    if (!date) return undefined

    return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
}

interface Reference {
    type: 'docdb' | 'epodoc' | 'original'
    reference: string
    country?: string
    date?: string
    number?: string
    kind?: string
}

function createReference(obj: object): Reference {
    const type = obj['@document-id-type']
    if (type === 'docdb') {
        const country = obj["country"]["$"]
        const number = obj["doc-number"]["$"]
        const kind = obj["kind"]["$"]
        const date = toIsoDate(obj["date"]["$"])
        return {type, reference: `${country}${number}${kind ? ('.' + kind) : ''}`, country, number, kind, date}
    } else if (type === 'epodoc') {
        const reference = obj["doc-number"]["$"]
        const date = toIsoDate(obj["date"]["$"])
        // todo country, number, kind
        return {type, reference, date}
    } else { // original
        // TODO: should we even return it?
        const reference = obj["doc-number"]["$"]
        return {type, reference}
    }
}

function getBestReference(references: Reference[]): Reference {
    const docdb = references.find(r => r.type === 'docdb')
    if (docdb) return docdb
    const epodoc = references.find(r => r.type === 'epodoc')
    if (epodoc) return epodoc
    return references.length > 0 ? references[0] : undefined
}

/*
  def isPatent(number: DocDbId): Boolean = {
    number.country match {
      case "CA" | "RU" | "NL" => number.kind.startsWith("C")
      case "HK" | "IL" => number.kind == "A"
      case _ => number.kind.startsWith("B")
    }
  }*/
function isPatent(reference: Reference) {
    if (reference.type === 'docdb') {
        const {country, kind} = reference
        switch (country) {
            case "CA": case "RU": case "NL": return kind.startsWith("C")
            case "HK": case "IL": return kind === "A"
            default: return kind.startsWith("B")
        }
    }
}

/*
  def isPublication(number: DocDbId): Boolean = {
    number.country match {
      case "NL" | "CN" => number.kind.startsWith("A") || number.kind.startsWith("B")
      case "JP" | "KR" | "RU" | "GB" => number.kind == "A"
      case cc if isEpcCountry(cc) && number.kind == "T3" => true
      case _ => number.kind.startsWith("A") && number.kind.length >= 2
    }
  }
*/
function isPublication(reference: Reference) {
    if (reference.type === 'docdb' && reference.kind) {
        const {country, kind} = reference
        switch (country) {
            case "NL": case "CN": return kind.startsWith("A") || kind.startsWith("B")
            case "JP": case "KR": case "RU": case "GB": return kind === "A"
            default: return kind.startsWith("A") && kind.length >= 2
        }
    }
}

function isUtilityModel(reference: Reference) {
    if (reference.type === 'docdb' && reference.kind) {
        return reference.kind.startsWith("U")
    }
}

function isValidation(reference: Reference) {
    if (reference.type === 'docdb' && reference.kind) {
        return reference.kind.startsWith("T")
    }
}

type EpoAgent = ReturnType<typeof getAgent>
function getAgent(obj: object) {
    const path = 'inventor-name' in obj ? 'inventor-name' : 'applicant-name'
    const name = obj[path]['name']['$']
    const sequence = obj['@sequence']
    const format = obj['@data-format']
    return {name, sequence, format}
}

function toNice(agents: EpoAgent[]) {
    return agents.filter(a => a.format === 'original').map(a => a.name)
}

// url: https://worldwide.espacenet.com/patent/search?q=pn%20%3D%20%22<NUMBER>%22
// applicants: exchange-document.bibliographic-data.parties.applicants.applicant (obj | arr)
// inventors: exchange-document.bibliographic-data.parties.inventors.inventor (obj | arr)
// title: exchange-document.bibliographic-data.invention-title (obj | arr) -> "$" (max by @lang == de)

function CopyButton({id}) {
    return <button className="btn-secondary" onClick={() => copyTableToClipboard(id)}>Copy</button>
}

async function copyTableToClipboard(id: string) {
    // Get the table element
    const table = document.getElementById(id);
    // @ts-ignore
    const rows = table.rows;
    let tableText = '';

    // Iterate through each row and get the text content
    for (let i = 0; i < rows.length; i++) {
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
            tableText += cells[j].innerText + (j === cells.length - 1 ? '' : '\t');
        }
        tableText += '\n';
    }

    // Copy to clipboard using the Clipboard API
    return navigator.clipboard.writeText(tableText).then(() => {
        //alert('Table content copied to clipboard!');
    }).catch(err => {
        console.error('Error copying to clipboard: ', err);
        //alert('Failed to copy table content.');
    });
}