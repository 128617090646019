import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'

import { supportedCurrencies } from '../data'
import { emptyStringAsUndefined } from '../utils/strings'
import { useAuth } from "../user/Auth"
import Image from "../components/Image"
import { useSingleTeamMangement, useTeamSettings, TeamManagementProvider } from '../Management'
import { LinkedTabCard } from '../components/TabCard'

export default function TeamSettings() {
    const {t} = useTranslation()
    const {team} = useAuth()

    return (
        <>
            <div className="header-row">
                <h2 className='modern-h2'>{t('team')}</h2>
            </div>
            <TeamManagementProvider {...{team}}>
                <div className="main-content bg-pcx-100 pt-2" >
                        <div className='w-fit sm:min-w-2xl'>
                            <LinkedTabCard links={[
                                { to: ".", label: t('team-settings') },
                                { to: "users", label: t('users') },
                                { to: "create", label: t('create-user') },
                                { to: "add", label: t('add-user') }
                            ]} />
                        </div>
                </div>
            </TeamManagementProvider>
        </>
    )
}

export function TeamGeneralSettings() {

    const {t} = useTranslation()
    const { team, realmId } = useSingleTeamMangement()

    return (
        <>
            <h3 className="mb-2">{t('general-information')}</h3>
            <div className="flex sm:flex-row flex-col gap-8 items-start">
                <div className="border-2 border-pc-200 w-48">
                    <Image {...{
                        entity: "realm",
                        entityId: realmId,
                    }} />
                </div>

                {team && <SingleTeamSettings {...{realm: team.realm}} />}
            </div>
        </>
    )
}

export function SingleTeamSettings({realm}) {
    const {t} = useTranslation()

    const {team, updateTeam} = useTeamSettings(realm)

    const initialValues = {
        displayName: team?.displayName ?? '',
        currency: team?.currency ?? 'EUR',
    }

    async function onSubmit(values) {
        const settings = {displayName: emptyStringAsUndefined(values.displayName), currency: values.currency}
        return await updateTeam(settings)
    }

    return (
        <Formik
            {...{ initialValues, onSubmit }}
            enableReinitialize
        >{({ dirty }) =>
            <Form className="flex flex-col gap-4 max-w-sm">
                <label>
                    <span className='label mb-1'>{t('display-name')}</span>
                    <Field name="displayName" className="form-input" placeholder={team?.realm} />
                </label>
                <label>
                    <span className='label mb-1'>{t('currency')}</span>
                    <Field name="currency" as="select" className="form-select">
                        {supportedCurrencies.map(c => <option key={c} value={c}>{c}</option>)}
                    </Field>
                </label>

                <input
                    type="submit"
                    disabled={!dirty} className="btn-primary disabled:btn-disabled w-fit self-end mt-2"
                    value={t('save')}
                />

            </Form>
            }</Formik>
    )
}