import { Outlet} from 'react-router-dom'

import { useBackend } from '../BackendProvider'
import { LoadingModal } from '../components/Modal'

// TODO: make is loading check somewhere global
export default function ProductsMenu() {
    const { isLoading } = useBackend()

    return isLoading ? <LoadingModal /> : <Outlet />
}