import { useTranslation } from "react-i18next";
import { Link, createSearchParams } from "react-router-dom";
import clsx from "clsx";
import _ from 'lodash'

import { Member } from "../patents/patents";
import { CostItem, useCosts } from "./CostsProvider";
import { memberUrl } from "../patents/utils";
import { IconEdit } from "../components/icons";
import DeleteButton from "../components/DeleteButton";
import { family_member } from "../data";
import { SortButton } from "../patents/datawizard/DataWizard";
import { useLocalState } from "../settings/localStorage";
import { usePatents } from "../patents/PatentsProvider";
import { useRoles } from "../user/Auth";
import { useFxContext } from "../forecast/FxProvider";
import { useSingleTeamMangement } from "../Management";


export function FamilyCosts({patentFamilyId}) {
    const {membersByFamilyId} = usePatents()

    const members = membersByFamilyId[patentFamilyId] ?? []

    return <CostsTable {...{members}} />
}

export function MemberCosts({familyMemberId}) {
    const {memberById} = usePatents()

    const members = [memberById[familyMemberId]].filter(Boolean)

    return <CostsTable {...{members}} />
}

const format = new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

function CostsTable({members}: {members: Member[]}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const [sortField, setSortField] = useLocalState('family-costs-sort-field', 'date')
    const [sortOrder, setSortOrder] = useLocalState('family-costs-sort-order', 1)

    const {costsByMemberId, deleteCost} = useCosts()

    //const members = membersByFamilyId[patentFamilyId] ?? []

    //console.log({costsPerMemberId})
    const costs = _(members)
        .flatMap((member: Member) => (costsByMemberId[member.familyMemberId] ?? []).map(c => ({...c, member})))
        .sortBy(c => c[sortField])
        .thru(cs => sortOrder === 1 ? cs : cs.reverse())
        .value()

    const memberIds = members.map(m => m.familyMemberId).filter(Boolean)

    const baseSearch = {entity: family_member, limitedIds: memberIds.join(",")} // `?entity=${family_member}&limitedIds=${memberIds}`

    function Actions(c: CostItem & {member: Member}) {
        return <>
            <Link to={{ pathname: 'cost', search: `?${createSearchParams({ ...baseSearch, costId: '' + c.costId })}` }} className="btn-secondary w-6 h-6 p-0.5">
                <IconEdit />
            </Link>
            <DeleteButton
                className="w-6 h-6 p-0.5 btn-warn-secondary"
                deleteAction={() => deleteCost(c)}
                question={t('really-delete-name', {name: `${c.reference} (${c.member.internalReference})`})}
             />
        </>
    }

    return (
        <div>
            <div className="flex flex-row gap-2 justify-between">
                <h3 id="costs" className="sm:pl-2">{t('costs')}</h3>
                {isEditUser && <Link to={{ pathname: 'cost', search: `?${createSearchParams(baseSearch)}` }} className="btn-secondary text-sm">
                    {t('add-cost')}
                </Link>}
            </div>
            <div className="lg:hidden pt-4">
                {costs.map(cost => 
                    <div key={cost.costId} className="space-y-2 p-2 border-t-2 last:border-b-2 border-pcx-300">
                        <div className="flex flex-row gap-1">
                            <Link 
                                className="text-pcx-600 hover:text-pcx-800 font-medium text-lg mr-auto" 
                                to={memberUrl(cost.member)}>{cost.member.internalReference}</Link>
                            <Actions {...cost} />
                        </div>
                        <CostItemDetails {...{cost, format}} />
                    </div>
                )}
            </div>
            <table className="hidden lg:table">
                <thead className="text-left hidden md:table-header-group">
                    <tr className="border-b-2 border-pcx-200">
                        {[
                            { label: 'date', field: 'date'},
                            { label: 'familyMemberReference', field: 'member.internalReference'},
                            { label: 'amount-excl-vat', field: 'amount'},
                            { label: 'vat', field: 'vat'},
                            { label: 'invoice-number', field: 'reference'},
                            { label: 'cost-center', field: 'costCenter'},
                            { label: 'comment', field: 'comment'},
                        ].map(({label, field}) =>
                            <th key={field}
                                className={clsx(
                                    "md:pl-3 pr-3 pt-3 pb-2 text-pcx-600 text-sm font-semibold uppercase tracking-wider whitespace-nowrap",
                                    field === 'amount' ? 'text-right' : '',
                                )}>
                                {t(label)} <SortButton searchField={field} {...{sortField, sortOrder, setSortField, setSortOrder}} />
                            </th>)}
                            <th/>
                    </tr>
                </thead>
                <tbody>
                    <tr className="hidden only:table-row">
                        <td className="py-2 px-3 text-slate-600" colSpan={5}>
                            {t('no-costs-yet')} {t('click-top-right-to-start')}
                        </td>
                    </tr>
                    {costs.map(c => 
                        <tr key={c.costId}>
                            <td className="py-2 px-3 whitespace-nowrap align-top">{c.date}</td>
                            <td className="py-2 px-3 whitespace-nowrap align-top">
                                <Link className="text-pcx-600 hover:text-pcx-800 font-medium" to={memberUrl(c.member)}>{c.member.internalReference}</Link>
                            </td>
                            <td className="py-2 px-3 text-right whitespace-nowrap align-top">{format.format(c.amount)} {c.currency}</td>
                            <td className="py-2 px-3 text-right whitespace-nowrap align-top">{format.format(c.vat)} {c.currency}</td>
                            <td className="py-2 px-3 whitespace-nowrap align-top">{c.reference}</td>
                            <td className="py-2 px-3 whitespace-nowrap align-top">{c.costCenter ?? '-'}</td>
                            <td className="py-2 px-3 text-slate-600">{c.comment}</td>
                            <td className="flex flex-row gap-1 py-2 px-3">
                                {isEditUser && <Actions {...c} />}
                            </td>
                        </tr>)}
                </tbody>
                <TotalCostsFooter {...{costs}} />
            </table>
        </div>
    )
}

function TotalCostsFooter({costs}: {costs: CostItem[]}) {
    const {t} = useTranslation()

    const {team} = useSingleTeamMangement()
    const {fxConverter} = useFxContext()

    if (costs.length === 0) return null

    const ccy = team?.currency ?? 'EUR'

    const total = _(costs).map(c => fxConverter.convert(c.amount, c.currency, ccy)).sum()
    const vat = _(costs).map(c => fxConverter.convert(c.vat ?? 0.0, c.currency, ccy)).sum()


    return (
        <tfoot>
            <tr className="border-t-2 border-pcx-200">
                <td className="py-2 px-3 whitespace-nowrap text-right text-pcx-600 text-sm font-semibold uppercase tracking-wider">{t('total')}</td>
                <td className="py-2 px-3 whitespace-nowrap text-right"></td>
                <td className="py-2 px-3 whitespace-nowrap text-right">{format.format(total)} {ccy}</td>
                <td className="py-2 px-3 whitespace-nowrap text-right">{format.format(vat)} {ccy}</td>
            </tr>
        </tfoot>
    )
}

export function CostItemDetails({cost, format}: {cost: CostItem, format: Intl.NumberFormat}) {
    const {t} = useTranslation()
    return <>
        <div className="grid grid-cols-[auto_auto] gap-y-1 items-end border-t border-b border-pcx-200 pb-1">
            <div className="label">{t('amount')}:</div>
            <div className="justify-self-end"><span className="text-2xl font-light">{format.format(cost.amount)}</span> {cost.currency}</div>

            <div className="label">{t('vat')}:</div>
            <div className="justify-self-end">{format.format(cost.vat)} {cost.currency}</div>
        </div>
        <div className="text-slate-600 pt-2 flex flex-row justify-between gap-2">
            <div>{cost.reference} @ {cost.date}</div>
            {cost.costCenter && <div className="ribbon text-sm">{cost.costCenter}</div>}
        </div>
        <div className="text-slate-400 text-sm max-w-xs empty:hidden pt-2">
            {cost.comment}
        </div>
    </>
}