import _ from 'lodash'

export function collectFirst<T, R>(xs: T[], f: (x: T) => R | undefined): R | undefined {
    for (const x of xs) {
        const r = f(x)
        if (r !== undefined) return r
    }
    return undefined
}

export function add<T>(xs: T[], x: T) {
    return [...xs, x]
}

export function bulkAdd<T>(xs: T[], ys: T[]) {
    return [...xs, ...ys]
}

export function del<T>(xs: T[], rhs: T, get_lhs = undefined) {
    if (get_lhs === undefined) {
        const filtered = xs.filter(x => !_.isEqual(x, rhs))
        return filtered
    } else {
        const filtered = xs.filter(x => get_lhs(x) !== rhs)
        return filtered
    }
}

export function bulkDel<T>(xs: T[], rhss: T[], get_lhs = undefined) {
    if (get_lhs === undefined) {
        const filtered = xs.filter(x => !_.find(rhss, rhs => _.isEqual(x, rhs)))
        return filtered
    } else {
        const filtered = xs.filter(x => !_.find(rhss, rhs => get_lhs(x) !== rhs))
        return filtered
    }
}

export function update<T>(xs: T[], new_x: T, get_id = (x: T | any) => x.id) {
    const new_id = get_id(new_x)
    const updated = xs.map(x => (get_id(x) === new_id) ? new_x : x)
    return updated
}



// Just for testing right now
export function dragElementTo<T>(xs: T[], drag: T, drop: T) {
    const source = xs.indexOf(drag)
    const target = xs.indexOf(drop)
    return dragIndexTo(xs, source, target)
}

export function dragIndexTo<T>(xs: T[], source: number, target: number) {
    const drag = xs[source]
    if (target < source) {
        return [...xs.slice(0, target), drag, ...xs.slice(target, source), ...xs.slice(source + 1)]
    } else {
        return [...xs.slice(0, source), ...xs.slice(source + 1, target), drag, ...xs.slice(target)]
    }
}