import kanji2roman from '../utils/kanji'
import ToolTip from './ToolTip'

export default function KanjiTip({kanjiOpt, children}: {kanjiOpt?: string, children?: JSX.Element}) {
    const trans = kanjiOpt && kanji2roman(kanjiOpt)
    //console.log({kanjiOpt, trans, children})
    if (trans)
        return <ToolTip className='capitalize' anchor={children}>{trans}</ToolTip>
    else 
        return children || null
}