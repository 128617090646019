import { Menu } from '@headlessui/react'

import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import { EditorEvents, useCurrentEditor, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import Highlight from '@tiptap/extension-highlight'

import clsx from "clsx";

import { AiOutlineFontColors } from "react-icons/ai";
import { FaRemoveFormat, FaSuperscript, FaSubscript, FaHighlighter, FaBold, FaItalic, FaUnderline, FaStrikethrough, FaUndo, FaRedo, FaListOl, FaListUl } from "react-icons/fa";
import Underline from '@tiptap/extension-underline'

import './editor.css'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'

// TODO: add 
// [ ] "code view"
// [x] "clear formatting"
//   https://tiptap.dev/api/commands/reset-attributes
//   https://tiptap.dev/api/commands/unset-all-marks
// [x] font color
// [ ] bg color
// [x] upper case
// [x] lower case
// [x] copying
// [x] command hint

//function MenuBar() {
//    const { editor } = useCurrentEditor()
function MenuBar({editor}) {
    const { t } = useTranslation()

    if (!editor) {
        return null
    }

    const buttons = [
        [
            {
                onClick: () => editor.chain().focus().undo().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run(),
                icon: FaUndo,
                hint: 'editor.undo',
            },
            {
                onClick: () => editor.chain().focus().redo().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .redo()
                    .run(),
                icon: FaRedo,
                hint: 'editor.redo',
            },
        ], [
            {
                onClick: () => editor.chain().focus().toggleBold().run(),
                disabled: !editor.can().chain().focus().toggleBold().run(),
                isActive: editor.isActive('bold'),
                icon: FaBold,
                hint: 'editor.bold',
            },
            {
                onClick: () => editor.chain().focus().toggleUnderline().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleUnderline()
                    .run(),
                isActive: editor.isActive('underline'),
                icon: FaUnderline,
                hint: 'editor.underline',
            },
            {
                onClick: () => editor.chain().focus().toggleItalic().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run(),
                isActive: editor.isActive('italic'),
                icon: FaItalic,
                hint: 'editor.italic',
            },
            {
                onClick: () => editor.chain().focus().toggleStrike().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run(),
                isActive: editor.isActive('strike'),
                icon: FaStrikethrough,
                hint: 'editor.strike',
            },
            {
                onClick: () => editor.chain().focus().toggleSubscript().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleSubscript()
                    .run(),
                isActive: editor.isActive('subscript'),
                icon: FaSubscript,
                hint: 'editor.subscript',
            },
            {
                onClick: () => editor.chain().focus().toggleSuperscript().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleSuperscript()
                    .run(),
                isActive: editor.isActive('superscript'),
                icon: FaSuperscript,
                hint: 'editor.superscript',
            },],
        [
            {
                onClick: () => editor.chain().focus().toggleBulletList().run(),
                isActive: editor.isActive('bulletList'),
                icon: FaListUl,
                hint: 'editor.bulletList',
            },
            {
                onClick: () => editor.chain().focus().toggleOrderedList().run(),
                isActive: editor.isActive('orderedList'),
                icon: FaListOl,
                hint: 'editor.orderedList',
            },
        ],
        [
            {
                button: FontColorButton,
            },
            {
                onClick: () => editor.chain().focus().toggleHighlight().run(),
                disabled: !editor.can()
                    .chain()
                    .focus()
                    .toggleHighlight()
                    .run(),
                isActive: editor.isActive('highlight'),
                icon: FaHighlighter,
                hint: 'editor.highlight',
            },
        ],
        [
            {
                onClick: () => editor.chain().focus().unsetAllMarks().run(),
                icon: FaRemoveFormat,
                hint: 'editor.removeFormat',
            }
        ]
    ]

    return (
        <div className='flex flex-row flex-wrap gap-x-2 gap-y-1 '>
            {/* TODO: spacing between groups of buttons */}
            {buttons.map((group, bi) =>
                <div key={`group-${bi}`} className=''> {group.map((button, i: number) => {
                    if (button.button) {
                        return <button.button key={i} />
                    } else {
                        const { onClick, disabled, isActive, icon: Icon, hint } = button
                        return (
                            <button
                                key={i} type='button'
                                onClick={onClick} disabled={disabled ?? false}
                                className={clsx('btn bg-white hover:bg-pcx-200', isActive ? 'is-active' : '')}
                                title={hint && t(hint)}
                            >
                                <Icon />
                            </button>
                        )
                    }
                })
                }</div>
            )}
        </div>
    )
}

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    Highlight.configure({ multicolor: true }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
    }),
    Underline,
    Subscript,
    Superscript,
    TextStyle
]


export function PureEditor({content, onUpdate}: {content: string, onUpdate: (props: EditorEvents['update']) => void}) {
    const ref = useRef()
    
    const editor = useEditor({
        content, extensions, onUpdate,
        editorProps: {
            attributes: {
                class: 'overflow-auto rounded sm:h-full min-h-0 h-28 p-2 border border-slate-400 focus:ring-0 focus:outline-none',
            }
        }
    })

    useEffect(() => {
        // @ts-ignore
        if (editor !== null && ref?.current && editor.options.element && editor?.view?.docView) {
            //console.log('effect')
            if (editor.contentComponent) {
                return
              }
            // @ts-ignore
            ref.current.append(...editor.options.element.childNodes)
            editor.setOptions({
                element: ref.current,
            })
            editor.contentComponent = this
            editor.createNodeViews()
        }
    }, [editor])

    return (
        <div ref={ref} className='h-full flex flex-col gap-1'>
            <MenuBar editor={editor} />
        </div>
    )
}

export default function Editor({ name }) {
    const [, meta, helpers] = useField(name)
    const { setValue } = helpers
    const { value } = meta

    return <PureEditor content={value} onUpdate={({editor}) => setValue(editor.getHTML()) } />
}

const colors = [
    '#ff0000', '#ff5e00', '#ffe400', '#abf200', '#00d8ff', '#0055ff', '#6600ff', '#ff00dd', '#000000',
    '#ffd8d8', '#fae0d4', '#faf4c0', '#e4f7ba', '#d4f4fa', '#d9e5ff', '#e8d9ff', '#ffd9fa', '#f1f1f1',
    '#ffa7a7', '#ffc19e', '#faed7d', '#cef279', '#b2ebf4', '#b2ccff', '#d1b2ff', '#ffb2f5', '#bdbdbd',
    '#f15f5f', '#f29661', '#e5d85c', '#bce55c', '#5cd1e5', '#6699ff', '#a366ff', '#f261df', '#8c8c8c',
    '#980000', '#993800', '#998a00', '#6b9900', '#008299', '#003399', '#3d0099', '#990085', '#353535',
    '#670000', '#662500', '#665c00', '#476600', '#005766', '#002266', '#290066', '#660058', '#222222'
]

function FontColorButton() {
    const {editor} = useCurrentEditor()
    return (
        <Menu as="div" className="relative inline-block">
            <div>
            <Menu.Button className='btn hover:bg-pcx-200'><AiOutlineFontColors /></Menu.Button>
            </div>
            <Menu.Items className="absolute shadow-lg border border-pcx-500 rounded mt-1 left-0 w-52 z-20 py-1 bg-white">
                <div className='grid grid-cols-9'>
                    {colors.map(color =>
                            <Menu.Item key={color}>
                                <button 
                                    type="button" 
                                    className='block w-6 h-6 shrink-0' style={{ backgroundColor: color }}
                                    onClick={() => editor.chain().focus().setColor(color).run()}
                                ></button>
                            </Menu.Item>)}
                </div>
                <div className='flex flex-row-reverse px-2 mt-2'>
                    <Menu.Item>
                        <button 
                            type="button" 
                            className='btn hover:bg-pcx-200' 
                            onClick={() => editor.chain().focus().unsetColor().run()}
                        ><FaRemoveFormat /></button>
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    )
}