import { useState, useEffect } from 'react'
import { Formik, Field, Form } from "formik";
import { Navigate, useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next"
import _ from 'lodash'

import { useAuth } from "./Auth";
import { IconLoad } from "../components/icons";
import { confirmToken } from "../backend";
import { useMessages } from '../Messages';
import { validateUsername } from './utils';

/* CURRENT SIGNUP PROCESS

- User goes to /signup
- User fills in details. 
- Request is sent to /auth/signup
- User Login and Settings are added
- Verification Email is sent out
- Back to UI: redirect to /signup/waiting 
- User is informed about mail in his inbox
- User click on link; this goes to the UI /signup/confirm/{token}
- {token} is extracted and used for GET to /auth/cornfirm/{token}
- If error, it is displayed and user should contact support
- If good, user is redirected to /teams/signup
- Enter Name for new Team
- If good, user is redirected to /; DONE
- Else Enter new Name and try again.
*/

const labelStyle = "text-gray-700 text-sm mt-1"

export default function SignUp() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const {signup} = useAuth()

    // TODO: readquery params for indication of price etc.
    // TODO: when navigating to Team Signup, pass params as a state variable

    // TODO
    function doSignUp(req) {
        // TODO: either ok with cookie set or error to be reported
        //return Promise.reject({status: "fail", message: "User already exists!"})
        return signup(req)
    }

    // TODO: error reporting from server
    // TODO: add hidden dummy checkbox
    return (
        <div>
            <h2 className="text-center">{t('sign-up')}</h2>
            <div className="text-center text-slate-400 pt-2 text-sm">{t('first-3-ip-families-free')}</div>
            <Formik
                initialValues={{
                    email: "",
                    username: "",
                    password: "",
                    tcAccepted: false,
                }}
                onSubmit={(signupRequest, {setStatus}) => {
                    // Block competition
                    if (signupRequest.email.toLocaleLowerCase().trim().endsWith("scambia.ch"))
                        setStatus("There was an issue. Please contact Support")
                    else
                        doSignUp(signupRequest)
                            .then(() => navigate(`/signup/waiting?email=${signupRequest.email}`))
                            .catch(({message}) => setStatus(message))
                }}
                validate={({email, username, password, tcAccepted}) => {
                    const errors = {}
                    if (typeof email !== "string" || email === "")
                        errors.email = t('email-error')
                    else if (typeof username !== "string" || username === "")
                        errors.username = t('user-error')
                    else if (typeof password !== "string" || password.length < 8)
                        errors.password = t('password-error')
                    else if (!tcAccepted)
                        errors.tcAccepted = t('tc-error')
                    else if (!validateUsername(username))
                        errors.username = t('use-usernames')

                    return errors
                }}
            >{({values: {tcAccepted}, isValid, errors, touched, status}) => {
                const disabled = !tcAccepted || !isValid
                const validErrors = Object.entries(errors).filter(([key]) => touched[key])
                return (
                    <Form className="pt-2 flex flex-col gap-2">
                        <label className={labelStyle} htmlFor="email">{t('email')}</label>
                        <Field className="form-input" id="email" name="email" type="email" autoFocus required />

                        <label className={labelStyle} htmlFor="username">{t('username')}</label>
                        <Field className="form-input" id="username" name="username" required pattern="^[a-zA-Z0-9-_\.]{3,64}$" title={t('only-use-asci')} />

                        <label className={labelStyle} htmlFor="password">{t('password')}</label>
                        <Field className="form-input" id="password" name="password" type="password" required />

                        <label className={labelStyle + " inline-flex items-center"} htmlFor="tcAccepted">
                            <Field className="form-checkbox" id="tcAccepted" name="tcAccepted" type="checkbox" />
                            <span className="pl-2">
                                <Trans i18nKey="accept-tc" 
                                // eslint-disable-next-line
                                    components={{tclink: <a
                                        className="underline decoration-gray-700"
                                        href="https://patent-cockpit.com/terms"
                                        target="_blank" rel="noopener noreferrer"
                                        ></a>
                                    }} />
                            </span>
                        </label>

                        <button
                            type="submit"
                            disabled={disabled}
                            className={`${disabled ? "btn-disabled" : "btn-primary"} py-2 mt-2 text-lg`}
                        >{t('sign-up')}</button>

                        {(!_.isEmpty(validErrors) || status) &&
                            <div className="border border-red-500 p-2 flex flex-col gap-1">
                                {validErrors.map(([key, msg]) => <div key={key}>{t(key)}: {msg}</div>)}
                                {status && <div>{status}</div>}
                            </div>
                        }

                    </Form>
                )
            }}
            </Formik>
        </div>
    )
}

export function WaitingForConfirmation() {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email")
    return (
        <div className="flex flex-col gap-2 max-w-sm font-light">
            <h2 className="pb-2">{t('waiting-email-confirmation')}</h2>
            <div>
                <Trans i18nKey="email-sent-to" components={{email: <span className="whitespace-nowrap"/>}} values={{email}} />
            </div>
            <div>
                {t('check-inbox')}
            </div>
        </div>
    )
}

export function WaitingForApproval() {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-2 max-w-sm">
            <h2 className="text-center pb-2">{t('waiting-team-approval')}</h2>
            <div className="py-2 font-semibold text-center">
                {t('thanks-signup')}
            </div>
            <div className="text-center">
                {t('respond-in-24h')}
            </div>
            <div className="text-center text-4xl">
                &#x1F64F;
            </div>
        </div>
    )
}

export function Confirmation({forwardUrl = "/teams/signup"}) {
    const { t } = useTranslation()
    const {token} = useParams()

    const [verified, setVerified] = useState(false)
    const [message, setMessage] = useState(undefined)

    useEffect(() => {
        if (!verified && token) {
            confirmToken(token)
                .then(() => setVerified(true))
                .catch(({message}) => setMessage(message))
        }
    }, [verified, token])
    
    return (
        <div className="text-center">
            <h2 className="pb-2">{t('waiting-email-verification')}</h2>
            {verified 
                ? <Navigate to={forwardUrl} /> 
                : message === undefined 
                ? <IconLoad />
                : <div>
                    <p className="text-red-700 mb-2">{message}</p>
                    <p><Trans i18nKey='please-contact-support' 
                        components={{ email: <a className="underline" href="mailto:support@patent-cockpit.com">Support</a> }} />
                    </p>
                    <p><Trans i18nKey='or-try-login' 
                        components={{ loginlink: <Link to="/login" className="underline" /> }} />
                    </p>
                </div>
            }
        </div>
    )
}

export function TeamSignup() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { signupTeam } = useAuth()

    return (
        <div>
            <h2 className="text-center">{t('create-team')}</h2>
            <Formik
                initialValues={{teamName: ""}}
                onSubmit={({teamName}, {setStatus}) =>
                    signupTeam({teamName})
                        .then(() => navigate("/teams/waiting"))
                        .catch(({message}) => setStatus(message))
                }
                validate={({teamName}) => {
                    const errors = {}
                    if (!validateUsername(teamName))
                        errors['teamName'] = t('use-usernames')
                        
                    return errors
                }}
            >{({ values: { teamName }, status, touched, errors }) => {
                return (
                    <Form className="flex flex-col gap-2 pt-2">
                        <label className={labelStyle} htmlFor="teamName">{t('team-name')}</label>
                        <Field className="form-input" id="teamName" name="teamName" required />
                        {touched.teamName && errors.teamName && <div className="text-red-700">{errors.teamName}</div>}

                        <button
                            className={`${teamName === "" ? "btn-disabled" : "btn-primary"} py-2 mt-2 text-lg`}
                            disabled={teamName === ""}
                            type="submit"
                        >
                            {t('create')}
                        </button>

                        {status &&
                            <div className="border border-red-500 p-2 flex flex-col gap-1">
                                <div>{status}</div>
                            </div>
                        }
                    </Form>
                )
            }}</Formik>
        </div>
    )
}

export function TeamLogin() {
    const { t } = useTranslation()
    const { setErrorMessage } = useMessages()
    const navigate = useNavigate();

    const {signin} = useAuth()

    const fieldStyle = "px-1 border border-1 border-gray-200 font-light mt-1 w-full h-8"
    const labelStyle = "text-gray-700 text-sm"

    return (
        <div>
            <h2 className="text-center">{t('login')}</h2>
            <Formik initialValues={{ user: '', password: '' }}
                onSubmit={({ user, password }) =>
                    signin(user, password)
                        .then(() => { setErrorMessage(undefined); navigate("/teams/signup") })
                        .catch(err => { console.log(err); setErrorMessage(err.message) })
                }
            >{({touched}) => 
                <Form className="flex flex-col gap-4">
                    <label className={labelStyle}>
                        {t('user')}
                        <Field name="user" className={fieldStyle} required autoFocus />
                    </label>

                    <label className={labelStyle} htmlFor="password">{t('password')}
                        <Field name="password" className={fieldStyle} type="password" required />
                    </label>

                    <input 
                        disabled={!(touched.user && touched.password)} 
                        className="btn-primary disabled:btn-disabled text-xl" 
                        type="submit" 
                        value={t("login")}
                    />
                </Form>
            }</Formik>
        </div>
    )
}