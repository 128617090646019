import { useTranslation } from "react-i18next"
import { PauseCircleIcon, PlayCircleIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../../user/Auth"
import { usePatents } from "../../patents/PatentsProvider"
import { Link, useNavigate, useParams } from "react-router-dom"
import { MaintenanceAction, useDennemeyer, useMaintenance, useTimeline } from "../DennemeyerProvider"
import Modal from "../../components/Modal"
import { useEffect, useState } from "react"
import clsx from "clsx"
import { Instruction } from "../renewals"
import { RadioGroup, Radio, Label, Field } from "@headlessui/react";
import { memberUrl } from "../../patents/utils";
import { emptyStringAsUndefined } from "../../utils/strings";
import { family_member } from "../../data";
import { useComments } from "../../comments/CommentsProvider";
import { post_instructions } from "../dennemeyer_backend";
import { useIpRightImport } from "../import/PaymentHandlingProvider";

export function InstructionModal() {
    const { t } = useTranslation()
    const {user: {name: user}} = useAuth()

    const navigate = useNavigate()
    const { id } = useParams()

    const {memberById, familyById, postMember} = usePatents()
    const {ipRightByDennemeyerId, owners, invalidateIpRights, instructionByDennemeyerId, postInstruction, deleteInstruction} = useDennemeyer()
    const {addComment, commentById} = useComments()
    const {calculateDueDates} = useTimeline()

    const {maintenance} = useMaintenance(id) // maintenance can be undefined

    const ipRight = ipRightByDennemeyerId[maintenance?.IpRightInfo?.DennemeyerId]
    const member = memberById[ipRight?.familyMemberId]
    const family = familyById[member?.patentFamilyId]

    const [newInstruction, setNewInstruction] = useState(undefined)
    const [alsoStopWithPc, setAlsoStopWithPc] = useState(true)
    const [comment, setComment] = useState('')

    const {postIpRight, initial} = useIpRightImport(member.familyMemberId, owners[0]?.ownerId)

    const instruction = instructionByDennemeyerId[maintenance?.DennemeyerId]
    useEffect(() => {
        if (!newInstruction && instruction) {
            setNewInstruction(instruction.instruction)
        }
    }, [instruction, setNewInstruction, newInstruction])

    const oldComment = commentById[instruction?.commentId]
    useEffect(() => {
        if (oldComment && comment === '') {
            setComment(oldComment.comment)
        }
    }, [oldComment, comment])

    if (member === undefined || maintenance === undefined) return null

    async function takeBackInstruction() {
        if (instruction) {
            await deleteInstruction(instruction)
            setNewInstruction(undefined)
        }
    }

    const {status} = calculateDueDates(maintenance.DueDate)

    const isInstructed = instruction !== undefined

    const {isPermanentOrder, isPayTooLate, isCancelTooLate, tooLate} = isTooLate({maintenance, status})

    const cannotChangeDecision = (instruction?.instruction === 'Pay' && !isPermanentOrder) || (instruction?.instruction === 'Cancel' && isPermanentOrder)

    const disabled = 
        newInstruction === undefined ||
        newInstruction === instruction?.instruction ||
        (newInstruction === 'Cancel' && isCancelTooLate) ||
        (newInstruction === 'Pay' && isPayTooLate)

    const actions: SelectionActionProps[] = [
        { 
            icon:  <PlayCircleIcon className="h-8 w-8" />,
            label: t('ip-right-pay'), value: 'Pay' as Instruction,
            comment: isPayTooLate ? <MailWarning {...{instruction: 'Pay', maintenanceAction: maintenance}}/> : t('ip-right-pay-comment'),
            indication: isPayTooLate ? 'disabled' : 'pay',
        },
        { 
            icon: <PauseCircleIcon className="h-8 w-8" />, 
            label: t('ip-right-cancel'), value: 'Cancel' as Instruction,
            comment: isCancelTooLate ? <MailWarning {...{instruction: 'Cancel', maintenanceAction: maintenance}}/> : t('ip-right-cancel-comment'),
            indication: isCancelTooLate ? 'disabled' : 'cancel',
            addon: !isCancelTooLate &&
                <label className="flex flex-row gap-2 items-center mt-2">
                    <input type="checkbox" className={clsx(
                        "form-checkbox bg-white/50",
                        newInstruction !== 'Cancel' 
                            ? 'checked:bg-slate-300' 
                            : 'checked:bg-white/20 focus:checked:bg-white/20 hover:checked:bg-white/30'
                    )} checked={alsoStopWithPc} disabled={cannotChangeDecision} onChange={e => setAlsoStopWithPc(e.target.checked)} />
                    <div className="text-sm">{t('stop-pc-case')}</div>
                </label>,
         },
    ]

    async function handleInstruction() {
        // save comment
        // save instruction (with comment id) in PC
        // if pay && !permanentOrder, send PAY instruction
        // or if cancel && permanentOrder, send CANCEL instruction
        // if cancel, stop with DM, 
        // is also stop with PC, stop with PC
        if (!disabled) { // just to be sure
            let commentId = undefined
            if (emptyStringAsUndefined(comment) !== undefined) {
                const result = await addComment({
                    entity: family_member,
                    entityId: ipRight.familyMemberId,
                    comment: `${newInstruction} - ${comment}`,
                    user,
                    created: new Date().toISOString()
                })
                commentId = result?.commentId
            }
            await postInstruction({dennemeyerId: maintenance.DennemeyerId, instruction: newInstruction, ipRightId: ipRight.ipRightId, commentId})

            if (newInstruction === 'Pay' && !isPermanentOrder || newInstruction === 'Cancel' && isPermanentOrder) {
                await post_instructions([
                    { DennemeyerId: maintenance.DennemeyerId, Instruction: newInstruction }
                ])
            }
            if (newInstruction === 'Cancel') {
                // stop with DM
                postIpRight({...initial, status: 'Inactive'})
                if (alsoStopWithPc && member) {
                    await postMember({...member, familyMemberStatus: "stopped"})
                }
            }
            invalidateIpRights()
            navigate(-1)
        }
    }

    // TODO
    // console.log({maintenance, status, isInstructed, tooLate, b: isInstructed && !tooLate})
    return (
        // TODO: navigate only if there is no decision
        <Modal escAction={() => navigate(-1)}>
            <div className="p-4 space-y-4">
                <h3 className="grow min-w-0 whitespace-nowrap text-ellipsis overflow-hidden max-w-2xl">
                    <Link to={memberUrl(member)}>{t('instruct-name', {
                        name: emptyStringAsUndefined(family.familyName)
                            ? `${member.internalReference} - ${family.familyName}`
                            : member.internalReference
                    })}</Link>
                </h3>
                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
                    <SelectDecision {...{value: newInstruction, setValue: setNewInstruction, actions, disabled: cannotChangeDecision}} />
                    <div className="max-w-md h-full flex flex-col gap-2">
                        <label htmlFor="comment" className="label">{t('comment')} <span className="text-slate-400">({t('optional')})</span></label>
                        <textarea id="comment" disabled={cannotChangeDecision} className="w-full form-textarea border-gray-300 grow" value={comment} onChange={e => setComment(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="p-4 bg-pcx-200 flex flex-col sm:flex-row-reverse gap-4">
                {isInstructed && !tooLate
                    ? <button type="button" disabled={cannotChangeDecision} className="btn-secondary disabled:btn-disabled" onClick={takeBackInstruction}>{t('ip-right-undecide')}</button>
                    : <button type="submit" className="btn-primary disabled:btn-disabled" onClick={handleInstruction} disabled={disabled}>
                        {t('instruct')}
                    </button>}
                {/* @ts-ignore */}
                <Link to={-1} className="btn-tertiary mx-auto sm:mx-0">{t('back')}</Link>
            </div>
        </Modal>
    )
}

export function isTooLate({maintenance, status}: {maintenance: MaintenanceAction, status: string}) {
    const isPermanentOrder = maintenance?.PermanentOrder ?? false
    const tooLate = status === 'manual-instruction' || status === 'too-late'

    const isPayTooLate = tooLate && !isPermanentOrder
    const isCancelTooLate = tooLate && isPermanentOrder

    return {isPermanentOrder, tooLate, isPayTooLate, isCancelTooLate}
}

function MailWarning({instruction, maintenanceAction}: {instruction: Instruction, maintenanceAction: MaintenanceAction}) {
    return (
        <span>
            There is not enough time to send {instruction} instruction.
            Please write an email to <ManualEmailLink {...{maintenanceAction}}/>.
        </span>
    )
}

const dm_email = 'taxes@dennemeyer.com'

function emailSubject(maintenance: MaintenanceAction) {
    const uck = maintenance.IpRightInfo?.UniqueCaseKey
    const reference = maintenance.IpRightInfo?.CustomerReference
    const s = [uck, reference].filter(s => s !== undefined).join(' / ')

    const subject = `Late Fee${s.length > 0 ? ':' : ''} ${s}`
    return `Subject=${encodeURIComponent(subject)}`
}

function ManualEmailLink({maintenanceAction, className = undefined}: {maintenanceAction: MaintenanceAction, className?: string}) {
    return (
        <a 
            href={`mailto:${dm_email}?${emailSubject(maintenanceAction)}`}
            className={className}
        >
            {dm_email}
        </a>
    )
}

interface SelectionActionProps {
    label: string,
    value: Instruction,
    comment: React.ReactNode,
    icon: React.ReactNode,
    indication: Indication,
    addon?: React.ReactNode,
}

type Indication = 'pay' | 'cancel' | 'disabled'


function SelectDecision(
    { value, setValue, actions, disabled=false }:
    { value: Instruction, setValue: (action: Instruction) => void, actions: SelectionActionProps[], disabled?: boolean }
) {
    const { t } = useTranslation()

    return (
        <Field>
            <Label className="label mb-2">{t('take-decision')}</Label>
            <RadioGroup {...{disabled, value, onChange: setValue}} className='flex flex-col gap-2'>
                {actions.map((action) =>
                    <Radio key={action.value} value={action.value} >{({ checked: isSelected, hover: isHovered }) => {
                        const { label, comment, indication, icon, addon } = action
                        return (
                            <div title={action.value} className={clsx(
                                'py-2 px-4 rounded shadow-md md:max-w-md cursor-pointer flex flex-row gap-6 items-center border border-slate-200',
                                ...selectionColors(isSelected, isHovered, indication),
                            )}>
                                <div className={clsx(!isSelected && !isHovered && "text-slate-300")}>
                                    {icon}
                                </div>
                                <div>
                                    <p className={clsx("font-semibold mb-px")}>{label}</p>
                                    <p className={clsx("text-sm pt-0 opacity-80 mb-0")}>{comment}</p>
                                    {addon}
                                </div>
                            </div>
                        )
                    }}</Radio>
                )}
            </RadioGroup>
        </Field>
    )
}

function selectionColors(isSelected: boolean, isHovered: boolean, indication?: Indication) {
    //console.log({indication})
    if (!isSelected && !isHovered)
        return ['bg-white text-slate-700']
    switch (indication) {
        case 'cancel':
            return [
                isHovered && 'text-slate-100 bg-warn-500 border-warn-500',
                isSelected && !isHovered && 'text-slate-100 bg-warn-600 border-warn-600',
            ]
        case 'pay':
            return [
                isHovered && 'text-slate-100 bg-green-500 border-green-500',
                isSelected && !isHovered && 'text-slate-100 bg-green-600 border-green-600',
            ]
        case 'disabled':
            return [
                'text-slate-800',
                isHovered && 'bg-slate-200 border-slate-200',
                !isHovered && 'bg-slate-300 border-slate-300',
            ]
        default:
            return [
                isHovered && 'text-slate-100 bg-pcx-500 border-pcx-500',
                isSelected && !isHovered && 'text-slate-100 bg-pcx-600 border-pcx-600',
            ]
    }
}