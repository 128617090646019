import { useState } from 'react'
import { useBackend } from '../BackendProvider'

import Modal from '../components/Modal'

function DeletableImage({image, entityOperation}) {

    const loadImages = () => entityOperation("image", "get")

    const [hovering, setHovering] = useState(false)
    const [showPopup, setShowPopup] = useState(false)

    function DeleteModal() {
        return (
            <Modal blurClick={() => {setShowPopup(false); setHovering(false)}}>
                <div className="p-3">
                    <h5>Do you really want to delete the image?</h5>
                    <div className="flex flex-row pt-2 space-x-2 justify-end">
                        <button className="btn-secondary" onClick={() => {setShowPopup(false); setHovering(false)}}>Cancel</button>
                        <button className="btn-primary" onClick={() => {
                            console.log(image.imageId)
                            entityOperation("image", "delete", image.imageId).then(() => {
                                setShowPopup(false); 
                                setHovering(false)
                                loadImages()
                            })
                        }}>Delete</button>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            className={"relative h-56 w-48 flex flex-col border-2 border-pc-300" + ((hovering || showPopup) ? "relative" : "")}>
            <img className="justify-center max-h-48 my-auto mx-auto p-1" src={image.url} alt={"Patent " + image.imageId}/>
            {(hovering || showPopup) &&
                <div className="flex place-content-center bg-white/30 backdrop-blur-sm absolute h-full w-full bottom-0 left-0">
                    <button onClick={() => setShowPopup(true)} className="btn-primary self-center">Delete</button>
                </div>
            }
            <div className="text-center h-8 w-full truncate">{image.originalFilename}</div>
            {showPopup && <DeleteModal />}
        </div>
    )

}

export default function ImageAdmin() {

    const {images, entityOperation} = useBackend()

    const deletableImages = images.filter(i => i.entityId === undefined)

    return (
        <div className="py-2">
            <h3>Images</h3>
            <h4 className="mt-2">Unlinked Images</h4>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-1">
                {deletableImages.map(image => <DeletableImage key={image.imageId} {...{image, entityOperation}}/>)}
            </div>
        </div>
    )
}