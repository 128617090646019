import { parseBackend } from "../../backend";

export interface Event {
    eventId?: number;
    created?: Date;
    user: string;
    realm?: string;
    eventType: string;
    payload?: string;
}

export function postEvent(event: Event) {
    return parseBackend(fetch("/api/events", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(event)
    }))
}

export function getEvents() {
    return parseBackend(fetch("/api/events"))
}