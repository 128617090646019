import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import _ from 'lodash'

import { getEvents, Event } from './'
import { useLocalState } from "../localStorage"
import clsx from "clsx"

const ourNames = ['noah', 'bas', 'dev-ops-1', 'admin']

export default function Events() {
    const [searchText, setSearchText] = useState("")
    const [showUs, setShowUs] = useLocalState('admin-show-us', false)

    const {data: events} = useQuery<Event[]>({queryKey: ['events'], queryFn: getEvents, initialData: []})

    const fields = [
        'user', 'realm', 'eventType', 'created', 
        //'payload'
    ]

    const classNames = {
        'created': 'tabular-nums',
        'payload': 'max-w-xs truncate text-xs',
    }

    const stlc = searchText.toLowerCase()
    const displayEvents = _(searchText === "" ? events : events.filter(e => JSON.stringify(e).toLowerCase().includes(stlc)))
        .filter(e => showUs || !ourNames.includes(e.user))
        .slice(0, 1000)
        .value()

    return <>
    <div>
        <input type="search" value={searchText} onChange={e => setSearchText(e.target.value)} className="form-input mb-4" placeholder="filter"/>
        <label className="ml-4">
            <input type="checkbox" checked={showUs} onChange={e => setShowUs(e.target.checked)} className="mr-2"/>
            <span>Show us ({ourNames.join(', ')})</span>
        </label>
    </div>
        <table>
            <thead>
                <tr className="border-b-2 border-pcx-300">
                    {fields.map(f => <th key={f} className="capitalize text-left text-pcx-600 pr-4">{f}</th>)}
                </tr>
            </thead>
            <tbody>
                {displayEvents.map((event, i) => (
                    <tr key={i} className="border-b border-pcx-200">
                        {fields.map(f => <td key={f} title={f === 'payload' && event[f]} className={clsx("pr-4", classNames[f] ?? '')}>{event[f]}</td>)}
                        <td title={"Click to copy to clipboard: " + event.payload} className="pr-4 max-w-xs truncate text-xs">
                            <button onClick={async () => await navigator.clipboard.writeText(event.payload)}>
                                {event.payload}
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </>
}